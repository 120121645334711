export default {
  env: process.env.REACT_APP_ENV,
  api: {
    BASE_URL: process.env.REACT_APP_API_BASE_URL
  },
  aws: {
    REGION: process.env.REACT_APP_REGION
  },
  cognito: {
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    COGNITO_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
    COGNITO_CALLBACK_URL: process.env.REACT_APP_COGNITO_CALLBACK_URL
  },
  s3: {
    PUBLIC_BUCKET: process.env.REACT_APP_S3_PUBLIC_BUCKET
  },
  stripe: {
    PUBLISHABLE_KEY: process.env.REACT_APP_PUBLISHABLE_KEY,
    DEFAULT_TAX_RATE: process.env.REACT_APP_DEFAULT_TAX_RATE
  },
  professionalDeputyEmails:
    process.env.REACT_APP_DEFAULT_DEPUTY_EMAILS.split(','),
  tinyMce: {
    API_KEY: process.env.REACT_APP_TINY_API_KEY
  },
  ga: {
    TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID
  },
  optimize: {
    LAYOUT_EXP_ID: process.env.REACT_APP_LAYOUT_EXP_ID
  },
  appSync: {
    GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
    REGION: process.env.REACT_APP_APPSYNC_REGION,
    AUTHENTICATION_TYPE: process.env.REACT_APP_AUTHENTICATION_TYPE,
    API_KEY: process.env.REACT_APP_API_KEY
  }
}

import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Table, Empty } from 'antd'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'
import { ThemeContext } from 'styled-components'
import { useMediaQuery } from '@material-ui/core'
import api from '../../lib/api'

export default function GroupOwner(props) {
  const { mainUserId } = props

  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const [groupOwner, setGroupOwner] = useState([])
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  useEffect(() => {
    const fetchGroupHolder = async mainUserId => {
      const response = await api.getGroupOwner(user.username)
      response.data.body
        ? setGroupOwner(JSON.parse(response.data.body))
        : setGroupOwner([])
    }

    if (mainUserId) {
      fetchGroupHolder(mainUserId)
    }
  }, [mainUserId, user])

  const groupOwnerColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('Email')
    },
    {
      key: 'fullName',
      title: t('NAME'),
      render: record => record.fullName
    }
  ]

  return (
    <Fragment>
      <SimpleHeader title={<H4>{t('GROUP_OWNER')}</H4>} size="small" />
      <Table
        dataSource={[groupOwner]}
        columns={groupOwnerColumns}
        rowKey="email"
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('Empty')}
            />
          )
        }}
        size={isMdUp ? '' : 'middle'}
      />
    </Fragment>
  )
}

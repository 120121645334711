import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  passwords: [],
  deletedPasswords: [],
  activePasswords: [],
  pendingPasswords: [],
  rejectedPasswords: [],
  passwordTags: [],
  pendingPasswordTags: [],
  isLoading: false,
  isPendingLoading: false,
  error: null,
  pendingError: null
}

const otherPasswords = createSlice({
  name: 'otherPasswords',
  initialState,
  reducers: {
    getOtherPasswordsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherPasswordsSuccess(state, action) {
      const { passwords } = action.payload
      state.passwords = passwords
      state.deletedPasswords = passwords.filter(item => item.deleted)
      state.activePasswords = passwords.filter(item => !item.deleted)
      state.passwordTags = passwords
        .filter(item => !item.deleted && !!item.tags?.length)
        .map(item => item.tags)
      state.isLoading = false
      state.error = null
    },
    getOtherPasswordsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getOtherPendingPasswordsStart(state) {
      state.isPendingLoading = true
      state.pendingError = null
    },
    getOtherPendingPasswordsSuccess(state, action) {
      const { passwords } = action.payload
      state.pendingPasswords = passwords.filter(c => c.status === 'Draft')
      state.rejectedPasswords = passwords.filter(c => c.status === 'Rejected')
      state.pendingPasswordTags = passwords
        .filter(
          password => password.status === 'Draft' && !!password.tags?.length
        )
        .map(password => password.tags)
      state.isPendingLoading = false
      state.pendingError = null
    },
    getOtherPendingPasswordsFailure(state, action) {
      state.isPendingLoading = false
      state.pendingError = action.payload
    }
  }
})

export const {
  getOtherPasswordsStart,
  getOtherPasswordsSuccess,
  getOtherPasswordsFailure,
  getOtherPendingPasswordsStart,
  getOtherPendingPasswordsSuccess,
  getOtherPendingPasswordsFailure
} = otherPasswords.actions

export default otherPasswords.reducer

export const fetchOtherPasswords = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherPasswordsStart())
    const passwords = await getRecords(userId, 'passwords', masterKey)
    dispatch(getOtherPasswordsSuccess({ passwords }))
  } catch (err) {
    onError(err)
    dispatch(getOtherPasswordsFailure(err.toString()))
  }
}

export const fetchOtherPendingPasswords = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherPendingPasswordsStart())
    const passwords = await getRecords(userId, 'pendingPasswords', masterKey)
    dispatch(getOtherPendingPasswordsSuccess({ passwords }))
  } catch (err) {
    onError(err)
    dispatch(getOtherPendingPasswordsFailure(err.toString()))
  }
}
import React, { useContext, useState } from 'react'
import { Avatar, Popconfirm } from 'antd'
import CustomIcon from '../override/Icon'
import { H4 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import ContactLinksModal from './ContactLinksModal'
import SimpleList from '../override/SimpleList'
import { useTranslation } from 'react-i18next'
import { ACCESS_LEVEL } from './../../share/Constants'
import { useSelector } from 'react-redux'
import AuthContext from '../../contexts/AuthContext'

export default function ContactLinks({
  linkedContacts,
  handleUnlinkContact,
  handleLinkContacts,
  isReadonly,
  isDisableAdd,
  record
}) {
  const theme = useContext(ThemeContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { accessLevel } = useSelector(state => state.settings)

  const [contactLinksModalVisible, setContactLinksModalVisible] =
    useState(false)
  const { t } = useTranslation()

  return (
    <>
      <SimpleList
        className="tour-existed-contacts-link"
        header={<H4>{t('CONTACT_LINKS')}</H4>}
        extra={
          (!isReadonly ||
            (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL)) && (
            <CustomIcon
              type="add"
              style={{ fontSize: 30, color: theme.primary, cursor: 'pointer' }}
              onClick={() => setContactLinksModalVisible(true)}
            />
          )
        }
        data={linkedContacts}
        itemKey="_id"
        icon={contact => (
          <Avatar size={36} icon="user" style={{ minWidth: 36 }} />
        )}
        mainInfo={contact => contact.name}
        subInfo={contact =>
          contact.emails && !!contact.emails.length && contact.emails[0].value
        }
        action={contact =>
          ((!isReadonly && !contact.status) ||
            (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL)) && (
            <Popconfirm
              title={t('UNLINK_CONTACT_CONFIRM')}
              onConfirm={() => handleUnlinkContact(contact._id)}
              okText={t('YES')}
              cancelText={t('NO')}
              arrowPointAtCenter
              placement="bottomRight"
            >
              <CustomIcon
                type="trash"
                style={{ color: theme.red, fontSize: 12 }}
              />
            </Popconfirm>
          )
        }
      />
      {contactLinksModalVisible && (
        <ContactLinksModal
          visible={contactLinksModalVisible}
          setVisible={setContactLinksModalVisible}
          handleLinkContacts={handleLinkContacts}
          linkedContacts={linkedContacts}
          isDisableAdd={isDisableAdd}
          record={record}
        />
      )}
    </>
  )
}

import React, { useState, useContext } from 'react'
import PouchDB from 'pouchdb'
import { withRouter } from 'react-router-dom'
import { Table, Empty, message } from 'antd'
//import { StringResources } from '../../share/StringResources'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import NodeRSA from 'node-rsa'
import { AES, enc } from 'crypto-js'
import sss from 'shamirs-secret-sharing'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import api from '../../lib/api'
import Button from '../override/Button'
import { flatten } from 'lodash'
import TableHeader from '../common/TableHeader'
import { logAccessAttempt } from '../../share/logs'
import { onError } from '../../lib/sentry'
import {
  addPDNameColumn,
  checkSelectedLevel,
  getExternalUserAttributes
} from '../../share/helpers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function Vaults({
  history,
  vaults,
  isLoading,
  fetchVaults,
  fetchData,
  professionalDeputies
}) {
  const { user, isProfessionalDeputy } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const [isUnlocking, setIsUnlocking] = useState(null)
  const [isLocking, setIsLocking] = useState(null)
  const [isViewing, setIsViewing] = useState()
  const externalUser = localStorage.getItem('External_User')

  const { t } = useTranslation()

  const { delegatedByProfessionalDeputies } = useSelector(
    state => state.user
  ).user

  const columns = [
    {
      title: t('OWNER'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('UNLOCK_STATUS'),
      key: 'unlockStatus',
      render: (text, record) =>
        record.unlockedShares && record.unlockedShares.length
          ? `${record.unlockedShares.length}/${record.sharesThreshold || 2}`
          : `0/${record.sharesThreshold || 2}`
    },
    {
      key: 'action',
      render: (text, record) => (
        <div style={{ textAlign: 'right' }}>
          {record.shareKey &&
            (!record.unlockedShares ||
              !record.unlockedShares.find(
                s => s.deputyUserId === user.username
              )) && (
              <Button
                onClick={() => unlock(record)}
                loading={isUnlocking === record.key}
              >
                {t('UNLOCK')}
              </Button>
            )}
          {record.unlockedShares?.find(
            share => share.deputyUserId === user.username
          ) && (
            <Button
              onClick={() => lock(record)}
              loading={isLocking === record.key}
            >
              {t('LOCK')}
            </Button>
          )}
          {record.unlockedShares?.length === (record.sharesThreshold || 2) && (
            <Button
              type="primary"
              onClick={() => view(record)}
              style={{ marginLeft: 8 }}
              loading={isViewing === record.key}
            >
              {t('VIEW')}
            </Button>
          )}
        </div>
      )
    }
  ]

  const accessLevelColumn = {
    title: t('ACCESS_LEVEL'),
    key: 'accessLevel',
    dataIndex: 'accessLevel',
    render: text => checkSelectedLevel(text)
  }

  if (isProfessionalDeputy) {
    columns.splice(2, 0, accessLevelColumn)
  }

  if (delegatedByProfessionalDeputies?.length) {
    addPDNameColumn(
      columns,
      professionalDeputies,
      delegatedByProfessionalDeputies
    )
  }

  const destroyPrimaryUserDbs = async primaryUserId => {
    const dbs = await PouchDB.allDbs()
    try {
      await Promise.all(
        dbs.map(async db => {
          return db.includes(primaryUserId)
            ? await new PouchDB(db).destroy()
            : null
        })
      )
      localStorage.removeItem(primaryUserId)
    } catch (e) {
      onError(e)
    }
  }

  const handleView = async (record, shares, privateKey) => {
    const decryptedPrivateKey = AES.decrypt(privateKey, masterKey).toString(
      enc.Latin1
    )

    const key = new NodeRSA()
    key.importKey(decryptedPrivateKey, 'pkcs8')
    const rawShareKeys = shares.map(s => key.decrypt(s.share, 'ascii'))

    const puMasterKey = sss.combine(rawShareKeys).toString()
    const res = await api.getUser(user.username)
    const { extraKey } = res.data
    const encryptedKey = AES.encrypt(puMasterKey, extraKey).toString()
    localStorage.setItem(record.key, encryptedKey)
    setIsViewing(null)
  }

  const view = record => {
    setIsViewing(record.key)
    const shares = flatten(record.unlockedShares?.map(s => s.shares)).filter(
      sh => sh.deputyId === user.username
    )
    if (!!externalUser) {
      const userAttributes = getExternalUserAttributes()
      const privateKey = userAttributes.private_key
      handleView(record, shares, privateKey)
      logAccessAttempt(record.key, userAttributes, user.username)
      history.push(`/${record.key}`)
    } else {
      getUserData(user, async (err, data) => {
        if (err) {
          onError(err)
          setIsViewing(null)
          return
        }
        const privateKey = getUserAttributeValue(
          data.UserAttributes,
          'custom:private_key'
        )
        handleView(record, shares, privateKey)
        logAccessAttempt(record.key, data.UserAttributes, user.username)
        history.push(`/${record.key}`)
      })
    }
  }

  const handleUnlock = async (record, privateKey) => {
    const decryptedPrivateKey = AES.decrypt(privateKey, masterKey).toString(
      enc.Latin1
    )

    const key = new NodeRSA()
    key.importKey(decryptedPrivateKey, 'pkcs8')
    const decryptedShare = key.decrypt(record.shareKey, 'ascii')

    const unlockedShares = record.allPublicKeys.map(pk => {
      const depkey = new NodeRSA()
      depkey.importKey(pk.publicKey, 'public')
      const encryptedShare = depkey.encrypt(
        decryptedShare.toString('hex'),
        'base64'
      )
      return {
        deputyId: pk.deputyId,
        share: encryptedShare
      }
    })

    const unlockData = {
      primaryUserId: record.key,
      encryptedShares: unlockedShares // encrypt by deps' public keys
    }

    await api.unlockVault(user.username, JSON.stringify(unlockData))
    setIsUnlocking(null)
    message.success(t('SUCCESSFULLY_UNLOCKED_VAULT'))
    fetchVaults()
    fetchData()
  }

  const unlock = record => {
    if (!!externalUser) {
      setIsUnlocking(record.key)
      try {
        const userAttributes = JSON.parse(
          localStorage.getItem('UserAttributes')
        )
        const privateKey =
          userAttributes.find(a => a.Name === 'custom:private_key')?.Value || ''
        handleUnlock(record, privateKey)
      } catch (e) {
        setIsUnlocking(null)
        message.error('FAILED_TO_UNLOCK_VAULT')
        onError(e)
      }
    } else {
      getUserData(user, async (err, data) => {
        if (err) {
          onError(err)
          return
        }

        setIsUnlocking(record.key)
        try {
          const privateKey = getUserAttributeValue(
            data.UserAttributes,
            'custom:private_key'
          )
          handleUnlock(record, privateKey)
        } catch (e) {
          setIsUnlocking(null)
          message.error('FAILED_TO_UNLOCK_VAULT')
          onError(e)
        }
      })
    }
  }

  const lock = async record => {
    try {
      setIsLocking(record.key)
      await api.lockVault(user.username, { primaryUserId: record.key })
      await destroyPrimaryUserDbs(record.key)
      setIsLocking(null)
      message.success(t('SUCCESSFULLY_LOCKED_VAULT'))
      fetchVaults()
      fetchData()
    } catch (e) {
      setIsLocking(null)
      message.error(t('FAILED_TO_LOCK_VAULT'))
      onError(e)
    }
  }

  return (
    <>
      <TableHeader
        title={t('VAULTBOXES_OF_PRIMARY_USERS')}
        count={vaults.length}
      />
      <Table
        columns={columns}
        dataSource={vaults}
        scroll={{ x: true }}
        loading={isLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_VAULTBOXES')}
            />
          )
        }}
        showHeader={!!vaults.length}
      />
    </>
  )
}

export default withRouter(Vaults)

import React from 'react'
import { Form } from 'antd'

class FormContent extends React.Component {
  render() {
    const { form, children } = this.props
    const { getFieldDecorator } = form
    return <Form>{children(getFieldDecorator)}</Form>
  }
}

const WrappedForm = Form.create({ name: 'wrappedForm' })(FormContent)
export default WrappedForm

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Radio } from 'antd'
import ReactCountryFlag from 'react-country-flag'

const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const changeLanguage = event => {
    i18n.changeLanguage(event.target.value)
  }

  return (
    <div style={{ marginBottom: 10 }}>
      <Radio.Group
        name="language"
        onChange={changeLanguage}
        value={i18n.language}
      >
        <Radio value="en">
          <ReactCountryFlag countryCode="GB" svg /> English
        </Radio>
        <Radio value="vn">
          <ReactCountryFlag countryCode="VN" svg /> Tiếng Việt
        </Radio>
        <Radio value="id">
          <ReactCountryFlag
            countryCode="ID"
            svg
            style={{
              width: 16,
              height: 12,
              border: '1px solid rgb(234, 229, 229, 0.7)',
              borderRadius: 2
            }}
          />{' '}
          Bahasa Indonesia
        </Radio>
        <Radio value="cn">
          <ReactCountryFlag countryCode="CN" svg /> 简体中文
        </Radio>
      </Radio.Group>
    </div>
  )
}

export default LanguageSelector

import React, { useContext } from 'react'
import AuthContext from '../../contexts/AuthContext'
import SignupForm from './SignupForm'

function RegisterModal(props) {
  const { visible, setVisible, initUserData } = props
  const { user } = useContext(AuthContext)

  return (
    <SignupForm
      isModal
      user={user}
      visible={visible}
      setVisible={setVisible}
      initUserData={initUserData}
    />
  )
}

export default RegisterModal

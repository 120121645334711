import React, { Fragment } from 'react'
import { Descriptions } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function PromotionCodeInfo() {
  const { t } = useTranslation()
  const { subscription } = useSelector(state => state.customer)
  const discount = subscription?.discount
  const promotionInfo = discount?.coupon

  return (
    <Fragment>
      <Descriptions
        title={t('APPLIED_PROMOTION')}
        column={1}
        size="small"
        style={{ marginBottom: 16 }}
      >
        <Descriptions.Item label={t('PROMOTION_CODE')}>
          {promotionInfo.id}
        </Descriptions.Item>
        <Descriptions.Item label={t('PROMOTION_NAME')}>
          {promotionInfo.name}
        </Descriptions.Item>
        {promotionInfo.percent_off && (
          <Descriptions.Item label={t('PERCENTAGE_DISCOUNT')}>
            {promotionInfo.percent_off}%
          </Descriptions.Item>
        )}
        {promotionInfo.amount_off && (
          <Descriptions.Item label={t('FIXED_AMOUNT_DISCOUNT')}>
            ${promotionInfo.amount_off / 100}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t('DURATION')}>
          {promotionInfo.duration === 'repeating'
            ? `${promotionInfo.duration_in_months} ${t('MONTHS')}`
            : promotionInfo.duration === 'once'
            ? t('ONCE')
            : promotionInfo.duration === 'forever'
            ? t('FOREVER')
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t('APPLIED_DATE')}>
          {moment(discount.start * 1000).format('LL')}
        </Descriptions.Item>
      </Descriptions>
    </Fragment>
  )
}

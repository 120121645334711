import React, { Fragment, useEffect } from 'react'
import { Result, Icon, Spin } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import api, { setProviderApiAuthorization } from '../../lib/api'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { getUserPool } from '../../lib/cognito'
import { onError } from '../../lib/sentry'
import { logAccessAttempt } from '../../share/logs'
import { AES, enc } from 'crypto-js'
import { useTranslation } from 'react-i18next'
import Button from '../override/Button'

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const RedirectVault = props => {
  const { setIsAuthenticated, setIsRemoveLanguage, setUser, user } = props
  const history = useHistory()
  const url = new URL(window.location).hash
  const errorUrl = url.includes('error_description')
  const accessToken = url.substring(url.indexOf('=') + 1, url.indexOf('&'))
  const idToken = url.substring(
    url.indexOf('id_token') + 9,
    url.indexOf('&token_type')
  )
  const decodeToken = parseJwt(accessToken)
  const { t } = useTranslation()
  setProviderApiAuthorization(idToken)

  useEffect(() => {
    const fetchData = async () => {
      if (!user.username) {
        try {
          const cognitoResponse = await api.getExternalUserAttributes(
            decodeToken.username
          )
          const dynamoResponse = await api.getUser(decodeToken.username)
          const userAttributes = cognitoResponse.data.cognitoUser.UserAttributes
          const encryptedPassword = dynamoResponse.data.encryptedPassword
          if (!!encryptedPassword) {
            const extraKey = dynamoResponse.data.extraKey
            const password = AES.decrypt(encryptedPassword, extraKey).toString(
              enc.Latin1
            )
            const encryptedMasterKey =
              userAttributes.find(a => a.Name === 'custom:master_key')?.Value ||
              ''
            const masterKey = AES.decrypt(
              encryptedMasterKey,
              password
            ).toString(enc.Latin1)
            const encryptedKey = AES.encrypt(masterKey, extraKey).toString()
            localStorage.setItem(decodeToken.username, encryptedKey)
          }
          localStorage.setItem('token_expired', decodeToken.exp * 1000)
          localStorage.setItem('UserAttributes', JSON.stringify(userAttributes))
          localStorage.setItem('id_token', idToken)
          localStorage.setItem('access_token', accessToken)
          localStorage.setItem('External_User', decodeToken.username)
          await logAccessAttempt(
            decodeToken.username,
            userAttributes,
            decodeToken.username
          )
          const cognitoUser = new CognitoUser({
            Username: decodeToken.username,
            Pool: getUserPool()
          })
          setUser(cognitoUser)
          setIsAuthenticated(true)
          history.push('/')
        } catch (error) {
          onError(error)
        }
      }
    }

    fetchData()
  }, [
    user,
    setUser,
    history,
    idToken,
    accessToken,
    decodeToken,
    setIsAuthenticated
  ])

  useEffect(() => {
    if (errorUrl) {
      setIsRemoveLanguage(false)
    }
  }, [errorUrl, setIsRemoveLanguage])

  return (
    <Fragment>
      {errorUrl && url.includes('attributes+required') ? (
        <div className="submitted-result">
          <Result
            icon={<Icon type="info-circle" theme="filled" />}
            title={t('ACCESS_DENIED')}
            subTitle={t(
              'PLEASE_MAKE_SURE_THAT_YOUR_FACEBOOK_ACCOUNT_HAS_AN_EMAIL'
            )}
            extra={
              <Button size="large" type="primary">
                <Link to="/login">{t('RETURN_TO_HOMEPAGE')}</Link>
              </Button>
            }
          />
        </div>
      ) : errorUrl ? (
        <div className="submitted-result">
          <Result
            icon={<Icon type="info-circle" theme="filled" />}
            title={t('ACCESS_DENIED')}
            extra={
              <Button size="large" type="primary">
                <Link to="/login">{t('RETURN_TO_HOMEPAGE')}</Link>
              </Button>
            }
          />
        </div>
      ) : (
        <div className="submitted-result" style={{ textAlign: 'center' }}>
          <Spin tip={t('LOADING')} size="large" />
        </div>
      )}
    </Fragment>
  )
}

export default RedirectVault

import React, { useEffect } from 'react'
import { useSubscription, useMutation } from 'react-apollo-hooks'
import { onCreateByUserId } from '../../graphql/subscriptions'
import { deleteS3Change } from '../../graphql/mutations'
import {
  fetchContacts,
  fetchPendingContacts
} from '../../features/contacts/contactsSlice'
import {
  fetchAssetsLiabilities,
  fetchPendingAssetsLiabilities,
  fetchValuations
} from '../../features/assets-liabilities/assetsLiabilitiesSlice'
import {
  fetchDocuments,
  fetchPendingDocuments
} from '../../features/documents/documentsSlice'
import { useDispatch } from 'react-redux'
import { fetchEvents, fetchPendingEvents } from '../../features/events/eventsSlice'
import { reloadRecords } from '../../lib/pouchDb'
import { fetchPasswords, fetchPendingPasswords } from '../../features/passwords/passwordsSlice'

const SubscriptionData = ({ userId, masterKey }) => {
  const dispatch = useDispatch()
  const isNotReload = localStorage.getItem('NotReload')
  const { data } = useSubscription(onCreateByUserId, {
    variables: {
      userId: userId
    }
  })

  const [removeS3Change] = useMutation(deleteS3Change)

  useEffect(() => {
    const fetchDataFunction = async (dbName, userId, masterKey) => {
      switch (dbName) {
        case 'assetsLiabilities':
          return dispatch(fetchAssetsLiabilities(userId, masterKey))
        case 'assetsLiabilitiesValuations':
          return dispatch(fetchValuations(userId, masterKey))
        case 'contacts':
          return dispatch(fetchContacts(userId, masterKey))
        case 'documents':
          return dispatch(fetchDocuments(userId, masterKey))
        case 'pendingAssetsLiabilities':
          return dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
        case 'pendingContacts':
          return dispatch(fetchPendingContacts(userId, masterKey))
        case 'pendingDocuments':
          return dispatch(fetchPendingDocuments(userId, masterKey))
        case 'events':
          return dispatch(fetchEvents(userId, masterKey))
        case 'pendingEvents':
          return dispatch(fetchPendingEvents(userId, masterKey))
        case 'passwords':
          return dispatch(fetchPasswords(userId, masterKey))
        case 'pendingPasswords':
          return dispatch(fetchPendingPasswords(userId, masterKey))
        default:
          return null
      }
    }

    const loadRecords = async () => {
      const dbsReload = data?.onCreateByUserId?.message.split(', ') || []
      await Promise.all(
        dbsReload.map(dbName => reloadRecords(dbName, userId, masterKey))
      )
      await Promise.all(
        dbsReload.map(dbName => fetchDataFunction(dbName, userId, masterKey))
      )
    }

    if (data?.onCreateByUserId?.userId === userId && isNotReload === 'false') {
      loadRecords()
      if (data?.onCreateByUserId?.id) {
        removeS3Change({
          variables: { id: data?.onCreateByUserId?.id }
        })
      }
    } else {
      localStorage.setItem('NotReload', false)
    }
  }, [data, isNotReload, masterKey, removeS3Change, userId, dispatch])

  return <></>
}
export default SubscriptionData

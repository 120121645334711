import React from 'react'
import { Form, Modal, Select } from 'antd'
import FormItem from '../override/FormItem'
import { TEXT_FILE_TYPES } from '../../share/Constants'
//import { StringResources } from '../../share/StringResources'
import { validateFilename } from '../../share/formHelpers'
import { withTranslation } from 'react-i18next'
import TextInput from './../common/TextInput'

class SaveTextFileModal extends React.Component {
  render() {
    const {
      form,
      visible,
      handleOk,
      handleCancel,
      isSaving,
      activeFiles,
      destination,
      fileName,
      fileExtension,
      t
    } = this.props
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form

    return (
      <Modal
        title={t('SAVE_TEXT_FILE')}
        visible={visible}
        onOk={handleOk}
        okText={t('OK')}
        cancelText={t('CANCEL')}
        onCancel={handleCancel}
        okButtonProps={{ loading: isSaving }}
        maskClosable={false}
      >
        <Form hideRequiredMark={true}>
          <FormItem label={t('FILE_NAME')}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: t('FILE_NAME_IS_REQUIRED')
                },
                {
                  validator: (rule, value, callback) => {
                    // when edit don't need to validate this
                    if (fileName && fileExtension) {
                      callback()
                    } else {
                      const extension = getFieldValue('saveAsType')

                      const filename = `${value}${extension}`
                      validateFilename(
                        rule,
                        filename,
                        callback,
                        activeFiles,
                        destination
                      )
                    }
                  }
                }
              ],
              initialValue: fileName
            })(
              <TextInput
                name="name"
                setFieldsValue={setFieldsValue}
                placeholder={t('INPUT_FILE_NAME_MSG')}
              />
            )}
          </FormItem>
          <FormItem label={t('SAVE_AS_TYPE')}>
            {getFieldDecorator('saveAsType', {
              initialValue: fileExtension || TEXT_FILE_TYPES.DOC.extension
            })(
              <Select>
                {Object.values(TEXT_FILE_TYPES).map(value => (
                  <Select.Option key={value.extension} value={value.extension}>
                    {value.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const WrappedSaveTextFileModal = Form.create({ name: 'saveTextFileModal' })(
  SaveTextFileModal
)
export default withTranslation()(WrappedSaveTextFileModal)

import React from 'react'
import { Form, DatePicker, AutoComplete } from 'antd'
import { formItemLayout } from '../../share/Layout'
//import { StringResources } from '../../share/StringResources'
import { DATE_FORMAT } from '../../share/Constants'
import { allValuesUndefined } from '../../share/helpers'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import TextInput from '../common/TextInput'
import { withTranslation } from 'react-i18next'

class SearchForm extends React.Component {
  state = {
    startValue: null,
    endValue: null
  }

  disabledStartDate = startValue => {
    const { endValue } = this.state
    if (!startValue || !endValue) {
      return false
    }
    return startValue.valueOf() > endValue.valueOf()
  }

  disabledEndDate = endValue => {
    const { startValue } = this.state
    if (!endValue || !startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  onStartChange = value => {
    this.setState({
      startValue: value
    })
  }

  onEndChange = value => {
    this.setState({
      endValue: value
    })
  }

  render() {
    const { form, handleSearch, resetFields, t, allTags } = this.props
    const { getFieldDecorator, getFieldsValue } = form

    return (
      <Form
        {...formItemLayout}
        className="upload-form"
        onKeyUp={e => (e.keyCode === 13 || e.which === 13) && handleSearch()}
      >
        <FormItem label={t('FILE_NAME')}>
          {getFieldDecorator('fileName')(<TextInput />)}
        </FormItem>

        <FormItem label={t('TAGS')}>
          {getFieldDecorator('tags')(
            <AutoComplete
              dataSource={allTags}
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(input.trim().toLowerCase())
              }
            />
          )}
        </FormItem>

        <FormItem label={t('DESCRIPTION')}>
          {getFieldDecorator('description')(<TextInput />)}
        </FormItem>

        {/* <FormItem label={t('FILE_NAME')}>
          {getFieldDecorator('fileName')(<TextInput type="text" />)}
        </FormItem> */}

        <FormItem label={t('START_TIME')}>
          {getFieldDecorator('startTime')(
            <DatePicker
              disabledDate={this.disabledStartDate}
              format={DATE_FORMAT}
              placeholder={t('SELECT_START_DATE')}
              onChange={this.onStartChange}
            />
          )}
        </FormItem>

        <FormItem label={t('END_TIME')}>
          {getFieldDecorator('endTime')(
            <DatePicker
              disabledDate={this.disabledEndDate}
              format={DATE_FORMAT}
              placeholder={t('SELECT_END_DATE')}
              onChange={this.onEndChange}
            />
          )}
        </FormItem>
        <FormItem>
          <Button
            size="large"
            type="primary"
            onClick={handleSearch}
            disabled={allValuesUndefined(getFieldsValue())}
          >
            {t('SEARCH')}
          </Button>
          &nbsp;
          <Button size="large" type="default" onClick={resetFields}>
            {t('RESET')}
          </Button>
        </FormItem>
      </Form>
    )
  }
}

const WrappedSearchForm = Form.create({ name: 'search' })(SearchForm)
export default withTranslation()(WrappedSearchForm)

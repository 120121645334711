import React, { useState, useContext } from 'react'
import { Form, Row, Col, Checkbox, Tooltip, Icon, Select } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import { P } from '../override/Typography'
import TextArea from 'antd/lib/input/TextArea'
import { ThemeContext } from 'styled-components'
import { countries } from 'country-data'
import { NUMBER_ONLY_REGEX } from '../../share/Constants'
import { uniq } from 'lodash'
import UploadOrSelectFile from '../file/UploadOrSelectFile'
import nationalities from 'i18n-nationality'
import { useTranslation } from 'react-i18next'
import TextInput from '../common/TextInput'
import { sanitizeValue } from '../../share/helpers'

nationalities.registerLocale(require('i18n-nationality/langs/en.json'))

const nationalitiesList = uniq(Object.values(nationalities.getNames('en')))

const { Option } = Select

const countryCallingCodes = countries.all.reduce((allCodes, country) => {
  return [...allCodes, ...country.countryCallingCodes]
}, [])

const uniqueCountryCallingCodes = [
  ...new Set(countryCallingCodes)
].sort((a, b) => a.localeCompare(b))

const UserDetailForm = props => {
  const {
    form,
    userInfo,
    userDetails,
    idPassportData,
    setIdPassportData,
    addressProofData,
    setAddressProofData
  } = props

  const { t } = useTranslation()
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const theme = useContext(ThemeContext)
  const [phoneNumberDirty, setPhoneNumberDirty] = useState(false)

  // TODO: duplicate with PersonalInfo, should refactor to common component
  const validatePhoneNumber = (rule, value, callback) => {
    if (value) {
      const prefix = getFieldValue('contactNumberPrefix')
      if (!prefix) {
        callback(t('SELECT_COUNTRY_CODE'))
      } else if (!value.match(NUMBER_ONLY_REGEX)) {
        callback(t('INVALID_MOBLIE_NUMBER'))
      }
    }

    callback()
  }

  const validateToPhoneNumber = (rule, value, callback) => {
    if (value && phoneNumberDirty) {
      form.validateFields(['contactNumber'], { force: true })
    }
    callback()
  }

  const handlePhoneNumberBlur = e => {
    const { value } = e.target
    setPhoneNumberDirty(phoneNumberDirty || !!value)
  }

  const prefixSelector = getFieldDecorator('contactNumberPrefix', {
    initialValue: userDetails?.contactNumberPrefix || userInfo?.prefix,
    rules: [
      {
        validator: validateToPhoneNumber
      }
    ]
  })(
    <Select
      style={{ width: 85 }}
      showAction={['click', 'focus']}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children
          .toString()
          .toLowerCase()
          .includes(input.trim().toLowerCase())
      }
    >
      {uniqueCountryCallingCodes.map(code => (
        <Option key={code} value={code.replace(/ /g, '')}>
          {code}
        </Option>
      ))}
    </Select>
  )

  return (
    <>
      <Form>
        <Row gutter={20}>
          <Col md={12}>
            <FormItem
              label={
                <span>
                  {t('FULLNAME')}&nbsp;
                  <Tooltip
                    title={t('USE_SAME_NAME_AS_PASSPORT_TOOLTIP')}
                    overlayStyle={{ maxWidth: 300 }}
                  >
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('fullname', {
                initialValue: userDetails?.fullname,
                rules: [
                  {
                    required: true,
                    message: t('ENTER_FULL_NAME')
                  }
                ]
              })(
                <TextInput
                  name="fullname"
                  setFieldsValue={setFieldsValue}
                  autoFocus
                />
              )}
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem label={t('CONTACT_NUMBER')}>
              {getFieldDecorator('contactNumber', {
                initialValue: userDetails?.contactNumber || userInfo?.phone,
                rules: [
                  {
                    required: true,
                    message: t('ENTER_CONTACT_NUMBER')
                  },
                  {
                    validator: validatePhoneNumber
                  }
                ]
              })(
                <TextInput
                  addonBefore={prefixSelector}
                  style={{ width: '100%' }}
                  onBlur={handlePhoneNumberBlur}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={12}>
            <FormItem label={t('ID_PASSPORT_NUMBER')}>
              {getFieldDecorator('idPassportNumber', {
                initialValue: userDetails?.idPassportNumber,
                rules: [
                  {
                    required: true,
                    message: t('ENTER_ID_PASSPORT_NUMBER')
                  }
                ]
              })(
                <TextInput
                  name="idPassportNumber"
                  setFieldsValue={setFieldsValue}
                />
              )}
            </FormItem>
          </Col>
          <Col md={12}>
            <FormItem label={t('NATIONALITY')}>
              {getFieldDecorator('nationality', {
                initialValue: userDetails?.nationality,
                rules: [
                  {
                    required: true,
                    message: t('SELECT_NATIONALITY')
                  }
                ]
              })(
                <Select allowClear showSearch showAction={['click', 'focus']}>
                  {nationalitiesList.map(nationality => (
                    <Select.Option key={nationality} value={nationality}>
                      {nationality}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem label={t('RESIDENTIAL_ADDRESS')}>
          {getFieldDecorator('address', {
            initialValue: userDetails?.address,
            rules: [
              {
                required: true,
                message: t('ENTER_RESIDENTIAL_ADDRESS')
              }
            ]
          })(
            <TextArea
              onBlur={e =>
                setFieldsValue({
                  address: sanitizeValue(e.target.value)
                })
              }
              rows={3}
              maxLength={2000}
            />
          )}
        </FormItem>
        <UploadOrSelectFile
          label={t('YOUR_ID_PASSPORT_FILENAME_IS')}
          fieldName="idPassport"
          fileData={idPassportData}
          setFileData={setIdPassportData}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
          required={true}
          labelForErrorMsg={t('ID_PASSPORT')}
        />
        <FormItem>
          {getFieldDecorator('idContainsAddress', {
            initialValue: userDetails?.idContainsAddress,
            valuePropName: 'checked'
          })(<Checkbox>{t('MY_ID_CONTAINS_MY_RESIDENTIAL_ADDRESS')}</Checkbox>)}
        </FormItem>
        <UploadOrSelectFile
          label={t('YOUR_PROOF_OF_ADDRESS_FILENAME_IS')}
          fieldName="addressProof"
          fileData={addressProofData}
          setFileData={setAddressProofData}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
          required={!getFieldValue('idContainsAddress')}
          labelForErrorMsg={t('ADDRESS_PROOF')}
        />
        <P style={{ marginTop: '0.5em' }}>
          {t('UPLOAD_ADDRESS_PROOF_DATED_WITHIN_THE_LAST_SIX_MONTHS')}
        </P>
        <ul style={{ color: theme.dark }}>
          <li>{t('ID_CARD')}</li>
          <li>{t('UTILITY_BILL')}</li>
          <li>
            {t('CORRESPONDENCE_FROM_TAX_OFFICE_OR_OTHER_GOVERMENT_AUTHORITY')}
          </li>
        </ul>
      </Form>
    </>
  )
}

const WrappedUserDetailForm = Form.create({ name: 'UserDetailForm' })(
  UserDetailForm
)

export default WrappedUserDetailForm

import React, { useState, useContext, useEffect } from 'react'
import { Button, Divider, Modal } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Span } from '../override/Typography'
import SpecifyDeputy from '../settings/SpecifyDeputy'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import api from '../../lib/api'
import { groupBy } from 'lodash'
import { onError } from '../../lib/sentry'
import { revokeDeputy } from '../../features/deputies/deputiesSlice'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'

const DisconectUserRequestModal = props => {
  const { visible } = props
  const { deputies } = useSelector(state => state.deputies)
  const { user } = useContext(AuthContext)
  const { masterKey, fullName } = useContext(VaultContext)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedName, setSelectedName] = useState()
  const [disconectRequest, setDisconectRequests] = useState([])
  const [transferModalVisible, setTransferModalVisible] = useState(false)
  const [selectedDeputy, setSelectedDeputy] = useState()
  const [userInfo, setUserInfo] = useState({
    fullname: '',
    email: '',
    phone: '',
    prefix: ''
  })

  useEffect(() => {
    const getProfessionalDeputies = async () => {
      try {
        const rs = await api.getProfessionalDeputies()
        const professionalDeputies = rs.data

        const groupedDeputies = groupBy(deputies, d => d.professionalDeputyId)
        const profGroupKey = Object.keys(groupedDeputies)?.find(
          gp => gp !== 'undefined'
        )

        const accepted =
          groupedDeputies['undefined']?.filter(d => d.publicKey) || []

        if (profGroupKey) {
          const matchingPD = professionalDeputies?.find(
            pd => pd.id === profGroupKey
          )

          if (groupedDeputies[profGroupKey]?.every(d => d.publicKey)) {
            accepted.push(matchingPD)
          }
        }
        setDisconectRequests(
          accepted.filter(a =>
            deputies.some(
              d => d.professionalDeputyId === a.id && d.pendingDisconect
            )
          )
        )
      } catch (err) {
        onError(err)
      }
    }
    if (deputies.some(d => d.professionalDeputyId)) {
      getProfessionalDeputies()
    } else {
      setDisconectRequests(deputies.filter(d => d.pendingDisconect))
    }
  }, [user, deputies])

  useEffect(() => {
    if (visible)
      getUserData(
        user,
        async (err, data) => {
          if (err) {
            onError(err)
            return
          }
          const prefix = getUserAttributeValue(
            data.UserAttributes,
            'custom:prefix'
          )
          const phoneNumber = getUserAttributeValue(
            data.UserAttributes,
            'phone_number'
          )

          setUserInfo({
            fullname: getUserAttributeValue(
              data.UserAttributes,
              'custom:full_name'
            ),
            phone: phoneNumber.slice(prefix.length),
            prefix,
            email: getUserAttributeValue(data.UserAttributes, 'email')
          })
        },
        { bypassCache: true }
      )
  }, [user, visible])

  const handleDisconnect = async deputy => {
    setSelectedName(
      deputy.accounts?.length ? deputy.professionalDeputyName : deputy.email
    )
    dispatch(revokeDeputy(user.username, deputy, fullName, masterKey, true))
  }

  const transferToAnOther = deputy => {
    setSelectedDeputy(deputy)
    setTransferModalVisible(true)
  }

  return (
    <>
      <Modal
        width={700}
        visible={visible}
        title={t(t('DISCONNECT_USER_REQUESTS'))}
        maskClosable={false}
        closable={false}
        closeIcon={false}
        footer={null}
      >
        <Span>
          {t('REQUEST_TO_DISCONNECT_ACCOUNT')}

          {disconectRequest.map(d => (
            <div
              key={d.accounts?.length ? d.professionalDeputyName : d.email}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: 20
              }}
            >
              <div style={{ lineHeight: '32px' }}>
                <Span>
                  {d.accounts?.length ? d.professionalDeputyName : d.email}
                </Span>
              </div>
              <div>
                <Button
                  loading={
                    (d.accounts?.length
                      ? d.professionalDeputyName
                      : d.email) === selectedName
                  }
                  onClick={() => handleDisconnect(d)}
                  type="primary"
                >
                  {t('APPROVE_TO_DISCONNECT')}
                </Button>
                <Divider type="vertical" />
                <Button onClick={() => transferToAnOther(d)} type="primary">
                  {t('TRANSFER_TO_AN_OTHER_DEPUTY')}
                </Button>
              </div>
            </div>
          ))}
        </Span>
      </Modal>
      <SpecifyDeputy
        existingEmails={deputies.map(d => d.email)}
        visible={transferModalVisible}
        setVisible={setTransferModalVisible}
        deputies={deputies}
        userInfo={userInfo}
        isTransfer={true}
        requestedDeputy={selectedDeputy}
      />
    </>
  )
}

export default DisconectUserRequestModal

import React, { useState, useContext, useEffect } from 'react'
import {
  ResponsiveContainer,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Legend
} from 'recharts'
import { currencyFormat } from '../../share/helpers'
import { ThemeContext } from 'styled-components'
import { Empty } from 'antd'
import { useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import { CORE_TYPES, CHART_GROUP_BY } from '../../share/Constants'
import { assetSubtypes, liabilitySubtypes } from './AssetLiabilitySubtypes'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'

// Assumption: the number of currencies won't be more than the number of colors here
const colors = [
  'secondaryRed',
  'secondaryBlue',
  'secondaryOrange',
  'secondaryGreenMedium',
  'secondaryCyan',
  'secondaryYellow',
  'secondaryPurple',
  'secondaryLightGreen',
  'darkYellow',
  'red',
  'primaryDark'
]

export default function GroupedPieChart({ type, chartGroupBy, records }) {
  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()

  const [chartData, setChartData] = useState([])
  const { baseCurrency } = useSelector(state => state.settings)
  const { assetSubTypeColors, liabilitySubtypeColor } = useSelector(
    state => state.user
  ).user

  useEffect(() => {
    const subtypes =
      type === CORE_TYPES.ASSET
        ? assetSubTypeColors?.length
          ? assetSubTypeColors
          : assetSubtypes
        : liabilitySubtypeColor?.length
        ? liabilitySubtypeColor
        : liabilitySubtypes

    const groupedData = groupBy(records, chartGroupBy)

    let chartData = []
    let index = 0
    for (const groupKey in groupedData) {
      let totalValue = groupedData[groupKey].reduce((total, item) => {
        return (
          total +
          (type === CORE_TYPES.ASSET
            ? item.valuationInBaseCurrency
            : item.outstandingValueInBaseCurrency)
        )
      }, 0)
      chartData.push({
        groupByCurrency: chartGroupBy === CHART_GROUP_BY.CURRENCY,
        name: !groupKey || groupKey === 'undefined' ? 'Unknown' : groupKey,
        value: totalValue,
        color:
          chartGroupBy === CHART_GROUP_BY.CURRENCY
            ? colors[index % colors.length]
            : subtypes.find(st => st.name === groupKey).color
      })
      index++
    }

    setChartData(chartData)
  }, [records, type, chartGroupBy, assetSubTypeColors, liabilitySubtypeColor])

  const RADIAN = Math.PI / 180
  const TINY_PERCENT = 6

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    fill
  }) => {
    const pct = (percent * 100).toFixed(2)

    const radius =
      pct < TINY_PERCENT
        ? outerRadius + 20
        : innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const textAnchor =
      pct < TINY_PERCENT ? (x > cx ? 'start' : 'end') : 'middle'
    const textColor = pct < TINY_PERCENT ? fill : 'white'

    return (
      <text
        x={x}
        y={y}
        fill={textColor}
        textAnchor={textAnchor}
        dominantBaseline="central"
      >
        {pct}%
      </text>
    )
  }

  const renderCustomizedLabelLine = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    stroke,
    percent
  }) => {
    if (percent * 100 > TINY_PERCENT) return false

    const x2 = cx + (outerRadius + 20) * Math.cos(-midAngle * RADIAN)
    const y2 = cy + (outerRadius + 20) * Math.sin(-midAngle * RADIAN)
    const x1 = cx + outerRadius * Math.cos(-midAngle * RADIAN)
    const y1 = cy + outerRadius * Math.sin(-midAngle * RADIAN)

    return (
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={stroke}
        strokeWidth={1}
      ></line>
    )
  }

  const CustomPieTooltip = pie => {
    return (
      <div className="custom-tooltip">
        {pie?.payload &&
          pie.payload[0] &&
          `${pie.payload[0].name}: ${baseCurrency} ${currencyFormat(
            pie.payload[0].value
          )}`}
      </div>
    )
  }
  const legendProps = isMdUp
    ? {
        width: 200,
        layout: 'vertical',
        verticalAlign: 'middle',
        align: 'right'
      }
    : {
        height: isSmUp ? 60 : 120,
        layout: 'horizontal',
        verticalAlign: 'bottom',
        align: 'center'
      }

  return chartData?.length ? (
    <ResponsiveContainer height={isSmUp ? 420 : 380}>
      <PieChart>
        <Pie
          data={chartData}
          cx={'50%'}
          cy={'50%'}
          labelLine={renderCustomizedLabelLine}
          label={renderCustomizedLabel}
          outerRadius={isMdUp ? 180 : isSmUp ? 150 : 120}
          dataKey="value"
          isAnimationActive={false}
        >
          {chartData.map((entry, index) => {
            return (
              <Cell
                key={entry.name}
                fill={
                  entry.groupByCurrency
                    ? theme[entry.color]
                    : assetSubTypeColors?.length
                    ? entry.color
                    : theme[entry.color]
                }
              />
            )
          })}
        </Pie>
        <Legend {...legendProps} />
        <Tooltip content={<CustomPieTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={t('NO_DATA')}
      style={{
        height: 420,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    />
  )
}

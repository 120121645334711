import { createSlice } from '@reduxjs/toolkit'
import api from '../../lib/api'
import React from 'react'
import { Spin, notification, Button } from 'antd'
import { updateAssetsLiabilitiesValuation } from '../../lib/pouchDb'
import { decryptFilePromise } from '../../lib/crypto'
import { s3Get } from '../../lib/awsSDK'
import { onError } from '../../lib/sentry'
import i18next from 'i18next'

const initialState = {
  baseCurrency: '',
  allRates: {},
  accessLevel: '',
  ratesLastUpdated: null,
  baseCurrencyLoading: false,
  accessLevelLoading: false,
  ratesLoading: false,
  ratesError: null
}

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getRatesStart(state) {
      state.ratesLoading = true
      state.ratesError = null
    },
    getRatesSuccess(state, action) {
      const { rates, ratesLastUpdated } = action.payload
      state.allRates = rates
      state.ratesLastUpdated = ratesLastUpdated
      state.ratesLoading = false
      state.ratesError = null
    },
    getRatesFailure(state, action) {
      state.ratesLoading = false
      state.ratesError = action.payload
    },
    getBaseCurrencyStart(state) {
      state.baseCurrencyLoading = true
      state.error = null
    },
    getBaseCurrencySuccess(state, action) {
      const { baseCurrency } = action.payload
      state.baseCurrency = baseCurrency
      state.baseCurrencyLoading = false
      state.error = null
    },
    getBaseCurrencyFailure(state, action) {
      state.baseCurrencyLoading = false
      state.error = action.payload
    },
    getAccessLevelStart(state) {
      state.accessLevelLoading = true
      state.error = null
    },
    getAccessLevelSuccess(state, action) {
      const { accessLevel } = action.payload
      state.accessLevel = accessLevel
      state.accessLevelLoading = false
      state.error = null
    },
    getAccessLevelFailure(state, action) {
      state.accessLevelLoading = false
      state.error = action.payload
    }
  }
})

export const {
  getRatesStart,
  getRatesSuccess,
  getRatesFailure,
  getBaseCurrencyStart,
  getBaseCurrencySuccess,
  getBaseCurrencyFailure,
  getAccessLevelStart,
  getAccessLevelSuccess,
  getAccessLevelFailure
} = settings.actions

export default settings.reducer

export const fetchAccessLevel = userId => async dispatch => {
  try {
    dispatch(getAccessLevelStart())
    const response = await api.getAccessLevel(userId)
    dispatch(getAccessLevelSuccess({ accessLevel: response.data.accessLevel }))
  } catch (err) {
    dispatch(getAccessLevelFailure(err.toString()))
  }
}

export const fetchBaseCurrency = userId => async dispatch => {
  try {
    dispatch(getBaseCurrencyStart())
    const response = await api.getBaseCurrency(userId)
    dispatch(
      getBaseCurrencySuccess({ baseCurrency: response.data.baseCurrency })
    )
  } catch (err) {
    dispatch(getBaseCurrencyFailure(err.toString()))
  }
}

export const fetchRates = (userId, isProfessionalDeputy?) => async dispatch => {
  try {
    const currencyRes = await api.getBaseCurrency(userId)
    if (currencyRes.data.baseCurrency) {
      dispatch(getRatesStart())

      const res = await api.getUser(userId, isProfessionalDeputy)

      dispatch(getRatesSuccess(res.data))
    }
  } catch (err) {
    dispatch(getRatesFailure(err.toString()))
  }
}

export const fetchLatestRatesAndUpdateValuation = (
  userId,
  baseCurrency,
  masterKey,
  updatedAssetsLiabilities,
  updatedPendingAssetsLiabilities,
  isChangeBaseCurrency = false
) => async dispatch => {
  notification.open({
    key: 'fetchLatestRates',
    message: (
      <>
        <Spin />
        <span style={{ marginLeft: 12 }}>
          {i18next.t('FETCHING_LASTEST_EXCHANGE_RATES')}
        </span>
      </>
    ),
    duration: null,
    className: 'upload-progress-noti'
  })

  let latestData
  try {
    const res = await api.getLatestRates(baseCurrency)
    latestData = res?.data
  } catch (err) {
    onError(err)
    notification.error({
      key: 'fetchLatestRates',
      message: (
        <span>
          {i18next.t('FAILED_TO_FETCH_LASTEST_EXCHANGE_RATES')}{' '}
          <Button
            type="link"
            onClick={() =>
              dispatch(
                fetchLatestRatesAndUpdateValuation(
                  userId,
                  baseCurrency,
                  masterKey,
                  updatedAssetsLiabilities,
                  updatedPendingAssetsLiabilities,
                  isChangeBaseCurrency
                )
              )
            }
          >
            {i18next.t('RETRY')}
          </Button>
        </span>
      ),
      duration: null,
      className: 'upload-progress-noti'
    })
  }

  try {
    const updateData = latestData
      ? {
          ratesLastUpdated: latestData.timestamp,
          rates: latestData.rates
        }
      : {
          ratesLastUpdated: Math.floor(new Date().getTime() / 1000),
          rates: {}
        }
    const setRes = await api.setRatesData(userId, JSON.stringify(updateData))
    if (setRes.data.message) throw Error(setRes.data.message)

    dispatch(fetchRates(userId))

    if (latestData) {
      notification.success({
        key: 'fetchLatestRates',
        message: i18next.t('SUCCESSFULLY_FETCHED_LASTEST_EXCHANGE_RATES'),
        duration: 5,
        className: 'upload-progress-noti'
      })

      if (updatedAssetsLiabilities?.length) {
        await handleUpdateAssetsLiabilitiesValuation(
          userId,
          updatedAssetsLiabilities,
          updatedPendingAssetsLiabilities,
          latestData.rates,
          masterKey,
          isChangeBaseCurrency
        )
      }
    }
  } catch (err) {
    notification.error({
      key: 'fetchLatestRates',
      message: (
        <span>
          {i18next.t('FAILED_TO_FETCH_SAVE_EXCHANGE_RATES')}{' '}
          <Button
            type="link"
            onClick={() =>
              dispatch(
                fetchLatestRatesAndUpdateValuation(
                  userId,
                  baseCurrency,
                  masterKey,
                  updatedAssetsLiabilities,
                  updatedPendingAssetsLiabilities,
                  isChangeBaseCurrency
                )
              )
            }
          >
            {i18next.t('RETRY')}
          </Button>
        </span>
      ),
      duration: null,
      className: 'upload-progress-noti'
    })
    onError(err)
  }
}

const handleUpdateAssetsLiabilitiesValuation = async (
  userId,
  updatedAssetsLiabilities,
  updatedPendingAssetsLiabilities,
  latestRates,
  masterKey,
  isChangeBaseCurrency
) => {
  notification.open({
    key: 'updateValuation',
    message: (
      <>
        <Spin />
        <span style={{ marginLeft: 12 }}>
          {i18next.t('UPDATING_VALUATION_IN_BASE_CURRENCY')}
        </span>
      </>
    ),
    duration: null,
    className: 'upload-progress-noti'
  })
  try {
    await updateAssetsLiabilitiesValuation(
      userId,
      updatedAssetsLiabilities,
      updatedPendingAssetsLiabilities,
      latestRates,
      masterKey,
      isChangeBaseCurrency
    )
    notification.success({
      key: 'updateValuation',
      message: i18next.t('SUCCESS_UPDATED_VALUATION_IN_BASE_CURRENCY'),
      duration: 5,
      className: 'upload-progress-noti'
    })
  } catch (err) {
    notification.error({
      key: 'updateValuation',
      message: (
        <span>
          {i18next.t('FAILED_TO_UPDATE_VALUATION_IN_BASE_CURRENCY')}{' '}
          <Button
            type="link"
            onClick={() =>
              handleUpdateAssetsLiabilitiesValuation(
                userId,
                updatedAssetsLiabilities,
                updatedPendingAssetsLiabilities,
                latestRates,
                masterKey,
                isChangeBaseCurrency
              )
            }
          >
            {i18next.t('RETRY')}
          </Button>
        </span>
      ),
      duration: null,
      className: 'upload-progress-noti'
    })
    onError(err)
  }
}

// TODO: should make legacyInfo a redux state to avoid extra API calls (deleting file/contact currently takes longer time due to this check)
export const getLegacyInfo = async (userId, masterKey) => {
  try {
    const statusRes = await api.getUser(userId)
    if (
      statusRes.data?.legacyManagementEnabled === undefined ||
      statusRes.data?.legacyManagementEnabled === null
    )
      return null

    const detailsRes = await s3Get(
      userId,
      `legacy/details`,
      {},
      { responseType: 'blob' }
    )
    const data = await decryptFilePromise(detailsRes, masterKey)
    return JSON.parse(String.fromCharCode(...data))
  } catch (error) {
    throw error
  }
}

import React, { useState, useContext } from 'react'
import {
  Form,
  Modal,
  Upload,
  Icon,
  Select,
  Alert,
  Tooltip,
  Button,
  message
} from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import {
  CSV_TEMPLATES,
  VAULTBOX_SAMPLE_DATA,
  SAMPLE_TEMPLATE_EXCLUDED_COLUMNS
} from '../../share/Constants'
import moment from 'moment'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid/v4'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { importAssetsLiabilities } from '../../lib/pouchDb'
import csvTemplateMappings, {
  getHeaderRow,
  getDataRows,
  getColumnIndexes
} from '../../share/csvTemplateMappings'
import {
  currencyParse,
  exportToExcel,
  removeHtmlTags,
  showUpgradeSubscriptionPlanConfirm
} from '../../share/helpers'
import { draggerProps, normFile } from '../../share/formHelpers'
import { getAssetLiabilityColumns } from './assetLiabilityHelpers'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import SubscriptionModal from '../payment/SubscriptionModal'

const { Dragger } = Upload

const csvStringToArray = strData => {
  const objPattern = new RegExp(
    '(\\,|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^\\,\\r\\n]*))',
    'gi'
  )
  let arrMatches = null,
    arrData = [[]]
  while ((arrMatches = objPattern.exec(strData))) {
    if (arrMatches[1].length && arrMatches[1] !== ',') arrData.push([])
    arrData[arrData.length - 1].push(
      arrMatches[2]
        ? arrMatches[2].replace(new RegExp('""', 'g'), '"')
        : arrMatches[3]
    )
  }
  return arrData
}

function ImportCsvModal({
  visible,
  setVisible,
  sourceAssetsLiabilities,
  form
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { masterKey, limitedRecord } = useContext(VaultContext)

  const { allRates } = useSelector(state => state.settings)
  const { limit } = useSelector(state => state.customer)

  const { getFieldDecorator, getFieldValue } = form
  const [addS3Change] = useMutation(createS3Change)

  const handleOk = () => {
    setErrMsg('')
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      setIsSaving(true)

      const file = values.file[0].originFileObj
      const mapping = csvTemplateMappings[values.template]

      let fileValuationDate

      const reader = new FileReader()
      reader.onload = async () => {
        try {
          const text = reader.result
          const lines = csvStringToArray(text)

          const headerRow = getHeaderRow(lines, mapping.header)
          if (!headerRow) {
            throw Error(t('INVALID_FILE_MISSING_HEADER_ROW'))
          }

          const indexes = getColumnIndexes(mapping.data.columns, headerRow)

          const requiredFields = [
            'title',
            'currency',
            'quantity',
            'valuationInAssetCurrency'
          ]
          for (const field of requiredFields) {
            if (indexes[field] === -1) {
              throw Error(
                `Invalid file: missing column ${
                  mapping.data.columns.find(col => col.key === field).title
                }`
              )
            }
          }

          const dataRows = getDataRows(lines, mapping.data)

          let statementHeaderRow = []
          let statementDataRows = []
          let statementIndexes = {}
          if (mapping.statement) {
            statementHeaderRow = getHeaderRow(lines, mapping.statement.header)
            if (!statementHeaderRow) {
              throw Error(t('INVALID_FILE_MISSING_THE_STATEMENT_HEADER_ROW'))
            }
            statementDataRows = getDataRows(lines, mapping.statement.data)
            statementIndexes = getColumnIndexes(
              mapping.statement.data.columns,
              statementHeaderRow
            )

            for (const field of ['fieldName', 'fieldValue']) {
              if (statementIndexes[field] === -1) {
                throw Error(
                  <Trans
                    i18nKey="INVALID_FILE_MISSING_COLUMN"
                    values={{ name: mapping.statement.data.columns[field] }}
                  ></Trans>
                )
              }
            }
          }

          if (values.template === 'IB') {
            const periodRow = statementDataRows.find(
              row => row[statementIndexes.fieldName] === 'Period'
            )
            fileValuationDate = moment(periodRow[statementIndexes.fieldValue])
          }

          if (!fileValuationDate && indexes.valuationDate === -1) {
            throw Error(t('INVALID_FILE_MISSING_VALUATION_DATE'))
          }

          const parseValue = (col, row) => {
            const rawValue = row[indexes[col.key]]
            switch (col.key) {
              case 'purchaseDate':
              case 'disposalDate':
              case 'startDate':
              case 'maturityDate':
              case 'valuationDate':
              case 'expiryDate':
              case 'buildDate':
              case 'rentalStartDate':
              case 'rentalEndDate':
              case 'paymentDueDate':
                return rawValue ? moment(rawValue) : undefined
              case 'percentageOwnership':
                return +rawValue?.split('%')[0] || 100
              case 'percentageOfShares':
              case 'entitlement':
                return +rawValue?.split('%')[0] || undefined
              case 'sumAssuredInAssetCurrency':
              case 'monthlyPayment':
              case 'creditLimit':
                return rawValue ? +currencyParse(rawValue) : undefined
              case 'premium':
              case 'rentAmount':
                if (!rawValue) return {}
                const currencyAmount = rawValue.split(' ')
                return {
                  value: +currencyParse(currencyAmount[0]),
                  unit: currencyAmount[1]
                }
              case 'loanPeriod':
                // case 'lengthOfLoan':
                if (!rawValue) return {}
                const arr = rawValue.split(' ')
                return { value: +arr[0], unit: arr[1] }
              case 'interestRate':
                if (!rawValue) return {}
                const interestRate = rawValue.split('% ')
                return { value: +interestRate[0], unit: interestRate[1] }
              case 'jointAccount':
                return rawValue === 'Yes'
              case 'floorSize':
                if (!rawValue) return {}
                const floorSizeValue = rawValue.substr(0, rawValue.indexOf(' '))
                const floorSizeUnit = rawValue.substr(rawValue.indexOf(' ') + 1)
                return { value: +floorSizeValue, unit: floorSizeUnit }
              case 'quantity':
                return +rawValue
              default:
                return rawValue
            }
          }

          let updatedRecordsCount = 0
          let backdatedAmendRecordsCount = 0
          const records = dataRows.map(row => {
            const rate = allRates[row[indexes.currency]]
            const subType =
              indexes.subType > -1
                ? row[indexes.subType]
                : mapping.default.subType
            const type =
              indexes.type > -1 ? row[indexes.type] : mapping.default.type

            let record
            const existingRecord = sourceAssetsLiabilities.find(
              asset =>
                asset.title === row[indexes.title] &&
                asset.type === type &&
                asset.subType === subType
            )
            if (existingRecord) {
              record = existingRecord
              updatedRecordsCount++
            } else {
              record = {
                _id: uuidv4(),
                percentageOwnership: 100,
                // TODO: refactor this hard-coding
                references:
                  mapping.name === CSV_TEMPLATES.VB
                    ? []
                    : [{ name: 'Ticker', value: row[indexes.title] }]
              }
            }

            const valuationDate =
              indexes.valuationDate > -1
                ? moment(row[indexes.valuationDate]?.trim())
                : fileValuationDate
            const isBackdated =
              record.valuationDate &&
              valuationDate.isBefore(record.valuationDate)
            if (isBackdated) backdatedAmendRecordsCount++

            const description =
              indexes.description > -1 ? row[indexes.description] : ''

            const rowData = mapping.data.columns.reduce(
              (data, col) => {
                const value = parseValue(col, row)
                return { ...data, [col.key]: value }
              },
              { ...record }
            )
            const valuationInAssetLiabilityCurrency =
              Math.abs(
                +currencyParse(row[indexes.valuationInAssetLiabilityCurrency])
              ) || 0

            return removeHtmlTags({
              ...record,
              ...rowData,
              descriptionWithMarkup: description,
              valuationDate,
              valuationInAssetCurrency: valuationInAssetLiabilityCurrency,
              outstandingValueInLiabilityCurrency:
                valuationInAssetLiabilityCurrency,
              valuationInBaseCurrency:
                valuationInAssetLiabilityCurrency / +rate,
              outstandingValueInBaseCurrency:
                valuationInAssetLiabilityCurrency / +rate,
              sumAssuredInBaseCurrency:
                indexes.sumAssuredInAssetCurrency > -1
                  ? +currencyParse(row[indexes.sumAssuredInAssetCurrency]) /
                    +rate
                  : undefined,
              type: row[indexes.type] || mapping.default.type,
              subType: row[indexes.subType] || mapping.default.subType,
              isBackdated
            })
          })

          if (updatedRecordsCount) {
            Modal.confirm({
              title: t('UPDATING_ASSETS_LIABILITIES'),
              content: (
                <>
                  <div>
                    <Trans
                      i18nKey="UPDATING_ASSETS_LIABILITIES_SUMMARY"
                      values={{ updatedRecordsCount: updatedRecordsCount }}
                    ></Trans>{' '}
                    {!!backdatedAmendRecordsCount && (
                      <span>
                        ({backdatedAmendRecordsCount}{' '}
                        {t('BACK_DATED_AMEND'.toLowerCase())})
                      </span>
                    )}
                  </div>
                  <div>{t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE')}</div>
                </>
              ),
              onOk: async () => {
                if (limitedRecord + records.length > limit) {
                  setIsSaving(false)
                  showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
                  return
                }
                await importAssetsLiabilities(user.username, records, masterKey)
                localStorage.setItem('NotReload', true)
                addS3Change({
                  variables: {
                    message: 'assetsLiabilities',
                    userId: user.username
                  }
                })
                setIsSaving(false)
                resetModal()
                message.success(t('SUCCESSFULLY_IMPORTED_DATA'))
              }
            })
          } else {
            if (limitedRecord + records.length > limit) {
              setIsSaving(false)
              showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
              return
            }
            await importAssetsLiabilities(user.username, records, masterKey)
            localStorage.setItem('NotReload', true)
            addS3Change({
              variables: {
                message: 'assetsLiabilities',
                userId: user.username
              }
            })
            setIsSaving(false)
            resetModal()
            message.success(t('SUCCESSFULLY_IMPORTED_DATA'))
          }
        } catch (err) {
          onError(err)
          setErrMsg(t('FAILED_TO_IMPORT_DATA'))
          setIsSaving(false)
        }
      }
      reader.readAsText(file)
    })
  }

  const resetModal = () => {
    form.resetFields()
    setVisible(false)
    setErrMsg('')
  }

  const handleDownloadTemplate = () => {
    const columns = getAssetLiabilityColumns().filter(
      col => !SAMPLE_TEMPLATE_EXCLUDED_COLUMNS.includes(col.key)
    )
    exportToExcel(
      'vaultbox_template',
      columns,
      VAULTBOX_SAMPLE_DATA,
      false,
      'csv'
    )
  }

  return (
    <>
      <Modal
        title={t('IMPORT_FROM_CSV')}
        visible={visible}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleOk}
        onCancel={resetModal}
        okButtonProps={{ loading: isSaving }}
        maskClosable={false}
      >
        <Form className="upload-form">
          <FormItem label={t('FILE')}>
            {getFieldDecorator('file', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  required: true,
                  message: t('SELECT_FILE_TO_UPLOAD_MSG')
                },
                {
                  validator: (rule, value, callback) => {
                    const fileType = value && value[0]?.originFileObj.type
                    if (fileType && fileType !== 'application/vnd.ms-excel') {
                      callback(`Invalid file type`)
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="upload" />
                </p>
                <p className="ant-upload-hint">
                  {t('UPLOAD_FILE_INSTRUCTION')}
                </p>
              </Dragger>
            )}
          </FormItem>
          <FormItem
            label={
              <span>
                {t('TEMPLATE')}:{' '}
                {getFieldValue('template') === 'VB' && (
                  <Tooltip title={t('DOWNLOAD_SAMPLE_TEMPLATE')}>
                    <Button
                      icon="download"
                      type="link"
                      onClick={handleDownloadTemplate}
                    />
                  </Tooltip>
                )}
              </span>
            }
            colon={false}
          >
            {getFieldDecorator('template', {
              rules: [
                {
                  required: true,
                  message: t('SELECT_A_TEMPLATE')
                }
              ]
            })(
              <Select placeholder={t('SELECT_A_TEMPLATE')}>
                {Object.keys(CSV_TEMPLATES).map(key => (
                  <Select.Option key={key} value={key}>
                    {CSV_TEMPLATES[key]}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Form>
        {errMsg && (
          <Alert
            style={{ marginTop: 10 }}
            description={errMsg}
            closable
            afterClose={() => setErrMsg('')}
            type="error"
          />
        )}
      </Modal>
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </>
  )
}

const WrappedImportCsvForm = Form.create({ name: 'ImportCsvModal' })(
  ImportCsvModal
)
export default WrappedImportCsvForm

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Col, Row, Alert, Divider, message, Icon } from 'antd'
import PersonalInfo from '../common/PersonalInfo'
import AuthContext from '../../contexts/AuthContext'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { Span, B, H4, StatusText } from '../override/Typography'
import SimpleHeader from '../override/SimpleHeader'
import CustomIcon from '../override/Icon'
import api from '../../lib/api'
import Button from '../override/Button'
//import { StringResources } from '../../share/StringResources'
import { ThemeContext } from 'styled-components'
import ChangePassword from './ChangePassword'
import MFAInfo from './MFAInfo'
import ReferralInfo from './ReferralInfo'
import ConfigInactivityState from './ConfigInactivityState'
import EmailVerificationModal from './EmailVerificationModal'
import { useSelector } from 'react-redux'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import {
  removeHtmlTags,
  fetchExternalUserAttributes,
  getExternalUserAttributes
} from './../../share/helpers'
import SetupLanguageModal from './SetupLanguageModal'
import ProfessionalDeputyAccessLevelModal from './ProfessionalDeputyAccessLevelModal'
import MarketingMaterials from './MarketingMaterials'

function Field({ title, value, icon }) {
  return (
    <Row style={{ padding: '10px 0' }} gutter={8}>
      <Col sm={8}>
        {icon}
        <Span style={{ paddingLeft: 10 }}>{title}</Span>
      </Col>
      <Col sm={16}>
        <B>{value}</B>
      </Col>
    </Row>
  )
}

export default function Profile() {
  const { user, isDeputyOnly } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [userInfo, setUserInfo] = useState({
    fullname: '',
    phone: '',
    prefix: '',
    preferredName: '',
    email: '',
    govId: '',
    referralCode: ''
  })
  const [errMsg, setErrMsg] = useState('')
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState()
  const [verificationModalVisible, setVerificationModalVisible] =
    useState(false)
  const [userMFASettingList, setUserMFASettingList] = useState([])
  const { t } = useTranslation()
  const externalUser = localStorage.getItem('External_User')
  const { customer } = useSelector(state => state.customer)
  const { deputies } = useSelector(state => state.deputies)

  let personalFormRef

  const getUserInfo = useCallback(() => {
    if (!!externalUser) {
      const userAttributes = getExternalUserAttributes()
      const prefix = userAttributes.prefix || ''
      const phoneNumber = userAttributes.phone_number || ''
      const country = userAttributes.country
      setUserInfo({
        fullname: userAttributes.full_name,
        phone: phoneNumber.slice(prefix ? prefix.length : 3),
        prefix: prefix || phoneNumber.slice(0, 3),
        preferredName: userAttributes.preferred_name,
        email: userAttributes.email,
        email_verified: userAttributes.email_verified,
        govId: userAttributes.government_id,
        referralCode: userAttributes.referral_code,
        country: country ? JSON.parse(country) : {}
      })
    } else {
      getUserData(
        user,
        async (err, data) => {
          if (err) {
            onError(err)
            return
          }
          const userAttributes = data.UserAttributes
          const prefix = getUserAttributeValue(userAttributes, 'custom:prefix')
          const phoneNumber = getUserAttributeValue(
            userAttributes,
            'phone_number'
          )
          const country = getUserAttributeValue(
            userAttributes,
            'custom:country'
          )
          setUserMFASettingList(data.UserMFASettingList)
          setUserInfo({
            username: data.Username,
            fullname: getUserAttributeValue(userAttributes, 'custom:full_name'),
            phone: phoneNumber.slice(prefix ? prefix.length : 3),
            prefix: prefix || phoneNumber.slice(0, 3),
            preferredName: getUserAttributeValue(
              userAttributes,
              'custom:preferred_name'
            ),
            email: getUserAttributeValue(userAttributes, 'email'),
            email_verified: getUserAttributeValue(
              userAttributes,
              'email_verified'
            ),
            govId: getUserAttributeValue(
              userAttributes,
              'custom:government_id'
            ),
            referralCode: getUserAttributeValue(
              userAttributes,
              'custom:referral_code'
            ),
            country: country ? JSON.parse(country) : {}
          })
        },
        { bypassCache: true }
      )
    }
  }, [user, externalUser])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const handleEditInfo = () => {
    personalFormRef.props.form.validateFields(async (err, values) => {
      if (err) return
      removeHtmlTags(values)
      const keys = Object.keys(values)
      if (keys.every(key => values[key] === userInfo[key])) {
        setIsEditing(false)
        return
      }

      setIsSaving(true)
      const attributes = externalUser
        ? [
            {
              Name: 'phone_number',
              Value: values.prefix + values.phone
            },
            {
              Name: 'custom:preferred_name',
              Value: values.preferredName
            },
            {
              Name: 'custom:government_id',
              Value: values.govId || ''
            },
            {
              Name: 'custom:prefix',
              Value: values.prefix
            },
            {
              Name: 'custom:country',
              Value: JSON.stringify(values.country)
            }
          ]
        : [
            {
              Name: 'custom:full_name',
              Value: values.fullname
            },
            {
              Name: 'phone_number',
              Value: values.prefix + values.phone
            },
            {
              Name: 'custom:preferred_name',
              Value: values.preferredName
            },
            {
              Name: 'email',
              Value: values.email
            },
            {
              Name: 'custom:government_id',
              Value: values.govId || ''
            },
            {
              Name: 'custom:prefix',
              Value: values.prefix
            },
            {
              Name: 'custom:country',
              Value: JSON.stringify(values.country)
            }
          ]

      // update profile to UserPool
      if (externalUser) {
        const updatedInfo = keys.reduce((info, key) => {
          if (values[key] !== userInfo[key]) {
            if (key === 'prefix' || key === 'phone') {
              return { ...info, prefix: values.prefix, phone: values.phone }
            }
            return { ...info, [key]: values[key] }
          }
          return info
        }, {})

        try {
          await api.updateExternalUserAttributes(user.username, {
            attributes: attributes
          })
          await api.updatePersonalInfo(user.username, updatedInfo)
          await fetchExternalUserAttributes(user.username)
          getUserInfo()
          onUpdateComplete()
        } catch (err) {
          setIsSaving(false)
          message.error(t('FAILED_TO_EDIT_PROFILE'))
          onError(err)
        }
      } else {
        user.updateAttributes(attributes, async function (err) {
          if (err) {
            setErrMsg(t('FAILED_TO_UPDATE_USER_ATTRIBUTES'))
            setIsSaving(false)
          } else {
            setUserInfo({
              ...userInfo,
              ...values
            })

            // send emails to notify primary users about the changes
            const updatedInfo = keys.reduce((info, key) => {
              if (values[key] !== userInfo[key]) {
                if (key === 'prefix' || key === 'phone') {
                  return { ...info, prefix: values.prefix, phone: values.phone }
                }
                return { ...info, [key]: values[key] }
              }
              return info
            }, {})

            try {
              await api.updatePersonalInfo(user.username, updatedInfo)

              if ('email' in updatedInfo) {
                if (customer?.id) {
                  await api.updateCustomer(
                    customer.id,
                    JSON.stringify({ email: updatedInfo.email })
                  )
                }

                setVerificationModalVisible(true)
              } else {
                onUpdateComplete()
              }
            } catch (err) {
              setIsSaving(false)
              message.error(t('FAILED_TO_EDIT_PROFILE'))
              onError(err)
            }
          }
        })
      }
    })
  }

  const onUpdateComplete = () => {
    setIsSaving(false)
    setIsEditing(false)
    message.success(t('SUCCESSFULLY_EDITED_PROFILE'))
  }

  return (
    <>
      <SimpleHeader
        title={<H4>{t('PROFILE_INFORMATION')}</H4>}
        extra={
          <Button type="link" icon="edit" onClick={e => setIsEditing(true)}>
            {t('EDIT')}
          </Button>
        }
        size="small"
      />
      {isEditing ? (
        <div style={{ maxWidth: 750 }}>
          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 16 }}
            />
          )}
          <PersonalInfo
            wrappedComponentRef={fr => (personalFormRef = fr)}
            personalInfo={userInfo}
            isEditing={isEditing}
          />
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={handleEditInfo}
              style={{ marginRight: 8 }}
              loading={isSaving}
            >
              {t('SAVE')}
            </Button>
            <Button
              onClick={e => {
                setErrMsg('')
                setIsEditing(false)
              }}
            >
              {t('CANCEL')}
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ maxWidth: 750 }}>
          <Field
            title={t('NAME')}
            value={userInfo.fullname}
            icon={<CustomIcon type="personal" />}
          />
          <Field
            title={t('PREFERRED_NAME')}
            value={userInfo.preferredName}
            icon={<CustomIcon type="personal" />}
          />
          <Field
            title={t('COUNTRY_TERRITORY')}
            value={userInfo.country?.name}
            icon={<Icon type="global" />}
          />
          <Field
            title={t('EMAIL')}
            value={
              <span>
                {userInfo.email}{' '}
                {userInfo.email_verified === 'false' && !externalUser && (
                  <Button
                    style={{ height: '22px' }}
                    type="link"
                    onClick={() => setVerificationModalVisible(true)}
                  >
                    {t('VERIFY_EMAIL_ADDRESS')}
                  </Button>
                )}
              </span>
            }
            icon={<CustomIcon type="email" />}
          />
          <Field
            title={t('PHONE_NUMBER')}
            value={userInfo.prefix + userInfo.phone}
            icon={<CustomIcon type="mobile" />}
          />

          {userInfo.govId && (
            <Field
              title={t('PASSPORT_NUMBER')}
              value={userInfo.govId}
              icon={<CustomIcon type="id" />}
            />
          )}
        </div>
      )}
      <Divider />
      {!externalUser && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10
            }}
          >
            <H4>{t('PASSWORD')}</H4>
            <Span
              color={theme.primary}
              style={{ cursor: 'pointer' }}
              onClick={() => setChangePasswordModalVisible(true)}
            >
              <CustomIcon type="simpleKey" style={{ marginRight: 8 }} />
              {t('CHANGE_PASSWORD')}
            </Span>
          </div>
          <StatusText color={theme.dark2}>
            {t('SET_A_STRONG_PASSWORD_TO_PROTECT_YOUR_ACCOUNT')}
          </StatusText>
          <ChangePassword
            visible={changePasswordModalVisible}
            setVisible={setChangePasswordModalVisible}
          />
          <Divider />
        </>
      )}
      <SetupLanguageModal user={user} />
      {deputies.some(d => d.professionalDeputyId) && (
        <>
          <Divider />
          <ProfessionalDeputyAccessLevelModal user={user} />
        </>
      )}

      {!externalUser && (
        <>
          <Divider />
          <MFAInfo
            userInfo={userInfo}
            userMFASettingList={userMFASettingList}
          />
        </>
      )}

      <EmailVerificationModal
        visible={verificationModalVisible}
        setVisible={setVerificationModalVisible}
        getUserInfo={getUserInfo}
        onVerificationComplete={onUpdateComplete}
      />
      {!isDeputyOnly && (
        <>
          <Divider />
          <ReferralInfo userInfo={userInfo} />
        </>
      )}
      <Divider />
      <ConfigInactivityState />
      <Divider />
      <MarketingMaterials user={user} />
    </>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import {
  Modal,
  Form,
  Radio,
  message,
  Select,
  Icon,
  Row,
  Col,
  List,
  Button
} from 'antd'
import {
  CORE_TYPES,
  ASSET_TYPES,
  LIABILITY_TYPES,
  END_OF_TIME,
  ACCESS_LEVEL
} from '../../share/Constants'
//import { StringResources } from '../../share/StringResources'
import uuidv4 from 'uuid/v4'
import PouchDB from 'pouchdb'
import { uploadEncryptedData, getRecords } from '../../lib/pouchDb'
import moment from 'moment'
import { checkRegistryType, filterEmptyEls } from '../../share/helpers'
import VaultContext from '../../contexts/VaultContext'
import FormItem from '../override/FormItem'
import DocumentsLinksModal from './DocumentsLinksModal'
import { Span } from '../override/Typography'
import ContactSelect from '../common/ContactSelect'
import PasswordSelect from '../common/PasswordSelect'
import EventSelect from '../common/EventSelect'
import { uniq } from 'lodash'
import AssetLiabilityValuation from '../../model/AssetLiabilityValuationModel'
import { mapAssetLiabilityModel } from './assetLiabilityHelpers'
import { onError } from '../../lib/sentry'
import AssetLiabilityForm from './AssetLiabilityForm'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'
import { removeHtmlTags } from './../../share/helpers'
import { useSelector, useDispatch } from 'react-redux'
import AuthContext from '../../contexts/AuthContext'
import { fetchOtherPendingAssetsLiabilities } from '../../features/assets-liabilities/otherAssetsLiabilitiesSlice'
import api from '../../lib/api'
import { fetchOtherAssetsLiabilities } from './../../features/assets-liabilities/otherAssetsLiabilitiesSlice'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'

const AssetLiabilityModal = props => {
  const {
    visible,
    setVisible,
    form,
    record,
    contactDataSource,
    onAddComplete
  } = props
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)

  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const [selectedType, setSelectedType] = useState('')
  const [selectedSubType, setSelectedSubType] = useState(ASSET_TYPES.CASH)
  const [descriptionValue, setDescriptionValue] = useState('')
  const [references, setReferences] = useState([])
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [documentsLinksVisible, setDocumentsLinksVisible] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [tags, setTags] = useState([])

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { activeContacts, pendingContacts } = useSelector(state =>
    (isReadonly && isDelegateByPD) || isProfessionalDeputy
      ? state.otherContacts
      : state.contacts
  )
  const { activeDocuments, pendingDocuments } = useSelector(state =>
    (isReadonly && isDelegateByPD) || isProfessionalDeputy
      ? state.otherDocuments
      : state.documents
  )

  const { accessLevel } = useSelector(state => state.settings)

  const db = new PouchDB(`${userId}_assetsLiabilities`)
  const pendingDb = new PouchDB(`${userId}_pendingAssetsLiabilities`)
  const {
    _id,
    title,
    type,
    subType,
    descriptionWithMarkup,
    contacts,
    documents,
    passwords,
    events
  } = record

  const initialContacts = contacts || []
  const initialPasswords = passwords || []
  const initialEvents = events || []

  const [addS3Change] = useMutation(createS3Change, {
    variables: {
      message:
        'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords, assetsLiabilitiesHistory, assetsLiabilitiesValuations',
      userId: userId
    }
  })

  useEffect(() => {
    setSelectedType(type || CORE_TYPES.ASSET)
  }, [type])

  useEffect(() => {
    if (subType) setSelectedSubType(subType)
  }, [subType])

  useEffect(() => {
    setReferences(record.references || [])
  }, [record])

  useEffect(() => {
    setDescriptionValue(descriptionWithMarkup || '')
  }, [descriptionWithMarkup, visible])

  useEffect(() => {
    const fetchLinkedDocuments = async () => {
      try {
        const linkedDocuments =
          documents && documents.length
            ? ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL
              ? [
                  ...(await getRecords(userId, 'documents', masterKey)).filter(
                    c => documents.includes(c._id)
                  ),
                  ...(
                    await getRecords(userId, 'pendingDocuments', masterKey)
                  ).filter(c => documents.includes(c._id))
                ]
              : await getRecords(userId, 'documents', masterKey, {
                  keys: documents
                })
            : []
        setLinkedDocuments(linkedDocuments)
      } catch (err) {
        onError(err)
      }
    }
    if (masterKey) {
      fetchLinkedDocuments()
    }
  }, [
    documents,
    userId,
    masterKey,
    accessLevel,
    isProfessionalDeputy,
    isReadonly,
    isDelegateByPD
  ])

  const handleOk = e => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)

      setIsSaving(true)
      const id = _id || uuidv4()
      const newRecord = mapAssetLiabilityModel(
        {
          ...record,
          ...values,
          _id: id,
          descriptionWithMarkup: descriptionValue,
          references: filterEmptyEls(values.references),
          documents: linkedDocuments.map(doc => doc._id),
          valuationDate: values.valuationDate || moment(),
          status:
            ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
              ? 'Draft'
              : undefined,
          tags
        },
        selectedType,
        selectedSubType
      )

      try {
        ;((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ? pendingDb.crypto(masterKey)
          : db.crypto(masterKey)
        const putResult =
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? await pendingDb.put(newRecord)
            : await db.put(newRecord)

        // add a version record to history db
        const historyDb = new PouchDB(`${userId}_assetsLiabilitiesHistory`)
        const versionId = `${putResult.id}_${putResult.rev}`
        const versionRecord = {
          ...newRecord,
          _id: versionId,
          time: new Date()
        }
        delete versionRecord._rev

        historyDb.crypto(masterKey)
        await historyDb.put(versionRecord)
        const valuationDb = new PouchDB(`${userId}_assetsLiabilitiesValuations`)
        if (!isProfessionalDeputy) {
          valuationDb.crypto(masterKey)
          const valuationRecord = new AssetLiabilityValuation({
            ...newRecord,
            _id: `${putResult.id}_${uuidv4()}`,
            validFrom: (
              newRecord.valuationDate || moment().startOf('day')
            ).toJSON(),
            validTo: END_OF_TIME
          })
          await valuationDb.put(valuationRecord)
        }

        await Promise.all([
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? uploadEncryptedData(pendingDb, userId, 'pendingAssetsLiabilities')
            : uploadEncryptedData(db, userId, 'assetsLiabilities'),
          uploadEncryptedData(historyDb, userId, 'assetsLiabilitiesHistory'),
          (!(isReadonly && isDelegateByPD) && !isProfessionalDeputy) ||
          accessLevel !== ACCESS_LEVEL.NEED_APPROVAL
            ? uploadEncryptedData(
                valuationDb,
                userId,
                'assetsLiabilitiesValuations'
              )
            : ''
        ])

        // update contacts, assuming the modal is now only used for adding new
        const addedContacts = uniq([
          ...(values.contacts || []),
          ...(values.tenant || []),
          ...(values.insuranceAdvisor || []),
          ...(values.beneficiaries || []),
          ...(values.borrower ? [values.borrower] : []),
          ...(values.nameAssured ? [values.nameAssured] : []),
          ...(values.company ? [values.company] : []),
          ...(values.lender ? [values.lender] : []),
          ...(values.typeOfTrustInterest?.values || [])
        ])

        const updatedActiveContacts = addedContacts.filter(cid =>
          activeContacts.map(ac => ac._id).includes(cid)
        )

        const updatedPendingContacts = addedContacts.filter(cid =>
          pendingContacts.map(pc => pc._id).includes(cid)
        )
        if (updatedActiveContacts?.length) {
          await updateLinkedItems('contacts', updatedActiveContacts, id)
        }
        if (updatedPendingContacts?.length) {
          await updateLinkedItems('pendingContacts', updatedPendingContacts, id)
        }

        const updatedActiveDocuments = linkedDocuments
          .map(d => d._id)
          .filter(id => activeDocuments.map(ac => ac._id).includes(id))

        const updatedPendingDocuments = linkedDocuments
          .map(d => d._id)
          .filter(id => pendingDocuments.map(pd => pd._id).includes(id))

        if (updatedActiveDocuments?.length) {
          await updateLinkedItems('documents', updatedActiveDocuments, id)
        }

        if (updatedPendingDocuments?.length) {
          await updateLinkedItems(
            'pendingDocuments',
            updatedPendingDocuments,
            id
          )
        }

        if (values.passwords?.length) {
          await updateLinkedItems('passwords', values.passwords, id)
        }

        if (values.events?.length) {
          await updateLinkedItems('passwords', values.events, id)
        }

        if ((isReadonly && isDelegateByPD) || isProfessionalDeputy) {
          if (accessLevel === ACCESS_LEVEL.NEED_APPROVAL) {
            await api.sendAddRecordNotification(
              JSON.stringify({
                userId,
                recordType: 'asset / liability'
              })
            )

            dispatch(fetchOtherPendingAssetsLiabilities(userId, masterKey))
          } else {
            dispatch(fetchOtherAssetsLiabilities(userId, masterKey))
          }
        }
        setIsSaving(false)
        message.success(
          title
            ? t('UPDATE_ASSET_LIABILITY_SUCCESS')
            : t('ADD_ASSET_LIABILITY_SUCCESS')
        )
        localStorage.setItem('NotReload', true)
        addS3Change()

        onAddComplete && onAddComplete(newRecord._id)
        resetModal()
      } catch (err) {
        setIsSaving(false)
        onError(err)
      }
    })
  }

  const updateLinkedItems = async (dbName, addedItems, assetLiabilityId) => {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: addedItems,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row
        const newAssetsLiabilities = doc.assetsLiabilities
          ? [...doc.assetsLiabilities, assetLiabilityId]
          : [assetLiabilityId]
        return { ...doc, assetsLiabilities: newAssetsLiabilities }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }

  const resetModal = () => {
    form.resetFields()
    setTags([])
    setSelectedType(type || CORE_TYPES.ASSET)
    setSelectedSubType(subType || ASSET_TYPES.CASH)
    setDescriptionValue(descriptionWithMarkup || '')
    setVisible(false)
  }

  const handleContactMention = (id, display) => {
    const selectedContacts = getFieldValue('contacts')
    if (!selectedContacts.includes(id)) {
      form.setFieldsValue({
        contacts: [...selectedContacts, id]
      })
    }
  }

  const handleContactDeselect = id => {
    const deselectedContact = contactDataSource.find(
      record => record._id === id
    )
    if (deselectedContact) {
      const markUp = new RegExp(`@\\[(((?!@\\[).)*)\\]\\(${id}\\)`, 'g')
      const newDescriptionValue = descriptionValue.replace(markUp, '$1')
      setDescriptionValue(newDescriptionValue)
    }
  }

  const handleTypeChange = e => {
    setSelectedType(e.target.value)
    setSelectedSubType(
      e.target.value === CORE_TYPES.ASSET
        ? ASSET_TYPES.CASH
        : LIABILITY_TYPES.PROPERTY_LOAN
    )
    form.setFieldsValue({
      subType:
        e.target.value === CORE_TYPES.ASSET
          ? ASSET_TYPES.CASH
          : LIABILITY_TYPES.PROPERTY_LOAN
    })
  }

  const unlinkDocument = itemId => {
    setLinkedDocuments(linkedDocuments.filter(doc => doc._id !== itemId))
  }

  const linkDocument = async documentId => {
    try {
      const documentIds = linkedDocuments.map(doc => doc._id).concat(documentId)
      const newLinkedDocuments =
        documentIds && documentIds.length
          ? ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [
                ...(await getRecords(userId, 'documents', masterKey)).filter(
                  c => documentIds?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingDocuments', masterKey)
                ).filter(c => documentIds?.includes(c._id))
              ]
            : await getRecords(userId, 'documents', masterKey, {
                keys: documentIds
              })
          : []
      setLinkedDocuments(newLinkedDocuments)
    } catch (err) {
      onError(err)
    }
  }

  const onAddContactComplete = (contactId, fieldName, mode) => {
    setFieldsValue({
      [fieldName]:
        mode === 'multiple'
          ? [...getFieldValue(fieldName), contactId]
          : contactId
    })
  }

  return (
    <Modal
      title={t('ADD_ASSET_LIABILITY')}
      visible={visible}
      okText={t('SAVE')}
      onOk={handleOk}
      onCancel={resetModal}
      width={'90vw'}
      className="asset-liability-modal"
      okButtonProps={{ loading: isSaving }}
      maskClosable={false}
    >
      <Form>
        <Row gutter={40}>
          <Col lg={8}>
            <FormItem label={t('TITLE')}>
              {getFieldDecorator('title', {
                initialValue: title,
                rules: [{ required: true, message: t('INPUT_TITLE_MSG') }]
              })(<TextInput name="title" setFieldsValue={setFieldsValue} />)}
            </FormItem>
            <FormItem label={t('TYPE')}>
              {getFieldDecorator('type', {
                initialValue: type || CORE_TYPES.ASSET
              })(
                <Radio.Group onChange={handleTypeChange}>
                  {Object.values(CORE_TYPES).map(type => (
                    <Radio key={type} value={type}>
                      {checkRegistryType(type)}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </FormItem>
            <FormItem label={t('SUBTYPE')}>
              {getFieldDecorator('subType', {
                initialValue: subType || ASSET_TYPES.CASH
              })(
                <Select onChange={setSelectedSubType}>
                  {Object.values(
                    selectedType === CORE_TYPES.ASSET
                      ? ASSET_TYPES
                      : LIABILITY_TYPES
                  ).map(st => (
                    <Select.Option key={st} value={st}>
                      {t(st)}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <List
              header={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Span>{t('LINKED_DOCUMENTS')}:</Span>
                  <Button
                    icon="plus"
                    onClick={() => setDocumentsLinksVisible(true)}
                  >
                    {t('ADD')}
                  </Button>
                </div>
              }
              dataSource={linkedDocuments}
              renderItem={item => (
                <List.Item className="linked-document-item">
                  <span>
                    <Icon type="file-text" style={{ marginRight: 10 }} />
                    {item.fileName}
                  </span>
                  <Icon
                    type="delete"
                    onClick={() => unlinkDocument(item._id)}
                  />
                </List.Item>
              )}
              locale={{ emptyText: t('NO_DOCUMENTS') }}
            />
          </Col>
          <Col lg={16} className="form-right">
            <AssetLiabilityForm
              record={record}
              selectedType={selectedType}
              selectedSubType={selectedSubType}
              form={form}
              references={references}
              descriptionValue={descriptionValue}
              setDescriptionValue={setDescriptionValue}
              handleContactMention={handleContactMention}
              isSpeedDialAdd={true}
              tags={tags}
              setTags={setTags}
            />

            <ContactSelect
              label={t('CONTACTS')}
              getFieldDecorator={getFieldDecorator}
              fieldName="contacts"
              initialValue={initialContacts}
              onDeselect={handleContactDeselect}
              mode="multiple"
              onAddComplete={contactId =>
                onAddContactComplete(contactId, 'contacts', 'multiple')
              }
            />

            <EventSelect
              label={t('EVENT')}
              getFieldDecorator={getFieldDecorator}
              fieldName="events"
              linkedEvents={[]}
              fetchEvents={() => {}}
              setLinkedEvents={() => {}}
              initialValue={initialEvents}
              mode="multiple"
              onAddComplete={eventId =>
                setFieldsValue({
                  events: [...(getFieldValue('events') || []), eventId]
                })
              }
            />
            <PasswordSelect
              label={t('PASSWORDS')}
              placeholder={t('SELECT_PASSWORDS')}
              required={false}
              getFieldDecorator={getFieldDecorator}
              fieldName="passwords"
              initialValue={initialPasswords}
              mode="multiple"
              onAddComplete={passwordId =>
                setFieldsValue({
                  passwords: [...(getFieldValue('passwords') || []), passwordId]
                })
              }
            />
          </Col>
        </Row>
      </Form>

      <DocumentsLinksModal
        visible={documentsLinksVisible}
        setVisible={setDocumentsLinksVisible}
        record={record}
        linkDocument={linkDocument}
        filteredDocIds={linkedDocuments.map(doc => doc._id)}
      />
    </Modal>
  )
}

const WrappedAssetLiabilityForm = Form.create({ name: 'AssetLiabilityModal' })(
  AssetLiabilityModal
)
export default WrappedAssetLiabilityForm

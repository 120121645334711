import React, { useContext, useState } from 'react'
import { Icon, Popconfirm } from 'antd'
import CustomIcon from '../override/Icon'
import { H4 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import DocumentsLinksModal from './DocumentsLinksModal'
import { formatBytes } from '../../share/helpers'
import SimpleList from '../override/SimpleList'
import { useTranslation } from 'react-i18next'
import { ACCESS_LEVEL } from '../../share/Constants'
import AuthContext from '../../contexts/AuthContext'
import { useSelector } from 'react-redux'

export default function DocumentLinks({
  linkedDocuments,
  handleUnlinkDocument,
  handleLinkDocument,
  isReadonly,
  record
}) {
  const theme = useContext(ThemeContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { accessLevel } = useSelector(state => state.settings)
  const [documentsLinksModalVisible, setDocumentsLinksModalVisible] =
    useState(false)
  const { t } = useTranslation()

  return (
    <div>
      <SimpleList
        className="tour-existed-docs-link"
        header={<H4>{t('DOCUMENT_LINKS')}</H4>}
        extra={
          (!isReadonly ||
            (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL)) && (
            <CustomIcon
              type="add"
              style={{ fontSize: 30, color: theme.primary, cursor: 'pointer' }}
              onClick={() => {
                setDocumentsLinksModalVisible(true)
              }}
            />
          )
        }
        data={linkedDocuments}
        itemKey="_id"
        icon={document => (
          <Icon type="file-text" style={{ marginRight: 10, fontSize: 24 }} />
        )}
        mainInfo={document => document.fileName}
        subInfo={document => formatBytes(document.file[0].size)}
        action={document =>
          ((!isReadonly && !document.status) ||
            (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL)) && (
            <Popconfirm
              title={t('UNLINK_DOCUMENT_CONFIRM')}
              onConfirm={() => handleUnlinkDocument(document._id)}
              okText={t('YES')}
              cancelText={t('NO')}
              arrowPointAtCenter
              placement="bottomRight"
            >
              <CustomIcon
                type="trash"
                style={{ color: theme.red, fontSize: 12 }}
              />
            </Popconfirm>
          )
        }
      />
      {documentsLinksModalVisible && (
        <DocumentsLinksModal
          visible={documentsLinksModalVisible}
          setVisible={setDocumentsLinksModalVisible}
          record={record}
          linkDocument={handleLinkDocument}
          filteredDocIds={linkedDocuments.map(doc => doc._id)}
        />
      )}
    </div>
  )
}

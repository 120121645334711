import React, { useContext, useState } from 'react'
import { Modal, Form, Alert } from 'antd'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import api from '../../lib/api'
import { useSelector } from 'react-redux'
import { onError } from '../../lib/sentry'
import VaultContext from '../../contexts/VaultContext'
import { useTranslation } from 'react-i18next'
import TextInput from './../common/TextInput'
import config from '../../config'

const PromoCodeModal = ({
  visible,
  setVisible,
  fetchCustomer,
  fetchUser,
  form
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const { userId } = useContext(VaultContext)
  const { subscription, vaultboxSubscription, customer, schedule } = useSelector(
    state => state.customer
  )
  const { email } = useSelector(state => state.user).user

  const { t } = useTranslation()
  const { getFieldDecorator, setFieldsValue } = form

  const handleCodeSubmit = e => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (err) return

      setIsSubmitting(true)
      try {
        let subscribeCustomer

        if (!customer?.id) {
          const res = await api.createCustomer(
            userId,
            JSON.stringify({ email })
          )
          if (res.data?.message) throw Error(res.data.message)
          subscribeCustomer = res.data
        }

        const promoRes = await api.getPromotionCode(values.promoCode, email)
        const promoInfo = promoRes.data
        if (!promoInfo?.valid) throw Error(t('INVALID_PROMOTION_CODE'))

        if (
          promoInfo?.appliesToPlan &&
          ((!promoInfo.groups?.length &&
            (schedule
              ? promoInfo.appliesToPlan !== schedule?.phases[1].plans[0].plan
              : promoInfo.appliesToPlan !== vaultboxSubscription?.plan?.id)) ||
            (promoInfo.groups?.length &&
              (schedule
                ? promoInfo.appliesToPlan === schedule?.phases[1].plans[0].plan
                : promoInfo.appliesToPlan === vaultboxSubscription?.plan?.id)))
        ) {
          throw Error(t('PROMOTION_CODE_CANT_APPLIED'))
        }

        if (schedule) {
          const currentPhase = schedule.phases[0]
          const nextPhase = schedule.phases[1]
          await api.updateSubscriptionSchedule(
            subscription.id,
            JSON.stringify({
              scheduleId: schedule.id,
              isRelease: false,
              phases: [
                {
                  plans: currentPhase.plans,
                  start_date: currentPhase.start_date,
                  end_date: currentPhase.end_date,
                  proration_behavior: 'none',
                  coupon: values.promoCode,
                  default_tax_rates: [config.stripe.DEFAULT_TAX_RATE]
                },
                {
                  plans: nextPhase.plans,
                  start_date: currentPhase.end_date,
                  proration_behavior: 'none',
                  coupon: values.promoCode,
                  default_tax_rates: [config.stripe.DEFAULT_TAX_RATE]
                }
              ],
              userId,
              customerEmail: customer.email
            })
          )
        } else {
          if (
            promoInfo.groups?.length &&
            promoInfo.groups.find(g =>
              g.members.map(m => m.email).includes(email)
            )
          ) {
            let items = [{ plan: promoInfo.appliesToPlan }]
            const createData = {
              userId,
              customerId: customer.id || subscribeCustomer.id,
              items,
              coupon: values.promoCode
            }

            await api.createSubscription(JSON.stringify(createData))
            await api.joinGroup(
              userId,
              JSON.stringify({
                email,
                promoCode: values.promoCode
              })
            )
          } else {
            await api.updateSubscription(
              subscription.id,
              JSON.stringify({ coupon: values.promoCode, userId })
            )
          }
        }

        resetModal()
        // await fetch customer to prevent create one more Subscription in AuthLayout's useEffect
        await fetchCustomer()
        await fetchUser()

        Modal.success({
          title: t('SUCCESSFULLY_APPLIED_PROMOTION_CODE'),
          content: (
            <>
              <div>
                {t('PROMOTION_NAME')}: {promoInfo.name}
              </div>
              {promoInfo.percent_off && (
                <div>
                  {t('PERCENTAGE_DISCOUNT')}: {promoInfo.percent_off}%
                </div>
              )}
              {promoInfo.amount_off && (
                <div>
                  {t('FIXED_AMOUNT_DISCOUNT')}: ${promoInfo.amount_off / 100}
                </div>
              )}
              <div>
                {t('DURATION')}:{' '}
                {promoInfo.duration === 'repeating'
                  ? `${promoInfo.duration_in_months} ${t('MONTHS')}`
                  : promoInfo.duration === 'once'
                  ? t('ONCE')
                  : promoInfo.duration === 'forever'
                  ? t('FOREVER')
                  : ''}
              </div>
            </>
          )
        })
      } catch (err) {
        onError(err)

        setErrMsg(t('FAILED_TO_APPLY_PROMOTION_CODE'))
      } finally {
        setIsSubmitting(false)
      }
    })
  }

  const resetModal = () => {
    form.resetFields()
    setErrMsg('')
    setVisible(false)
  }

  return (
    <Modal
      title={t('PROMOTION_CODE')}
      visible={visible}
      onCancel={resetModal}
      footer={null}
    >
      <Form onSubmit={handleCodeSubmit}>
        <FormItem>
          {getFieldDecorator('promoCode', {
            rules: [
              {
                required: true,
                message: t('PROMOTION_CODE_IS_REQUIRED')
              }
            ]
          })(
            <TextInput
              placeholder={t('ENTER_PROMOTION_CODE')}
              allowClear
              autoFocus
              style={{
                marginRight: 10,
                width: 'calc(100% - 60px)'
              }}
              name="promoCode"
              setFieldsValue={setFieldsValue}
            />
          )}
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            icon="right"
            style={{ fontSize: 14 }}
            loading={isSubmitting}
          />
        </FormItem>
      </Form>
      {errMsg && (
        <Alert
          style={{ marginTop: 10 }}
          description={errMsg}
          closable
          afterClose={() => setErrMsg('')}
          type="error"
        />
      )}
    </Modal>
  )
}

const WrappedPromoCodeModal = Form.create({
  name: 'PromoCodeModal'
})(PromoCodeModal)

export default WrappedPromoCodeModal

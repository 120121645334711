import React, { Fragment, useContext, useState } from 'react'
import { Table, Empty, Icon, Popconfirm } from 'antd'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import AddMembersModal from '../modals/AddMembersModal'
import AuthContext from '../../contexts/AuthContext'
import { fetchUser } from '../../features/user/userSlice'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { useMediaQuery } from '@material-ui/core'

export default function Members(props) {
  const { members, disabledAddMember, removeMember, professionalDeputies } =
    props

  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [membersModalVisible, setMembersModalVisible] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const memberColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('Email')
    },
    {
      key: 'status',
      title: t('Status'),
      render: (text, record) => (record.userId ? t('ACTIVE') : t('PENDING'))
    },
    {
      key: 'professionalDeputyId',
      title: t('professional Deputy'),
      dataIndex: 'professionalDeputyId',
      render: text =>
        professionalDeputies?.find(pd => pd.id === text)?.professionalDeputyName
    },
    {
      key: 'actions',
      width: 20,
      render: (text, record) => (
        <div className="asset-liability-actions">
          <Popconfirm
            title={t('ARE_YOU_SURE_TO_REMOVE_THIS_MEMBER')}
            onConfirm={() =>
              removeMember(
                record.email,
                record.userId,
                null,
                record.professionalDeputyId
              )
            }
            okText={t('YES')}
            cancelText={t('NO')}
            arrowPointAtCenter
            placement="bottomRight"
          >
            <Icon type="delete" />
          </Popconfirm>
        </div>
      )
    }
  ]

  return (
    <Fragment>
      <SimpleHeader
        title={<H4>{t('MEMBERS')}</H4>}
        extra={
          <Button
            disabled={disabledAddMember}
            onClick={() => setMembersModalVisible(true)}
          >
            {t('ADD_MEMBERS')}
          </Button>
        }
        size="small"
      />
      <Table
        dataSource={members}
        columns={memberColumns}
        rowKey="email"
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('Empty')}
            />
          )
        }}
        size={isMdUp ? '' : 'middle'}
      />

      <AddMembersModal
        visible={membersModalVisible}
        setVisible={setMembersModalVisible}
        fetchUser={() => dispatch(fetchUser(user.username))}
        members={members}
      />
    </Fragment>
  )
}

import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
  Checkbox,
  Steps,
  Descriptions,
  Alert,
  Result,
  Icon,
  Tooltip,
  Divider,
  Modal
} from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { getUserPool } from '../../lib/cognito'
//import { StringResources } from '../../share/StringResources'
import { randomBytes } from 'crypto'
import { AES, enc } from 'crypto-js'
import NodeRSA from 'node-rsa'
import PersonalInfo from '../common/PersonalInfo'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import { P1, H3 } from '../override/Typography'
import shortid from 'shortid'
import { H4 } from '../override/Typography'
import SubscriptionPlans from '../payment/SubscriptionPlans'
import usePlans from '../../hooks/usePlans'
import api, { setProviderApiAuthorization } from '../../lib/api'
import { GApageView, GAmodalView, GAevent } from '../../lib/ga'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'
import {
  sanitizeValue,
  getExternalUserAttributes,
  getPlanNickname
} from './../../share/helpers'
import i18next from 'i18next'
import config from '../../config'
import { ThemeContext } from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import generator from 'generate-password'

const { Step } = Steps
const modalName = currentStep => {
  switch (currentStep) {
    case 0:
      return 'Register details'
    case 1:
      return 'Register subscription plans'
    case 2:
      return 'Register review'
    default:
      return null
  }
}

function SignupForm(props) {
  const { user, isModal, visible, setVisible, initUserData } = props
  const [errMsg, setErrMsg] = useState('')
  const [hasAgreed, setHasAgreed] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [personalInfo, setPersonalInfo] = useState({})
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState({
    id: '',
    nickname: '',
    amount: 0,
    interval: '',
    currency: ''
  })
  const [appliedReferralCode, setAppliedReferralCode] = useState('')
  const [preselectedPlan, setPreselectedPlan] = useState('')
  const [promoCode, setPromoCode] = useState('')
  const [promotionInfo, setPromotionInfo] = useState(null)
  const [isCheckingCodeValidity, setIsCheckingCodeValidity] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const [currencyCode, setCurrencyCode] = useState('')
  const [giftInfo, setGiftInfo] = useState(null)
  const [referredProfessionalDeputyId, setReferredProfessionalDeputyId] =
    useState('')
  const { t } = useTranslation()
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const primaryUserName = urlSearchParams.get('username')
  const [email, setEmail] = useState(urlSearchParams.get('email'))
  const fullname = urlSearchParams.get('fullname')
  const professionalDeputyId = urlSearchParams.get('professionalDeputyId')
  const planParam = urlSearchParams.get('plan')
  const groupParam = urlSearchParams.get('group')
  const referralCodeParam = urlSearchParams.get('referralCode')
  const referredProfessionalDeputyIdParam = urlSearchParams.get(
    'referredProfessionalDeputyId'
  )
  const discountCode = urlSearchParams.get('discountCode')
  const promoCodeParam = urlSearchParams.get('promotionCode')
  const sender = urlSearchParams.get('sender')
  const giftId = urlSearchParams.get('giftId')

  const plans = usePlans(discountCode, true)
  const { defaultPlans } = plans
  const monthlyPlan = defaultPlans.find(plan => plan.nickname === 'Monthly')
  const externalUser = localStorage.getItem('External_User')
  const idToken = localStorage.getItem('id_token')

  const url = new URL(window.location).hash
  const accessToken = url.substring(url.indexOf('=') + 1, url.indexOf('&'))
  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'))
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  let personalInfoForm
  const personalInfoFormRef = fr => {
    personalInfoForm = fr
  }

  useEffect(() => {
    if (!!monthlyPlan && externalUser) {
      setSelectedPlan({
        id: monthlyPlan.id,
        nickname: getPlanNickname(monthlyPlan.nickname),
        amount: monthlyPlan.amount,
        interval: monthlyPlan.interval,
        currency: monthlyPlan.currency,
        metadata: monthlyPlan.metadata
      })
    }
  }, [monthlyPlan, externalUser])

  useEffect(() => {
    if (!!externalUser) {
      const userAttributes = getExternalUserAttributes()
      const fullname = userAttributes.full_name
      const email = userAttributes.email
      setEmail(email)
      setPersonalInfo({ fullname: fullname })
    }
  }, [externalUser])

  useEffect(() => {
    if ((primaryUserName || professionalDeputyId) && email) {
      GApageView('deputy-register')
    } else {
      GApageView('register')
    }
  }, [primaryUserName, professionalDeputyId, email])

  useEffect(() => {
    GAmodalView(modalName(currentStep))
    if (!isModal) {
      window.scrollTo(0, 0)
    }
  }, [currentStep, isModal])

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const res = await api.getIpStackInfo()
        setCountryCode(res.data.country_code)
        setCurrencyCode(res.data.currency_code)
      } catch (error) {
        onError(error)
        console.log('Fail to get country code')
      }
    }

    fetchCountryCode()
  }, [])

  useEffect(() => {
    if (fullname) {
      setPersonalInfo({ fullname: fullname })
    }
  }, [fullname])

  useEffect(() => {
    if (planParam && planParam !== 'free') {
      let preselectedPlan
      const vaultboxPlans = plans.customPlans?.length
        ? plans.customPlans
        : plans.defaultPlans

      if (groupParam) {
        preselectedPlan = vaultboxPlans.find(
          p =>
            p.nickname?.toLowerCase() === planParam.toLowerCase() &&
            p.metadata?.membersCode
        )
      } else {
        preselectedPlan = vaultboxPlans.find(
          p =>
            p.nickname?.toLowerCase() === planParam.toLowerCase() ||
            p.id === planParam
        )
      }

      if (preselectedPlan) {
        setSelectedPlan({
          id: preselectedPlan.id,
          nickname: getPlanNickname(preselectedPlan.nickname, !!groupParam),
          amount: preselectedPlan.amount,
          interval: preselectedPlan.interval,
          currency: preselectedPlan.currency,
          metadata: preselectedPlan.metadata
        })
        setPreselectedPlan(planParam)
      }
    }
  }, [planParam, plans, groupParam])

  useEffect(() => {
    // this can't be merged with the similar useEffect above
    // as it will introduce an endless loop of re-rendering
    if (planParam === 'free') {
      setSelectedPlan({
        id: 'free',
        nickname: 'vaultbox Essential'
      })
      setPreselectedPlan(planParam)
    }
  }, [planParam])

  useEffect(() => {
    if (referralCodeParam) {
      setAppliedReferralCode(referralCodeParam)
    }
  }, [referralCodeParam])

  useEffect(() => {
    if (promoCodeParam) {
      setPromoCode(promoCodeParam)
    }
  }, [promoCodeParam])

  useEffect(() => {
    const fetchGiftInfo = async () => {
      try {
        const res = await api.getGift(sender, giftId)
        const gift = res?.data
        if (gift?.receiver) {
          setPersonalInfo({ email: gift.receiver })
          setGiftInfo(gift)
        }
      } catch (err) {
        onError(err)
      }
    }
    if (sender && giftId) {
      fetchGiftInfo()
    }
  }, [sender, giftId])

  useEffect(() => {
    if (referredProfessionalDeputyIdParam)
      setReferredProfessionalDeputyId(referredProfessionalDeputyIdParam)
  }, [referredProfessionalDeputyIdParam])

  const setMasterKey = async () => {
    const dynamoResponse = await api.getUser(user.username)
    const cognitoResponse = await api.getExternalUserAttributes(user.username)
    const userAttributes = cognitoResponse.data.cognitoUser.UserAttributes
    const extraKey = dynamoResponse.data.extraKey
    const encryptedPassword = dynamoResponse.data.encryptedPassword
    const password = AES.decrypt(encryptedPassword, extraKey).toString(
      enc.Latin1
    )
    const encryptedMasterKey =
      userAttributes.find(a => a.Name === 'custom:master_key')?.Value || ''
    const masterKey = AES.decrypt(encryptedMasterKey, password).toString(
      enc.Latin1
    )
    const encryptedKey = AES.encrypt(masterKey, extraKey).toString()
    localStorage.setItem(user.username, encryptedKey)
    localStorage.setItem('UserAttributes', JSON.stringify(userAttributes))
  }

  const handlePersonalInfoNext = () => {
    personalInfoForm.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return
      }

      if (promoCode) {
        const res = await api.getPromotionCode(promoCode, values.email)
        if (!res?.data?.valid) {
          setErrMsg(t('INVALID_PROMOTION_CODE'))
          return
        }

        if (!preselectedPlan && res?.data?.appliesToPlan) {
          const selectPlan = defaultPlans.find(
            plan => plan.id === res?.data?.appliesToPlan
          )
          setSelectedPlan({
            id: selectPlan.id,
            nickname: getPlanNickname(selectPlan.nickname),
            amount: selectPlan.amount,
            interval: selectPlan.interval,
            currency: selectPlan.currency,
            metadata: selectPlan.metadata
          })
          setPreselectedPlan(res?.data?.appliesToPlan)
        }

        if (
          res?.data?.appliesToPlan &&
          selectedPlan?.id &&
          res.data.appliesToPlan !== selectedPlan.id
        ) {
          setErrMsg(t('PROMOTION_CODE_MSG'))
          return
        }

        if (
          res?.data?.groups?.length &&
          !res.data.groups?.find(g =>
            g.members.map(m => m.email).includes(values.email)
          )
        ) {
          setErrMsg(t(`NOT_BE_INVITED_TO_GROUP_MSG`))
          return
        }

        setPromotionInfo(res.data)
      }

      if (sender && giftId) {
        if (!giftInfo) {
          setErrMsg(t('GIFT_INFO_NOT_FOUND'))
          return
        }
        if (giftInfo.receiver !== values.email) {
          setErrMsg(t('GIFT_RECEIVER_EMAIL_MISSMATCH'))
          return
        }
        if (giftInfo.plan !== selectedPlan.nickname) {
          setErrMsg(t('GIFT_PLAN_MISSMATCH'))
          return
        }
      }

      setErrMsg('')
      GAevent({
        category: 'Register',
        action: 'Register details completed'
      })
      setPersonalInfo({ ...personalInfo, ...values })
      setCurrentStep(currentStep + 1)
    })
  }

  const handlePreviewPrevious = () => {
    setErrMsg('')
    setCurrentStep(currentStep - 1)
    GAevent({
      category: 'Register',
      action: 'Clicked Previous',
      label: 'Review step'
    })
  }

  const handleSubscriptionNext = async () => {
    if (!selectedPlan.id) {
      setErrMsg(t('SUBSCRIPTION_PLAN_EMPTY_MSG'))
    } else {
      if (appliedReferralCode || promoCode) {
        try {
          setIsCheckingCodeValidity(true)
          if (appliedReferralCode) {
            const res = await api.getReferralCodeInfo(appliedReferralCode)

            if (res.data?.professionalDeputyId)
              setReferredProfessionalDeputyId(res.data.professionalDeputyId)
          }

          if (promoCode) {
            const res = await api.getPromotionCode(
              promoCode,
              personalInfo.email
            )
            if (!res?.data?.valid) {
              throw Error(t('INVALID_PROMOTION_CODE'))
            }
            if (
              res?.data?.appliesToPlan &&
              res.data.appliesToPlan !== selectedPlan.id
            ) {
              throw Error(t('PROMOTION_CODE_MSG'))
            }

            setPromotionInfo(res.data)
          }

          setErrMsg('')
          setIsCheckingCodeValidity(false)
          GAevent({
            category: 'Register',
            action: 'Register plan completed'
          })
          setCurrentStep(currentStep + 1)
        } catch (error) {
          setIsCheckingCodeValidity(false)
          setErrMsg(t('FAILED_TO_CHECK_CODE_VALIDATE'))
          onError(error)
        }
      } else {
        setPromotionInfo(null)
        setErrMsg('')
        GAevent({
          category: 'Register',
          action: 'Register plan completed'
        })
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const handleSubscriptionPrevious = () => {
    setCurrentStep(currentStep - 1)
    GAevent({
      category: 'Register',
      action: 'Clicked Previous',
      label: 'Subscription plans step'
    })
  }

  const handleSubmit = async (values = null) => {
    setErrMsg('')
    setIsSubmitting(true)
    const info = values || personalInfo
    const userPool = getUserPool()
    // generating user's master encryption key
    const masterKey = randomBytes(20).toString('hex')
    const password = generator.generate({
      numbers: true,
      symbols: true
    })
    const encryptedMasterKey = externalUser
      ? AES.encrypt(masterKey, password).toString()
      : AES.encrypt(masterKey, info.password).toString()

    // generating public & private keys
    const key = new NodeRSA()
    key.generateKeyPair(1024) // while it's recommended to have key size as 2048, need to use this size to fit the max length of Cognito's user attribute
    const publicKey = key.exportKey('public')
    const privateKey = key.exportKey('pkcs8')

    const encryptedPrivateKey = AES.encrypt(privateKey, masterKey).toString()

    const formData = [
      {
        Name: 'email',
        Value: info.email
      },
      {
        Name: 'phone_number',
        Value: info.phone ? info.prefix + info.phone : ''
      },
      {
        Name: 'custom:preferred_name',
        Value: sanitizeValue(info.fullname)
      },
      {
        Name: 'custom:full_name',
        Value: sanitizeValue(info.fullname)
      },
      {
        Name: 'custom:master_key',
        Value: encryptedMasterKey
      },
      {
        Name: 'custom:public_key',
        Value: publicKey
      },
      {
        Name: 'custom:private_key',
        Value: encryptedPrivateKey
      },
      {
        Name: 'custom:pending_primary',
        Value: primaryUserName
      },
      {
        Name: 'custom:government_id',
        Value: info.govId
      },
      {
        Name: 'custom:prefix',
        Value: info.prefix
      },
      {
        Name: 'custom:base_currency',
        Value: info.baseCurrency || ''
      },
      {
        Name: 'custom:referral_code',
        Value: shortid.generate()
      },
      {
        Name: 'custom:applied_referral',
        Value: appliedReferralCode
      },
      {
        Name: 'custom:plan_id',
        Value: selectedPlan.id
      },
      {
        Name: 'custom:professional_deputy',
        Value: professionalDeputyId
      },
      {
        Name: 'custom:created_at',
        Value: new Date().toJSON()
      },
      {
        Name: 'custom:referred_pd',
        Value: referredProfessionalDeputyId
      },
      {
        Name: 'custom:discount_code',
        Value: discountCode
      },
      {
        Name: 'custom:applied_promo',
        Value: promotionInfo?.id
      },
      {
        Name: 'custom:marketing_consent',
        Value: JSON.stringify({
          fromVaultbox: true,
          fromThirdParties: true
        })
      },
      {
        Name: 'custom:country',
        Value: JSON.stringify(info.country)
      },
      {
        Name: 'custom:gift_info',
        Value: JSON.stringify(
          giftId && sender
            ? {
                id: giftId,
                sender
              }
            : {}
        )
      },
      {
        Name: 'custom:default_language',
        Value: i18next.language
      }
    ]
    const attributeList = formData.map(item => {
      return new CognitoUserAttribute(item)
    })

    if (externalUser) {
      try {
        setProviderApiAuthorization(idToken)
        await api.getUser(user.username).then(async res => {
          const extraKey = res.data.extraKey
          const encryptedPassword = AES.encrypt(password, extraKey).toString()
          await api.updateExternalUser(
            user.username,
            JSON.stringify({
              attributeList: formData,
              encryptedPassword: encryptedPassword
            })
          )
          await setMasterKey()
          setVisible(false)
          initUserData()
        })
      } catch (error) {
        setVisible(false)
        onError(error)
      }
    } else {
      userPool.signUp(
        info.email,
        info.password,
        attributeList,
        null,
        function (err, result) {
          setIsSubmitting(false)
          if (err) {
            setErrMsg(t('FAILED_TO_REGISTER_USER'))
            return
          }

          setHasSubmitted(true)
          GAevent({
            category: 'Register',
            action: 'Register successfully submitted'
          })

          GAmodalView('Account created')
        }
      )
    }
  }

  const handleQuickSubmit = () => {
    GAevent({
      category: 'Register',
      action: 'Clicked Submit (for deputy)'
    })
    personalInfoForm.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return
      }

      handleSubmit(values)
    })
  }

  const handleAgreementChange = e => {
    setHasAgreed(e.target.checked)
  }

  const steps = [
    {
      title: t('DETAILS'),
      content: (
        <>
          <PersonalInfo
            isModal={isModal}
            wrappedComponentRef={personalInfoFormRef}
            email={email}
            personalInfo={personalInfo}
            countryCode={countryCode}
            currencyCode={currencyCode}
            promoCode={promoCode}
          />
          {!isModal && (
            <P1>
              <Trans i18nKey="PERSONAL_INFO_DESCRIPTION"></Trans>
            </P1>
          )}

          {errMsg && (
            <Alert
              style={{ marginTop: 10 }}
              description={errMsg}
              closable
              afterClose={() => setErrMsg('')}
              type="error"
            />
          )}
          <div className="steps-actions">
            <Button
              size="large"
              type="primary"
              onClick={handlePersonalInfoNext}
            >
              {t('NEXT')}
            </Button>
          </div>
          {!accessToken && !isModal && (
            <>
              <Divider>{t('OR')}</Divider>
              <button
                type="button"
                onClick={() => {
                  window.location.href = `${config.cognito.COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${config.cognito.COGNITO_CALLBACK_URL}&response_type=TOKEN&client_id=${config.cognito.APP_CLIENT_ID}&scope=email openid phone profile`
                }}
                class="btn google-button socialButton-customizable"
                style={isMdUp ? { width: '40%', margin: '0 0 0 8%' } : {}}
              >
                <span>
                  <svg
                    class="social-logo"
                    viewBox="0 0 256 262"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                  >
                    <path
                      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                      fill="#34A853"
                    ></path>
                    <path
                      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                      fill="#EA4335"
                    ></path>
                  </svg>
                </span>
                <span>{t('LOGIN_WITH_GOOGLE')}</span>
              </button>
              <button
                type="button"
                onClick={() => {
                  window.location.href = `${config.cognito.COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Facebook&redirect_uri=${config.cognito.COGNITO_CALLBACK_URL}&response_type=TOKEN&client_id=${config.cognito.APP_CLIENT_ID}&scope=email openid phone profile`
                }}
                class="btn facebook-button socialButton-customizable"
                style={isMdUp ? { width: '40%', margin: '0 0 0 4%' } : {}}
              >
                <span>
                  <svg
                    class="social-logo"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 216 216"
                    color="#ffffff"
                  >
                    <path
                      fill="#ffffff"
                      d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"
                    ></path>
                  </svg>
                </span>
                <span>{t('LOGIN_WITH_FACEBOOK')}</span>
              </button>
            </>
          )}
        </>
      )
    },
    {
      title: t('SUBSCRIPTION_PLANS'),
      hidden: !!preselectedPlan,
      content: (
        <>
          <SubscriptionPlans
            showReferralCodeInput
            showPromoCodeInput
            plans={plans}
            currentPlanId={selectedPlan.id}
            setReferralCode={setAppliedReferralCode}
            referralCode={appliedReferralCode}
            setPromoCode={setPromoCode}
            promoCode={promoCode}
            setSelectedPlan={setSelectedPlan}
          />
          {errMsg && (
            <Alert
              style={{ marginTop: 10 }}
              description={errMsg}
              closable
              afterClose={() => setErrMsg('')}
              type="error"
            />
          )}
          <div className="steps-actions">
            <Button
              size="large"
              type="default"
              onClick={handleSubscriptionPrevious}
            >
              {t('PREVIOUS')}
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={handleSubscriptionNext}
              loading={isCheckingCodeValidity}
            >
              {t('NEXT')}
            </Button>
          </div>
        </>
      )
    },
    {
      title: t('REVIEW'),
      content: (
        <>
          <Descriptions
            title={t('PERSONAL_INFORMATION')}
            column={{ xs: 1, sm: 1, md: 2 }}
          >
            <Descriptions.Item label={t('NAME')}>
              {personalInfo.fullname}
            </Descriptions.Item>
            <Descriptions.Item label={t('COUNTRY_TERRITORY')}>
              {personalInfo.country?.name}
            </Descriptions.Item>
            {personalInfo.baseCurrency && (
              <Descriptions.Item label={t('BASE_CURRENCY')}>
                {personalInfo.baseCurrency}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={t('EMAIL')}>
              {personalInfo.email}
            </Descriptions.Item>
            <Descriptions.Item label={t('PHONE_NUMBER')}>
              {personalInfo.prefix} {personalInfo.phone}
            </Descriptions.Item>
            {personalInfo.govId && (
              <Descriptions.Item label={t('PASSPORT_NUMBER')}>
                {personalInfo.govId}
              </Descriptions.Item>
            )}
          </Descriptions>
          <Descriptions
            title={t('SUBSCRIPTION')}
            column={1}
            style={{ marginTop: 16 }}
          >
            <Descriptions.Item label={t('PLAN')}>
              {selectedPlan.nickname}
            </Descriptions.Item>
            <Descriptions.Item label={t('PRICE')}>
              {selectedPlan.id === 'free' ? (
                t('FREE')
              ) : (
                <>
                  {selectedPlan.currency.toUpperCase()}${' '}
                  <H4 display="inline">{selectedPlan.amount / 100}</H4> /{' '}
                  {t(selectedPlan.interval)}
                </>
              )}
            </Descriptions.Item>
            {appliedReferralCode && selectedPlan.id !== 'free' && (
              <Descriptions.Item label={t('APPLIED_REFERRAL_CODE')}>
                {appliedReferralCode}
              </Descriptions.Item>
            )}
          </Descriptions>
          {promotionInfo && (
            <Descriptions
              title={t('APPLIED_PROMOTION')}
              column={1}
              style={{ marginTop: 16 }}
            >
              <Descriptions.Item label={t('PROMOTION_NAME')}>
                {promotionInfo.name}
              </Descriptions.Item>
              {promotionInfo.percent_off && (
                <Descriptions.Item label={t('PERCENTAGE_DISCOUNT')}>
                  {promotionInfo.percent_off}%
                </Descriptions.Item>
              )}
              {promotionInfo.amount_off && (
                <Descriptions.Item label={t('FIXED_AMOUNT_DISCOUNT')}>
                  ${promotionInfo.amount_off / 100}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t('DURATION')}>
                {promotionInfo.duration === 'repeating'
                  ? `${promotionInfo.duration_in_months} ${t('MONTHS')}`
                  : promotionInfo.duration === 'once'
                  ? t('ONCE')
                  : promotionInfo.duration === 'forever'
                  ? t('FOREVER')
                  : ''}
              </Descriptions.Item>
            </Descriptions>
          )}
          <FormItem>
            <Checkbox onChange={handleAgreementChange} checked={hasAgreed}>
              {t('READ_AND_AGREE_TERM_OF_SERVICE')}{' '}
              <a href="/terms-of-service" target="_blank">
                {t('TERMS_OF_SERVICE')}
              </a>
            </Checkbox>
          </FormItem>
          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 24 }}
              afterClose={() => setErrMsg('')}
            />
          )}
          <div className="steps-actions">
            <Button size="large" onClick={handlePreviewPrevious}>
              {t('PREVIOUS')}
            </Button>
            <div style={{ float: 'right' }}>
              <Tooltip
                title={hasAgreed ? null : t('NOT_AGREE_TERMS_OF_SERVICE_MSG')}
              >
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    GAevent({
                      category: 'Register',
                      action: 'Clicked Submit'
                    })
                    handleSubmit()
                  }}
                  disabled={!hasAgreed}
                  loading={isSubmitting}
                >
                  {t('SUBMIT')}
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      )
    }
  ]

  return isModal ? (
    <Modal
      title={
        <span>
          <H3>{t('REGISTER')}</H3>
          <P1>{t('REGISTER_DESCRIPTION')}</P1>
        </span>
      }
      visible={visible}
      footer={null}
      closable={false}
      width={
        currentStep === 0
          ? '640px'
          : isXlUp
          ? '1200px'
          : isLgUp
          ? '900px'
          : '600px'
      }
    >
      <Fragment>
        <Steps current={currentStep} style={{ marginBottom: 32 }}>
          {steps
            .filter(s => !s.hidden)
            .map(item => (
              <Step key={item.title} title={item.title} />
            ))}
        </Steps>
        <div className="steps-content">{steps[currentStep].content}</div>
      </Fragment>
    </Modal>
  ) : !hasSubmitted ? (
    <div className="signup-form">
      <div className="form-header">
        <H3>{t('REGISTER')}</H3>
        <P1>{t('REGISTER_DESCRIPTION')}</P1>
      </div>
      {(primaryUserName || professionalDeputyId) && email ? (
        <>
          <PersonalInfo
            wrappedComponentRef={personalInfoFormRef}
            email={email}
            personalInfo={personalInfo}
            countryCode={countryCode}
          />
          <FormItem>
            <Checkbox onChange={handleAgreementChange} checked={hasAgreed}>
              {t('READ_AND_AGREE_TERM_OF_SERVICE')}{' '}
              <a href="/terms-of-service" target="_blank">
                {t('TERMS_OF_SERVICE')}
              </a>
            </Checkbox>
          </FormItem>

          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 24 }}
              afterClose={() => setErrMsg('')}
            />
          )}
          <Button
            size="large"
            type="primary"
            block
            onClick={handleQuickSubmit}
            disabled={!hasAgreed}
            loading={isSubmitting}
          >
            {t('CREATE_A_NEW_ACCOUNT')}
          </Button>
        </>
      ) : (
        <>
          <Steps current={currentStep} style={{ marginBottom: 32 }}>
            {steps
              .filter(s => !s.hidden)
              .map(item => (
                <Step key={item.title} title={item.title} />
              ))}
          </Steps>
          <div className="steps-content">
            {steps.filter(s => !s.hidden)[currentStep].content}
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="submitted-result">
      <Result
        icon={
          <Icon
            type="check-circle"
            theme="filled"
            style={{ color: '#02884E' }}
          />
        }
        title={t('ACCOUNT_CREATED')}
        subTitle={
          <Trans
            i18nKey="ACCOUNT_CREATED_SUCCESSFULLY_MSG"
            values={{ email: personalInfo.email }}
          ></Trans>
        }
        extra={
          <Button size="large" type="primary">
            <Link to="/login">{t('RETURN_TO_HOMEPAGE')}</Link>
          </Button>
        }
      />
    </div>
  )
}

export default SignupForm

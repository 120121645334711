import Liability, { liabilityColumns } from './LiabilityModel'
import { referenceColumns } from './AssetLiabilityModel'
import { LIABILITY_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class LoanFromOthers extends Liability {
  /**
   * Construct a new Loan instance
   * @param {object} params - Loan creation options:
   * @param {string} [params.interestRate]
   * @param {string} [params.loanPeriod]
   * @param {string} [params.numberOfRepayments]
   * @param {string} [params.lender]
   * @param {string} [params.securityCollateral]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    startDate,
    interestRate,
    loanPeriod,
    numberOfRepayments,
    lender,
    securityCollateral,
    maturityDate,
    currency,
    valuationDate,
    valuationDateInBaseCurrency,
    outstandingValueInLiabilityCurrency,
    outstandingValueInBaseCurrency,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords,
    tags
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      startDate,
      maturityDate,
      valuationDateInBaseCurrency,
      currency,
      valuationDate,
      outstandingValueInLiabilityCurrency,
      outstandingValueInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords,
      tags
    })

    this.subType = LIABILITY_TYPES.LOAN_FROM_OTHERS
    this.interestRate = interestRate
    this.loanPeriod = loanPeriod
    this.numberOfRepayments = numberOfRepayments
    this.lender = lender
    this.securityCollateral = securityCollateral
  }
}

export default LoanFromOthers

export const loanFromOtherColumns = () => [
  ...liabilityColumns(),
  {
    key: 'startDate',
    title: i18next.t('LIABILITY_START_DATE'),
    type: IMPORT_COL_TYPES.DATE,
    required: true
  },
  {
    key: 'maturityDate',
    title: i18next.t('LIABILITY_MATURITY_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'interestRateValue',
    title: i18next.t('INTEREST_RATE_VALUE'),
    dataIndex: 'interestRate',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.PERCENTAGE
  },
  {
    key: 'interestRateUnit',
    title: i18next.t('INTEREST_RATE_UNIT'),
    dataIndex: 'interestRate',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'loanPeriodValue',
    title: i18next.t('LOAN_PERIOD_VALUE'),
    dataIndex: 'loanPeriod',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.NUMBER
  },
  {
    key: 'loanPeriodUnit',
    title: i18next.t('LOAN_PERIOD_UNIT'),
    dataIndex: 'loanPeriod',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'numberOfRepayments',
    title: i18next.t('NUMBER_OF_REPAYMENTS'),
    type: IMPORT_COL_TYPES.NUMBER
  },
  {
    key: 'securityCollateral',
    title: i18next.t('SECURITY_COLLATERAL')
  },
  ...referenceColumns()
]

import { filterEmptyEls } from '../share/helpers'
import { IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

// TODO: should this extend the BaseModel? and maybe separate the PouchDB params?
class AssetLiabilityModel {
  /**
   * Construct a new AssetLiabilityModel instance
   * @param {object} params - AssetLiabilityModel creation options:
   * @param {string} [params._id]
   * @param {string} [params._rev]
   * @param {boolean} [params._deleted]
   * @param {string} [params.title]
   * @param {string} [params.description]
   * @param {string} [params.descriptionWithMarkup]
   * @param {string} [params.percentageOwnership]
   * @param {string} [params.currency]
   * @param {boolean} [params.includeValueInNetWorth]
   * @param {date} [params.valuationDate]
   * @param {date} [params.valuationDateInBaseCurrency]
   * @param {any} [params.contacts]
   * @param {any} [params.references]
   * @param {any} [params.links]
   * @param {any} [params.documents]
   * @param {string} [params.status]
   * @param {any} [params.events]
   * @param {any} [params.passwords]
   * @param {any} [params.tags]
   */

  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    currency,
    valuationDate,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    valuationDateInBaseCurrency,
    status,
    events,
    passwords,
    tags
  }) {
    this._id = _id
    this._rev = _rev
    this._deleted = _deleted
    this.title = title
    this.description = description
    this.descriptionWithMarkup = descriptionWithMarkup
    this.percentageOwnership = percentageOwnership
    this.currency = currency
    this.valuationDate = valuationDate && valuationDate.startOf('day')
    this.valuationDateInBaseCurrency = valuationDateInBaseCurrency
    this.contacts = contacts
    this.references = filterEmptyEls(references)
    this.links = links
    this.documents = documents
    this.includeValueInNetWorth = includeValueInNetWorth
    this.status = status
    this.events = events
    this.passwords = passwords
    this.tags = tags
  }
}

export default AssetLiabilityModel

export const baseAssetLiabilityColumns = () => [
  {
    key: 'title',
    title: i18next.t('TITLE'),
    required: true
  },
  {
    key: 'description',
    title: i18next.t('DESCRIPTION')
  },
  {
    key: 'percentageOwnership',
    title: i18next.t('PERCENTAGE_OWNERSHIP'),
    type: IMPORT_COL_TYPES.PERCENTAGE
  },
  {
    key: 'valuationDate',
    title: i18next.t('VALUATION_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'valuationDateInBaseCurrency',
    title: i18next.t('VALUATION_DATE_IN_BASE_CURRENCY'),
    type: IMPORT_COL_TYPES.DATE,
    ignore: true
  },
  {
    key: 'currency',
    title: i18next.t('CURRENCY'),
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'includeValueInNetWorth',
    title: i18next.t('INCLUDE_IN_NETWORTH'),
    type: IMPORT_COL_TYPES.BOOLEAN
  }
]

export const referenceColumns = () => [
  {
    key: 'reference1Name',
    title: i18next.t('REFERENCE_NAME_WITH_INDEX', {
      index: 1
    })
  },
  {
    key: 'reference1Value',
    title: i18next.t('REFERENCE_VALUE_WITH_INDEX', {
      index: 1
    })
  },
  {
    key: 'reference2Name',
    title: i18next.t('REFERENCE_NAME_WITH_INDEX', {
      index: 2
    })
  },
  {
    key: 'reference2Value',
    title: i18next.t('REFERENCE_VALUE_WITH_INDEX', {
      index: 2
    })
  },
  {
    key: 'reference3Name',
    title: i18next.t('REFERENCE_NAME_WITH_INDEX', {
      index: 3
    })
  },
  {
    key: 'reference3Value',
    title: i18next.t('REFERENCE_VALUE_WITH_INDEX', {
      index: 3
    })
  }
]

import { useEffect, useState } from 'react'
import { PRODUCT_NAME } from '../share/Constants'
import api from '../lib/api'
import { onError } from '../lib/sentry'
import { useTranslation } from 'react-i18next'

export default function usePlans(discountCode, shouldFetch) {
  const [defaultPlans, setDefaultPlans] = useState([])
  const [defaultPdPlans, setDefaultPdPlans] = useState([])
  const [customPlans, setCustomPlans] = useState([])
  const [customPdPlans, setCustomPdPlans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    const fetchPlans = async () => {
      setIsLoading(true)
      try {
        const res = await Promise.all([
          api.getPlans(PRODUCT_NAME.VAULTBOX),
          api.getPlans(PRODUCT_NAME.PROFESSIONAL_DEPUTY)
        ])

        if (res[0].data.message || res[1].data.message)
          throw Error(res[0].data.message || res[1].data.message)

        if (res[0].data.data?.length) {
          const allVaultboxPlans = res[0].data.data

          const defaultVaultboxPlans = allVaultboxPlans.filter(
            p => !p.metadata.discountCode
          )
          setDefaultPlans(
            defaultVaultboxPlans.sort((a, b) => a.amount - b.amount)
          )

          if (discountCode) {
            const customVaultboxPlans = allVaultboxPlans.filter(
              p => p.metadata.discountCode === discountCode
            )
            setCustomPlans(
              customVaultboxPlans.sort((a, b) =>
                a.amount && b.amount
                  ? a.amount - b.amount
                  : a.interval.localeCompare(b.interval)
              )
            )
          }
        }

        if (res[1].data.data?.length) {
          const allPdPlans = res[1].data.data

          const defaultPdPlans = allPdPlans.filter(
            p => !p.metadata.discountCode
          )
          setDefaultPdPlans(defaultPdPlans.sort((a, b) => a.amount - b.amount))

          if (discountCode) {
            const customPdPlans = allPdPlans.filter(
              p => p.metadata.discountCode === discountCode
            )
            setCustomPdPlans(
              customPdPlans.sort((a, b) =>
                a.amount && b.amount
                  ? a.amount - b.amount
                  : a.interval.localeCompare(b.interval)
              )
            )
          }
        }
      } catch (err) {
        onError(err)
        setError(t('FAILED_TO_GET_PLAN'))
      } finally {
        setIsLoading(false)
      }
    }

    if (shouldFetch) fetchPlans()
  }, [discountCode, shouldFetch, t])

  return {
    defaultPlans,
    defaultPdPlans,
    customPlans,
    customPdPlans,
    isLoading,
    error
  }
}

import React, { useState, useEffect, useContext } from 'react'
import {
  Input,
  DatePicker,
  InputNumber,
  Select,
  Divider,
  Icon,
  Tooltip,
  Row,
  Col,
  Checkbox
} from 'antd'
import {
  DATE_FORMAT,
  CORE_TYPES,
  ASSET_TYPES,
  LIABILITY_TYPES,
  BUILDING_TYPES,
  HOLDING_TYPES,
  SQUARE_UNITS,
  LOAN_PERIOD_UNITS,
  FIELDS,
  EQUITY_LISTED_DEFAULT_REFERENCES,
  ENTITY_TYPES,
  TYPE_OF_POLICY_DEFAULT_OPTIONS,
  PREMIUM_UNITS,
  ACCOUNT_TYPES,
  RENT_AMOUNT_UNITS,
  DEFAULT_OTHER_ASSET_TYPES,
  INTEREST_RATE_UNITS,
  //LENGTH_OF_LOAN_UNITS,
  DEFAULT_INTEREST_RATE_TYPES,
  DEFAULT_CLASS_OF_SHARES,
  DEFAULT_RETIREMENT_ACCOUNT_TYPES,
  CONTACT_TYPES,
  ASSET_TYPES_WITHOUT_PURCHASE_DATE,
  ASSET_TYPES_WITHOUT_DISPOSAL_DATE,
  DEFAULT_TYPE_OF_TRUST_INTEREST,
  DEFAULT_TRUST_TYPE,
  TRUST_REVOCABILITY,
  TYPE_OF_TRUST_INTEREST_PLURAL_MAP
} from '../../share/Constants'
import CurrencySelect from '../common/CurrencySelect'
//import { StringResources } from '../../share/StringResources'
import moment from 'moment'
import {
  currencyInputFormat,
  currencyParse,
  disabledDate,
  getOptions,
  getReferencesTooltip,
  purchaseDateLabel,
  purchaseDateMessage,
  sanitizeValue,
  validateAlphaNumericDash
} from '../../share/helpers'
import { MentionsInput, Mention } from 'react-mentions'
import AddMultipleFields from '../common/AddMultipleFields'
import VaultContext from '../../contexts/VaultContext'
import MICS from '../../share/CommonMICs'
import { CRYPTO_EXCHANGES, CRYPTO_CURRENCIES } from '../../share/CommonCryptos'
import { validateMinValue } from '../../share/formHelpers'
import AddItemModal from '../modals/AddItemModal'
import FormItem from '../override/FormItem'
import { useSelector } from 'react-redux'
import ContactSelect from '../common/ContactSelect'
import SelectWithOther from '../common/SelectWithOther'
import CurrencyInputWithCode from './../common/CurrencyInputWithCode'
import CryptoCurrencySelect from '../common/CryptoCurrencySelect'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'
import { H4 } from '../override/Typography'
import { removeHtmlTags } from './../../share/helpers'
import AddEventModal from '../calendar/AddEventModal'
import ConvertToEvent from '../common/ConvertToEvent'
import FileTags from '../file/FileTags'

const { TextArea } = Input

const buildingTypes = Object.values(BUILDING_TYPES)

export default function AssetLiabilityForm({
  title,
  record,
  selectedType,
  selectedSubType,
  form,
  references,
  descriptionValue,
  setDescriptionValue,
  descriptionExtraValue,
  setDescriptionExtraValue,
  handleContactMention,
  showPropertyLoan,
  setShowPropertyLoan,
  isSpeedDialAdd,
  linkedEvents,
  setLinkedEvents,
  tags,
  setTags
}) {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form

  const { userId, isReadonly } = useContext(VaultContext)

  const { activeContacts } = useSelector(state => state.contacts)
  const { baseCurrency, allRates } = useSelector(state => state.settings)

  const [rates, setRates] = useState([])
  const [referencesOptions, setReferencesOptions] = useState([])
  const [activeReferencesRow, setActiveReferencesRow] = useState('')
  const [addEventModalVisible, setAddEventModalVisible] = useState(false)
  const [eventRecurringValue, setEventRecurringValue] = useState()
  const [eventDate, setEventDate] = useState()
  const [eventDescription, setEventDescription] = useState()
  const [changedValuationDate, setChangedValuationDate] = useState()

  const { t } = useTranslation()
  let addItemFormRef

  useEffect(() => {
    if (allRates) {
      // only get the rates with matching base currency to save query time
      const rates = Object.keys(allRates).map(key => ({
        quoteCurrency: key,
        rate: allRates[key],
        baseCurrency
      }))
      setRates(rates.filter(rate => rate.baseCurrency === baseCurrency))
    }
  }, [userId, allRates, baseCurrency])

  useEffect(() => {
    setReferencesOptions(
      selectedSubType === ASSET_TYPES.EQUITY_LISTED
        ? getOptions(EQUITY_LISTED_DEFAULT_REFERENCES, references)
        : []
    )
  }, [references, selectedSubType])

  useEffect(() => {
    if (showPropertyLoan) {
      const address = getFieldValue('address')
      const description = getFieldValue('description')
      const title = getFieldValue('title')
      const currency = getFieldValue('currency')

      setFieldsValue({
        currencyExtra: currency,
        addressExtra: address,
        descriptionExtra: description,
        titleExtra: title
      })
      setDescriptionExtraValue(descriptionValue)
    }
  }, [
    getFieldValue,
    setFieldsValue,
    showPropertyLoan,
    descriptionValue,
    setDescriptionExtraValue
  ])

  const handleDescriptionChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions,
    isCallLiab = false
  ) => {
    form.setFieldsValue(
      isCallLiab
        ? {
            descriptionExtra: sanitizeValue(newPlainTextValue).trim()
          }
        : {
            description: sanitizeValue(newPlainTextValue).trim()
          }
    )
    isCallLiab
      ? setDescriptionExtraValue(newValue)
      : setDescriptionValue(newValue)
  }

  const handleMentionBlur = (e, isCallLiab = false) => {
    isCallLiab
      ? setDescriptionExtraValue(sanitizeValue(e.target.value).trim())
      : setDescriptionValue(sanitizeValue(e.target.value).trim())
  }

  const getRate = quoteCurrency => {
    if (quoteCurrency === baseCurrency) return 1
    const existingRate = rates.find(r => r.quoteCurrency === quoteCurrency)
    return existingRate && existingRate.rate
  }

  const setBaseCurrencyValue = (value, isInsurance = false) => {
    return form.setFieldsValue({
      [selectedType === CORE_TYPES.ASSET
        ? isInsurance
          ? 'sumAssuredInBaseCurrency'
          : 'valuationInBaseCurrency'
        : 'outstandingValueInBaseCurrency']: value
    })
  }

  const handleCurrencyValueChange = (
    value,
    isLiabCall = false,
    isInsurance = false
  ) => {
    if (!baseCurrency) return

    const currencyValue = currencyParse(value)
    if (!currencyValue) return

    const selectedCurrency = getFieldValue(
      isLiabCall && showPropertyLoan ? 'currencyExtra' : 'currency'
    )
    if (!selectedCurrency) return

    if (!isInsurance) {
      const valuationDate = getFieldValue('valuationDate')

      if (record?.valuationDate && !valuationDate.diff(record.valuationDate)) {
        setChangedValuationDate(moment(new Date()).startOf('day'))
      } else {
        setChangedValuationDate()
      }
    }

    const rate = getRate(selectedCurrency)
    if (!rate) return

    const baseCurrencyValue = +currencyValue / +rate

    if (isLiabCall) {
      form.setFieldsValue({
        outstandingValueInBaseCurrency: baseCurrencyValue
      })
    } else {
      setBaseCurrencyValue(baseCurrencyValue, isInsurance)
    }
  }

  const handleCurrencyChange = (selectedCurrency, isLiabCall = false) => {
    if (!baseCurrency || !selectedCurrency) return

    const valuationDate = getFieldValue('valuationDate')

    if (record?.valuationDate && !valuationDate.diff(record.valuationDate)) {
      setChangedValuationDate(moment(new Date()).startOf('day'))
    } else {
      setChangedValuationDate()
    }

    const currencyValue = getFieldValue(
      selectedType === CORE_TYPES.ASSET && !(isLiabCall && showPropertyLoan)
        ? 'valuationInAssetCurrency'
        : 'outstandingValueInLiabilityCurrency'
    )

    const rate = getRate(selectedCurrency)

    if (currencyValue) {
      const value = rate && +currencyValue / +rate

      if (isLiabCall) {
        form.setFieldsValue({
          outstandingValueInBaseCurrency: value
        })
      } else {
        setBaseCurrencyValue(value)
      }
    }

    if (selectedSubType === ASSET_TYPES.INSURANCE) {
      const sumAssuredValue = getFieldValue('sumAssuredInAssetCurrency')
      sumAssuredValue &&
        form.setFieldsValue({
          sumAssuredInBaseCurrency: rate && +sumAssuredValue / +rate
        })
    }
  }

  const calculateValuation = (value, key) => {
    const price =
      key === 'price' ? currencyParse(value) : form.getFieldValue('price')
    const quantity = key === 'quantity' ? value : form.getFieldValue('quantity')

    if (!price || !quantity) return

    form.setFieldsValue({
      valuationInAssetCurrency: price * quantity
    })

    handleCurrencyValueChange(price * quantity + '')
  }

  const handleAddNewOption = () => {
    addItemFormRef.props.form.validateFields((err, values) => {
      if (err) return
      removeHtmlTags(values)
      setReferencesOptions([...referencesOptions, values.itemName])
      setActiveReferencesRow('')
      addItemFormRef.props.form.resetFields()
      form.setFieldsValue({
        [`references[${activeReferencesRow}][name]`]: values.itemName
      })
    })
  }

  const onAddContactComplete = (contactId, fieldName, mode) => {
    setFieldsValue({
      [fieldName]:
        mode === 'multiple'
          ? [...getFieldValue(fieldName), contactId]
          : contactId
    })
  }

  const loanPeriodUnitSelector = getFieldDecorator('loanPeriod.unit', {
    initialValue:
      (record.loanPeriod && record.loanPeriod.unit) || LOAN_PERIOD_UNITS.DAYS
  })(
    <Select showAction={['click', 'focus']} style={{ width: 85 }}>
      {Object.values(LOAN_PERIOD_UNITS).map(unit => (
        <Select.Option key={unit} value={unit}>
          {t(unit)}
        </Select.Option>
      ))}
    </Select>
  )

  const floorSizeUnitSelector = getFieldDecorator('floorSize.unit', {
    initialValue: (record.floorSize && record.floorSize.unit) || SQUARE_UNITS.M2
  })(
    <Select showAction={['click', 'focus']} style={{ width: 70 }}>
      {Object.values(SQUARE_UNITS).map(unit => (
        <Select.Option key={unit} value={unit}>
          {unit}
        </Select.Option>
      ))}
    </Select>
  )

  // const lengthOfLoanSelector = getFieldDecorator('lengthOfLoan.unit', {
  //   initialValue: t(record.lengthOfLoan?.unit) || t('Days')
  // })(
  //   <Select showAction={['click', 'focus']} style={{ width: 100 }}>
  //     {Object.values(LENGTH_OF_LOAN_UNITS).map(unit => (
  //       <Select.Option key={unit} value={unit}>
  //         {t(unit)}
  //       </Select.Option>
  //     ))}
  //   </Select>
  // )

  const vehicleRegistrationNumberField = (
    <FormItem label={t('VEHICLE_REGISTRATION_NUMBER')}>
      {getFieldDecorator('vehicleRegistrationNumber', {
        initialValue: record.vehicleRegistrationNumber
      })(
        <TextInput
          name="vehicleRegistrationNumber"
          setFieldsValue={setFieldsValue}
        />
      )}
    </FormItem>
  )

  const securityCollateralField = (
    <FormItem label={t('SECURITY_COLLATERAL')}>
      {getFieldDecorator('securityCollateral', {
        initialValue: record.securityCollateral
      })(
        <TextInput name="securityCollateral" setFieldsValue={setFieldsValue} />
      )}
    </FormItem>
  )

  const loanPeriodField = (
    <FormItem label={t('LOAN_PERIOD')}>
      {getFieldDecorator('loanPeriod.value', {
        initialValue: record.loanPeriod?.value
      })(<TextInput type="number" addonAfter={loanPeriodUnitSelector} />)}
    </FormItem>
  )

  const interestRateField = (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}>
        <FormItem label={`${t('INTEREST_RATE')} %`}>
          {getFieldDecorator('interestRate.value', {
            initialValue: record.interestRate?.value
          })(
            <InputNumber
              style={{
                borderTopRightRadius: 'unset',
                borderBottomRightRadius: 'unset'
              }}
              min={0}
              max={9999999999}
              precision={2}
            />
          )}
        </FormItem>
      </div>
      <div>
        <FormItem label=" " colon={false}>
          {getFieldDecorator('interestRate.unit', {
            initialValue: t(record.interestRate?.unit) || t('per month')
          })(
            <Select
              showAction={['click', 'focus']}
              className="custom-addon-after"
              style={{ width: 110 }}
            >
              {Object.values(INTEREST_RATE_UNITS).map(unit => (
                <Select.Option key={unit} value={unit}>
                  {t(unit)}
                </Select.Option>
              ))}
            </Select>
          )}
        </FormItem>
      </div>
    </div>
  )

  const referencesFields = FIELDS.map(field =>
    referencesOptions && referencesOptions.length && field.key === 'name'
      ? {
          ...field,
          node: (name, rowId) => (
            <Select
              showAction={['click', 'focus']}
              key={`${field.key}`}
              style={{ minWidth: 180 }}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: 0 }} />
                  <div
                    style={{ padding: '5px 12px', cursor: 'pointer' }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setActiveReferencesRow(rowId)}
                  >
                    <Icon type="plus" /> {t('CUSTOM')}
                  </div>
                </div>
              )}
            >
              {referencesOptions.map(option => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          ),
          default: name => referencesOptions[0]
        }
      : field
  )

  return (
    <>
      <FormItem label={t('DESCRIPTION_COL')}>
        <MentionsInput
          value={descriptionValue}
          onChange={handleDescriptionChange}
          onBlur={handleMentionBlur}
          allowSpaceInQuery={true}
          className="mentions"
          maxLength={2000}
        >
          <Mention
            className="mentions__mention"
            data={activeContacts.map(record => ({
              id: record._id,
              display: record.name
            }))}
            onAdd={handleContactMention}
          />
        </MentionsInput>
        {getFieldDecorator('description', {
          initialValue: record.description
        })(<TextArea style={{ display: 'none' }} />)}
      </FormItem>
      {selectedType === CORE_TYPES.ASSET && (
        <>
          <Row gutter={20}>
            {selectedSubType !== ASSET_TYPES.CASH &&
              selectedSubType !== ASSET_TYPES.CRYPTO && (
                <Col
                  lg={
                    selectedSubType === ASSET_TYPES.BROKERAGE_ACCOUNT ||
                    selectedSubType === ASSET_TYPES.RETIREMENT_ACCOUNT ||
                    selectedSubType === ASSET_TYPES.TRUST
                      ? 12
                      : 8
                  }
                >
                  <FormItem
                    label={
                      <span>
                        {t('PERCENTAGE_OWNERSHIP')}&nbsp;
                        <Tooltip
                          title={t('PERCENTAGE_OWNERSHIP_TOOLTIP')}
                          overlayStyle={{ maxWidth: 300 }}
                        >
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator('percentageOwnership', {
                      initialValue: record.percentageOwnership || 100
                    })(<InputNumber min={0} max={100} />)}
                  </FormItem>
                </Col>
              )}

            {!ASSET_TYPES_WITHOUT_PURCHASE_DATE.includes(selectedSubType) && (
              <Col md={12} lg={8}>
                <FormItem
                  label={
                    <span>
                      {purchaseDateLabel(selectedSubType)}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('purchaseDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={purchaseDateLabel(selectedSubType)}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('purchaseDate', {
                    rules: [
                      {
                        required: true,
                        message: purchaseDateMessage(selectedSubType)
                      }
                    ],
                    initialValue: record.purchaseDate
                      ? moment(record.purchaseDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
            )}
            {(selectedSubType === ASSET_TYPES.CASH ||
              selectedSubType === ASSET_TYPES.BROKERAGE_ACCOUNT ||
              selectedSubType === ASSET_TYPES.RETIREMENT_ACCOUNT ||
              selectedSubType === ASSET_TYPES.CRYPTO) && (
              <Col lg={12}>
                <FormItem
                  label={
                    <span>
                      {t('BALANCE_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('valuationDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('BALANCE_DATE')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('valuationDate', {
                    rules: [
                      {
                        required: true,
                        message: t('INPUT_BALANCE_DATE_MSG')
                      }
                    ],
                    initialValue: record.valuationDate
                      ? changedValuationDate || moment(record.valuationDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
            )}
            {(selectedSubType === ASSET_TYPES.EQUITY_LISTED ||
              selectedSubType === ASSET_TYPES.EQUITY_UNLISTED) && (
              <Col md={12} lg={8}>
                <FormItem
                  label={
                    <span>
                      {t('POSITION_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('valuationDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('POSITION_DATE')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('valuationDate', {
                    rules: [
                      {
                        required: true,
                        message: t('INPUT_POSITION_DATE_MSG')
                      }
                    ],
                    initialValue: record.valuationDate
                      ? changedValuationDate || moment(record.valuationDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
            )}
            {!ASSET_TYPES_WITHOUT_DISPOSAL_DATE.includes(selectedSubType) && (
              <Col md={12} lg={8}>
                <FormItem
                  label={
                    <span>
                      {selectedSubType === ASSET_TYPES.LOAN_TO_OTHERS ||
                      selectedSubType === ASSET_TYPES.INSURANCE
                        ? t('MATURITY_DATE')
                        : t('DISPOSAL_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('disposalDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={
                            selectedSubType === ASSET_TYPES.LOAN_TO_OTHERS ||
                            selectedSubType === ASSET_TYPES.INSURANCE
                              ? t('MATURITY_DATE')
                              : t('DISPOSAL_DATE')
                          }
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('disposalDate', {
                    initialValue: record.disposalDate
                      ? moment(record.disposalDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
            )}
          </Row>
          <Row gutter={20}>
            <Col md={12}>
              <FormItem
                label={
                  selectedSubType === ASSET_TYPES.CRYPTO
                    ? t('CRYPTO_CURRENCY')
                    : t('ASSETS_CURRENCY')
                }
              >
                {getFieldDecorator('currency', {
                  initialValue: record.currency,
                  rules: [
                    {
                      required: true,
                      message: t(
                        selectedSubType === ASSET_TYPES.CRYPTO
                          ? 'SELECT_CRYPTOCURRENCY'
                          : 'SELECT_CURRENCY'
                      )
                    }
                  ]
                })(
                  selectedSubType === ASSET_TYPES.CRYPTO ? (
                    <CryptoCurrencySelect
                      defaultOptions={CRYPTO_CURRENCIES.sort((a, b) =>
                        a.code.localeCompare(b.code)
                      )}
                      onChange={selectedCurrency =>
                        handleCurrencyChange(selectedCurrency, false)
                      }
                      onAddComplete={currency =>
                        form.setFieldsValue({
                          currency
                        })
                      }
                    />
                  ) : (
                    <CurrencySelect
                      onChange={selectedCurrency =>
                        handleCurrencyChange(selectedCurrency, false)
                      }
                    />
                  )
                )}
              </FormItem>
            </Col>
            <Col md={12}>
              {selectedSubType === ASSET_TYPES.CASH && (
                <FormItem label={t('ACCOUNT_TYPE')}>
                  {getFieldDecorator('accountType', {
                    initialValue: record.accountType
                  })(
                    <Select showAction={['click', 'focus']} allowClear>
                      {Object.values(ACCOUNT_TYPES)
                        .map(act => {
                          return {
                            key: act,
                            value: t(act)
                          }
                        })
                        .sort((a, b) => a.value.localeCompare(b.value))
                        .map(type => (
                          <Select.Option key={type.key} value={type.key}>
                            {type.value}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </FormItem>
              )}
              {selectedSubType === ASSET_TYPES.CRYPTO && (
                <SelectWithOther
                  defaultOptions={CRYPTO_EXCHANGES.sort((a, b) =>
                    a.localeCompare(b)
                  )}
                  initialValue={record.cryptoExchange}
                  label={t('WALLET_PROVIDER')}
                  fieldName="cryptoExchange"
                  getFieldDecorator={getFieldDecorator}
                  onAddComplete={itemName =>
                    form.setFieldsValue({
                      cryptoExchange: itemName
                    })
                  }
                />
              )}
              {(!ASSET_TYPES_WITHOUT_PURCHASE_DATE.includes(selectedSubType) ||
                selectedSubType === ASSET_TYPES.TRUST) && (
                <FormItem
                  label={
                    <span>
                      {t('VALUATION_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('valuationDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('VALUATION_DATE')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('valuationDate', {
                    initialValue: record.valuationDate
                      ? changedValuationDate || moment(record.valuationDate)
                      : moment()
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                      allowClear
                      disabledDate={endDate =>
                        disabledDate(getFieldValue('purchaseDate'), endDate)
                      }
                    />
                  )}
                </FormItem>
              )}
            </Col>
            {(selectedSubType === ASSET_TYPES.EQUITY_LISTED ||
              selectedSubType === ASSET_TYPES.EQUITY_UNLISTED) && (
              <>
                <Col md={6}>
                  <CurrencyInputWithCode
                    currency={getFieldValue('currency')}
                    label={t('PRICE')}
                    fieldName="price"
                    initialValue={record.price}
                    getFieldDecorator={getFieldDecorator}
                    onBlur={e => calculateValuation(e.target.value, 'price')}
                  />
                </Col>
                <Col md={6}>
                  <FormItem label={t('NUMBER_OF_SHARES')}>
                    {getFieldDecorator('quantity', {
                      initialValue: record.quantity
                    })(
                      <InputNumber
                        max={9999999999}
                        onBlur={e =>
                          calculateValuation(e.target.value, 'quantity')
                        }
                      />
                    )}
                  </FormItem>
                </Col>
              </>
            )}
          </Row>

          <Row gutter={20}>
            <Col md={12}>
              <CurrencyInputWithCode
                currency={getFieldValue('currency')}
                label={
                  selectedSubType === ASSET_TYPES.CASH ||
                  selectedSubType === ASSET_TYPES.CRYPTO
                    ? t('BALANCE_AMOUNT')
                    : t('VALUATION_ASSET_CURRENCY')
                }
                fieldName="valuationInAssetCurrency"
                initialValue={record.valuationInAssetCurrency}
                getFieldDecorator={getFieldDecorator}
                onBlur={e => handleCurrencyValueChange(e.target.value)}
              />
            </Col>
            <Col md={12}>
              <CurrencyInputWithCode
                currency={baseCurrency}
                label={
                  <span>
                    {t('VALUATION_BASE_CURRENCY')}&nbsp;
                    <Tooltip title={t('VALUATION_IN_BASE_CURRENCY_TOOLTIP')}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
                fieldName="valuationInBaseCurrency"
                initialValue={record.valuationInBaseCurrency}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
          <FormItem>
            {getFieldDecorator('includeValueInNetWorth', {
              valuePropName: 'checked',
              initialValue:
                record.includeValueInNetWorth === undefined
                  ? selectedSubType !== ASSET_TYPES.TRUST
                  : record.includeValueInNetWorth
            })(<Checkbox>{t('INCLUDE_IN_NETWORTH')}</Checkbox>)}
          </FormItem>

          {selectedSubType === ASSET_TYPES.PROPERTY && (
            <>
              <Row gutter={20}>
                <Col md={8}>
                  <SelectWithOther
                    defaultOptions={buildingTypes}
                    initialValue={record.buildingType || BUILDING_TYPES.HOUSE}
                    label={t('BUILDING_TYPE')}
                    fieldName="buildingType"
                    getFieldDecorator={getFieldDecorator}
                    required={true}
                    onAddComplete={itemName =>
                      form.setFieldsValue({
                        buildingType: itemName
                      })
                    }
                  />
                </Col>
                <Col md={8}>
                  <FormItem
                    label={
                      <span>
                        {t('BUILD_DATE')}
                        {!isReadonly && (
                          <ConvertToEvent
                            date={getFieldValue('buildDate')}
                            setEventDate={setEventDate}
                            name={title}
                            setAddEventModalVisible={setAddEventModalVisible}
                            setEventDescription={setEventDescription}
                            label={t('BUILD_DATE')}
                          />
                        )}
                      </span>
                    }
                  >
                    {getFieldDecorator('buildDate', {
                      initialValue: record.buildDate
                        ? moment(record.buildDate)
                        : null
                    })(
                      <DatePicker
                        placeholder={t('SELECT_DATE')}
                        format={DATE_FORMAT}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col md={8}>
                  <FormItem label={t('FLOOR_SIZE')}>
                    {getFieldDecorator('floorSize.value', {
                      initialValue: record.floorSize && record.floorSize.value,
                      rules: [
                        {
                          validator: (rule, value, callback) =>
                            validateMinValue(0, value, callback)
                        }
                      ]
                    })(
                      <TextInput
                        type="number"
                        addonAfter={floorSizeUnitSelector}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <FormItem label={t('PROPERTY_ADDRESS')}>
                {getFieldDecorator('address', {
                  initialValue: record.address,
                  rules: [
                    {
                      required: true,
                      message: t('PROPERTY_ADDRESS_MSG')
                    }
                  ]
                })(
                  <TextInput name="address" setFieldsValue={setFieldsValue} />
                )}
              </FormItem>

              <Row gutter={20}>
                <Col md={12}>
                  <FormItem label={t('HOLDING_TYPE')}>
                    {getFieldDecorator('holdingType', {
                      initialValue: record.holdingType
                    })(
                      <Select showAction={['click', 'focus']} allowClear>
                        {Object.values(HOLDING_TYPES)
                          .map(ht => {
                            return {
                              key: ht,
                              value: t(ht)
                            }
                          })
                          .sort((a, b) => a.value.localeCompare(b.value))
                          .map(ht => (
                            <Select.Option key={ht.key} value={ht.key}>
                              {ht.value}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col md={12}>
                  {getFieldValue('holdingType') === HOLDING_TYPES.LEASEHOLD && (
                    <FormItem label={t('LEASEHOLD_DURATION')}>
                      {getFieldDecorator('leaseholdDuration', {
                        initialValue: record.leaseholdDuration
                      })(
                        <TextInput
                          name="leaseholdDuration"
                          setFieldsValue={setFieldsValue}
                        />
                      )}
                    </FormItem>
                  )}
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xxl={12}>
                  <ContactSelect
                    label={t('TENANT')}
                    getFieldDecorator={getFieldDecorator}
                    fieldName="tenant"
                    initialValue={record.tenant || []}
                    mode="multiple"
                    onAddComplete={contactId =>
                      onAddContactComplete(contactId, 'tenant', 'multiple')
                    }
                  />
                </Col>
                <Col xxl={12} style={{ display: 'flex' }}>
                  <CurrencyInputWithCode
                    currency={getFieldValue('currency')}
                    fieldName="rentAmount.value"
                    label={t('RENT_AMOUNT')}
                    initialValue={record.rentAmount?.value}
                    getFieldDecorator={getFieldDecorator}
                    hasAddonAfter={true}
                  />
                  <FormItem label=" " colon={false}>
                    {getFieldDecorator('rentAmount.unit', {
                      initialValue:
                        record.rentAmount?.unit || RENT_AMOUNT_UNITS.MONTHLY
                    })(
                      <Select
                        className="custom-addon-after"
                        style={{ width: 110 }}
                        showAction={['click', 'focus']}
                      >
                        {Object.values(RENT_AMOUNT_UNITS).map(unit => (
                          <Select.Option key={unit} value={unit}>
                            {t(unit)}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={12}>
                  <FormItem
                    label={
                      <span>
                        {t('RENTAL_START_DATE')}
                        {!isReadonly && (
                          <ConvertToEvent
                            date={getFieldValue('rentalStartDate')}
                            setEventDate={setEventDate}
                            name={title}
                            setAddEventModalVisible={setAddEventModalVisible}
                            setEventDescription={setEventDescription}
                            label={t('RENTAL_START_DATE')}
                          />
                        )}
                      </span>
                    }
                  >
                    {getFieldDecorator('rentalStartDate', {
                      initialValue: record.rentalStartDate
                        ? moment(record.rentalStartDate)
                        : null
                    })(
                      <DatePicker
                        placeholder={t('SELECT_DATE')}
                        format={DATE_FORMAT}
                        disabledDate={startDate =>
                          disabledDate(
                            startDate,
                            getFieldValue('rentalEndDate')
                          )
                        }
                      />
                    )}
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem
                    label={
                      <span>
                        {t('RENTAL_END_DATE')}
                        {!isReadonly && (
                          <ConvertToEvent
                            date={getFieldValue('rentalEndDate')}
                            setEventDate={setEventDate}
                            name={title}
                            setAddEventModalVisible={setAddEventModalVisible}
                            setEventDescription={setEventDescription}
                            label={t('RENTAL_END_DATE')}
                          />
                        )}
                      </span>
                    }
                  >
                    {getFieldDecorator('rentalEndDate', {
                      initialValue: record.rentalEndDate
                        ? moment(record.rentalEndDate)
                        : null
                    })(
                      <DatePicker
                        placeholder={t('SELECT_DATE')}
                        format={DATE_FORMAT}
                        disabledDate={endDate =>
                          disabledDate(
                            getFieldValue('rentalStartDate'),
                            endDate
                          )
                        }
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </>
          )}
          {selectedSubType === ASSET_TYPES.BROKERAGE_ACCOUNT && (
            <>
              <FormItem label={t('BROKERAGE_NAME')}>
                {getFieldDecorator('brokerageName', {
                  initialValue: record.brokerageName
                })(
                  <TextInput
                    name="brokerageName"
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </FormItem>
              <Row gutter={20}>
                <Col md={12}>
                  <FormItem label={t('ACCOUNT_NUMBER')}>
                    {getFieldDecorator('accountNumber', {
                      initialValue: record.accountNumber,
                      rules: [
                        {
                          validator: validateAlphaNumericDash
                        }
                      ]
                    })(
                      <TextInput
                        name="accountNumber"
                        setFieldsValue={setFieldsValue}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem label={t('ACCOUNT_TYPE')}>
                    {getFieldDecorator('accountType', {
                      initialValue: record.accountType
                    })(
                      <TextInput
                        name="accountType"
                        setFieldsValue={setFieldsValue}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <FormItem>
                {getFieldDecorator('jointAccount', {
                  valuePropName: 'checked',
                  initialValue: !!record.jointAccount
                })(<Checkbox>{t('JOINT_ACCOUNT')}</Checkbox>)}
              </FormItem>
            </>
          )}
          {selectedSubType === ASSET_TYPES.VEHICLE && (
            <Row gutter={20}>
              <Col md={12}>{vehicleRegistrationNumberField}</Col>
            </Row>
          )}

          {selectedSubType === ASSET_TYPES.EQUITY_LISTED && (
            <FormItem label={t('EXCHANGE')}>
              {getFieldDecorator('exchange', {
                initialValue: record.exchange
              })(
                <Select
                  allowClear
                  showSearch
                  showAction={['click', 'focus']}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  placeholder={t('SELECT_EXCHANGE')}
                >
                  {MICS.map(record => (
                    <Select.Option key={record.code} value={record.code}>
                      {record.code} - {record.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
          )}

          {selectedSubType === ASSET_TYPES.CRYPTO && (
            <Row gutter={20}>
              <Col md={12}>
                <FormItem label={t('WALLET_ADDRESS')}>
                  {getFieldDecorator('walletAddress', {
                    initialValue: record.walletAddress
                  })(
                    <TextInput
                      name="walletAddress"
                      setFieldsValue={setFieldsValue}
                    />
                  )}
                </FormItem>
              </Col>
              <Col md={12}>
                <FormItem label={t('PASSWORD')}>
                  {getFieldDecorator('password', {
                    initialValue: record.password
                  })(
                    <Input.Password
                      autoComplete="new-password"
                      maxLength={30}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          )}

          {selectedSubType === ASSET_TYPES.CASH && (
            <>
              <Row gutter={20}>
                <Col md={12}>
                  <FormItem label={t('BANK')}>
                    {getFieldDecorator('bank', {
                      initialValue: record.bank
                    })(
                      <TextInput name="bank" setFieldsValue={setFieldsValue} />
                    )}
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem label={t('ACCOUNT_NUMBER')}>
                    {getFieldDecorator('accountNumber', {
                      initialValue: record.accountNumber,
                      rules: [
                        {
                          validator: validateAlphaNumericDash
                        }
                      ]
                    })(
                      <TextInput
                        name="accountNumber"
                        setFieldsValue={setFieldsValue}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </>
          )}

          {selectedSubType === ASSET_TYPES.INSURANCE && (
            <>
              <Row gutter={20}>
                <Col md={12}>
                  <ContactSelect
                    label={
                      <span>
                        {t('LIFE_ASSURED')}&nbsp;
                        <Tooltip
                          title={t('LIFE_ASSURED_TOOLTIP')}
                          overlayStyle={{ maxWidth: 300 }}
                        >
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                    placeholder={`${t('SELECT')} ${t(
                      t('LIFE_ASSURED').toLowerCase()
                    )}`}
                    getFieldDecorator={getFieldDecorator}
                    fieldName="nameAssured"
                    initialValue={record.nameAssured}
                    onAddComplete={contactId =>
                      onAddContactComplete(contactId, 'nameAssured')
                    }
                  />
                </Col>
                <Col md={12}>
                  <SelectWithOther
                    defaultOptions={TYPE_OF_POLICY_DEFAULT_OPTIONS}
                    initialValue={record.typeOfPolicy}
                    label={t('TYPE_OF_POLICY')}
                    fieldName="typeOfPolicy"
                    getFieldDecorator={getFieldDecorator}
                    required={true}
                    onAddComplete={itemName =>
                      form.setFieldsValue({
                        typeOfPolicy: itemName
                      })
                    }
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col lg={12}>
                  <CurrencyInputWithCode
                    currency={getFieldValue('currency')}
                    label={t('SUM_ASSURED_IN_ASSET_CURRENCY')}
                    onBlur={e =>
                      handleCurrencyValueChange(e.target.value, false, true)
                    }
                    fieldName="sumAssuredInAssetCurrency"
                    initialValue={record.sumAssuredInAssetCurrency}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
                <Col lg={12}>
                  <CurrencyInputWithCode
                    currency={baseCurrency}
                    label={t('SUM_ASSURED_IN_BASE_CURRENCY')}
                    fieldName="sumAssuredInBaseCurrency"
                    initialValue={record.sumAssuredInBaseCurrency}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
              </Row>
              <div style={{ display: 'flex' }}>
                <CurrencyInputWithCode
                  currency={getFieldValue('currency')}
                  label={t('PREMIUM')}
                  fieldName="premium.value"
                  initialValue={record.premium && record.premium.value}
                  getFieldDecorator={getFieldDecorator}
                  hasAddonAfter={true}
                />
                <FormItem label=" " colon={false}>
                  {getFieldDecorator('premium.unit', {
                    initialValue:
                      (record.premium && record.premium.unit) ||
                      PREMIUM_UNITS.MONTHLY
                  })(
                    <Select
                      style={{ width: 110 }}
                      className="custom-addon-after"
                      showAction={['click', 'focus']}
                    >
                      {Object.values(PREMIUM_UNITS).map(unit => (
                        <Select.Option key={unit} value={unit}>
                          {t(unit)}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
              </div>
              <Row gutter={20}>
                <Col>
                  <FormItem label={t('INSURER')}>
                    {getFieldDecorator('insurer', {
                      initialValue: record.insurer
                    })(
                      <TextInput
                        name="insurer"
                        setFieldsValue={setFieldsValue}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col>
                  <ContactSelect
                    label={
                      <span>
                        {t('POLICY_OWNER')}&nbsp;
                        <Tooltip
                          title={t('POLICY_OWNER_TOOLTIP')}
                          overlayStyle={{ maxWidth: 300 }}
                        >
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    }
                    placeholder={`${t('SELECT')} ${t(
                      t('POLICY_OWNER').toLowerCase()
                    )}`}
                    getFieldDecorator={getFieldDecorator}
                    fieldName="policyOwner"
                    initialValue={record.policyOwner}
                    onAddComplete={contactId =>
                      onAddContactComplete(contactId, 'policyOwner')
                    }
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={12}>
                  <FormItem label={t('POLICY_NUMBER')}>
                    {getFieldDecorator('policyNumber', {
                      initialValue: record.policyNumber
                    })(
                      <TextInput
                        name="policyNumber"
                        setFieldsValue={setFieldsValue}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem label={t('ENTITLEMENT')}>
                    {getFieldDecorator('entitlement', {
                      initialValue: record.entitlement
                    })(<InputNumber min={0} max={100} precision={2} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col>
                  <ContactSelect
                    label={t('INSURANCE_ADVISOR')}
                    getFieldDecorator={getFieldDecorator}
                    fieldName="insuranceAdvisor"
                    initialValue={record.insuranceAdvisor || []}
                    mode="multiple"
                    onAddComplete={contactId =>
                      onAddContactComplete(
                        contactId,
                        'insuranceAdvisor',
                        'multiple'
                      )
                    }
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col>
                  <ContactSelect
                    label={t('BENEFICIARIES')}
                    getFieldDecorator={getFieldDecorator}
                    fieldName="beneficiaries"
                    initialValue={record.beneficiaries || []}
                    mode="multiple"
                    onAddComplete={contactId =>
                      onAddContactComplete(
                        contactId,
                        'beneficiaries',
                        'multiple'
                      )
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormItem label={t('OTHER_NOTES')}>
                    {getFieldDecorator('otherNotes', {
                      initialValue: record.otherNotes
                    })(
                      <TextArea
                        onBlur={e =>
                          setFieldsValue({
                            otherNotes: sanitizeValue(e.target.value)
                          })
                        }
                        rows={4}
                        maxLength={2000}
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </>
          )}
          {selectedSubType === ASSET_TYPES.EQUITY_UNLISTED && (
            <>
              <Row gutter={20}>
                <Col md={12}>
                  <ContactSelect
                    label={t('COMPANY')}
                    required={true}
                    getFieldDecorator={getFieldDecorator}
                    contactType={CONTACT_TYPES.ORGANISATION}
                    fieldName="company"
                    initialValue={record.company}
                    onAddComplete={contactId =>
                      onAddContactComplete(contactId, 'company')
                    }
                  />
                </Col>
                <Col md={12}>
                  <SelectWithOther
                    defaultOptions={DEFAULT_CLASS_OF_SHARES}
                    initialValue={record.classOfShares}
                    label={t('CLASS_OF_SHARES')}
                    fieldName="classOfShares"
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                    onAddComplete={itemName =>
                      form.setFieldsValue({
                        classOfShares: itemName
                      })
                    }
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={12}>
                  <FormItem label={t('SHARE_CERTIFICATE_NUMBER')}>
                    {getFieldDecorator('shareCertificateNumber', {
                      initialValue: record.shareCertificateNumber
                    })(
                      <TextInput
                        name="shareCertificateNumber"
                        setFieldsValue={setFieldsValue}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col md={12}>
                  <FormItem label={t('PERCENTAGE_OF_SHARES')}>
                    {getFieldDecorator('percentageOfShares', {
                      initialValue: record.percentageOfShares
                    })(<InputNumber min={0} />)}
                  </FormItem>
                </Col>
              </Row>
            </>
          )}
          {selectedSubType === ASSET_TYPES.RETIREMENT_ACCOUNT && (
            <Row>
              <Col md={12}>
                <SelectWithOther
                  defaultOptions={DEFAULT_RETIREMENT_ACCOUNT_TYPES}
                  initialValue={record.retirementAccountType}
                  label={t('RETIREMENT_ACCOUNT_TYPE')}
                  fieldName="retirementAccountType"
                  getFieldDecorator={getFieldDecorator}
                  required={false}
                  onAddComplete={itemName =>
                    form.setFieldsValue({
                      retirementAccountType: itemName
                    })
                  }
                />
              </Col>
            </Row>
          )}

          {selectedSubType === ASSET_TYPES.TRUST && (
            <>
              <Row gutter={20}>
                <Col md={12}>
                  <FormItem label={t('TRUST_REVOCABILITY')}>
                    {getFieldDecorator('trustRevocability', {
                      initialValue: record.trustRevocability
                    })(
                      <Select showAction={['click', 'focus']}>
                        {TRUST_REVOCABILITY.map(rev => {
                          return {
                            key: rev,
                            value: t(rev)
                          }
                        })
                          .sort((a, b) => a.value.localeCompare(b.value))
                          .map(rev => (
                            <Select.Option key={rev.key} value={rev.key}>
                              {rev.value}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col md={12}>
                  <SelectWithOther
                    defaultOptions={DEFAULT_TRUST_TYPE}
                    initialValue={record.trustType}
                    label={t('TRUST_TYPE')}
                    fieldName="trustType"
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                    onAddComplete={itemName =>
                      form.setFieldsValue({
                        trustType: itemName
                      })
                    }
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={12}>
                  <SelectWithOther
                    defaultOptions={DEFAULT_TYPE_OF_TRUST_INTEREST}
                    initialValue={record.typeOfTrustInterest?.key}
                    label={t('TYPE_OF_TRUST_INTEREST')}
                    fieldName="typeOfTrustInterest.key"
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                    onAddComplete={itemName =>
                      form.setFieldsValue({
                        'typeOfTrustInterest.key': itemName
                      })
                    }
                  />
                </Col>
              </Row>
              {getFieldValue('typeOfTrustInterest.key') && (
                <ContactSelect
                  label={t(
                    TYPE_OF_TRUST_INTEREST_PLURAL_MAP[
                      getFieldValue('typeOfTrustInterest.key')
                    ] || getFieldValue('typeOfTrustInterest.key')
                  )}
                  getFieldDecorator={getFieldDecorator}
                  fieldName="typeOfTrustInterest.values"
                  initialValue={record.typeOfTrustInterest?.values || []}
                  mode="multiple"
                  onAddComplete={contactId =>
                    onAddContactComplete(
                      contactId,
                      'typeOfTrustInterest.values',
                      'multiple'
                    )
                  }
                />
              )}
            </>
          )}
          {selectedSubType === ASSET_TYPES.OTHER_ASSETS && (
            <Row gutter={20}>
              <Col md={12}>
                <SelectWithOther
                  defaultOptions={DEFAULT_OTHER_ASSET_TYPES}
                  initialValue={record.otherAssetType}
                  label={t('OTHER_ASSET_TYPE')}
                  fieldName="otherAssetType"
                  getFieldDecorator={getFieldDecorator}
                  required={false}
                  onAddComplete={itemName =>
                    form.setFieldsValue({
                      otherAssetType: itemName
                    })
                  }
                />
              </Col>
            </Row>
          )}
        </>
      )}
      {selectedSubType === ASSET_TYPES.PROPERTY &&
        !record?._id &&
        !isSpeedDialAdd && (
          <>
            <FormItem>
              <Checkbox
                checked={showPropertyLoan}
                onClick={({ target }) => {
                  setShowPropertyLoan(target.checked)
                }}
              >
                {t('INCLUDE_PROPERTY_LOAN')}
              </Checkbox>
            </FormItem>

            {showPropertyLoan && <H4>{t('PROPERTY_LOAN_INFORMATION')}</H4>}
          </>
        )}
      {(selectedType === CORE_TYPES.LIABILITY || showPropertyLoan) && (
        <>
          {showPropertyLoan && (
            <>
              <FormItem label={t('TITLE')}>
                {getFieldDecorator('titleExtra', {
                  initialValue: record.title,
                  rules: [{ required: true, message: t('INPUT_TITLE_MSG') }]
                })(
                  <TextInput
                    name="titleExtra"
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </FormItem>

              <FormItem label={t('DESCRIPTION_COL')}>
                <MentionsInput
                  value={descriptionExtraValue}
                  onChange={(event, newValue, newPlainTextValue, mentions) =>
                    handleDescriptionChange(
                      event,
                      newValue,
                      newPlainTextValue,
                      mentions,
                      showPropertyLoan
                    )
                  }
                  onBlur={e => handleMentionBlur(e, showPropertyLoan)}
                  allowSpaceInQuery={true}
                  className="mentions"
                  maxLength={2000}
                >
                  <Mention
                    className="mentions__mention"
                    data={activeContacts.map(record => ({
                      id: record._id,
                      display: record.name
                    }))}
                    onAdd={handleContactMention}
                  />
                </MentionsInput>
                {getFieldDecorator('descriptionExtra', {
                  initialValue: record.description
                })(<TextArea style={{ display: 'none' }} />)}
              </FormItem>
            </>
          )}
          {selectedSubType === LIABILITY_TYPES.CREDIT_CARD ? (
            <Row gutter={20}>
              <Col md={12} lg={8}>
                <FormItem
                  label={
                    <span>
                      {t('EXPIRY_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('expiryDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('EXPIRY_DATE')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('expiryDate', {
                    initialValue: record.expiryDate
                      ? moment(record.expiryDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col md={12} lg={8}>
                <FormItem
                  label={
                    <span>
                      {t('PAYMENT_DUE_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('paymentDueDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('PAYMENT_DUE_DATE')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('paymentDueDate', {
                    initialValue: record.paymentDueDate
                      ? moment(record.paymentDueDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={8}>
                <FormItem
                  label={
                    <span>
                      {t('RECURRING_PAYMENT_DUE_DAY')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={
                            getFieldValue('recurringPaymentDueDay')
                              ? moment().clone().endOf('month').date() >=
                                  getFieldValue('recurringPaymentDueDay') &&
                                moment().date() <
                                  getFieldValue('recurringPaymentDueDay')
                                ? moment().set(
                                    'date',
                                    getFieldValue('recurringPaymentDueDay')
                                  )
                                : moment()
                                    .add(1, 'M')
                                    .set(
                                      'date',
                                      getFieldValue('recurringPaymentDueDay')
                                    )
                              : null
                          }
                          setEventRecurringValue={setEventRecurringValue}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('RECURRING_PAYMENT_DUE_DAY')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('recurringPaymentDueDay', {
                    initialValue: record.recurringPaymentDueDay
                  })(<InputNumber min={1} max={31} precision={0} />)}
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row gutter={20}>
              <Col lg={8}>
                <FormItem
                  label={
                    <span>
                      {t('PERCENTAGE_OWNERSHIP')}&nbsp;
                      <Tooltip
                        title={t('PERCENTAGE_OWNERSHIP_TOOLTIP')}
                        overlayStyle={{ maxWidth: 300 }}
                      >
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator(
                    showPropertyLoan
                      ? 'percentageOwnershipExtra'
                      : 'percentageOwnership',
                    {
                      initialValue: record.percentageOwnership || 100
                    }
                  )(<InputNumber min={0} max={100} />)}
                </FormItem>
              </Col>
              <Col md={12} lg={8}>
                <FormItem
                  label={
                    <span>
                      {t('START_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('startDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('START_DATE')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('startDate', {
                    rules: [
                      {
                        required: true,
                        message: t('INPUT_START_DATE')
                      }
                    ],
                    initialValue: record.startDate
                      ? moment(record.startDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
              <Col md={12} lg={8}>
                <FormItem
                  label={
                    <span>
                      {t('MATURITY_DATE')}
                      {!isReadonly && (
                        <ConvertToEvent
                          date={getFieldValue('maturityDate')}
                          setEventDate={setEventDate}
                          name={title}
                          setAddEventModalVisible={setAddEventModalVisible}
                          setEventDescription={setEventDescription}
                          label={t('MATURITY_DATE')}
                        />
                      )}
                    </span>
                  }
                >
                  {getFieldDecorator('maturityDate', {
                    initialValue: record.maturityDate
                      ? moment(record.maturityDate)
                      : null
                  })(
                    <DatePicker
                      placeholder={t('SELECT_DATE')}
                      format={DATE_FORMAT}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          )}

          <Row gutter={20}>
            <Col md={12}>
              <FormItem label={t('LIABILITIES_CURRENCY')}>
                {getFieldDecorator(
                  showPropertyLoan ? 'currencyExtra' : 'currency',
                  {
                    initialValue: record.currency,
                    rules: [{ required: true, message: t('SELECT_CURRENCY') }]
                  }
                )(
                  <CurrencySelect
                    onChange={selectedCurrency => {
                      handleCurrencyChange(selectedCurrency, true)
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col md={12}>
              <FormItem
                label={
                  <span>
                    {selectedSubType === LIABILITY_TYPES.CREDIT_CARD ? (
                      t('STATEMENT_DATE')
                    ) : (
                      <span>
                        {t('VALUATION_DATE')}&nbsp;
                        <Tooltip title={t('LIABILITY_VALUATION_DATE_TOOLTIP')}>
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      </span>
                    )}
                    {!isReadonly && (
                      <ConvertToEvent
                        date={getFieldValue(
                          showPropertyLoan
                            ? 'valuationDateExtra'
                            : 'valuationDate'
                        )}
                        setEventDate={setEventDate}
                        name={title}
                        setAddEventModalVisible={setAddEventModalVisible}
                        setEventDescription={setEventDescription}
                        label={
                          selectedSubType === LIABILITY_TYPES.CREDIT_CARD
                            ? t('STATEMENT_DATE')
                            : t('VALUATION_DATE')
                        }
                      />
                    )}
                  </span>
                }
              >
                {getFieldDecorator(
                  showPropertyLoan ? 'valuationDateExtra' : 'valuationDate',
                  {
                    initialValue: record.valuationDate
                      ? changedValuationDate || moment(record.valuationDate)
                      : moment()
                  }
                )(
                  <DatePicker
                    placeholder={t('SELECT_DATE')}
                    format={DATE_FORMAT}
                    allowClear
                    disabledDate={endDate =>
                      disabledDate(getFieldValue('startDate'), endDate)
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col md={12}>
              <CurrencyInputWithCode
                currency={getFieldValue(
                  showPropertyLoan ? 'currencyExtra' : 'currency'
                )}
                label={
                  showPropertyLoan ||
                  selectedSubType === LIABILITY_TYPES.PROPERTY_LOAN ||
                  selectedSubType === LIABILITY_TYPES.VEHICLE_LOAN
                    ? t('AMOUNT_BORROWED')
                    : t('OUTSTANDING_VALUE_LIABILITY_CURRENCY')
                }
                fieldName="outstandingValueInLiabilityCurrency"
                initialValue={record.outstandingValueInLiabilityCurrency}
                onBlur={e => {
                  handleCurrencyValueChange(e.target.value, true)
                }}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
            <Col md={12}>
              <CurrencyInputWithCode
                currency={baseCurrency}
                fieldName="outstandingValueInBaseCurrency"
                label={
                  <span>
                    {t('OUTSTANDING_VALUE_BASE_CURRENCY')}
                    &nbsp;
                    <Tooltip
                      title={t('OUTSTANDING_AMOUNT_IN_BASE_CURRENCY_TOOLTIP')}
                    >
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
                initialValue={record.outstandingValueInBaseCurrency}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
          <FormItem>
            {getFieldDecorator(
              showPropertyLoan
                ? 'includeValueInNetWorthExtra'
                : 'includeValueInNetWorth',
              {
                valuePropName: 'checked',
                initialValue:
                  record.includeValueInNetWorth === undefined
                    ? true
                    : record.includeValueInNetWorth
              }
            )(<Checkbox>{t('INCLUDE_IN_NETWORTH')}</Checkbox>)}
          </FormItem>
        </>
      )}
      {(selectedSubType === LIABILITY_TYPES.LOAN_FROM_OTHERS ||
        showPropertyLoan ||
        selectedSubType === LIABILITY_TYPES.PROPERTY_LOAN ||
        selectedSubType === LIABILITY_TYPES.VEHICLE_LOAN) && (
        <ContactSelect
          label={t('LENDER')}
          getFieldDecorator={getFieldDecorator}
          fieldName="lender"
          initialValue={record.lender}
          onAddComplete={contactId => onAddContactComplete(contactId, 'lender')}
        />
      )}
      {selectedSubType === ASSET_TYPES.LOAN_TO_OTHERS && (
        <ContactSelect
          label={t('BORROWER')}
          getFieldDecorator={getFieldDecorator}
          fieldName="borrower"
          initialValue={record.borrower}
          onAddComplete={contactId =>
            onAddContactComplete(contactId, 'borrower')
          }
        />
      )}
      {(selectedSubType === LIABILITY_TYPES.LOAN_FROM_OTHERS ||
        selectedSubType === ASSET_TYPES.LOAN_TO_OTHERS) && (
        <>
          <Row gutter={20}>
            <Col lg={12}>{interestRateField}</Col>
            <Col lg={12}>
              <FormItem label={t('LOAN_PERIOD')}>
                {getFieldDecorator('loanPeriod.value', {
                  initialValue: record.loanPeriod && record.loanPeriod.value,
                  rules: [
                    {
                      validator: (rule, value, callback) =>
                        validateMinValue(0, value, callback)
                    }
                  ]
                })(
                  <TextInput
                    type="number"
                    addonAfter={loanPeriodUnitSelector}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col md={12}>
              <FormItem label={t('NUMBER_OF_REPAYMENTS')}>
                {getFieldDecorator('numberOfRepayments', {
                  initialValue: record.numberOfRepayments
                })(<InputNumber min={0} />)}
              </FormItem>
            </Col>
            {selectedSubType === LIABILITY_TYPES.LOAN_FROM_OTHERS && (
              <Col md={12}>{securityCollateralField}</Col>
            )}
          </Row>
        </>
      )}
      {selectedSubType === LIABILITY_TYPES.CREDIT_CARD && (
        <>
          <Row gutter={20}>
            <Col md={12}>
              <FormItem label={t('ISSUING_BANK')}>
                {getFieldDecorator('issuingBank', {
                  initialValue: record.issuingBank
                })(
                  <TextInput
                    name="issuingBank"
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </FormItem>
            </Col>
            <Col md={12}>
              <CurrencyInputWithCode
                currency={getFieldValue('currency')}
                label={t('CREDIT_LIMIT')}
                fieldName="creditLimit"
                initialValue={record.creditLimit}
                getFieldDecorator={getFieldDecorator}
              />
            </Col>
          </Row>
          <Row gutter={20}>
            <Col md={12}>{interestRateField}</Col>
          </Row>
        </>
      )}
      {(showPropertyLoan ||
        selectedSubType === LIABILITY_TYPES.PROPERTY_LOAN) && (
        <>
          <Row gutter={20}>
            <Col md={12}>{interestRateField}</Col>
            <Col md={12}>
              <FormItem label={t('MONTHLY_PAYMENT')}>
                {getFieldDecorator('monthlyPayment', {
                  initialValue: record.monthlyPayment
                })(
                  <InputNumber
                    formatter={currencyInputFormat}
                    parser={currencyParse}
                    precision={2}
                    min={0}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col md={12}>
              <SelectWithOther
                defaultOptions={DEFAULT_INTEREST_RATE_TYPES}
                initialValue={record.typeOfInterestRate}
                label={t('TYPE_OF_INTEREST_RATE')}
                fieldName="typeOfInterestRate"
                getFieldDecorator={getFieldDecorator}
                required={false}
                onAddComplete={itemName =>
                  form.setFieldsValue({
                    typeOfInterestRate: itemName
                  })
                }
              />
            </Col>
            <Col md={12}>
              <FormItem label={t('ACCOUNT_NUMBER')}>
                {getFieldDecorator('accountNumber', {
                  initialValue: record.accountNumber,
                  rules: [
                    {
                      validator: validateAlphaNumericDash
                    }
                  ]
                })(
                  <TextInput
                    name="accountNumber"
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col md={12}>{loanPeriodField}</Col>
            <Col md={12}>
              <FormItem label={t('NO_OF_YEARS_REPAYMENT_COMPLETED')}>
                {getFieldDecorator('yearOfLoan', {
                  initialValue: record.yearOfLoan
                })(<TextInput />)}
              </FormItem>
            </Col>
          </Row>
          <FormItem label={t('PROPERTY_ADDRESS')}>
            {getFieldDecorator(showPropertyLoan ? 'addressExtra' : 'address', {
              initialValue: record.address,
              rules: [
                {
                  required: true,
                  message: t('PROPERTY_ADDRESS_MSG')
                }
              ]
            })(
              <TextInput
                name={showPropertyLoan ? 'addressExtra' : 'address'}
                setFieldsValue={setFieldsValue}
              />
            )}
          </FormItem>
        </>
      )}
      {selectedSubType === LIABILITY_TYPES.VEHICLE_LOAN && (
        <>
          <Row gutter={20}>
            <Col md={12}>{interestRateField}</Col>
            <Col md={12}>{loanPeriodField}</Col>
          </Row>
          <Row gutter={20}>
            <Col md={12}>{vehicleRegistrationNumberField}</Col>
            <Col md={12}>{securityCollateralField}</Col>
          </Row>
        </>
      )}
      <FormItem label={t('TAGS')}>
        <FileTags tags={tags} setTags={setTags} />
      </FormItem>
      <FormItem
        label={
          <span>
            {t('REFERENCES')}&nbsp;
            <Tooltip
              title={getReferencesTooltip(ENTITY_TYPES.ASSET_LIABILITY)}
              overlayClassName="references-tooltip"
            >
              <Icon type="question-circle-o" />
            </Tooltip>
          </span>
        }
      >
        <AddMultipleFields
          name="references"
          fields={referencesFields}
          customPlaceholders={{
            name: t('REFERENCE_LABEL'),
            value: t('REFERENCE_INFO')
          }}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
          value={references}
        />
      </FormItem>
      <AddItemModal
        wrappedComponentRef={fr => (addItemFormRef = fr)}
        visible={activeReferencesRow !== ''}
        handleOk={handleAddNewOption}
        handleCancel={() => setActiveReferencesRow('')}
        item={t('LABEL')}
        existingItems={referencesOptions}
      />
      <AddEventModal
        visible={addEventModalVisible}
        setVisible={setAddEventModalVisible}
        eventRecurringValue={eventRecurringValue}
        handleClose={() => {
          setAddEventModalVisible(false)
          setEventRecurringValue('')
        }}
        linkedEvents={linkedEvents}
        setLinkedEvents={setLinkedEvents}
        eventDate={eventDate}
        eventDescription={eventDescription}
        fetchEvents={() => {}}
        eventDetails={{}}
      />
    </>
  )
}

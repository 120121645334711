import React, { useState, useContext, } from 'react'
import { Modal, Form, Select, Tooltip } from 'antd'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import VaultContext from '../../contexts/VaultContext'
import { ACCESS_LEVEL, DATE_FORMAT } from '../../share/Constants'
import moment from 'moment'
import AddEventModal from './AddEventModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchEvents,
  fetchPendingEvents
} from '../../features/events/eventsSlice'

const EventLinksModal = props => {
  const { Option } = Select
  const {
    visible,
    setVisible,
    handleLinkEvents,
    form,
    handleClose,
    linkedEvents,
    setLinkedEvents
  } = props
  const { getFieldDecorator } = form

  const { accessLevel } = useSelector(state => state.settings)
  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const [isSaving, setIsSaving] = useState(false)
  const [eventModalVisibile, setEventModalVisibile] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()


  const data =
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? [...events, ...pendingEvents]
      : events
  const sortedData = [...data].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  )

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      setIsSaving(true)
      try {
        if (values.events && values.events.length) {
          await handleLinkEvents(values.events)
        }

        setIsSaving(false)
        form.resetFields()
        setVisible(false)
      } catch (e) {
        setIsSaving(false)
        onError(e)
      }
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      title={t('EVENT_LINKS')}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t('SAVE')}
      cancelText={t('CANCEL')}
      okButtonProps={{ loading: isSaving }}
      maskClosable={false}
    >
      <Form>
        <FormItem style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: 'calc(100% - 58px)' }}>
              {getFieldDecorator('events')(
                <Select mode="multiple" placeholder={t('SELECT_EVENT')}>
                  {sortedData.map(e => (
                    <Option
                      key={e._id}
                      disabled={
                        !!linkedEvents.find(l => l._id === e._id) ||
                        (!isReadonly && !!e.status)
                      }
                    >
                      {moment(e.date).format(DATE_FORMAT)} - {e.description}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
            <Tooltip title={t('ADD_NEW_EVENT')}>
              <Button
                size="large"
                onClick={() => setEventModalVisibile(true)}
                style={{ marginLeft: 8 }}
                icon="plus"
              />
            </Tooltip>
          </div>
        </FormItem>
      </Form>
      <AddEventModal
        visible={eventModalVisibile}
        setVisible={setEventModalVisibile}
        handleClose={handleClose}
        selectedRecord={{}}
        fetchEvents={() => {
          dispatch(fetchEvents(userId, masterKey))
          dispatch(fetchPendingEvents(userId, masterKey))
        }}
        linkedEvents={linkedEvents}
        setLinkedEvents={setLinkedEvents}
        eventDetails={{}}
      />
    </Modal>
  )
}

const WrappedEventLinksForm = Form.create({
  name: 'EventLinksModal'
})(EventLinksModal)

export default WrappedEventLinksForm

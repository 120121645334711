import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Select, Tooltip } from 'antd'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import AssetLiabilityModal from './AssetLiabilityModal'
import { useTranslation, Trans } from 'react-i18next'
import AuthContext from './../../contexts/AuthContext'
import { ACCESS_LEVEL } from './../../share/Constants'
import VaultContext from '../../contexts/VaultContext'

export default function AssetLiabilitySelect({
  label,
  placeholder,
  required,
  getFieldDecorator,
  fieldName,
  initialValue,
  onAddComplete,
  mode,
  disabled
}) {
  const { isReadonly } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      (isReadonly && isDelegateByPD) || isProfessionalDeputy
        ? state.otherAssetsLiabilities
        : state.assetsLiabilities
  )
  const { activeContacts, pendingContacts } = useSelector(state =>
    (isReadonly && isDelegateByPD) || isProfessionalDeputy
      ? state.otherContacts
      : state.contacts
  )
  const { accessLevel } = useSelector(state => state.settings)

  const [assetLiabilityModalVisibile, setAssetLiabilityModalVisibile] =
    useState(false)

  const { t } = useTranslation()

  return (
    <div
      style={{
        display: 'flex'
      }}
    >
      <FormItem label={label} style={{ flexGrow: 1 }}>
        {getFieldDecorator(fieldName, {
          initialValue,
          rules: [
            {
              required: required,
              message: label && (
                <Trans
                  i18nKey="ENTER_LABEL"
                  values={{ label: label.toLowerCase() }}
                ></Trans>
              )
            }
          ]
        })(
          <Select
            showAction={['click', 'focus']}
            mode={mode}
            placeholder={
              placeholder || (
                <Trans
                  i18nKey="SELECT_LABEL"
                  values={{ label: label.toLowerCase() }}
                ></Trans>
              )
            }
            allowClear={!mode}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .includes(input.trim().toLowerCase())
            }
            disabled={disabled}
          >
            {(accessLevel === ACCESS_LEVEL.NEED_APPROVAL
              ? [...activeAssetsLiabilities, ...pendingAssetsLiabilities]
              : activeAssetsLiabilities
            ).map(al => (
              <Select.Option
                disabled={!isReadonly && !!al.status}
                key={al._id}
                value={al._id}
              >
                {al.title}
              </Select.Option>
            ))}
          </Select>
        )}
      </FormItem>
      <Tooltip title={t('ADD_NEW')}>
        <Button
          size="large"
          onClick={() => setAssetLiabilityModalVisibile(true)}
          className={`add-button ${label ? '' : 'no-label'}`}
          icon="plus"
          disabled={disabled}
        />
      </Tooltip>
      <AssetLiabilityModal
        visible={assetLiabilityModalVisibile}
        setVisible={setAssetLiabilityModalVisibile}
        record={{}}
        contactDataSource={
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [...activeContacts, ...pendingContacts]
            : activeContacts
        }
        onAddComplete={onAddComplete}
      />
    </div>
  )
}

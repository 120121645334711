class AssetLiabilityValuation {
  /**
   * Construct a new AssetLiabilityValuation instance
   * @param {object} params - AssetLiabilityValuation creation options:
   * @param {string} [params._id]
   * @param {string} [params._rev]
   * @param {boolean} [params._deleted]
   * @param {string} [params.title]
   * @param {string} [params.type]
   * @param {string} [params.subType]
   * @param {number} [params.percentageOwnership]
   * @param {string} [params.currency]
   * @param {number} [params.valuationInAssetCurrency]
   * @param {number} [params.valuationInBaseCurrency]
   * @param {number} [params.outstandingValueInLiabilityCurrency]
   * @param {number} [params.outstandingValueInBaseCurrency]
   * @param {number} [params.quantity]
   * @param {date} [params.validFrom]
   * @param {date} [params.validTo]
   * @param {boolean} [params.updatedByRate]
   */

  constructor({
    _id,
    _rev,
    _deleted,
    title,
    type,
    subType,
    percentageOwnership,
    currency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    outstandingValueInLiabilityCurrency,
    outstandingValueInBaseCurrency,
    quantity,
    validFrom,
    validTo,
    includeValueInNetWorth,
    updatedByRate
  }) {
    this._id = _id
    this._rev = _rev
    this._deleted = _deleted
    this.title = title
    this.type = type
    this.subType = subType
    this.percentageOwnership = percentageOwnership
    this.currency = currency
    this.valuationInAssetCurrency = valuationInAssetCurrency
    this.valuationInBaseCurrency = valuationInBaseCurrency
    this.outstandingValueInLiabilityCurrency = outstandingValueInLiabilityCurrency
    this.outstandingValueInBaseCurrency = outstandingValueInBaseCurrency
    this.quantity = quantity
    this.validFrom = validFrom
    this.validTo = validTo
    this.includeValueInNetWorth = includeValueInNetWorth
    this.updatedByRate = updatedByRate
  }
}

export default AssetLiabilityValuation

import React from 'react'
import moment from 'moment'
import {
  DATE_FORMAT,
  CURRENCY_FORMAT_REGEX,
  CURRENCY_PARSE_REGEX,
  DATE_TIME_FORMAT,
  CORE_TYPES,
  ENTITY_TYPES,
  ALPHA_NUMERIC_DASH_REGEX,
  RENDER_TYPES,
  EXPORT_DATE_TIME_FORMAT,
  COLUMNS_NOT_SHOW_IN_GROUP,
  DEFAULT_ASSET_LIABILITY_COLUMNS,
  ASSET_TYPES,
  MAX_FILE_SIZE,
  LANGUAGES,
  CONTACT_TYPES,
  ACCESS_LEVEL
} from './Constants'
import { Tag, Modal, Spin, message, Icon } from 'antd'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { uniq, groupBy } from 'lodash'
import { s3Get } from '../lib/awsSDK'
//import { contactAllColumns } from '../components/contacts/contactHelpers'
import { getAssetLiabilityColumns } from '../components/assets-liabilities/assetLiabilityHelpers'
//import { StringResources } from './StringResources'
import api from '../lib/api'
import { randomBytes } from 'crypto'
import NodeRSA from 'node-rsa'
import { AES } from 'crypto-js'
import { decryptFilePromise } from '../lib/crypto'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import domtoimage from 'dom-to-image'
import * as htmlToImage from 'html-to-image'
import { logAccessAttempt } from './logs'
import { onError } from '../lib/sentry'
import i18next from 'i18next'
import sanitizeHtml from 'sanitize-html'
import {
  contactCommonColumns,
  contactColumnsByType
} from '../components/contacts/contactHelpers'
import { Trans } from 'react-i18next'
import logo from '../assets/dark.png'
import folderIcon from '../assets/ic/16/folderIcon.png'
import docIcon from '../assets/ic/16/docIcon.png'
import exelIcon from '../assets/ic/16/exelIcon.png'
import pdfIcon from '../assets/ic/16/Starred-PDF.png'
import pictureIcon from '../assets/ic/16/picture-black.png'
import pptIcon from '../assets/ic/16/pptIcon.png'
import txtIcon from '../assets/ic/16/txtIcon.png'
import wordIcon from '../assets/ic/16/wordIcon.png'
import zipIcon from '../assets/ic/16/zipIcon.png'

export const disabledDate = (startValue, endValue) => {
  if (!startValue || !endValue) {
    return false
  }
  return startValue.startOf('day').isSameOrAfter(endValue.endOf('day'))
}

export const renderDate = text =>
  text ? moment(text).startOf('day').format(DATE_FORMAT) : ''

export const renderDateTime = text =>
  text ? moment(text).format(DATE_TIME_FORMAT) : ''

export const renderItems = items =>
  items && items.length
    ? items.map((item, index) => (
        <span key={index}>
          {item['name']}: {item['value']}
          <br />
        </span>
      ))
    : null

export const renderItemsPlainText = items => {
  return items && items.length
    ? items
        .map((item, index) => `${item['name']}: ${item['value']}`)
        .join('\r\n')
    : null
}

export const renderLinkedItemsPlainText = (
  linkedItemIds,
  sourceItems,
  field
) => {
  const linkedItems =
    linkedItemIds?.length &&
    sourceItems
      .filter(item => linkedItemIds.includes(item._id))
      .map(item => item[field])
  return linkedItems?.length ? linkedItems.join(', ') : null
}

export const renderTextOrHighlight = (value, comparedValue, transform?) =>
  JSON.stringify(value) === JSON.stringify(comparedValue) ? (
    transform ? (
      transform(value)
    ) : (
      value
    )
  ) : (
    // TODO: replace hardcoded color
    <span style={{ backgroundColor: '#ffc069' }}>
      {transform ? transform(value) : value}
    </span>
  )

export const renderSignedNumber = (number, transform?) => (
  <span style={number && number < 0 ? { color: '#eb4444' } : {}}>
    {transform ? transform(number) : number}
  </span>
)

export const currencyInputFormat = value => {
  let parts = value.toString().split('.')
  parts[0] = parts[0].replace(CURRENCY_FORMAT_REGEX, ',')
  return parts.join('.')
}

export const currencyChartFormat = value => {
  return (
    value && Math.round(value).toString().replace(CURRENCY_FORMAT_REGEX, ',')
  )
}

export const currencyFormat = value => {
  return (
    value && value.toFixed(2).toString().replace(CURRENCY_FORMAT_REGEX, ',')
  )
}

export const currencyParse = value => {
  return value && value.replace(CURRENCY_PARSE_REGEX, '')
}

const numberOrDefault = (value, isNegative = false) => {
  return value && (isNegative ? -value : +value)
}

export function getAssetLiabilityValueWithSign(record, isBaseCurrency) {
  switch (record.type) {
    case CORE_TYPES.ASSET:
      return isBaseCurrency
        ? numberOrDefault(record.valuationInBaseCurrency)
        : numberOrDefault(record.valuationInAssetCurrency)
    case CORE_TYPES.LIABILITY:
      return isBaseCurrency
        ? numberOrDefault(record.outstandingValueInBaseCurrency, true)
        : numberOrDefault(record.outstandingValueInLiabilityCurrency, true)
    default:
      return null
  }
}

export const renderTags = (ids, sources) => {
  return ids.map(id => {
    const record = sources.find(r => r._id === id)
    return record && <Tag key={id}>{record.name}</Tag>
  })
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const queryLinkedRecords = (sources, fieldName, selection, func) => {
  return (
    sources &&
    sources.filter(
      record =>
        record[fieldName] && selection[func](s => record[fieldName].includes(s))
    )
  )
}

export const allValuesUndefined = obj => Object.values(obj).every(v => !v)

export const filterEmptyEls = els => els && els.filter(el => el)

export const getOptions = (defaultOptions, data) => {
  const options = [...defaultOptions]
  data &&
    data.length &&
    data.forEach(item => {
      if (!options.includes(item.name)) {
        options.push(item.name)
      }
    })
  return options
}

export const getReferencesTooltip = entityType => {
  const basicInstruction = <p>{i18next.t('REFERENCES_TOOLTIP_SUMMARY')}</p>
  switch (entityType) {
    case ENTITY_TYPES.ASSET_LIABILITY:
      return (
        <>
          {basicInstruction}
          <p>{i18next.t('ASSET_LIABILITY_USE_CASE_EXAMPLES')}:</p>
          <ul>
            <li>{i18next.t('EQITY_LISTED_INFORMATION_OF_TICKER_ISIN')}</li>
            <li>{i18next.t('INSURANCE_SURRENDER_VALUE')}</li>
          </ul>
        </>
      )
    case ENTITY_TYPES.CONTACT:
      return (
        <>
          {basicInstruction}
          <p>{i18next.t('CONTACT_USE_CASE_EXAMPLES')}:</p>
          <ul>
            <li>{i18next.t('INDIVIDUAL_CONTACT_INFORMATION')}</li>
            <li>{i18next.t('ORGANISATION_CONTACT_INFORMATION')}</li>
          </ul>
        </>
      )

    default:
      return basicInstruction
  }
}

export const validateAlphaNumericDash = (rule, value, callback) => {
  if (value && !value.toString().match(ALPHA_NUMERIC_DASH_REGEX))
    callback(i18next.t('ENTER_ALPHANUMERIC_CHARACTERS_AND_DASH_ONLY'))
  callback()
}

export const errorMessage = err => {
  switch (err.code) {
    case 'CodeMismatchException':
    case 'UserLambdaValidationException':
      return i18next.t('INVALID_CODE_PLEASE_TRY_AGAIN')
    case 'EnableSoftwareTokenMFAException':
      return i18next.t('INVALID_CODE_FAILED_TO_SETUP_MFA')
    case 'UserNotConfirmedException':
      return i18next.t('USER_IS_NOT_CONFIRMED')
    case 'InvalidParameterException':
      return i18next.t('INVALID_PARAMETER_EXCEPTION')
    case 'NotAuthorizedException':
      return i18next.t('INCORRECT_USERNAME_OR_PASSWORD')

    default:
      return err.message || i18next.t('UNKNOWN_ERROR_DETECTED')
  }
}

export const search = (records, fieldsToSearch, searchValue) => {
  return records.filter(record => {
    return fieldsToSearch.reduce((found, field) => {
      return (
        found ||
        !searchValue ||
        (record[field] &&
          (field === 'tags'
            ? record[field].includes(searchValue)
            : record[field]
                .toLowerCase()
                .includes(searchValue?.trim().toLowerCase())))
      )
    }, false)
  })
}

export const generateExcelData = async (
  fileName,
  columns,
  sourceData,
  exportDate,
  extension = 'xlsx'
) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet(fileName)

  const dateString = `&L${moment(exportDate).format(DATE_TIME_FORMAT)}`

  worksheet.columns = columns.map(c => ({
    header: typeof c.title === 'string' ? c.title : c.title.props.children,
    key: c.key,
    width: c.exportWidth,
    style: c.style
  }))
  worksheet.addRows(dataToExport(columns, sourceData))
  worksheet.headerFooter.oddHeader = worksheet.headerFooter.oddFooter =
    dateString
  return await workbook[extension].writeBuffer()
}

export const exportToExcel = (
  fileName,
  columns,
  sourceData,
  withExportDate = true,
  extension = 'xlsx'
) => {
  const exportDate = new Date()
  const exportFileName = withExportDate
    ? `${fileName}_${moment(exportDate).format(
        EXPORT_DATE_TIME_FORMAT
      )}.${extension}`
    : `${fileName}.${extension}`

  generateExcelData(fileName, columns, sourceData, exportDate, extension)
    .then(data => {
      const blob = new Blob([data])
      saveAs(blob, exportFileName)
    })
    .catch(err => {
      onError(err)
    })
}

export const dataToExport = (columns, data) => {
  const getValue = (row, col) => {
    switch (col.type) {
      case RENDER_TYPES.TITLE_WITH_ICON:
        return row[col.key]
      case RENDER_TYPES.CURRENCY:
        return currencyFormat(row[col.key])
      case RENDER_TYPES.VALUATION:
        return currencyFormat(
          getAssetLiabilityValueWithSign(
            row,
            col.key === 'valuationInBaseCurrency' ||
              col.key === 'outstandingValueInLiabilityCurrency'
          )
        )
      case RENDER_TYPES.ITEMS:
        return renderItemsPlainText(row[col.key])
      default:
        return col.render ? col.render(row[col.key]) : row[col.key]
    }
  }

  return data.map(row =>
    columns
      .filter(cl => cl.key !== 'actions')
      .reduce(
        (record, col) => ({ ...record, [col.key]: getValue(row, col) }),
        {}
      )
  )
}

// const convertDataURIToBlob = dataURI => {
//   const BASE64_MARKER = ';base64,'
//   const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
//   const base64 = dataURI.substring(base64Index)
//   const raw = window.atob(base64)
//   const rawLength = raw.length
//   const array = new Uint8Array(new ArrayBuffer(rawLength))

//   for (let i = 0; i < rawLength; i++) {
//     array[i] = raw.charCodeAt(i)
//   }
//   return new Blob([array])
// }

export const getFileExtension = filename => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
}

export const sortTree = arr => {
  const compare = (a, b) => {
    const compared =
      a.type === b.type
        ? getFileExtension(a.name) === getFileExtension(b.name)
          ? a.name === b.name
            ? ''
            : a.name.localeCompare(b.name)
          : getFileExtension(a.name).localeCompare(getFileExtension(b.name))
        : b.type.localeCompare(a.type)
    return compared
  }
  arr.forEach(element => {
    if (element.hasOwnProperty('children')) {
      sortTree(element.children)
    }
  })
  arr.sort(compare)
}

export const updatePathsTree = tree => {
  tree.forEach(element => {
    element.children.length > 0
      ? (element.type = 'folder')
      : (element.type = 'file')
    if (element.children) {
      updatePathsTree(element.children)
    }
  })
}

export const exportFilesToPDF = (fullName, pathsTree) => {
  try {
    const exportDate = new Date()
    const doc = new jsPDF()
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
    doc.setFontSize(20)
    doc.text(
      fullName + ' - List of Folders & Files',
      pageWidth / 2,
      15,
      'center'
    )
    doc.setFontType('bold')
    let index = 1
    let elementOfTree = 1
    const resultToPDF = tree => {
      tree.forEach(node => {
        if (node.name !== '') {
          doc.setFontSize(10)
          doc.setFontType('normal')
          if (node.type === 'folder') {
            doc.addImage(
              folderIcon,
              'png',
              10 * index + 5,
              elementOfTree * 6 + 18,
              4,
              4
            )
          } else {
            switch (getFileExtension(node.name).toLowerCase()) {
              case 'xls':
              case 'xlsx':
                doc.addImage(
                  exelIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
              case 'jpg':
              case 'jpeg':
              case 'png':
                doc.addImage(
                  pictureIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
              case 'pdf':
                doc.addImage(
                  pdfIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
              case 'ppt':
              case 'pptx':
                doc.addImage(
                  pptIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
              case 'txt':
                doc.addImage(
                  txtIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
              case 'doc':
              case 'docx':
                doc.addImage(
                  wordIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
              case 'zip':
                doc.addImage(
                  zipIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
              default:
                doc.addImage(
                  docIcon,
                  'png',
                  10 * index + 5,
                  elementOfTree * 6 + 18,
                  4,
                  4
                )
                break
            }
          }
          elementOfTree++
          doc.text('\t' + node.name, 10 * index + 5, elementOfTree * 6 + 15)
        }
        if (node.children) {
          index++
          resultToPDF(node.children)
        }
        if (!node.children?.length) {
          index--
        }
        if (tree.indexOf(node) === tree.length - 1) {
          index--
        }
      })
      if (elementOfTree * 6 + 15 > pageHeight - 30) {
        doc.addPage()
        elementOfTree = 1
      }
    }
    resultToPDF(pathsTree)

    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.addImage(logo, 'png', 15, pageHeight - 10)
      doc.text('Page ' + String(i), pageWidth - 15, pageHeight - 10, 'right')
      doc.text(exportDate.toString(), pageWidth - 15, pageHeight - 5, 'right')
    }

    doc.save(`Files_${moment(exportDate).format(EXPORT_DATE_TIME_FORMAT)}.pdf`)
  } catch (err) {
    onError(err)
    message.error(i18next.t('FAILED_TO_EXPORT_TO_PDF'))
  }
}

export const exportFilesToExcel = (fullName, pathsTree) => {
  const workbook = new Workbook()
  const exportDate = new Date()
  const dateString = `&L${moment(exportDate).format(DATE_TIME_FORMAT)}`
  const worksheet = workbook.addWorksheet('Export Folder')
  worksheet.headerFooter.oddHeader = worksheet.headerFooter.oddFooter =
    dateString
  worksheet.mergeCells('A1', 'D2')
  worksheet.getCell('A1').value = fullName + ' - List of Folders & Files'
  worksheet.getCell('A1').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  }

  worksheet.getCell('A1').font = {
    size: 20,
    bold: true
  }

  let level = 1
  let index = 3
  const resultToExcel = tree => {
    tree.forEach(node => {
      if (node.name !== '') {
        const row = worksheet.getRow(index)
        const space = '         '.repeat(level - 1)
        row.values = [space + node.name]
        row.outlineLevel = level
        if (level !== 1) {
          row.hidden = true
        }
        index++
      }
      if (node.children) {
        level++
        resultToExcel(node.children)
      }
      if (!node.children?.length) {
        level--
      }
      if (tree.indexOf(node) === tree.length - 1) {
        level--
      }
    })
  }
  resultToExcel(pathsTree)

  //set auto width
  worksheet.columns.forEach((column, i) => {
    let maxLength = 0
    column['eachCell']({ includeEmpty: true }, cell => {
      let columnLength = cell.value ? cell.value.toString().length : 10
      if (columnLength > maxLength) {
        maxLength = columnLength
      }
    })
    column.width = maxLength < 10 ? 10 : maxLength
  })

  const exportFileName = `Files_${moment(exportDate).format(
    EXPORT_DATE_TIME_FORMAT
  )}.xlsx`
  workbook.xlsx
    .writeBuffer()
    .then(data => {
      const blob = new Blob([data])
      saveAs(blob, exportFileName)
    })
    .catch(err => {
      onError(err)
      message.error(i18next.t('FAILED_TO_EXPORT_TO_EXCEL'))
    })
}

const getAssetLiabilityColumnsToExport = (
  activeAssetsLiabilities,
  activeContacts,
  activeFiles
) => {
  const subtypes = []
  activeAssetsLiabilities.forEach(record => {
    const key = `${record.type}_${record.subType || ''}`
    if (!subtypes.find(subtype => subtype.key === key)) {
      subtypes.push({ key, record })
    }
  })

  const uniqueKeys = uniq(
    subtypes.reduce((keys, subtype) => {
      return [...keys, ...Object.keys(subtype.record)]
    }, [])
  )

  return getAssetLiabilityColumns(
    activeContacts,
    activeAssetsLiabilities,
    activeFiles
  ).filter(
    col =>
      (uniqueKeys.includes(col.key) ||
        DEFAULT_ASSET_LIABILITY_COLUMNS.includes(col.key)) &&
      !COLUMNS_NOT_SHOW_IN_GROUP.includes(col.key)
  )
}

export const getFileSize = file => {
  return file?.file?.length ? file.file[0].size : 0
}

export const downloadData = async (
  userId,
  masterKey,
  fullname,
  activeContacts,
  activeAssetsLiabilities,
  filteredActiveFiles,
  currentZipSize,
  zip,
  getFileAndDecrypt,
  failedFiles
) => {
  const exportDate = new Date()
  const dateString = moment(exportDate).format(EXPORT_DATE_TIME_FORMAT)
  let partIndex = 0

  const modal = Modal.info({
    title: i18next.t('DOWNLOADING_VAULTBOX'),
    content: (
      <Spin
        tip={i18next.t('DOWNLOAD_STEP', {
          index: 0,
          size: filteredActiveFiles.length
        })}
      />
    ),
    maskClosable: false,
    keyboard: false,
    okButtonProps: { hidden: true },
    icon: null,
    className: 'centered-content-modal'
  })

  for (let i = 0; i < filteredActiveFiles.length; i++) {
    const file = filteredActiveFiles[i]
    currentZipSize += getFileSize(file)

    if (currentZipSize > MAX_FILE_SIZE) {
      modal.update({
        content: (
          <Spin
            tip={i18next.t('SAVING_ZIP_PART', {
              partIndex: ++partIndex
            })}
          />
        )
      })
      const partialContent = await zip.generateAsync({
        type: 'blob'
      })
      saveAs(partialContent, `vaultbox_${dateString}_${partIndex}`)
      modal.update({
        content: <Spin tip={i18next.t('SAVING_ZIP_PART', { partIndex })} />
      })

      zip = new JSZip()
      currentZipSize = getFileSize(file)
    }

    const uint8Array = await getFileAndDecrypt(file, userId, masterKey)
    modal.update({
      content: (
        <Spin
          tip={i18next.t('DOWNLOAD_STEP', {
            index: i + 1,
            size: filteredActiveFiles.length
          })}
        />
      )
    })
    if (uint8Array) {
      zip.file(`Files/${file.path}${file.fileName}`, uint8Array)
    }
  }

  const contactsExcelData = await exportContactToExcel(
    activeContacts,
    fullname,
    true
  )
  // await generateExcelData(
  //   'Contacts',
  //   contactAllColumns(),
  //   activeContacts,
  //   exportDate
  // )
  zip.file(`Contacts_${dateString}.xlsx`, new Blob([contactsExcelData]))

  const assetLiabilitiesExcelData = await generateExcelData(
    'AssetsLiabilities',
    getAssetLiabilityColumnsToExport(
      activeAssetsLiabilities,
      activeContacts,
      filteredActiveFiles
    ),
    activeAssetsLiabilities,
    exportDate
  )
  zip.file(
    `AssetsLiabilities_${dateString}.xlsx`,
    new Blob([assetLiabilitiesExcelData])
  )

  modal.update({
    content: (
      <Spin
        tip={
          partIndex
            ? i18next.t('SAVING_ZIP_PART', { partIndex: ++partIndex })
            : i18next.t('SAVING_ZIP')
        }
      />
    )
  })
  const zipContent = await zip.generateAsync({
    type: 'blob'
  })
  saveAs(
    zipContent,
    `vaultbox_${dateString}` + (partIndex ? `_${partIndex}` : '')
  )

  modal.destroy()

  // if (failedFiles.length) {
  //   modal.update({
  //     title: i18next.t('DOWNLOADED_VAULTBOX'),
  //     content: (
  //       <div>
  //         <p>{i18next.t('FAILED_TO_DOWNLOAD_THE_FOLLOWING_FILES')}:</p>
  //         <ul>
  //           {failedFiles.map(file => (
  //             <li key={file.fileId}>
  //               {file.path}
  //               {file.fileName}
  //             </li>
  //           ))}
  //         </ul>
  //         <p>{i18next.t('PLEASE_DOWNLOAD_THEM_MANUALLY')}</p>
  //       </div>
  //     ),
  //     className: '',
  //     okButtonProps: {}
  //   })
  //   throw Error(i18next.t('FAILED_TO_DOWNLOAD_ALL_VAULTBOX'))
  // } else {
  //   modal.destroy()
  // }
}

export const downloadAllDataAsZip = async (
  userId,
  activeAssetsLiabilities,
  activeContacts,
  masterKey,
  fullName,
  currentZipSize,
  filteredActiveFiles
) => {
  let zip = new JSZip()
  let failedFiles = []

  const getFileAndDecrypt = async (file, userId, masterKey) => {
    try {
      const resBody = await s3Get(
        userId,
        file.fileId,
        { sub: file.sub },
        { responseType: 'blob' }
      )
      return await decryptFilePromise(resBody, masterKey)
    } catch (err) {
      onError(err)
      failedFiles.push(file)
    }
  }

  try {
    await downloadData(
      userId,
      masterKey,
      fullName,
      activeContacts,
      activeAssetsLiabilities,
      filteredActiveFiles,
      currentZipSize,
      zip,
      getFileAndDecrypt,
      failedFiles
    )
  } catch (err) {
    throw err
  }
}

export const purchaseDateLabel = selectedSubType => {
  switch (selectedSubType) {
    case ASSET_TYPES.LOAN_TO_OTHERS:
      return i18next.t('START_DATE')
    default:
      return i18next.t('PURCHASE_DATE')
  }
}

export const purchaseDateMessage = selectedSubType => {
  switch (selectedSubType) {
    case ASSET_TYPES.LOAN_TO_OTHERS:
      return i18next.t('INPUT_START_DATE')
    default:
      return i18next.t('INPUT_PURCHASE_DATE_MSG')
  }
}

export const valuationDateLabel = selectedSubType => {
  switch (selectedSubType) {
    case ASSET_TYPES.CASH:
    case ASSET_TYPES.CRYPTO:
    case ASSET_TYPES.BROKERAGE_ACCOUNT:
    case ASSET_TYPES.RETIREMENT_ACCOUNT:
    case ASSET_TYPES.RETIREMENT_CRYPTO:
      return i18next.t('BALANCE_DATE')
    case ASSET_TYPES.EQUITY_LISTED:
    case ASSET_TYPES.EQUITY_UNLISTED:
      return i18next.t('POSITION_DATE')
    default:
      return i18next.t('VALUATION_DATE')
  }
}

export const resetAccount = async (
  userData,
  password,
  cognitoUser,
  setIsAuthenticated,
  setUser,
  history?
) => {
  try {
    // generating user's master encryption key
    const masterKey = randomBytes(20).toString('hex')
    const encryptedMasterKey = AES.encrypt(masterKey, password).toString()

    // generating public & private keys
    const key = new NodeRSA()
    key.generateKeyPair(1024) // while it's recommended to have key size as 2048, need to use this size to fit the max length of Cognito's user attribute
    const publicKey = key.exportKey('public')
    const privateKey = key.exportKey('pkcs8')
    const encryptedPrivateKey = AES.encrypt(privateKey, masterKey).toString()

    const attributes = [
      {
        Name: 'custom:master_key',
        Value: encryptedMasterKey
      },
      {
        Name: 'custom:public_key',
        Value: publicKey
      },
      {
        Name: 'custom:private_key',
        Value: encryptedPrivateKey
      }
    ]

    cognitoUser.updateAttributes(attributes, function (err, data) {
      if (err) {
        throw err
      } else {
        console.log(data)
      }
    })

    await api.resetAccount(
      userData.Username,
      JSON.stringify({ publicKey: publicKey })
    )

    const extraKeyRes = await api.getUser(userData.Username)
    const { extraKey } = extraKeyRes.data
    const encryptedKey = AES.encrypt(masterKey, extraKey).toString()
    localStorage.setItem(userData.Username, encryptedKey)
    setIsAuthenticated(true)
    setUser(cognitoUser)
    logAccessAttempt(
      userData.Username,
      userData.UserAttributes,
      userData.Username
    )
    history && history.push('/')
  } catch (err) {
    onError(err)
  }
}

export const exportToPDF = (
  columns,
  source,
  fileName,
  logo,
  fullname?,
  isContact = false
) => {
  jsPDF.autoTableSetDefaults({
    headStyles: { fillColor: 'darkGreen' }
  })
  const doc = new jsPDF('l')
  const cols = columns.map(d => {
    return {
      header: typeof d.title === 'string' ? d.title : d.title.props.children,
      dataKey: d.key
    }
  })

  const data = dataToExport(columns, source)
  const exportDate = new Date()

  doc.autoTable({
    columns: cols,
    body: data,
    didDrawPage: () => {
      const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
      const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
      if (isContact) {
        const title = `${fullname} - ${fileName}`

        doc.setFontSize(14)
        doc.setFontType('bold')
        doc.text(title, pageWidth / 2 - title.length, 10)
      }

      doc.addImage(logo, 'png', 15, pageHeight - 10)
      doc.setTextColor(100)
      doc.setFontSize(8)
      doc.setFontType('italic')
      const pages = doc.internal.getNumberOfPages()
      doc.text(` Page ${pages}`, pageWidth - 15, pageHeight - 10, 'right')
      doc.text(exportDate.toString(), pageWidth - 15, pageHeight - 5, 'right')
    }
  })

  doc.save(
    `${fileName}_${moment(exportDate).format(EXPORT_DATE_TIME_FORMAT)}.pdf`
  )
}

const convertSvgToPng = (svg, width, height, padding) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')

    // Set background to white
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, width, height)

    const xml = new XMLSerializer().serializeToString(svg)
    const dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml)
    const img = new Image(width, height)

    img.onload = () => {
      ctx.drawImage(img, 0, padding / 2)
      const imageData = canvas.toDataURL('image/png')
      resolve(imageData)
    }

    img.onerror = () => reject()

    img.src = dataUrl
  })
}

export const exportToPng = async (ref, fileName, padding = 30) => {
  if (ref?.container) {
    try {
      const svg = ref.container.children[0]
      const pngData = await convertSvgToPng(
        svg,
        ref.container.clientWidth + padding,
        ref.container.clientHeight + padding,
        padding
      )

      saveAs(
        pngData,
        `${fileName} - ${moment().format(EXPORT_DATE_TIME_FORMAT)}.png`
      )
    } catch (err) {
      onError(err)
      throw err
    }
  }
}

export const exportDomToPng = async (dom, bgcolor, fileName) => {
  domtoimage
    .toBlob(dom, { bgcolor })
    .then(blob =>
      saveAs(
        blob,
        `${fileName} - ${moment().format(EXPORT_DATE_TIME_FORMAT)}.png`
      )
    )
    .catch(err => {
      throw err
    })
}

export const exportHTMLToPng = async (dom, bgcolor, fileName) => {
  htmlToImage
    .toBlob(dom, { bgcolor })
    .then(blob =>
      saveAs(
        blob,
        `${fileName} - ${moment().format(EXPORT_DATE_TIME_FORMAT)}.png`
      )
    )
    .catch(err => {
      throw err
    })
}

export const getFileContent = async (userId, fileId, masterKey) => {
  try {
    const statusRes = await api.getFileStatus(userId, fileId)
    if (statusRes.data && statusRes.data.isLocked) {
      throw Error(i18next.t('THE_SELECTED_FILE_IS_LOCK'))
    }

    const resBody = await s3Get(userId, fileId, {}, { responseType: 'blob' })
    const uint8Array = await decryptFilePromise(resBody, masterKey)
    const blob = new Blob([uint8Array])
    return blob
  } catch (error) {
    throw error
  }
}

export const addPDNameColumn = (
  columns,
  professionalDeputies,
  delegatedByProfessionalDeputies,
  isPendingDeputyRequest
) => {
  const pdNameColumn = {
    title: i18next.t('PD_NAME'),
    dataIndex: 'pdName',
    key: 'pdName',
    render: (text, record) => {
      if (!delegatedByProfessionalDeputies?.length) return ''

      const delegatedByProfessionalDeputy =
        delegatedByProfessionalDeputies.find(dpd =>
          isPendingDeputyRequest
            ? dpd.pendingPrimaryUsers?.includes(record.key)
            : dpd.primaryUsers?.includes(record.key || record.userId)
        )

      if (!delegatedByProfessionalDeputy) return ''

      const pdName = professionalDeputies.find(
        pd => pd.id === delegatedByProfessionalDeputy.professionalDeputyId
      )?.professionalDeputyName

      return pdName || ''
    }
  }

  return columns.splice(1, 0, pdNameColumn)
}
export const getTimeRange = (dateFrom, isShowMonth = false) => {
  const times = []
  const endDate = new Date()
  const dateCounter = new Date(dateFrom)

  while (
    isShowMonth
      ? dateCounter.getFullYear() <= endDate.getFullYear()
      : dateCounter <= endDate
  ) {
    const newDate = new Date(
      `${dateCounter.getFullYear()}/${
        dateCounter.getMonth() + 1
      }/${dateCounter.getDate()}`
    ).getTime()

    times.push(newDate)

    isShowMonth
      ? dateCounter.setMonth(dateCounter.getMonth() + 1)
      : dateCounter.setFullYear(dateCounter.getFullYear() + 1)
  }

  return times
}

export const getTicks = (chartFrom, chartData, value, isDetail = false) => {
  const startDate =
    chartFrom === 'dataMin'
      ? Math.min(
          ...chartData.map(data => (isDetail ? data.validFromUnix : data.time))
        )
      : chartFrom

  const endDate = new Date().setHours(0, 0, 0, 0)
  const timeRange = getTimeRange(startDate, true)
  switch (value) {
    case '1Y':
      return timeRange.splice(0, 13)
    case '3M':
      return timeRange.splice(0, 4)
    case '6M':
      return timeRange.splice(0, 7)
    case '3Y':
    case '5Y':
      return getTimeRange(startDate)
    case 'allTime':
      if (
        new Date(endDate).getFullYear() - new Date(startDate).getFullYear() >
        2
      ) {
        return getTimeRange(startDate)
      }

      if (
        new Date(endDate).getFullYear() === new Date(startDate).getFullYear()
      ) {
        if (new Date(endDate).getMonth() - new Date(startDate).getMonth() > 2) {
          return timeRange.splice(0, timeRange.indexOf(endDate))
        }
      }
      return [startDate, endDate]

    default:
      return [startDate, endDate]
  }
}

export const removeHtmlTags = values => {
  Object.entries(values).forEach(entry => {
    if (typeof entry[1] === 'string') {
      values[entry[0]] = sanitizeValue(entry[1])
    }

    if (Array.isArray(entry[1])) {
      entry[1].forEach((v, i) => {
        if (typeof v === 'object' && !Array.isArray(v)) {
          removeHtmlTags(v)
        }

        if (typeof v === 'string') {
          entry[1][i] = sanitizeValue(v)
        }
      })
    }
  })
  return values
}

export const sanitizeValue = value => {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {}
  })
}

export const checkSelectedLanguage = language => {
  switch (language) {
    case LANGUAGES.ENGLISH:
      return 'English'
    case LANGUAGES.VIETNAMESE:
      return 'Tiếng Việt'
    case LANGUAGES.INDONESIA:
      return 'Bahasa Indonesia'
    case LANGUAGES.CHINESE:
      return '简体中文'

    default:
      return 'English'
  }
}

export const checkRegistryType = type => {
  if (type === CORE_TYPES.ASSET) {
    return i18next.t('ASSET')
  } else {
    return i18next.t('LIABILITY')
  }
}

export const checkSelectedLevel = level => {
  switch (level) {
    case ACCESS_LEVEL.ALLOW:
      return i18next.t('ALLOW')
    case ACCESS_LEVEL.NEED_APPROVAL:
      return i18next.t('NEED_APPROVAL')
    case ACCESS_LEVEL.DENY:
      return i18next.t('DENY')

    default:
      return i18next.t('DENY')
  }
}

export const pngToBase64 = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )

export const exportContactToExcel = async (
  data,
  fullname,
  isDownloaded = false
) => {
  const workbook = new Workbook()

  const individual = workbook.addWorksheet(CONTACT_TYPES.INDIVIDUAL)
  const organisation = workbook.addWorksheet(CONTACT_TYPES.ORGANISATION)

  const individualData = data
    .filter(ac => ac.type === CONTACT_TYPES.INDIVIDUAL)
    .sort((a, b) => a.name.localeCompare(b.name))

  const organisationData = data
    .filter(ac => ac.type === CONTACT_TYPES.ORGANISATION)
    .sort((a, b) => a.name.localeCompare(b.name))

  const individualCols = [
    ...contactColumnsByType()[CONTACT_TYPES.INDIVIDUAL],
    ...contactCommonColumns()
  ].filter(c => c.key !== 'type')

  const organisationCols = [
    ...contactColumnsByType()[CONTACT_TYPES.ORGANISATION],
    ...contactCommonColumns()
  ].filter(c => c.key !== 'type')

  const exportDate = new Date()
  addDataToWs(
    individual,
    individualData,
    individualCols,
    fullname,
    'Individual Contacts'
  )
  addDataToWs(
    organisation,
    organisationData,
    organisationCols,
    fullname,
    'Organisation Contacts'
  )
  if (isDownloaded) {
    return workbook.xlsx.writeBuffer()
  } else {
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data])
      saveAs(
        blob,
        `Contacts - ${moment(exportDate).format(EXPORT_DATE_TIME_FORMAT)}.xlsx`
      )
    })
  }
}

const addDataToWs = (ws, data, columns, fullName, sheetName) => {
  ws.columns = columns.map(c => ({
    width: c.exportWidth,
    style: c.style
  }))
  const dateString = `&L${moment().format(DATE_TIME_FORMAT)}`
  const title = `${fullName} - ${sheetName}`

  ws.addRow([title]).getCell(1).style = {
    alignment: {
      vertical: 'middle',
      horizontal: 'center'
    },
    font: {
      name: 'Arial',
      bold: true,
      size: 14
    }
  }

  ws.mergeCells('A1', 'K2')

  const headerCols = columns.map(c => c.title)

  ws.addRow(headerCols)

  const dataExport = dataToExport(columns, data)
  dataExport.forEach(d => {
    ws.addRow(Object.values(d))
  })
  ws.headerFooter.oddHeader = ws.headerFooter.oddFooter = dateString
}

export const showUpgradeSubscriptionPlanConfirm =
  setSubscriptionModalVisible => {
    Modal.confirm({
      content: (
        <span>
          <Trans i18nKey="UPGRAGE_PLAN_SUMMARY"></Trans>
        </span>
      ),
      okText: <Trans i18nKey="UPGRADE"></Trans>,
      cancelText: <Trans i18nKey="NOT_NOW"></Trans>,
      onOk: () => {
        setSubscriptionModalVisible(true)
      },
      onCancel: () => {},
      icon: <Icon type="info-circle" />
    })
  }

export const fetchExternalUserAttributes = async userId => {
  try {
    const response = await api.getExternalUserAttributes(userId)
    const userAttributes = response.data.cognitoUser.UserAttributes
    localStorage.setItem('UserAttributes', JSON.stringify(userAttributes))
  } catch (error) {
    console.log('error', error.toString())
    onError(error)
  }
}

export const getExternalUserAttributes = () => {
  try {
    const externalUserAttributes = JSON.parse(
      localStorage.getItem('UserAttributes')
    )
    const externalUserAttribute = externalUserAttributes.reduce(
      (object, attribute) =>
        Object.assign(object, {
          [attribute.Name.replace('custom:', '')]: attribute.Value
        }),
      {}
    )
    return externalUserAttribute
  } catch (error) {
    onError(error)
  }
}

export const getPlanNickname = (name, isGroup) => {
  if ((name === 'Monthly' || name === 'Yearly') && !isGroup) {
    return 'vaultbox Pro'
  }

  if (isGroup) {
    return 'vaultbox Group'
  }
  //wait for Group Subsription Plan to return Group
  return 'vaultbox Essential'
}

export const getMemberDeputies = async (
  memberUserDeputies,
  professionalDeputies,
  professionalDeputyId
) => {
  const groupedDeputies = groupBy(
    memberUserDeputies,
    d => d.professionalDeputyId
  )
  const profGroupKey = Object.keys(groupedDeputies)?.find(
    gp => gp !== 'undefined'
  )

  const accepted = groupedDeputies['undefined']?.filter(d => d.publicKey) || []
  const pending = groupedDeputies['undefined']?.filter(d => !d.publicKey) || []

  if (profGroupKey) {
    const matchingPD = professionalDeputies?.find(pd => pd.id === profGroupKey)
    if (groupedDeputies[profGroupKey]?.every(d => d.publicKey)) {
      accepted.push(matchingPD)
    } else {
      pending.push({ ...matchingPD, email: matchingPD.accounts[0].email })
    }
  }
  
  const allDeputies = [...accepted, ...pending].filter(
    d => !professionalDeputyId || d.id === professionalDeputyId
  )

  return allDeputies
}

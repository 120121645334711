import React, { useState, useEffect, useContext } from 'react'
import { Tag, Tooltip, Icon, AutoComplete } from 'antd'
import { useTranslation } from 'react-i18next'
import { sanitizeValue } from '../../share/helpers'
import TextInput from '../common/TextInput'
import { useSelector } from 'react-redux'
import AuthContext from '../../contexts/AuthContext'
import { uniq } from 'lodash'
import VaultContext from './../../contexts/VaultContext'

export default function FileTags(props) {
  const { tags, setTags } = props
  const [inputVisible, setInputVisible] = useState(false)
  const [allTags, setAllTags] = useState([])
  const [inputValue, setInputValue] = useState('')
  const { t } = useTranslation()
  const { isReadonly } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { contactTags, pendingContactTags } = useSelector(state =>
    isProfessionalDeputy ? state.otherContacts : state.contacts
  )

  const { fileTags, pendingFileTags } = useSelector(state =>
    isProfessionalDeputy || isDelegateByPD || isReadonly
      ? state.otherDocuments
      : state.documents
  )

  const { eventTags } = useSelector(state => state.events)
  const { assetsLiabilitiesTags, pendingAssetsLiabilitiesTags } = useSelector(
    state =>
      isProfessionalDeputy || isDelegateByPD || isReadonly
        ? state.otherAssetsLiabilities
        : state.assetsLiabilities
  )
  const { passwordTags } = useSelector(state => state.passwords)
  
  let tagInput

  const handleInputChange = value => {
    setInputValue(value)
  }
  const handleClose = removedTag => {
    setTags(tags.filter(tag => tag !== removedTag))
  }

  const showInput = () => {
    setInputVisible(true)
  }

  useEffect(() => {
    setAllTags(
      uniq(
        (isProfessionalDeputy
          ? [
              ...contactTags,
              ...pendingContactTags,
              ...fileTags,
              ...pendingFileTags,
              ...eventTags,
              ...assetsLiabilitiesTags,
              ...pendingAssetsLiabilitiesTags,
              ...passwordTags
            ]
          : [
              ...contactTags,
              ...fileTags,
              ...eventTags,
              ...assetsLiabilitiesTags,
              ...passwordTags
            ]
        ).flat()
      )
    )
  }, [
    pendingContactTags,
    contactTags,
    fileTags,
    pendingFileTags,
    eventTags,
    assetsLiabilitiesTags,
    pendingAssetsLiabilitiesTags,
    passwordTags,
    isProfessionalDeputy
  ])

  useEffect(() => {
    if (inputVisible) tagInput.focus()
  }, [inputVisible, tagInput])

  const handleInputConfirm = () => {
    if (
      inputValue.trim() &&
      tags.indexOf(inputValue.trim().toLowerCase()) === -1
    ) {
      setTags([...tags, sanitizeValue(inputValue).trim()])
    }
    setInputVisible(false)
    setInputValue('')
  }

  return (
    <div>
      {uniq(tags).map((tag, index) => {
        const isLongTag = tag.length > 20
        const tagElem = (
          <Tag key={tag} closable onClose={() => handleClose(tag)}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        )
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
      {inputVisible && (
        <AutoComplete
          dataSource={allTags.filter(tag => !tags.includes(tag))}
          style={{ width: 100 }}
          size="small"
          ref={el => (tagInput = el)}
          filterOption={(input, option) =>
            option.props.children
              .toString()
              .toLowerCase()
              .includes(input.trim().toLowerCase())
          }
          defaultOpen={true}
          onChange={handleInputChange}
        >
          <TextInput
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            value={inputValue}
          />
        </AutoComplete>
      )}
      {!inputVisible && (
        <Tag
          onClick={showInput}
          style={{ background: '#fff', borderStyle: 'dashed' }}
        >
          <Icon type="plus" /> {t('NEW_TAG')}
        </Tag>
      )}
    </div>
  )
}

import React, { useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Modal,
  Icon,
  Alert,
  Menu,
  DatePicker,
  Divider,
  Spin,
  Layout,
  Row,
  Col,
  message,
  Checkbox,
  Dropdown,
  Input
} from 'antd'
import moment from 'moment'
import { getAuthenticationDetails } from '../../lib/cognito'
//import { StringResources } from '../../share/StringResources'
import {
  destroyAllDbs,
  removeAllDocs,
  uploadEncryptedData
} from '../../lib/pouchDb'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import api from '../../lib/api'
import {
  errorMessage,
  renderDateTime,
  disabledDate,
  downloadAllDataAsZip,
  getFileSize,
  getMemberDeputies
} from '../../share/helpers'
import WrappedForm from '../common/WrappedForm'
import FormItem from '../override/FormItem'
import { Span, P, StatusText } from '../override/Typography'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import CustomIcon from '../override/Icon'
import { ThemeContext } from 'styled-components'
import { AUTH_FLOW, DATE_FORMAT } from '../../share/Constants'
import Button from '../override/Button'
import MfaForm from '../common/MfaVerificationForm'
import { MFA_TYPES } from '../../share/Constants'
import { onError } from '../../lib/sentry'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PouchDB from 'pouchdb'
import { useTranslation, Trans } from 'react-i18next'
import PasswordDownloadModal from '../modals/PasswordDownloadModal'
import DownloadVaultboxModal from '../modals/DownloadVaultboxModal'
import { fetchPendingAssetsLiabilities } from '../../features/assets-liabilities/assetsLiabilitiesSlice'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { revokeDeputy } from '../../features/deputies/deputiesSlice'

const { Content, Sider } = Layout

const menuItem = menuItem => (
  <Menu.Item key={menuItem.url}>
    <Link to={menuItem.url}>
      {menuItem.icon}
      <span className="menu-item-title">{menuItem.title}</span>
    </Link>
  </Menu.Item>
)

const menu = (menuItems, location, mode) => (
  <Menu selectedKeys={[(location && location.pathname) || '/']} mode={mode}>
    {menuItems.map(item => menuItem(item))}
  </Menu>
)

const settingMenuItems = [
  {
    title: <Trans i18nKey="PROFILE_INFORMATION"></Trans>,
    url: '/settings',
    icon: <CustomIcon type="personal" />
  },
  {
    title: <Trans i18nKey="EXCHANGE_RATES"></Trans>,
    url: '/settings/currency',
    icon: <CustomIcon type="cash" />
  },
  {
    title: <Trans i18nKey="SUBSCRIPTION"></Trans>,
    url: '/settings/payment',
    icon: <Icon type="credit-card" />
  },
  {
    title: <Trans i18nKey="GIFTS"></Trans>,
    url: '/settings/gifts',
    icon: <Icon type="gift" />
  }
]

let passwordFormRef, callbacks, formRef, cognitoUser, authDetails

const Settings = ({ location, children }) => {
  const { user, isDeputyOnly } = useContext(AuthContext)
  const { masterKey, fullName } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const [errMsg, setErrMsg] = useState('')
  const [accessAttempts, setAccessAttempts] = useState([])
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDeputyAccessOnly, setIsDeputyAccessOnly] = useState(false)
  const [mfaType, setMfaType] = useState()
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [passwordModalVisible, setPasswordModalVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleteUser, setIsDeleteUser] = useState(false)
  const [passwordDownloadModalVisible, setPasswordDownloadModalVisible] =
    useState(false)
  const [downloadVaultboxModalVisible, setDownloadVaultboxModalVisible] =
    useState(false)
  const [professionalDeputies, setProfessionalDeputies] = useState()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const externalUser = localStorage.getItem('External_User')
  const { activeContacts } = useSelector(state => state.contacts)

  const { activeAssetsLiabilities } = useSelector(
    state => state.assetsLiabilities
  )
  const { activeFolders, activeFiles } = useSelector(state => state.documents)

  const { customer, vaultboxSubscription, membersCode } = useSelector(
    state => state.customer
  )
  const currentPlanId = vaultboxSubscription?.plan?.id

  const {
    currentFlow,
    mainUserId,
    members,
    email,
    deputies,
    appointedProfessionalDeputyId,
    memberCode
  } = useSelector(state => state.user).user
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)

  const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    setIsLoading(true)
    // when there is endDate with no startDate,
    // startDate is still required in the QLDB query, so just use moment 0
    let params = {
      ...(startDate
        ? { startTime: startDate.startOf('day').toISOString() }
        : endDate
        ? { startTime: new Date(0).toISOString() }
        : {})
    }

    if (endDate) {
      const now = new Date()
      // endTime in QLDB query can't be after now
      const endTime = endDate.endOf('day').isAfter(now)
        ? now
        : endDate.endOf('day')

      params = { ...params, endTime: endTime.toISOString() }
    }

    api
      .getAccessAttempts(user.username, params)
      .then(res => {
        const data = res.data.length
          ? res.data
              .filter(
                // still need to add the filter on the client
                // since the QLDB query would still include the record
                // if the transaction's active period includes the start date
                r =>
                  !startDate ||
                  startDate.startOf('day').isSameOrBefore(r.metadata.txTime)
              )
              .map(record => ({
                ...record.data,
                Id: record.metadata.txId,
                Time: record.metadata.txTime
              }))
              .reverse()
          : []

        setAccessAttempts(data)
      })
      .catch(err => onError(err))
      .finally(() => setIsLoading(false))
  }, [user, startDate, endDate])

  useEffect(() => {
    const getProfessionalDeputies = async () => {
      try {
        const rs = await api.getProfessionalDeputies()
        setProfessionalDeputies(rs.data)
      } catch (err) {
        onError(err)
      }
    }
    getProfessionalDeputies()
  }, [])

  let currentZipSize = 0
  let filteredActiveFiles = activeFiles
  const deleteUserData = async (resolve, reject) => {
    if (!isDeputyOnly) {
      // download all data as a zip file
      const fileRes = await api.getLockedFiles(user.username)
      if (fileRes.data?.fileKeys?.length) {
        const pendingUnlockFileIds = fileRes.data.fileKeys
          .filter(fk => fk.isSecretFile)
          .map(puf => puf.fileId)

        filteredActiveFiles = filteredActiveFiles
          .filter(af => !pendingUnlockFileIds.includes(af.fileId))
          .sort((x, y) => {
            return getFileSize(x) - getFileSize(y)
          })
      }

      const privateFolder = activeFolders.find(folder => folder.isPrivate)
      const privateFiles = filteredActiveFiles.filter(
        file =>
          file.path.slice(0, privateFolder?.path.length) === privateFolder?.path
      )

      for (let i = 0; i < filteredActiveFiles.length; i++) {
        const file = filteredActiveFiles[i]
        currentZipSize += getFileSize(file)
      }

      if (privateFiles.length && privateFolder?.password) {
        setPasswordDownloadModalVisible(true)
      } else {
        await downloadAllDataAsZip(
          user.username,
          activeAssetsLiabilities,
          activeContacts,
          masterKey,
          fullName,
          currentZipSize,
          filteredActiveFiles
        )

        //remove member from group when deleting a member's account
        if (mainUserId) {
          if (deputies?.length) {
            const allDeputies = await getMemberDeputies(
              deputies,
              professionalDeputies,
              appointedProfessionalDeputyId
            )

            if (allDeputies?.length) {
              await Promise.all(
                allDeputies.map(deputy =>
                  dispatch(
                    revokeDeputy(user.username, deputy, fullName, masterKey)
                  )
                )
              )
            }
          }

          await api.removeMember(
            mainUserId,
            JSON.stringify({
              email,
              memberId: user.username,
              membersCode: membersCode || memberCode
            })
          )
        }

        // delete Group when deleting main user's account
        if (members?.length) {
          // using for-of instead of Promise.all to prevent conflict when updating appliedUsers on PromotionCodesDB in Backend
          for (const member of members) {
            //revoke deputies
            let memberUserDeputies = []
            let pendingJoinGroup = false

            const res = await api.getUsersByEmails(member.email)
            if (res.data?.length) {
              memberUserDeputies = res.data[0].deputies
              pendingJoinGroup = res.data[0].pendingJoinGroup
            }

            if (memberUserDeputies?.length && !pendingJoinGroup) {
              const allDeputies = await getMemberDeputies(
                memberUserDeputies,
                professionalDeputies,
                member.professionalDeputyId
              )

              if (allDeputies?.length) {
                await Promise.all(
                  allDeputies.map(deputy =>
                    dispatch(
                      revokeDeputy(
                        member.userId,
                        deputy,
                        fullName,
                        member.professionalDeputyId ? null : masterKey
                      )
                    )
                  )
                )
              }
            }

            //remove member
            await api.removeMember(
              user.username,
              JSON.stringify({
                email: member.email,
                memberId: member.userId,
                isDeleteMainUser: true,
                membersCode
              })
            )
          }
        }

        // delete user's account and all related data (S3 folder, DB records, Stripe customer, access attempts on QLDB, etc)
        const deletionTasks = [
          api.deleteAccount(user.username),
          api.deleteAccessAttempts(user.username)
        ]
        if (customer.id) deletionTasks.push(api.deleteCustomer(customer.id))

        await Promise.all(deletionTasks)

        // delete user account
        if (externalUser) {
          await api.deleteExternalUser(user.username)
          user.signOut()
          destroyAllDbs()
          localStorage.clear()
          window.location.href = `/goodbye?lang=${i18n.language}`
        } else {
          user.deleteUser(function (err, res) {
            if (err) {
              reject(err)
            } else {
              resolve(t('SUCCESSFULLY_DELETED'))
              user.signOut()

              // delete all Pouch Dbs
              destroyAllDbs()
              localStorage.clear()

              window.location.href = `/goodbye?lang=${i18n.language}`
            }
          })
        }
      }
    } else {
      // delete user's account and all related data (S3 folder, DB records, Stripe customer, access attempts on QLDB, etc)
      const deletionTasks = [
        api.deleteAccount(user.username),
        api.deleteAccessAttempts(user.username)
      ]
      if (customer.id) deletionTasks.push(api.deleteCustomer(customer.id))
      await Promise.all(deletionTasks)

      // delete user account
      if (externalUser) {
        await api.deleteExternalUser(user.username)
        user.signOut()
        destroyAllDbs()
        localStorage.clear()
        window.location.href = `/goodbye?lang=${i18n.language}`
      } else {
        user.deleteUser(function (err, res) {
          if (err) {
            reject(err)
          } else {
            resolve(t('SUCCESSFULLY_DELETED'))
            user.signOut()

            // delete all Pouch Dbs
            destroyAllDbs()
            localStorage.clear()

            window.location.href = `/goodbye?lang=${i18n.language}`
          }
        })
      }
    }
  }

  const authenticateUser = (resolve, reject) => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )

    if (currentFlow === AUTH_FLOW.CUSTOM_FLOW) {
      setIsResendEmailCode(true)
      user.setAuthenticationFlowType('CUSTOM_AUTH')
    }

    user.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        try {
          if (isDeleteUser) {
            await deleteUserData(resolve, reject)
          } else {
            await resetRegistry()
            addS3Change({
              variables: {
                message:
                  'assetsLiabilities, pendingAssetsLiabilities, assetsLiabilitiesHistory, assetsLiabilitiesValuations, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
                userId: user.username
              }
            })
            resolve(t('SUCCESSFULLY_RESET_REGISTRY'))
            setMfaModalVisible(false)
            resetFields()
          }
        } catch (err) {
          reject(
            new Error(
              isDeleteUser
                ? t('FAILED_TO_DELETE_USER')
                : t('FAILED_TO_RESET_REGISTRY')
            )
          )
          onError(err)
        } finally {
          setIsSubmitting(false)
        }
      },

      onFailure: function (err) {
        setErrMsg(t('FAILED_TO_AUTHENTICATE_USER'))
        setIsSubmitting(false)
        reject(err)
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      selectMFAType: function () {
        user.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },

      mfaRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.SMS)
        setMfaModalVisible(true)
      },

      // Require if user have setup MFA successfully
      totpRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.TOTP)
        setMfaModalVisible(true)
      }
    })
  }

  const handleConfirmPassword = () => {
    setErrMsg('')

    return new Promise((resolve, reject) => {
      passwordFormRef.props.form.validateFields((err, values) => {
        if (err) {
          // TODO: this will close the modal immediately and can't see the validation error
          resolve(t('INVALID_FORM'))
          return
        }

        cognitoUser = user
        authDetails = { username: user.username, password: values.password }

        authenticateUser(resolve, reject)
      })
    })
  }

  const handleDownloadVaultbox = () => {
    setDownloadVaultboxModalVisible(true)
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    passwordFormRef && passwordFormRef.props.form.resetFields()
    passwordModalVisible && setPasswordModalVisible(false)
    mfaModalVisible && setMfaModalVisible(false)
  }

  const Activities = () => (
    <>
      <H4 style={{ marginBottom: 20 }}>{t('ACTIVITIES')}</H4>
      <Row gutter={10} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <DatePicker
            onChange={setStartDate}
            placeholder={t('FROM')}
            value={startDate}
            disabledDate={startDate => disabledDate(startDate, endDate)}
            format={DATE_FORMAT}
          />
        </Col>
        <Col span={12}>
          <DatePicker
            onChange={setEndDate}
            placeholder={t('TO')}
            value={endDate}
            disabledDate={endDate => disabledDate(startDate, endDate)}
            format={DATE_FORMAT}
          />
        </Col>
      </Row>
      <Checkbox
        checked={isDeputyAccessOnly}
        onChange={e => setIsDeputyAccessOnly(e.target.checked)}
        style={{ marginBottom: 20 }}
      >
        {t('DEPUTY_ACCESSES_ONLY')}
      </Checkbox>
      <Spin spinning={isLoading}>
        {accessAttempts
          .filter(
            a => !isDeputyAccessOnly || a.AccessedByUserId !== user.username
          )
          .map(a => (
            <div
              key={a.Id}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20
              }}
            >
              <div
                style={{
                  height: 36,
                  width: 36,
                  minWidth: 36,
                  backgroundColor: theme.dark3,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 14
                }}
              >
                {a.AccessedByUserId === user.username ? (
                  <Icon
                    type="login"
                    style={{
                      fontSize: 16,
                      color: theme.dark2
                    }}
                  />
                ) : (
                  <CustomIcon
                    type="unlock"
                    style={{
                      fontSize: 16,
                      color: theme.dark2
                    }}
                  />
                )}
              </div>
              <div>
                <P>
                  {a.AccessedByUserId === user.username ? (
                    t('LOGIN')
                  ) : a.IsPrivateFolder === 'true' ? (
                    <Trans
                      i18nKey="SUCCESSFULLY_ACCESSED_YOUR_PRIVATE_FOLDER"
                      values={{
                        name: a.Name,
                        email: a.Email
                      }}
                    ></Trans>
                  ) : a.IsPrivateFolder === 'false' ? (
                    <Trans
                      i18nKey="ATTEMPT_TO_OPEN_THE_LOCKED_PRIVATE_FOLDER"
                      values={{
                        name: a.Name,
                        email: a.Email
                      }}
                    ></Trans>
                  ) : (
                    <Trans
                      i18nKey="ACCESSED_YOUR_VAULTBOX"
                      values={{
                        name: a.Name,
                        email: a.Email
                      }}
                    ></Trans>
                  )}
                </P>
                <StatusText color={theme.dark2}>
                  {renderDateTime(a.Time)}
                </StatusText>
              </div>
            </div>
          ))}
      </Spin>
    </>
  )

  const unlinkAssetsLiabilitiesFromAllItems = async dbName => {
    const db = new PouchDB(`${user.username}_${dbName}`)
    db.crypto(masterKey)
    const allDocs = await db.allDocs({ include_docs: true })
    const docsToUpdate = allDocs.rows?.filter(
      row => row.doc.assetsLiabilities?.length
    )
    if (docsToUpdate?.length) {
      const updatedDocs = docsToUpdate.map(row => {
        const { doc } = row
        return { ...doc, assetsLiabilities: [] }
      })

      await db.bulkDocs(updatedDocs)
      await uploadEncryptedData(db, user.username, dbName)
    } else {
      db.removeCrypto()
    }
  }

  const resetRegistry = async () => {
    try {
      await Promise.all(
        [
          'assetsLiabilities',
          'assetsLiabilitiesHistory',
          'assetsLiabilitiesValuations',
          'pendingAssetsLiabilities'
        ].map(
          async dbName => await removeAllDocs(user.username, masterKey, dbName)
        )
      )
      await Promise.all(
        [
          'contacts',
          'documents',
          'events',
          'pendingEvents',
          'passwords',
          'pendingPasswords',
          'pendingContacts',
          'pendingDocuments'
        ].map(async dbName => {
          await unlinkAssetsLiabilitiesFromAllItems(dbName)
        })
      )

      dispatch(fetchPendingAssetsLiabilities(user.username, masterKey))
      message.success(t('SUCCESSFULLY_RESET_REGISTRY'))
    } catch (err) {
      throw err
    }
  }

  const SettingActions = () => (
    <div className="setting-actions">
      <Button
        type="link"
        icon="download"
        style={{ margin: '8px 0' }}
        loading={isDownloading}
        onClick={handleDownloadVaultbox}
      >
        {t('DOWNLOAD_VAULTBOX')}
      </Button>

      <Button
        type="link"
        icon="reload"
        style={{ margin: '8px 0' }}
        onClick={e => {
          setIsDeleteUser(false)
          if (externalUser) {
            Modal.confirm({
              width: 500,
              title: t('RESET_REGISTRY'),
              content: t('ARE_YOU_SURE_YOU_WANT_TO_RESET_YOUR_REGISTRY'),
              onOk: async () => {
                await resetRegistry()
                addS3Change({
                  variables: {
                    message:
                      'assetsLiabilities, pendingAssetsLiabilities, assetsLiabilitiesHistory, assetsLiabilitiesValuations, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
                    userId: user.username
                  }
                })
              },
              onCancel() {}
            })
          } else {
            setPasswordModalVisible(true)
          }
        }}
      >
        {t('RESET_REGISTRY')}
      </Button>
      <div
        style={{
          marginLeft: 16,
          marginTop: 12,
          cursor: 'pointer'
        }}
      >
        <Span
          onClick={e => {
            setIsDeleteUser(true)
            if (externalUser) {
              new Promise((resolve, reject) => {
                Modal.confirm({
                  width: 500,
                  title: t('CLOSE_ACCOUNT'),
                  content: t('CONFIRM_CLOSE_ACCOUNT_MSG'),
                  onOk: async () => {
                    deleteUserData(resolve, reject)
                  },
                  onCancel() {}
                })
              })
            } else {
              setPasswordModalVisible(true)
            }
          }}
          color={theme.red}
        >
          <CustomIcon
            type="trash"
            style={{ fontSize: 16, color: theme.red, marginRight: 8 }}
          />
          {t('DELETE_ACCOUNT')}
        </Span>
      </div>
    </div>
  )

  return (
    <>
      <Layout style={{ height: '100%' }}>
        <Content style={{ backgroundColor: '#fff', padding: '0 20px 20px' }}>
          <div>
            <SimpleHeader
              title={<H4>{t('SETTINGS')}</H4>}
              extra={
                !isMdUp && (
                  <Dropdown
                    trigger={['click', 'hover']}
                    overlay={
                      <Menu>
                        {!isDeputyOnly && (
                          <Menu.Item>
                            <Span
                              style={{ color: theme.primary }}
                              onClick={handleDownloadVaultbox}
                            >
                              <Icon
                                type="download"
                                style={{ marginRight: 8 }}
                              />
                              {t('DOWNLOAD_VAULTBOX')}
                            </Span>
                          </Menu.Item>
                        )}
                        {!isDeputyOnly && (
                          <Menu.Item>
                            <Span
                              style={{ color: theme.primary }}
                              onClick={() => {
                                setIsDeleteUser(false)
                                setPasswordModalVisible(true)
                              }}
                            >
                              <Icon type="reload" style={{ marginRight: 8 }} />
                              {t('RESET_REGISTRY')}
                            </Span>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <Span
                            style={{ color: theme.red }}
                            onClick={e => {
                              setIsDeleteUser(true)
                              setPasswordModalVisible(true)
                            }}
                          >
                            <CustomIcon
                              type="trash"
                              style={{ marginRight: 8 }}
                            />
                            {t('DELETE_ACCOUNT')}
                          </Span>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Icon type="more" />
                  </Dropdown>
                )
              }
            />
            <div className="setting-content">
              <div className="setting-menu">
                {menu(
                  !!currentPlanId && !mainUserId
                    ? settingMenuItems
                    : settingMenuItems.filter(
                        item => item.url !== '/settings/gifts'
                      ),
                  location,
                  isMdUp ? 'vertical' : 'horizontal'
                )}

                {isMdUp && <SettingActions />}
              </div>

              <div className="setting-outlet">
                {children}

                {!isLgUp && location.pathname === '/settings' && (
                  <>
                    <Divider />
                    <Activities />
                  </>
                )}
              </div>
            </div>
          </div>
        </Content>
        {location.pathname === '/settings' && isLgUp && (
          <Sider
            width={320}
            theme="light"
            style={{
              background: 'rgba(242, 243, 247, 0.5)',
              padding: '20px 20px 80px'
            }}
          >
            <Activities />
          </Sider>
        )}
      </Layout>

      {/* mfa authentication modal */}
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={cognitoUser}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
        />
      </Modal>

      {/* Password modal */}
      <Modal
        visible={passwordModalVisible}
        title={isDeleteUser ? t('CLOSE_ACCOUNT') : t('RESET_REGISTRY')}
        okText={
          isDeleteUser
            ? isDeputyOnly
              ? t('DELETE_ACCOUNT')
              : t('DOWNLOAD_DATA_AND_DELETE_ACCOUNT')
            : t('RESET_REGISTRY')
        }
        okButtonProps={{
          style: {
            backgroundColor: theme.red,
            color: theme.white,
            borderColor: theme.red
          }
        }}
        onOk={async e => {
          try {
            await handleConfirmPassword()
          } catch (e) {
            onError(e)
            setErrMsg(errorMessage(e))
          }
        }}
        onCancel={resetFields}
      >
        <WrappedForm wrappedComponentRef={fr => (passwordFormRef = fr)}>
          {getFieldDecorator => (
            <>
              <P style={{ marginBottom: '1em' }}>
                {isDeleteUser
                  ? t('CONFIRM_CLOSE_ACCOUNT_MSG')
                  : t('ARE_YOU_SURE_YOU_WANT_TO_RESET_YOUR_REGISTRY')}
              </P>
              <P>{t('ENTER_YOUR_PASSWORD_TO_PROCEED')}: </P>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Password is required!'
                    }
                  ]
                })(<Input.Password placeholder={t('ENTER_PASSWORD')} />)}
              </FormItem>
            </>
          )}
        </WrappedForm>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
      </Modal>
      <DownloadVaultboxModal
        setIsDownloading={setIsDownloading}
        visible={downloadVaultboxModalVisible}
        setVisible={setDownloadVaultboxModalVisible}
      />
      <PasswordDownloadModal
        visible={passwordDownloadModalVisible}
        setVisible={setPasswordDownloadModalVisible}
        userId={user.username}
        activeAssetsLiabilities={activeAssetsLiabilities}
        activeContacts={activeContacts}
        activeFolders={activeFolders}
        masterKey={masterKey}
        fullName={fullName}
        filteredActiveFiles={filteredActiveFiles}
        currentZipSize={currentZipSize}
        isDeleteUser={isDeleteUser}
        user={user}
        customer={customer}
        membersCode={membersCode}
        mainUserId={mainUserId}
        members={members}
        email={email}
        professionalDeputies={professionalDeputies}
        deputies={deputies}
        appointedProfessionalDeputyId={appointedProfessionalDeputyId}
      />
    </>
  )
}

export default withRouter(Settings)

import React, { useState, useContext } from 'react'
import { Form, Icon, Modal, Alert, message } from 'antd'
import { H4, Span, StatusText } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import api from '../../lib/api'
import FormItem from '../override/FormItem'
import AddMultipleFields from '../common/AddMultipleFields'
// import config from '../../config'
import {
  compareWithCurrentUserEmail
  // compareWithProfessionalDeputyEmails
} from '../../share/formHelpers'
//import { StringResources } from '../../share/StringResources'
import { onError } from '../../lib/sentry'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function ReferralInfo({ userInfo, form }) {
  const theme = useContext(ThemeContext)
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [inviteErr, setInviteErr] = useState('')
  const [isSending, setIsSending] = useState(false)
  const { t } = useTranslation()

  const refereeEmails =
    useSelector(state => state.user).user?.refereeEmails || []

  const { getFieldDecorator } = form

  const sendInvitationEmails = e => {
    setInviteErr('')
    form.validateFields((err, values) => {
      if (err) return
      if (!values.emails?.length) {
        setInviteErr(t('PLEASE_ENTER_AT_LEAST_ONE_EMAIL_ADDRESS'))
        return
      }

      setIsSending(true)
      const invitedEmails = values.emails?.map(e => e.email)
      api
        .sendInvitationEmails(
          JSON.stringify({
            invitedEmails,
            email: userInfo.email,
            referralCode: userInfo.referralCode,
            fullname: userInfo.fullname
          })
        )
        .then(res => {
          setInviteModalVisible(false)
          form.resetFields()
          message.success(t('SUCCESSFULLY_SENT_INVITATION'))
        })
        .catch(err => {
          setInviteErr(t('FAILED_TO_SENT_INVITATION'))
          onError(err)
        })
        .finally(() => setIsSending(false))
    })
  }

  const compareWithRefereeEmails = (rule, value, callback, refereeEmails) => {
    if (value && refereeEmails.find(d => d === value)) {
      callback(t('THIS_EMAIL_HAS_APPLIED_YOUR_REFERRAL_CODE'))
    } else {
      callback()
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H4>
          {t('REFERRAL_CODE')}:{' '}
          <Span color={theme.primary}>{userInfo.referralCode}</Span>
        </H4>
        <Span
          color={theme.primary}
          style={{ cursor: 'pointer' }}
          onClick={() => setInviteModalVisible(true)}
        >
          <Icon type="share-alt" style={{ marginRight: 8 }} />
          {t('REFER')}
        </Span>
      </div>
      <StatusText color={theme.dark2}>{t('REFERRAL_CODE_SUMMARY')}</StatusText>
      <Modal
        title={t('SEND_REFERRAL_LINK')}
        visible={inviteModalVisible}
        okText={t('SEND')}
        okButtonProps={{
          loading: isSending
        }}
        onOk={sendInvitationEmails}
        onCancel={() => {
          setInviteErr('')
          setInviteModalVisible(false)
          form.resetFields()
        }}
        width={700}
      >
        <>
          <Form>
            <FormItem label={t('EMAIL_ADDRESSES')}>
              <AddMultipleFields
                name="emails"
                title={t('EMAIL').toLowerCase()}
                fields={[{ key: 'email', placeholder: 'email@example.com' }]}
                getFieldDecorator={getFieldDecorator}
                customRules={[
                  {
                    fieldKey: 'email',
                    rules: [
                      {
                        type: 'email',
                        message: t('INVALID_EMAIL_MSG')
                      },
                      // {
                      //   validator: (rule, value, callback) =>
                      //     compareWithProfessionalDeputyEmails(
                      //       rule,
                      //       value,
                      //       callback,
                      //       config.professionalDeputyEmails || []
                      //     )
                      // },
                      {
                        validator: (rule, value, callback) =>
                          compareWithCurrentUserEmail(
                            rule,
                            value,
                            callback,
                            userInfo.email
                          )
                      },
                      {
                        validator: (rule, value, callback) =>
                          compareWithRefereeEmails(
                            rule,
                            value,
                            callback,
                            refereeEmails
                          )
                      }
                    ]
                  }
                ]}
              />
            </FormItem>
          </Form>
          {inviteErr && (
            <Alert
              message={inviteErr}
              type="error"
              closable
              afterClose={() => setInviteErr('')}
            />
          )}
        </>
      </Modal>
    </>
  )
}

const WrappedReferralInfoForm = Form.create({
  name: 'ReferralInfo'
})(ReferralInfo)

export default WrappedReferralInfoForm

import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Elements, StripeProvider } from 'react-stripe-elements'
import api from '../../lib/api'
import { getUserData, getUserAttributeValue } from '../../lib/cognito'
import { Modal, Spin, message, notification } from 'antd'
import Button from '../override/Button'
import AuthContext from '../../contexts/AuthContext'
import CardModal from './CardModal'
import config from '../../config'
import {
  fetchCustomer,
  updateCancelSchedule
} from '../../features/payment/customerSlice'
import { fetchUser } from '../../features/user/userSlice'
import { getExternalUserAttributes, getPlanNickname } from '../../share/helpers'
import DownloadVaultboxModal from '../modals/DownloadVaultboxModal'
import SubscriptionPlans from '../payment/SubscriptionPlans'
import moment from 'moment'
import usePlans from '../../hooks/usePlans'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'

export default function SubcriptionModal({
  visible,
  setVisible,
  closable,
  removeMember,
  setGetInvoices
}) {
  const { user, isDeputyOnly } = useContext(AuthContext)
  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState(false)
  const [isApplyReferralCode, setIsApplyReferralCode] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [cardModalVisible, setCardModalVisible] = useState(false)
  const [downloadVaultboxModalVisible, setDownloadVaultboxModalVisible] =
    useState(false)
  const [subscribeCustomer, setSubscribeCustomer] = useState({})
  const [selectedPlan, setSelectedPlan] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [promoCode, setPromoCode] = useState('')
  const [userInfo, setUserInfo] = useState({
    fullname: '',
    email: '',
    discountCode: ''
  })
  const { customer, subscription, vaultboxSubscription, schedule } =
    useSelector(state => state.customer)
  const {
    appliedReferralCode,
    hasAppliedReferralDiscount,
    mainUserId,
    members,
    memberCode
  } = useSelector(state => state.user).user
  const { usedStorage } = useSelector(state => state.documents)
  const { deputies } = useSelector(state => state.deputies)
  const dispatch = useDispatch()
  const plans = usePlans(userInfo.discountCode, visible)
  const {
    defaultPlans,
    defaultPdPlans,
    customPlans,
    customPdPlans,
    isLoading
  } = plans
  const vaultboxPlans = customPlans?.length ? customPlans : defaultPlans
  const deputyPlans = customPdPlans?.length ? customPdPlans : defaultPdPlans
  const externalUser = localStorage.getItem('External_User')

  useEffect(() => {
    if (visible) {
      setIsSaving(false)
      dispatch(fetchCustomer(user.username))

      if (externalUser) {
        const userAttributes = getExternalUserAttributes()
        const fullname = userAttributes.full_name
        const email = userAttributes.email
        const discountCode = userAttributes.discount_code
        setUserInfo({ fullname, email, discountCode })
      } else {
        getUserData(user, async (err, data) => {
          if (err) {
            onError(err)
            return
          }
          const fullname = getUserAttributeValue(
            data.UserAttributes,
            'custom:full_name'
          )
          const email = getUserAttributeValue(data.UserAttributes, 'email')
          const discountCode = getUserAttributeValue(
            data.UserAttributes,
            'custom:discount_code'
          )

          setUserInfo({ fullname, email, discountCode })
        })
      }
    }
  }, [dispatch, user, externalUser, visible])

  const handleApplyReferralCode = async (email, isApply = false) => {
    if (isApply) {
      setIsApplyReferralCode(true)
    }

    if (referralCode) {
      const res = await api.getReferrer(referralCode)

      if (res.data.id) {
        const refereeEmails = res.data.refereeEmails || []
        if (refereeEmails.find(de => de === email)) {
          setIsApplyReferralCode(false)
          Modal.warn({
            title: t('INVALID_REFERRAL_CODE'),
            content: t('CODE_USED'),
            onOk() {
              setIsSaving(false)
            }
          })
          return
        } else {
          await api.saveAppliedReferralCode(
            user.username,
            JSON.stringify({ appliedReferralCode: referralCode })
          )
          dispatch(fetchUser(user.username))
          setIsApplyReferralCode(false)
        }
      } else {
        setIsApplyReferralCode(false)
        Modal.warn({
          title: t('INVALID_REFERRAL_CODE'),
          content: t('TRY_ANOTHER_ONE'),
          onOk() {
            setIsSaving(false)
          }
        })
        return
      }
    }
  }

  const onSubcribe = async (plan, email) => {
    const allowedStorageInGB = vaultboxPlans.find(p => p.id === plan.id)
      .metadata?.storage
    const allowedStorage = allowedStorageInGB
      ? allowedStorageInGB * 1024 * 1024 * 1024
      : null

    if (!allowedStorage || usedStorage > allowedStorage) {
      Modal.warn({
        title: t('INVALID_SUBSCRIPTION'),
        content: t('INVALID_SUBSCRIPTION_SUMMARY')
      })
      return
    }

    setIsSaving(true)

    try {
      let subscribeCustomer
      await handleApplyReferralCode(email)
      setIsSaving(true)
      if (!customer?.id) {
        const res = await api.createCustomer(
          user.username,
          JSON.stringify({ email })
        )
        if (res.data?.message) throw Error(res.data.message)

        subscribeCustomer = res.data
      } else {
        subscribeCustomer = customer
      }

      setSubscribeCustomer(subscribeCustomer)
      setSelectedPlan(plan)

      if (!subscribeCustomer.default_source) {
        setCardModalVisible(true)
      } else if (
        !vaultboxSubscription?.plan?.metadata?.members &&
        plan.metadata?.members
      ) {
        setIsSaving(true)
        Modal.confirm({
          title: t('CHANGE_SUBSCRIPTION'),
          content: t('CHANGE_VAULTBOX_PRO_TO_VAULTBOX_GROUP_MSG'),
          okText: t('CHANGE_SUBSCRIPTION'),
          onOk: async () => {
            try {
              await api.handleChangeProToGroup(
                user.username,
                JSON.stringify({
                  subscription,
                  plan,
                  deputyPlans,
                  vaultboxPlans
                })
              )
              setGetInvoices(true)
              setIsSaving(false)
              setVisible(false)
              dispatch(fetchCustomer(user.username))
              dispatch(fetchUser(user.username))
            } catch (error) {
              setIsSaving(false)
              setVisible(false)
              onError(error)
            }
          },
          onCancel() {
            setIsSaving(false)
          }
        })
      } else {
        await handleSaveSubscription(
          plan,
          subscribeCustomer.id,
          subscribeCustomer.subscriptions?.data[0]?.status === 'active'
        )
      }
    } catch (err) {
      onError(err)
      setIsSaving(false)
      message.error(t('FAILED_TO_SUBSCRIBE'))
    }
  }

  const handleSubscribe = async plan => {
    if (externalUser) {
      const userAttributes = getExternalUserAttributes()
      const email = userAttributes.email
      onSubcribe(plan, email)
    } else {
      getUserData(user, async (err, data) => {
        if (err) {
          onError(err)
          return
        }
        const email = getUserAttributeValue(data.UserAttributes, 'email')
        if (mainUserId) {
          showConfirmLeftGroup(plan, email)
        } else {
          if (members?.length && !plan.metadata.members) {
            Modal.info({
              content: t('MAIN_USER_CHANGE_SUBSCRIPTION_MSG')
            })
          } else {
            onSubcribe(plan, email)
          }
        }
      })
    }
  }

  const handleSaveSubscription = async (
    plan,
    customerId,
    isActive = false
  ) => {
    let activePromoCode, removedPromoCode
    if (subscription.discount?.coupon?.id) {
      try {
        activePromoCode = subscription.discount.coupon.id
        const promotionCodeInfo = await api.getPromotionCode(
          activePromoCode,
          userInfo.email,
          true,
          true
        )

        // remove active promo code if it can't be applied to the new selected plan
        removedPromoCode =
          !!promotionCodeInfo.data?.appliesToPlan &&
          promotionCodeInfo.data.appliesToPlan !== plan.id &&
          activePromoCode
      } catch (err) {
        onError(err)
        message.error(t('FAILED_TO_GET_PROMOTION_CODE_INFO'))
        setIsSaving(false)
        return
      }
    }

    if (
      (removedPromoCode || isActive) &&
      !(
        !subscription?.items?.data[0]?.plan?.metadata?.members &&
        plan.metadata?.members
      )
    ) {
      Modal.confirm({
        title: t('CHANGE_SUBSCRIPTION'),
        content: (
          <>
            {isActive && (
              <p>
                <Trans
                  i18nKey="CHANGE_SUBSCRIPTION_PLAN_FIRST_SUMMARY"
                  values={{
                    current_period_end: moment(
                      subscription.current_period_end * 1000
                    ).format('LL')
                  }}
                ></Trans>
              </p>
            )}
            {removedPromoCode && (
              <p>
                <Trans
                  i18nKey="CHANGE_SUBSCRIPTION_PLAN_SECOND_SUMMARY"
                  values={{
                    removedPromoCode,
                    nickname: getPlanNickname(
                      vaultboxSubscription?.plan?.nickname,
                      vaultboxSubscription?.plan?.metadata.membersCode
                    )
                  }}
                ></Trans>
              </p>
            )}
            <div>{t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE')}</div>
          </>
        ),
        onCancel() {
          setIsSaving(false)
        },
        async onOk() {
          await saveSubscription(
            plan,
            customerId,
            removedPromoCode
          )
        }
      })
    } else {
      await saveSubscription(
        plan,
        customerId,
        removedPromoCode
      )
    }
  }

  const showConfirmLeftGroup = (plan, email) => {
    Modal.confirm({
      title: t('Member changes subscription'),
      content: t(
        'You are member in a group. If you change subscription you will be removed from group. Are you sure to change subscription?'
      ),
      onOk: async () => {
        await removeMember(email, user.username, mainUserId)
        await onSubcribe(plan, email)
      }
    })
  }

  const saveSubscription = async (plan, customerId, removedPromoCode) => {
    try {
      let res

      if (!vaultboxSubscription?.plan?.id) {
        // if (withoutPaymentInfo) {
        //   const current = Math.floor(new Date().getTime() / 1000)

        //   if (trialEnd && trialEnd < current) {
        //     message.error(t('TRIAL_EXPIRES'))
        //     setIsSaving(false)
        //     return
        //   }
        // }

        let items = [{ plan: plan.id }]
        const deputyRes = await api.getDeputies(user.username)
        if (deputyRes.data.message)
          throw Error(
            t('FAILED_TO_GET_DEPUTIES_LIST_TO_CHECK_PROFESSTIONAL_DEPUTY_PLAN')
          )
        const { deputies } = deputyRes.data

        if (
          deputies?.filter(d => d.publicKey && d.professionalDeputyId).length >
          1
        ) {
          const matchingPlan = vaultboxPlans.find(p => p.id === plan.id)
          items.push({
            plan: deputyPlans.find(
              dp =>
                dp.interval === matchingPlan?.interval &&
                dp.interval_count === matchingPlan?.interval_count
            )?.id
          })
        }

        if (promoCode) {
          await api
            .getPromotionCode(promoCode, userInfo.email)
            .then(async res => {
              if (!res?.data?.valid) {
                throw Error(t('INVALID_PROMOTION_CODE'))
              }
              if (
                res?.data?.appliesToPlan &&
                res.data.appliesToPlan !== plan.id
              ) {
                throw Error(t('PROMOTION_CODE_MSG'))
              }

              const createData = {
                userId: user.username,
                customerId,
                items,
                coupon: res?.data?.id || undefined
              }

              const response = await api.createSubscription(
                JSON.stringify(createData)
              )
              if (response?.data.message) throw Error(response.data.message)
            })
            .catch(err => onError(err))
        } else {
          const createData = {
            userId: user.username,
            customerId,
            items
          }
          res = await api.createSubscription(JSON.stringify(createData))
        }
      } else if (subscription?.id) {
        const subscriptionItems = subscription.items.data

        const mappedSubItem = subscriptionItems.find(item =>
          vaultboxPlans.map(p => p.id).includes(item.plan.id)
        )

        const mappedDeputySubItem = subscriptionItems.find(item =>
          deputyPlans.map(p => p.id).includes(item.plan.id)
        )

        let updateItems = [
          {
            id: mappedSubItem.id,
            plan: plan.id // vaultbox plan
          }
        ]
        if (mappedDeputySubItem) {
          const matchingPlan = vaultboxPlans.find(p => p.id === plan.id)
          updateItems.push({
            id: mappedDeputySubItem.id,
            plan: deputyPlans.find(
              dp =>
                dp.interval === matchingPlan?.interval &&
                dp.interval_count === matchingPlan?.interval_count
            )?.id // Professional Deputy plan
          })
        }

        if (subscription.status === 'trialing') {
          const updateData = {
            items: updateItems,
            cancel_at_period_end: false,
            proration_behavior: 'none',
            coupon: removedPromoCode
              ? null // have to set it to null to remove the coupon
              : undefined // set to undefined will make no change (i.e. if the coupon was added, it would still be there)
          }

          res = await api.updateSubscription(
            subscription.id,
            JSON.stringify(updateData)
          )
        } else {
          const currentPlans = await subscriptionItems.map(item => ({
            plan: item.plan.id,
            quantity: item.quantity || 1
          }))

          res = await api.updateSubscriptionSchedule(
            subscription.id,
            JSON.stringify({
              // the case that user has Group Plan in the next phase, user have to leave group before updating schedule.
              // schedule will be released after user left the group, so we cannot update this schedule.
              // we have to create new schedule by set scheduleId as "null".
              scheduleId: mainUserId ? null : subscription.schedule,
              isRelease: false,
              phases: [
                {
                  plans: currentPlans,
                  start_date: subscription?.current_period_start,
                  end_date: subscription?.current_period_end,
                  proration_behavior: 'none',
                  default_tax_rates: [config.stripe.DEFAULT_TAX_RATE],
                  coupon: removedPromoCode
                    ? undefined
                    : subscription?.discount?.coupon?.id
                },
                {
                  plans: updateItems.map((item, index) => ({
                    plan: item.plan,
                    quantity: currentPlans[index].quantity || 1
                  })),
                  start_date: subscription?.current_period_end,
                  proration_behavior: 'none',
                  default_tax_rates: [config.stripe.DEFAULT_TAX_RATE],
                  coupon: removedPromoCode
                    ? undefined
                    : subscription?.discount?.coupon?.id
                }
              ]
            })
          )
        }
      }
      if (res?.data.message) throw Error(res.data.message)

      if (removedPromoCode) {
        const updateData = {
          code: removedPromoCode,
          end: moment().unix()
        }

        await api.updateAppliedPromotionCodes(
          user.username,
          JSON.stringify(updateData)
        )
      }

      setIsSaving(false)
      setVisible(false)
      dispatch(fetchCustomer(user.username))
      dispatch(fetchUser(user.username))
      if (isDeputyOnly) {
        // reload to update the whole app state, as the account has switched from deputy only to normal
        window.location.reload()
      }
    } catch (err) {
      onError(err)
      message.error(t('FAILED_TO_SAVE_SUBSCRIPTION'))
    }
  }

  const handleDownloadVaultbox = () => {
    setDownloadVaultboxModalVisible(true)
  }

  const handleCancelSubscription = async () => {
    Modal.confirm({
      title: t('CANCEL_SUBSCRIPTION'),
      content: (
        <>
          <p>{t('CANCEL_SUBSCRIPTION_FIRST_SUMMARY')}</p>
          <p>
            <Trans i18nKey="CANCEL_SUBSCRIPTION_SECOND_SUMMARY">
              After your subscription ends, you will no longer have access to
              your Registry and Files, which may be deleted some time after and
              cannot be recovered.{' '}
              <Button
                loading={isDownloading}
                type="link"
                style={{ padding: 0, height: 'auto', lineHeight: '18px' }}
                onClick={handleDownloadVaultbox}
              >
                <b>Remember to download a copy first.</b>
              </Button>
            </Trans>
          </p>
          <p>
            <Trans i18nKey="YOU_CAN_STILL_USE_YOUR_ACCOUNT_AS_DEPUTY"></Trans>
          </p>
          <div>{t('ARE_YOU_SURE_YOU_WISH_TO_CANCEL_SUBSCRIPTION')}</div>
        </>
      ),
      width: 500,
      cancelText: t('CANCEL_SUBSCRIPTION'),
      cancelButtonProps: { style: { color: 'rgb(200, 0, 0)' } },
      okText: t('KEEP_SUBSCRIPTION'),
      onCancel: async () => {
        try {
          setIsSaving(true)
          if (schedule?.id) {
            await api.updateSubscriptionSchedule(
              subscription.id,
              JSON.stringify({
                scheduleId: schedule?.id,
                isRelease: true
              })
            )
          }

          await updateCancelSchedule(
            subscription.id,
            user.username,
            dispatch,
            true
          )

          const professionalDeputyEmails = deputies
            ?.filter(d => d.professionalDeputyId)
            .map(e => e.email)

          if (professionalDeputyEmails?.length) {
            await api.sendUnsubscribeNotification(
              JSON.stringify({
                deputyEmails: professionalDeputyEmails,
                fullname: userInfo.fullname,
                email: userInfo.email,
                cancelDate: moment(
                  subscription.current_period_end * 1000
                ).format('LL')
              })
            )
          }

          setVisible(false)

          notification.info({
            message: t('SUBSCRIPTION_CANCELLED'),
            description: (
              <Trans
                i18nKey="SUBSCRIPTION_CANCELLED_SUMMARY"
                values={{
                  nickname: getPlanNickname(
                    vaultboxSubscription?.plan?.nickname,
                    vaultboxSubscription?.plan?.metadata.membersCode
                  ),
                  current_period_end: moment(
                    subscription.current_period_end * 1000
                  ).format('LL')
                }}
              ></Trans>
            ),
            duration: 5
          })
        } catch (err) {
          setIsSaving(false)
          message.error(t('FAILED_TO_CANCEL_SUBSCRIPTION'))
          onError(err)
        }
      }
    })
  }

  return (
    <>
      <Modal
        visible={visible}
        title={t('SUBSCRIPTION_PLANS')}
        maskClosable={false}
        closable={closable}
        keyboard={closable}
        footer={null}
        style={{ maxWidth: 1080 }}
        width={'90vw'}
        onCancel={() => setVisible(false)}
        okText={t('OK')}
        cancelText={t('CANCEL')}
      >
        <Spin spinning={isLoading || isSaving}>
          <SubscriptionPlans
            user={user}
            isModal
            showPromoCodeInput={!subscription.discount}
            setPromoCode={setPromoCode}
            promoCode={promoCode}
            isApplyReferralCode={isApplyReferralCode}
            setIsApplyReferralCode={setIsApplyReferralCode}
            showReferralCodeInput={
              !appliedReferralCode &&
              !hasAppliedReferralDiscount &&
              !isDeputyOnly
            }
            plans={plans}
            currentPlanId={vaultboxSubscription?.plan?.id}
            handleCancelSubscription={handleCancelSubscription}
            handleApplyReferralCode={handleApplyReferralCode}
            handleSubscribe={handleSubscribe}
            subscriptionStatus={subscription?.status}
            defaultSource={customer?.default_source}
            setReferralCode={setReferralCode}
            referralCode={referralCode}
            isCancelAtEnd={subscription?.cancel_at_period_end}
            handleReactivate={async () => {
              try {
                setIsSaving(true)
                await updateCancelSchedule(
                  subscription.id,
                  user.username,
                  dispatch
                )
                setVisible(false)
                message.success(t('REACTIVATE_SUBSCRIPTION_SUCCESS'))
              } catch (err) {
                message.error(t('REACTIVATE_SUBSCRIPTION_FAIL'))
                onError(err)
              } finally {
                setIsSaving(false)
              }
            }}
            nextPhase={schedule?.phases[1]}
            handleRelease={async () => {
              try {
                setIsSaving(true)

                if (mainUserId) {
                  //case user cancel Group Plan in the next phase
                  await api.removeMember(
                    mainUserId,
                    JSON.stringify({
                      email: userInfo.email,
                      memberId: user.username,
                      membersCode: memberCode
                    })
                  )
                } else {
                  await api.updateSubscriptionSchedule(
                    subscription.id,
                    JSON.stringify({
                      scheduleId: schedule?.id,
                      isRelease: true
                    })
                  )

                  if (subscription.discount?.coupon?.id) {
                    const activePromoCode = subscription.discount.coupon.id
                    const promotionCodeInfo = await api.getPromotionCode(
                      activePromoCode,
                      userInfo.email,
                      true,
                      true
                    )

                    // remove active promo code if it can't be applied to the current plan
                    const shouldRemovePromoCode =
                      !!promotionCodeInfo.data?.appliesToPlan &&
                      promotionCodeInfo.data.appliesToPlan !==
                        vaultboxSubscription?.plan?.id

                    if (shouldRemovePromoCode) {
                      await api.updateSubscription(
                        subscription.id,
                        JSON.stringify({
                          coupon: null
                        })
                      )
                    }
                  }
                }

                dispatch(fetchCustomer(user.username))
                setVisible(false)
                message.success(t('SUCCESSFULLY_CANCELLED_SUBSCRIPTION_CHANGE'))
              } catch (err) {
                message.error(t('FAILED_TO_CANCEL_SUBSCRIPTION_CHANGE'))
                onError(err)
              }
            }}
          />
        </Spin>
      </Modal>
      <StripeProvider apiKey={config.stripe.PUBLISHABLE_KEY}>
        <Elements>
          <CardModal
            visible={cardModalVisible}
            setVisible={setCardModalVisible}
            customerId={subscribeCustomer.id}
            handleOkComplete={() => {
              // fetch customer info, so in case users cancel in the following steps, then try to change subscription plan again,
              // the default_source is updated so the CardModal won't be displayed again.
              dispatch(fetchCustomer(user.username))

              // Assumption: CardModal is only shown when the subscription is not yet active
              // so the isActive arg is always false
              handleSaveSubscription(selectedPlan, subscribeCustomer.id)
            }}
            handleCancelComplete={() =>
              // use for trial period if required in the future
              // handleSaveSubscription(
              //   selectedPlanId,
              // subscribeCustomer.id,
              //   false,
              //   true
              // )
              {
                dispatch(fetchCustomer(user.username))
                setVisible(false)
                setIsSaving(false)
              }
            }
          />
        </Elements>
      </StripeProvider>
      <DownloadVaultboxModal
        setIsDownloading={setIsDownloading}
        visible={downloadVaultboxModalVisible}
        setVisible={setDownloadVaultboxModalVisible}
      />
    </>
  )
}

import React from 'react'
import logo from '../../assets/white@2x.png'
import { GAevent } from '../../lib/ga'
import { Experiment, Variant } from 'react-optimize'
import config from '../../config'
import LanguageSelector from './../pages/LanguageSelector'
import { Trans } from 'react-i18next'
import { Divider } from 'antd'

const UnauthContent = ({ children, header, isRemoveLanguage }) => (
  <>
    <div className="unauth-content">
      <div className="unauth-content-header">
        {!isRemoveLanguage && <LanguageSelector />}
        {header}
      </div>
      {children}
      <div className="unauth-content-footer">
        <div>
          &copy; {new Date().getFullYear()} <b>vaultbox</b>
        </div>
        <div className="unauth-content-footer-links">
          <span>
            <a
              href="https://www.vaultbox.tech"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Website'
                })
              }}
            >
              <Trans i18nKey="WEBSITE"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="https://www.vaultbox.tech/about-us"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked About us'
                })
              }}
            >
              <Trans i18nKey="ABOUT_US"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="http://support.vaultbox.tech/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Help'
                })
              }}
            >
              <Trans i18nKey="HELP"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="https://www.vaultbox.tech/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Privacy'
                })
              }}
            >
              <Trans i18nKey="PRIVACY"></Trans>
            </a>
          </span>
          <span>&bull;</span>
          <span>
            <a
              href="/terms-of-service"
              target="_blank"
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Terms of Service',
                  label: 'Footer link'
                })
              }}
            >
              <Trans i18nKey="TERMS_OF_SERVICE"></Trans>
            </a>
          </span>
        </div>
      </div>
    </div>
    <div className="unauth-bg">
      <div className="unauth-bg-img"></div>
    </div>
    <div className="banner">
      <Trans i18nKey="Free Sign Up"></Trans>
      <Divider type="vertical" />
      <Trans i18nKey="No Credit Card Required"></Trans>
    </div>
    <a className="logo" href="https://www.vaultbox.tech" target="blank">
      <img src={logo} alt="vaultbox logo" />
    </a>
  </>
)
export default function UnauthLayout({ children, ...rest }) {
  const { header, isRemoveLanguage } = rest

  return (
    <Experiment id={config.optimize.LAYOUT_EXP_ID}>
      <Variant id="0">
        <div className="unauth-wrapper unauth-wrapper--row">
          <UnauthContent
            children={children}
            header={header}
            isRemoveLanguage={isRemoveLanguage}
          />
        </div>
      </Variant>
      <Variant id="1">
        <div className="unauth-wrapper">
          <UnauthContent
            children={children}
            header={header}
            isRemoveLanguage={isRemoveLanguage}
          />
        </div>
      </Variant>
    </Experiment>
  )
}

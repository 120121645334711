import axios from 'axios'
import config from '../config'
import { Modal } from 'antd'
import { destroyAllDbs } from './pouchDb'
import { getUserSession, isValidSession } from './cognito'
import i18next from 'i18next'

const API = axios.create({
  baseURL: `https://${config.api.BASE_URL}`
})
let requestInterceptor

API.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      // Request made and server responded
      // response: data, status, headers
      return Promise.reject(error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      return Promise.reject(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error)
    }
  }
)

export const setApiAuthorization = session => {
  // TODO: check again whether it's necessary to clear the value when no longer authenticated
  const idToken = session.getIdToken().getJwtToken()
  API.defaults.headers.common['Authorization'] = `Bearer ${idToken}`
}

export const setProviderApiAuthorization = idToken => {
  API.defaults.headers.common['Authorization'] = `Bearer ${idToken}`
}

export const setApiInterceptor = user => {
  requestInterceptor = API.interceptors.request.use(
    function (config) {
      getUserSession(user, (err, session) => {
        if (!err && session && isValidSession(session)) {
          const idToken = session.getIdToken().getJwtToken()
          config.headers.Authorization = `Bearer ${idToken}`
        }
      })

      if (!config.headers.Authorization) {
        user.signOut()
        destroyAllDbs()
        localStorage.clear()

        Modal.info({
          title: i18next.t('SESSION_TIMEOUT'),
          content: i18next.t('SESSION_TIMEOUT_SUMMARY'),
          onOk() {
            Modal.destroyAll()
            window.location.reload()
          }
        })
      }
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )
}

export const clearApiInterceptor = () => {
  API.interceptors.request.eject(requestInterceptor)
}

export default {
  // Storage on S3
  getFileStatus: (userId, fileId) =>
    API.get(`/storage/${userId}/${fileId}/status`),
  deleteFile: (userId, fileId) => API.delete(`/storage/${userId}/${fileId}`),
  copyFile: (userId, data) => API.put(`/storage/${userId}/copy`, data),
  getSignedUrl: (folder, fileId, operation, metadata) =>
    API.post(`/storage/signed-url`, { folder, fileId, operation, metadata }),
  getFromS3Url: (url, config) => axios.get(url, config),
  uploadToS3Url: (url, data, option) => axios.put(url, data, option),
  deleteLockedFile: (userId, fileId) =>
    API.delete(`/storage/locked/${userId}/${fileId}`),
  bulkDeleteFiles: (userId, data) =>
    API.post(`/storage/${userId}/bulk-delete`, data),
  bulkDeleteLockedFiles: (userId, data) =>
    API.post(`/storage/locked/${userId}/bulk-delete`, data),
  bulkCopyFiles: (userId, data) =>
    API.put(`/storage/${userId}/bulk-copy`, data),

  // Deputy management
  getDeputies: userId => API.get(`/users/${userId}/deputies`),
  getPrimaryUsers: userId => API.get(`/users/${userId}/primary-users`),
  getPublicKeys: userId => API.get(`/users/${userId}/keys`),
  distributeShares: (userId, data) => API.put(`/users/${userId}/shares`, data),
  unlockVault: (userId, data) => API.put(`/users/${userId}/unlock-vault`, data),
  getDeputyRequests: userId => API.get(`/users/${userId}/deputy-requests`),
  requestDeputy: (userId, data) =>
    API.post(`/users/${userId}/deputy-requests`, data),
  handleDeputyRequest: (userId, data) =>
    API.put(`/users/${userId}/deputy-requests`, data),
  getResetRequests: userId => API.get(`/users/${userId}/reset-requests`),
  handleResetRequest: (userId, data) =>
    API.put(`/users/${userId}/reset-requests`, data),
  getUnlockRequests: userId => API.get(`/users/${userId}/unlock-requests`),
  requestUnlock: (userId, data) =>
    API.post(`/users/${userId}/unlock-requests`, data),
  handleUnlockRequest: (userId, fileId, data) =>
    API.put(`/users/${userId}/unlock-requests/${fileId}`, data),
  revokeDeputy: (userId, data) =>
    API.post(`/users/${userId}/revoke-deputy`, data),
  clearResetData: userId => API.put(`/users/${userId}/clear-reset-data`),
  lockVault: (userId, data) => API.post(`/users/${userId}/lock-vault`, data),
  removeUnlockedShares: userId =>
    API.post(`/users/${userId}/remove-unlocked-shares`),
  getUsersByEmails: emails => API.get('/users', { params: { emails } }),
  handlePdDelegation: data => API.put('/users/pd-delegation', data),
  getProfessionalDeputies: () => API.get('/users/professional-deputies'),
  requestToUpdateUserDetails: (userId, data) =>
    API.post(`/users/${userId}/update-details-requests`, data),
  handleUpdateUserDetailsRequest: (userId, data) =>
    API.put(`/users/${userId}/update-details-requests`, data),
  getGift: (sender, giftId) =>
    API.get(`/users/gifts/${giftId}`, { params: { sender } }),
  updateSharesThreshold: (userId, data) =>
    API.post(`users/${userId}/shares-threshold`, data),
  disconectUser: (userId, data) =>
    API.post(`users/${userId}/disconect-user`, data),

  // Misc,
  getUser: (userId, isProfessionalDeputy?) =>
    API.get(`/users/${userId}`, { params: { isProfessionalDeputy } }),
  resetAccount: (userId, data) =>
    API.post(`/users/${userId}/reset-account`, data),
  updateSetupMfaDate: (userId, data) =>
    API.post(`/users/${userId}/mfa-date`, data),
  getReferrer: referralCode => API.get(`/users/referrers/${referralCode}`),
  saveAppliedReferralCode: (userId, data) =>
    API.post(`/users/${userId}/referral-code`, data),
  sendResetRequestEmails: (userId, data) =>
    API.post(`/users/${userId}/email-deputies`, data),

  hideDeputiesSetupReminder: userId =>
    API.post(`/users/${userId}/deputies-setup-reminder`),
  getPendingUnlockFiles: userId =>
    API.get(`/users/${userId}/pending-unlock-files`),
  getLockedFiles: userId => API.get(`/users/${userId}/locked-files`),
  sendInvitationEmails: data => API.post(`/users/invite`, data),
  sendUnsubscribeNotification: data =>
    API.post(`/users/unsubscribe-notification`, data),
  getFileKey: (userId, data) => API.post(`/users/${userId}/file-keys`, data),
  saveFileKeys: (userId, data) => API.put(`/users/${userId}/file-keys`, data),
  updateLockFileDetails: (userId, data) =>
    API.post(`/users/${userId}/update-file`, data),
  sendUpdatePhoneRequest: data => API.post(`/users/send-update-requests`, data),
  getUpdateRequests: userId => API.get(`/users/${userId}/update-requests`),
  handleUpdatePhoneRequest: (userId, data) =>
    API.post(`/users/${userId}/update-requests`, data),
  setTourCompletionStatus: (userId, data) =>
    API.post(`/users/${userId}/tour-completion-status`, data),
  getIpStackInfo: () => API.get(`users/ipstack-info`),
  getRegistryColumns: userId => API.get(`/users/${userId}/registry-columns`),
  saveRegistryColumns: (userId, data) =>
    API.post(`users/${userId}/registry-columns`, data),
  sendGift: (userId, data) => API.post(`users/${userId}/gifts`, data),
  updateAppliedPromotionCodes: (userId, data) =>
    API.put(`/users/${userId}/applied-promotion-codes`, data),
  getReferralCodeInfo: code => API.get(`users/referral-code/${code}`),
  saveOtherCryptos: (userId, data) =>
    API.post(`users/${userId}/other-cryptos`, data),

  // Payment settings (cards management)
  getPlans: productName =>
    API.get('/payment/plans', { params: { productName } }),
  createSubscription: data => API.post('/payment/subscriptions', data),
  updateSubscription: (subscriptionId, data) =>
    API.put(`/payment/subscriptions/${subscriptionId}`, data),
  getCustomer: userId => API.get(`/payment/${userId}`),
  createCustomer: (userId, data) => API.post(`/payment/${userId}`, data),
  deleteCustomer: customerId => API.delete(`/payment/customers/${customerId}`),
  createCard: (customerId, data) =>
    API.post(`/payment/customers/${customerId}/cards`, data),
  updateCustomer: (customerId, data) =>
    API.put(`/payment/customers/${customerId}`, data),
  deleteCard: (customerId, cardId) =>
    API.delete(`/payment/customers/${customerId}/cards/${cardId}`),
  updateSubscriptionSchedule: (subscriptionId, data) =>
    API.put(`/payment/schedule/${subscriptionId}`, data),
  getInvoices: userId => API.get(`/payment/invoices/${userId}`),
  getCharge: chargeId => API.get(`/payment/charges/${chargeId}`),
  getPromotionCode: (code, email, skipValidation, useDynamoDb) =>
    API.get(`/payment/promotion-codes/${code}`, {
      params: { email, skipValidation, useDynamoDb }
    }),
  getProducts: () => API.get(`/payment/products`),

  // Settings (base currency, rates, delete account)
  getBaseCurrency: userId => API.get(`/users/${userId}/base-currency`),
  setBaseCurrency: (userId, data) =>
    API.post(`/users/${userId}/currency`, data),
  // saveRates: (userId, data) => API.post(`/users/${userId}/rates`, data),
  deleteAccount: userId => API.delete(`/users/${userId}`),
  updatePersonalInfo: (userId, data) =>
    API.put(`/users/${userId}/update-personal-info`, data),
  saveInactivityStateConfig: (userId, data) =>
    API.put(`/users/${userId}/inactivity-config`, data),
  migrateUser: (userId, data) => API.post(`/users/${userId}/migrate`, data),

  // Audit
  logAccessAttempt: data => API.post(`/audit/access-attempts`, data),
  getAccessAttempts: (userId, params = {}) =>
    API.get(`/audit/access-attempts/${userId}`, { params }),
  deleteAccessAttempts: userId =>
    API.delete(`/audit/access-attempts/${userId}`),

  // Rates
  getLatestRates: baseCurrency =>
    API.get('/users/latest-rates', {
      params: { base: baseCurrency, show_alternative: 1 }
    }),
  setRatesData: (userId, data) => API.post(`/users/${userId}/rates-data`, data),

  // Legacy management
  notifyLegacyManagementSetup: (userId, data) =>
    API.post(`users/${userId}/legacy-management/setup-notify`, data),
  updateLegacyManagementStatus: (userId, data) =>
    API.post(`users/${userId}/legacy-management/status`, data),
  sendRequestToRelease: (userId, data) =>
    API.post(`users/${userId}/legacy-management/request-release`, data),
  checkAuthFlow: userId => API.get(`/users/${userId}/check-auth-flow`),
  updateAuthFlow: data => API.post(`users/auth-flow-type`, data),
  updateDefaultLanguage: (userId, data) =>
    API.post(`/users/${userId}/default-language`, data),
  sendAddRecordNotification: data => API.post('/users/pd-add-record', data),
  handleAddRecordRequest: data =>
    API.post('/users/handle-add-record-request', data),
  updateAccessLevel: (userId, data) =>
    API.post(`/users/${userId}/access-level`, data),
  getAccessLevel: userId => API.get(`/users/${userId}/access-level`),

  // events
  getEvents: userId => API.get(`/users/${userId}/events`),
  updateEvents: (userId, data) => API.post(`/users/${userId}/events`, data),
  resendEmailVerification: data => API.post(`/users/resend-email-verify`, data),
  updateMFASettings: data => API.post(`/users/update-mfa-settings`, data),

  //releaseEmail
  releasePasswordEmail: (userId, data) =>
    API.post(`/users/${userId}/release-password`, data),
  setLegacyRequest: data => API.post(`/users/set-legacy-request`, data),

  //privateFolder
  updateUsedStorage: (userId, data) =>
    API.post(`/users/${userId}/used-storage`, data),
  updateMarketingMaterials: (userId, data) =>
    API.post(`/users/${userId}/marketing-materials`, data),

  updateExternalUser: (userId, data) =>
    API.post(`/payment/${userId}/update-external-user`, data),
  getExternalUserAttributes: userId =>
    API.get(`/users/${userId}/get-external-user-attributes`),
  deleteExternalUser: userId =>
    API.delete(`/users/${userId}/delete-external-user`),
  updateExternalUserAttributes: (userId, data) =>
    API.post(`/users/${userId}/update-external-user-attributes`, data),

  //privateFolder
  getPrivateFolderKey: userId => API.get(`/users/${userId}/private-folder-key`),
  setPrivateFolderKey: userId =>
    API.post(`/users/${userId}/private-folder-key`),

  setIsReleasePassword: (userId, data) =>
    API.post(`/users/${userId}/is-release-password`, data),
  lockPrivateFolder: (userId, data) =>
    API.post(`/users/${userId}/lock-private-folder`, data),
  updateAvatar: (userId, data) => API.post(`/users/${userId}/avatar`, data),
  getAvatar: userId => API.get(`/users/${userId}/avatar`),

  sendVaultboxLiteLimitedMail: (userId, data) =>
    API.post(`/users/${userId}/send-vaultbox-lite-limited-mail`, data),
  updateIsSendVaultboxLiteLimitedMail: userId =>
    API.post(`/users/${userId}/update-is-send-vaultbox-lite-limited-mail`),
  updatePieChartColor: (userId, data) =>
    API.post(`/users/${userId}/pie-chart-color`, data),
  addMembers: (userId, data) => API.post(`/users/${userId}/add-members`, data),
  removeMember: (userId, data) =>
    API.post(`/users/${userId}/remove-member`, data),
  hideRemovedGroupReminder: userId =>
    API.post(`/users/${userId}/hide-removed-group-reminder`),
  joinGroup: (userId, data) => API.post(`/users/${userId}/join-group`, data),
  handleChangeProToGroup: (userId, data) =>
    API.post(`/payment/${userId}/change-pro-to-group`, data),
  declineGroupInvitation: (userId, data) =>
    API.post(`/users/${userId}/decline-group-invitation`, data),
  acceptGroupInvitation: (userId, data) =>
    API.post(`/users/${userId}/accept-group-invitation`, data),
  getGroupOwner: (userId) => API.get(`/users/${userId}/group-owner`)
}

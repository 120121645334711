import React, { useState, useContext } from 'react'
import { Alert, Button, Form, Icon, message, Modal, Tooltip } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import Dragger from 'antd/lib/upload/Dragger'
import { Workbook } from 'exceljs'
import ContactModel, {
  CONTACT_COLUMNS_BASE_TYPE
} from '../../model/ContactModel'
import { CONTACT_TYPES } from '../../share/Constants'
import uuidv4 from 'uuid/v4'
import { onError } from '../../lib/sentry'
import { importContacts } from '../../lib/pouchDb'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { downloadExcelImportTemplate } from './contactHelpers'
import { parseValue } from '../../share/importHelpers'
import { draggerProps, normFile } from '../../share/formHelpers'
import { useTranslation } from 'react-i18next'
import { removeHtmlTags, showUpgradeSubscriptionPlanConfirm } from './../../share/helpers'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import SubscriptionModal from '../payment/SubscriptionModal'
import { useSelector } from 'react-redux'

function fieldsToList(record, key) {
  return [
    {
      name: record[key + '1Name'],
      value: record[key + '1Value']
    },
    {
      name: record[key + '2Name'],
      value: record[key + '2Value']
    },
    {
      name: record[key + '3Name'],
      value: record[key + '3Value']
    }
  ].filter(data => data.name && data.value)
}

function ImportExcelModal({
  visible,
  setVisible,
  sourceContacts,
  form,
  setContactId
}) {
  const { user } = useContext(AuthContext)
  const { masterKey, limitedRecord } = useContext(VaultContext)
  const [isSaving, setIsSaving] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const { getFieldDecorator } = form
  const { t } = useTranslation()
  const [addS3Change] = useMutation(createS3Change)
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const { limit } = useSelector(state => state.customer)

  const handleOk = () => {
    setErrMsg('')
    form.validateFields(async (err, values) => {
      if (err) return

      setIsSaving(true)
      const workbook = new Workbook()
      const file = values.file[0].originFileObj
      const fileReader = new FileReader()

      fileReader.onload = e => {
        const records = []
        const buffer = e.target.result
        let existsCorrectSheet = false

        workbook.xlsx
          .load(buffer)
          .then(async wb => {
            let allErrors = []

            wb.eachSheet((ws, id) => {
              if (
                [CONTACT_TYPES.INDIVIDUAL, CONTACT_TYPES.ORGANISATION].includes(
                  ws.name
                )
              ) {
                existsCorrectSheet = true
                let errors = []
                const columns = CONTACT_COLUMNS_BASE_TYPE(ws.name) || []
                const requiredColumns = columns.filter(col => col.required)
                let headerRow = []
                let rowIndex
                
                const columnsTitles = columns.map(col => col.title)
                ws.eachRow({ includeEmpty: false }, (row, index) => {
                  const titles = ws.getRow(index).values
                  if (
                    columnsTitles.every(colTitle => titles.includes(colTitle))
                  ) {
                    rowIndex = index
                    headerRow = titles
                  }
                })

                requiredColumns.forEach(col => {
                  if (!headerRow.includes(col.title)) {
                    errors.push(
                      `${t('SHEET')} ${ws.name}: ${t('MISSING_FILE')} ${
                        col.title
                      }`
                    )
                  }
                })
                if (errors.length) {
                  allErrors = allErrors.concat(errors)
                  return
                }

                ws.eachRow({ includeEmpty: false }, (row, rowNumber) => {
                  if (rowNumber <= rowIndex) return
                  let rowData = {}
                  headerRow.forEach((val, index) => {
                    const mappingColumn = columns.find(col => col.title === val)

                    if (mappingColumn) {
                      if (mappingColumn.required && !row.values[index]) {
                        errors.push(
                          `${t('SHEET')} ${ws.name} - ${t(
                            'ROW'
                          )} ${rowNumber}: ${mappingColumn.title} ${t(
                            'IS_MANDATORY'
                          )}.`
                        )
                      } else {
                        rowData[mappingColumn.key] = parseValue(
                          mappingColumn.type,
                          row.values[index]
                        )
                      }
                    }
                  })

                  if (errors.length) {
                    allErrors = allErrors.concat(errors)
                    return
                  }

                  const record = {
                    _id: uuidv4(),
                    type: ws.name,
                    ...rowData
                  }

                  const emails = fieldsToList(record, 'email')
                  const addresses = fieldsToList(record, 'address')
                  const phoneNumbers = fieldsToList(record, 'phoneNumber')
                  const references = fieldsToList(record, 'reference')
                  const newRecords = {
                    ...new ContactModel({
                      ...record,
                      emails,
                      addresses,
                      phoneNumbers,
                      references
                    }),
                    tags: record.tags,
                    _id: record._id,
                    _rev: record._rev
                  }

                  removeHtmlTags(newRecords)
                  records.push(newRecords)
                })
              }
            })

            if (allErrors.length) {
              throw Error(allErrors.join('\n'))
            }

            if (!existsCorrectSheet) {
              throw Error(t('INVALID_TEMPLATE'))
            }

            if (!records?.length) {
              setIsSaving(false)
              resetModal()
              return
            }

            if (limitedRecord + records.length > limit) {
              setIsSaving(false)
              showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
              return
            }

            await importContacts(user.username, records, masterKey)
            localStorage.setItem('NotReload', true)
            addS3Change({
              variables: {
                message: 'contacts',
                userId: user.username
              }
            })
            setIsSaving(false)
            resetModal()
            message.success(t('SUCCESSFULLY_IMPORTED_DATA'))
          })
          .catch(err => {
            onError(err)
            setErrMsg(t('FAILED_TO_IMPORT_DATA'))
            setIsSaving(false)
          })
      }
      fileReader.readAsArrayBuffer(file)
    })
  }

  const resetModal = () => {
    form.resetFields()
    setVisible(false)
    setErrMsg('')
    setContactId('')
  }

  return (
    <>
      <Modal
        title={t('IMPORT_FROM_EXCEL')}
        visible={visible}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleOk}
        onCancel={resetModal}
        okButtonProps={{ loading: isSaving }}
        maskClosable={false}
      >
        <Form className="upload-form">
          <FormItem label={t('FILE')}>
            {getFieldDecorator('file', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  required: true,
                  message: t('SELECT_FILE_TO_UPLOAD_MSG')
                },
                {
                  validator: (rule, value, callback) => {
                    const fileType = value && value[0]?.originFileObj.type
                    if (
                      fileType &&
                      fileType !==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ) {
                      callback(t('INVALID_FILE_TYPE'))
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="upload" />
                </p>
                <p className="ant-upload-hint">
                  {t('UPLOAD_FILE_INSTRUCTION')}
                </p>
              </Dragger>
            )}
          </FormItem>
          <FormItem
            label={
              <span>
                {t('TEMPLATE')}:{' '}
                <Tooltip title={t('DOWNLOAD_SAMPLE_TEMPLATE')}>
                  <Button
                    icon="download"
                    type="link"
                    onClick={downloadExcelImportTemplate}
                  />
                </Tooltip>
              </span>
            }
            colon={false}
          ></FormItem>
        </Form>
        {errMsg && (
          <Alert
            style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}
            description={errMsg}
            closable
            afterClose={() => setErrMsg('')}
            type="error"
          />
        )}
      </Modal>
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </>
  )
}

const WrappedImportExcelForm = Form.create({ name: 'ImportExcelModal' })(
  ImportExcelModal
)
export default WrappedImportExcelForm

import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  assetsLiabilities: [],
  deletedAssetsLiabilities: [],
  activeAssetsLiabilities: [],
  draftAssetsLiabilities: [],
  pendingAssetsLiabilities: [],
  rejectedAssetsLiabilities: [],
  assetsLiabilitiesTags: [],
  pendingAssetsLiabilitiesTags: [],
  valuations: [],
  isLoading: false,
  valuationsLoading: false,
  pendingLoading: false,
  error: null,
  valuationsError: null,
  pendingError: null
}

const assetsLiabilities = createSlice({
  name: 'assetsLiabilities',
  initialState,
  reducers: {
    getAssetsLiabilitiesStart(state) {
      state.isLoading = true
      state.error = null
    },
    getAssetsLiabilitiesSuccess(state, action) {
      const { assetsLiabilities } = action.payload
      state.assetsLiabilities = assetsLiabilities
      state.deletedAssetsLiabilities = assetsLiabilities.filter(
        al => al.deleted
      )
      state.activeAssetsLiabilities = assetsLiabilities.filter(
        al => !al.deleted
      )
      state.assetsLiabilitiesTags = assetsLiabilities
        .filter(al => !al.deleted && !!al.tags?.length)
        .map(al => al.tags)
      state.isLoading = false
      state.error = null
    },
    getAssetsLiabilitiesFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getValuationsStart(state) {
      state.valuationsLoading = true
      state.valuationsError = null
    },
    getValuationsSuccess(state, action) {
      const { valuations } = action.payload
      state.valuations = valuations
      state.valuationsLoading = false
      state.valuationsError = null
    },
    getValuationsFailure(state, action) {
      state.valuationsLoading = false
      state.valuationsError = action.payload
    },
    getPendingAssetsLiabilitiesStart(state) {
      state.pendingLoading = true
      state.pendingError = null
    },
    getPendingAssetsLiabilitiesSuccess(state, action) {
      const { assetsLiabilities } = action.payload
      state.pendingAssetsLiabilities = assetsLiabilities.filter(
        al => al.status === 'Draft'
      )
      state.rejectedAssetsLiabilities = assetsLiabilities.filter(
        al => al.status === 'Rejected'
      )
      state.pendingAssetsLiabilitiesTags = assetsLiabilities
        .filter(al => al.status === 'Draft' && !!al.tags?.length)
        .map(al => al.tags)
      state.pendingLoading = false
      state.pendingError = null
    },
    getPendingAssetsLiabilitiesFailure(state, action) {
      state.pendingLoading = false
      state.pendingError = action.payload
    }
  }
})

export const {
  getAssetsLiabilitiesStart,
  getAssetsLiabilitiesSuccess,
  getAssetsLiabilitiesFailure,
  getValuationsStart,
  getValuationsSuccess,
  getValuationsFailure,
  getPendingAssetsLiabilitiesStart,
  getPendingAssetsLiabilitiesSuccess,
  getPendingAssetsLiabilitiesFailure
} = assetsLiabilities.actions

export default assetsLiabilities.reducer

export const fetchAssetsLiabilities = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getAssetsLiabilitiesStart())
    const assetsLiabilities = await getRecords(
      userId,
      'assetsLiabilities',
      masterKey
    )

    dispatch(getAssetsLiabilitiesSuccess({ assetsLiabilities }))
  } catch (err) {
    onError(err)
    dispatch(getAssetsLiabilitiesFailure(err.toString()))
  }
}

export const fetchPendingAssetsLiabilities =
  (userId, masterKey) => async dispatch => {
    try {
      dispatch(getPendingAssetsLiabilitiesStart())
      const assetsLiabilities = await getRecords(
        userId,
        'pendingAssetsLiabilities',
        masterKey
      )

      dispatch(getPendingAssetsLiabilitiesSuccess({ assetsLiabilities }))
    } catch (err) {
      onError(err)
      dispatch(getPendingAssetsLiabilitiesFailure(err.toString()))
    }
  }

export const fetchValuations = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getValuationsStart())
    const valuations = await getRecords(
      userId,
      'assetsLiabilitiesValuations',
      masterKey
    )
    dispatch(getValuationsSuccess({ valuations }))
  } catch (err) {
    onError(err)
    dispatch(getValuationsFailure(err.toString()))
  }
}

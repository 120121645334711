import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AuthContext from './../../contexts/AuthContext'
import { Icon, Divider, message, Popconfirm, Empty, Modal, Spin } from 'antd'
import { ThemeContext } from 'styled-components'
import VaultContext from '../../contexts/VaultContext'
import FileDetails from './FileDetails'
import { uniq } from 'lodash'
import { sortTree } from './../../share/helpers'
import Button from '../override/Button'
import {
  handleDocumentsRequests,
  permanentlyDeleteDocuments,
  renameFolder,
  unlinkItemFromLinkedList,
  uploadEncryptedData
} from '../../lib/pouchDb'
import CreateFolderModal from '../file/CreateFolderModal'
import { fetchOtherPendingDocuments } from '../../features/documents/otherDocumentsSlice'
import { useTranslation } from 'react-i18next'
import { ACCESS_LEVEL } from './../../share/Constants'
import { onError } from '../../lib/sentry'
import PouchDB from 'pouchdb'
import { removeHtmlTags } from '../../share/helpers'
import RejectModal from '../modals/RejectModal'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import api from '../../lib/api'

const PendingList = props => {
  const { isRejected } = props
  const { isReadonly, userId, masterKey, fullName } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const [documents, setDocuments] = useState([])
  const [docItem, setDocItem] = useState({})
  const [visible, setVisible] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [approvedDocuments, setApprovedDocuments] = useState([])
  const [allRejectedDocuments, setAllRejectedDocuments] = useState([])
  const [editFormVisible, setEditFormVisible] = useState(false)
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejectRecord, setRejectRecord] = useState({})
  const [rejecting, setRejecting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    activeFolders,
    pendingDocuments,
    activeDocuments,
    rejectedDocuments,
    usedStorage
  } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { activeContacts, pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { accessLevel } = useSelector(state => state.settings)
  const { allowedStorage } = useSelector(state => state.customer)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  let rejectFormRef
  let editFolderForm
  const editFolderFormRef = fr => {
    editFolderForm = fr
  }
  const [addS3Change] = useMutation(createS3Change)



  useEffect(() => {
    const allDocs = [
      ...activeDocuments,
      ...(isRejected
        ? [...rejectedDocuments, ...pendingDocuments]
        : pendingDocuments)
    ]
    let allPendingDocuments = uniq(
      (isRejected ? rejectedDocuments : pendingDocuments)
        .map(item => {
          const parentItems = allDocs.filter(
            d => !d.fileName && item.path.indexOf(d.path) === 0
          )
          const subItems = allDocs.filter(
            d =>
              d.path.indexOf(item.path) === 0 &&
              (item.fileName ? item.fileName === d.fileName : true) &&
              item.status === d.status
          )
          return [...parentItems, ...subItems]
        })
        .flat()
    )

    const updateFoldersFromKeys = (item, keys, docs, level = 1) => {
      let index = docs.findIndex(f => f.name === keys[0])

      if (index === -1) {
        index =
          docs.push({
            name: keys[0],
            path: item.path,
            id: item._id,
            type: item.fileName ? 'file' : 'folder',
            status: item.status,
            reasonReject: item.reasonReject,
            fileId: item.fileId,
            level,
            children: []
          }) - 1
      }

      if (keys.length > 1) {
        updateFoldersFromKeys(
          item,
          keys.slice(1),
          docs[index].children,
          level + 1
        )
      }
    }

    const updatedDocuments = []
    const sortedDocuments = allPendingDocuments.some(item => !item.fileId)
      ? allPendingDocuments
          .sort((a, b) => a.fileId?.localeCompare(b.fileId))
          .reverse()
          .sort((a, b) => a.path?.localeCompare(b.path))
      : allPendingDocuments.sort((a, b) => a.fileId?.localeCompare(b.fileId))

    sortedDocuments.forEach(item => {
      const keys = (item.fileName ? item.path.concat(item.fileName) : item.path)
        .split('/')
        .filter(s => s !== '')

      updateFoldersFromKeys(item, keys, updatedDocuments)
    })

    setDocuments(updatedDocuments)
  }, [pendingDocuments, activeDocuments, isRejected, rejectedDocuments])

  const renderListItems = documents =>
    documents?.length ? (
      documents.map((item, index) => (
        <div key={item.id}>
          <div
            style={{
              borderBottom: '1px solid #e8e8e8',
              padding: '16px 0px',
              borderTop: `${
                index === 0 && item.level === 1 ? '1px solid #e8e8e8' : 'none'
              }`,
              marginLeft: item.level === 1 ? 10 : 20 * item.level
            }}
          >
            <Icon
              type={item.type}
              style={{
                fontSize: '20px',
                color: item.type === 'folder' ? theme.primary : theme.dark
              }}
              theme={item.type === 'folder' ? 'filled' : ''}
            />{' '}
            {item.type === 'folder' ? (
              item.name
            ) : (
              <Button
                style={{
                  padding: 0,
                  color: 'rgba(0, 0, 0, 0.65)'
                }}
                onClick={() => viewFileDetail(item)}
                type="link"
              >
                {item.name}
              </Button>
            )}
            {item.status &&
              (!isProfessionalDeputy && (!isReadonly || !isDelegateByPD) ? (
                <div
                  style={{
                    float: 'right'
                  }}
                >
                  <Icon
                    onClick={() => approveDocument(item)}
                    type="check-circle"
                    style={{
                      fontSize: '18px',
                      color: approvedDocuments
                        .map(ad => ad._id)
                        .includes(item.id)
                        ? theme.primary
                        : theme.dark1
                    }}
                  />
                  <Divider type="vertical" />
                  <Icon
                    onClick={() => {
                      rejectFormRef.props.form.resetFields()
                      setRejectModalVisible(true)
                      setRejectRecord(item)
                    }}
                    type="close-circle"
                    style={{
                      fontSize: '18px',
                      color: allRejectedDocuments
                        .map(rd => rd._id)
                        .includes(item.id)
                        ? theme.red
                        : theme.dark1
                    }}
                  />
                </div>
              ) : (
                ((isRejected && item.status === 'Rejected') ||
                  (!isRejected && item.status === 'Draft')) && (
                  <>
                    <div
                      style={{
                        float: 'right'
                      }}
                    >
                      <Icon
                        onClick={() => handleEditDocument(item)}
                        type="edit"
                        style={{
                          fontSize: 20,
                          color: theme.primary
                        }}
                      />
                      <Divider type="vertical" />
                      <Popconfirm
                        title={t('CONFIRM_DELETE_ITEM_MSG')}
                        onConfirm={() => handleDeleteDocument(item)}
                        okText={t('YES')}
                        cancelText={t('NO')}
                        arrowPointAtCenter
                        placement="bottomRight"
                      >
                        <Icon
                          type="delete"
                          style={{
                            fontSize: 20,
                            color: theme.red
                          }}
                        />
                      </Popconfirm>
                    </div>
                  </>
                )
              ))}
            {item.reasonReject && (
              <div style={{ width: 500, marginRight: 10, float: 'right' }}>
                <b>{t('REJECTION_REASON')}</b>: {item.reasonReject}
              </div>
            )}
          </div>

          {!!item.children.length && renderListItems(item.children)}
        </div>
      ))
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('No documents')}
      />
    )

  const viewFileDetail = item => {
    setVisible(true)
    setDocItem(item)
  }

  const approveDocument = item => {
    const parentItems = pendingDocuments.filter(
      d => !d.fileName && item.path.indexOf(d.path) === 0
    )
    const subItems = pendingDocuments.filter(d =>
      item.fileId
        ? item.id === d._id
        : d.path.indexOf(item.path) === 0 &&
          (item.fileId ? d.fileName === item.name : true)
    )
    const approvedItems = [...parentItems, ...subItems]
    setApprovedDocuments(uniq(approvedDocuments.concat(approvedItems)))
    setAllRejectedDocuments(
      allRejectedDocuments.filter(
        rd => !approvedItems.map(doc => doc._id).includes(rd._id)
      )
    )
  }

  const rejectDocument = () => {
    setRejecting(true)
    rejectFormRef.props.form.validateFields(async (err, values) => {
      if (err) {
        setRejecting(false)
        return
      }

      try {
        const subItems = pendingDocuments.filter(d =>
          rejectRecord.fileId
            ? rejectRecord.id === d._id
            : d.path.indexOf(rejectRecord.path) === 0 &&
              (rejectRecord.fileId ? d.fileName === rejectRecord.name : true)
        )

        setApprovedDocuments(
          approvedDocuments.filter(
            ad => !subItems.map(s => s._id).includes(ad._id)
          )
        )
        const updatedSubItems = subItems.map(record => {
          return {
            ...record,
            reasonReject: values.reasonReject
          }
        })

        const allRejectedItems = allRejectedDocuments.filter(
          rd => !updatedSubItems.map(si => si._id).includes(rd._id)
        )

        setAllRejectedDocuments(uniq(allRejectedItems.concat(updatedSubItems)))
        setRejecting(false)
        setRejectModalVisible(false)
      } catch (error) {
        message.error(t('FAILED_TO_REJECT_DOCUMENTS'))
      }
    })
  }

  const handlePendingDocumentsRequest = async () => {
    setIsLoading(true)
    try {
      if (approvedDocuments.length) {
        const pendingFilesSize = approvedDocuments
          .filter(ad => ad.fileId)
          .reduce((sum, item) => {
            return sum + item?.file[0].size
          }, 0)

        if (
          !allowedStorage ||
          usedStorage + pendingFilesSize > allowedStorage
        ) {
          Modal.warn({
            title: t('LOW_STORAGE_SPACE'),
            content: t('LOW_STORAGE_SPACE_CONTENT')
          })
          return
        }

        await handleDocumentsRequests(
          approvedDocuments,
          'documents',
          userId,
          masterKey,
          true
        )

        //delete records in pending table
        await permanentlyDeleteDocuments(
          userId,
          pendingDocuments,
          approvedDocuments,
          masterKey,
          'pendingDocuments'
        )
        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved: true,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'document'
          })
        )
      }

      if (allRejectedDocuments.length) {
        await handleDocumentsRequests(
          allRejectedDocuments,
          'pendingDocuments',
          userId,
          masterKey
        )
        //unlink items from files
        allRejectedDocuments
          .filter(item => item.fileId)
          .map(async item => {
            await handleUnlinkItemsFromFile(item)
          })

        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved: false,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'document'
          })
        )
      }
      setApprovedDocuments([])
      setAllRejectedDocuments([])
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message:
            'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
          userId: userId
        }
      })
      // dispatch(fetchPendingDocuments(userId, masterKey))
      // dispatch(fetchDocuments(userId, masterKey))
      setIsLoading(false)
      message.success(t('SUCCESSFULLY_UPDATED_PENDING_DOCUMENTS'))
    } catch (error) {
      message.error(t('FAILED_TO_UPDATE_PENDING_DOCUMENTS'))
      setIsLoading(false)
    }
  }

  const handleUnlinkItemsFromFile = async item => {
    try {
      if (item.contacts?.length) {
        const activeContactIds = item.contacts.filter(id =>
          activeContacts.map(ac => ac._id).includes(id)
        )
        const pendingContactIds = item.contacts.filter(id =>
          pendingContacts.map(pc => pc._id).includes(id)
        )

        if (activeContactIds?.length) {
          const contactsDb = new PouchDB(`${userId}_contacts`)
          contactsDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            activeContactIds,
            contactsDb
          )
          await uploadEncryptedData(contactsDb, userId, 'contacts')
        }

        if (pendingContactIds?.length) {
          const pendingContactsDb = new PouchDB(`${userId}_pendingContacts`)
          pendingContactsDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            pendingContactIds,
            pendingContactsDb
          )
          await uploadEncryptedData(
            pendingContactsDb,
            userId,
            'pendingContacts'
          )
        }
      }

      if (item.assetsLiabilities?.length) {
        const activeAssetsLiabilitiesIds = item.assetsLiabilities.filter(id =>
          activeAssetsLiabilities.map(aa => aa._id).includes(id)
        )
        const pendingAssetsLiabilitiesIds = item.assetsLiabilities.filter(id =>
          pendingAssetsLiabilities.map(pa => pa._id).includes(id)
        )

        if (activeAssetsLiabilitiesIds?.length) {
          const assetLiabilitiesDb = new PouchDB(`${userId}_assetsLiabilities`)
          assetLiabilitiesDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            activeAssetsLiabilitiesIds,
            assetLiabilitiesDb
          )
          await uploadEncryptedData(
            assetLiabilitiesDb,
            userId,
            'assetsLiabilities'
          )
        }

        if (pendingAssetsLiabilitiesIds?.length) {
          const pendingAssetLiabilitiesDb = new PouchDB(
            `${userId}_pendingAssetsLiabilities`
          )
          pendingAssetLiabilitiesDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            pendingAssetsLiabilitiesIds,
            pendingAssetLiabilitiesDb
          )
          await uploadEncryptedData(
            pendingAssetLiabilitiesDb,
            userId,
            'pendingAssetsLiabilities'
          )
        }
      }

      //unlink passwords
      if (item.passwords?.length) {
        const activePasswordIds = item.passwords.filter(id =>
          activePasswords.map(ad => ad._id).includes(id)
        )
        const pendingPasswordIds = item.passwords.filter(id =>
          pendingPasswords.map(pd => pd._id).includes(id)
        )
        if (activePasswordIds?.length) {
          const passwordDb = new PouchDB(`${userId}_passwords`)
          passwordDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            activePasswordIds,
            passwordDb
          )
          await uploadEncryptedData(passwordDb, userId, 'passwords')
        }

        if (pendingPasswordIds?.length) {
          const pendingPasswordDb = new PouchDB(`${userId}_pendingPasswords`)
          pendingPasswordDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            pendingPasswordIds,
            pendingPasswordDb
          )
          await uploadEncryptedData(
            pendingPasswordDb,
            userId,
            'pendingPasswords'
          )
        }
      }

      //unlink events
      if (item.events?.length) {
        const activeEventIds = item.events.filter(id =>
          events.map(ad => ad._id).includes(id)
        )
        const pendingEventIds = item.events.filter(id =>
          pendingEvents.map(pd => pd._id).includes(id)
        )
        if (activeEventIds?.length) {
          const eventsDb = new PouchDB(`${userId}_events`)
          eventsDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            activeEventIds,
            eventsDb
          )
          await uploadEncryptedData(eventsDb, userId, 'events')
        }

        if (pendingEventIds?.length) {
          const pendingEventsDb = new PouchDB(`${userId}_pendingEvents`)
          pendingEventsDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            item._id,
            'documents',
            pendingEventIds,
            pendingEventsDb
          )
          await uploadEncryptedData(pendingEventsDb, userId, 'pendingEvents')
        }
      }
    } catch (error) {
      message.error(t('FAILED_TO_UNLINK_FROM_FILE'))
    }
  }

  const handleDeleteDocument = async item => {
    try {
      const subItems = (
        isRejected ? rejectedDocuments : pendingDocuments
      ).filter(d =>
        item.fileId
          ? item.id === d._id
          : d.path.indexOf(item.path) === 0 &&
            (item.fileId ? d.fileName === item.name : true)
      )

      //delete records in pending table
      await permanentlyDeleteDocuments(
        userId,
        isRejected ? rejectedDocuments : pendingDocuments,
        subItems,
        masterKey,
        'pendingDocuments'
      )

      subItems.forEach(async item => {
        if (item.fileId) {
          await handleUnlinkItemsFromFile(item)
        }
      })
      dispatch(fetchOtherPendingDocuments(userId, masterKey))
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message:
            'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
          userId: userId
        }
      })
      message.success(t('SUCCESSFULLY_DELETED_DOCUMENTS'))
    } catch (error) {
      message.error(t('FAILED_TO_DELETE_DOCUMENTS'))
    }
  }

  const handleEditDocument = item => {
    if (item.type === 'folder') {
      setDocItem(item)
      setEditFormVisible(true)
    } else {
      setVisible(true)
      setDocItem(item)
      setIsEdited(true)
    }
  }

  const handleRenameFolder = item => {
    editFolderForm.props.form.validateFields(async (err, values) => {
      if (err) return
      removeHtmlTags(values)
      if (values.folderName.trim() === item.name) {
        setEditFormVisible(false)
        return
      }

      try {
        await renameFolder(
          [...rejectedDocuments, ...pendingDocuments],
          item,
          userId,
          values.folderName,
          masterKey,
          'pendingDocuments',
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        )

        dispatch(fetchOtherPendingDocuments(userId, masterKey))
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'pendingDocuments',
            userId: userId
          }
        })
        message.success(t('SUCCESSFULLY_RENAMED_FOLDER'))
        setEditFormVisible(false)
      } catch (err) {
        message.error(t('FAILED_TO_RENAME_FOLDER'))
        onError(err)
      }
    })
  }

  sortTree(documents)

  return (
    <Spin spinning={isLoading}>
      <div style={{ padding: 20 }}>{renderListItems(documents)}</div>
      {(!!approvedDocuments?.length || !!allRejectedDocuments?.length) && (
        <div style={{ padding: 20, float: 'right' }}>
          <Button
            onClick={() => {
              setApprovedDocuments([])
              setAllRejectedDocuments([])
            }}
          >
            {t('RESET')}
          </Button>
          <Divider type="vertical" />

          <Button onClick={handlePendingDocumentsRequest} type="primary">
            {t('SAVE')}
          </Button>
        </div>
      )}

      <FileDetails
        visible={visible}
        setVisible={setVisible}
        docItem={docItem}
        isEdited={isEdited}
        setIsEdited={setIsEdited}
      />
      <CreateFolderModal
        wrappedComponentRef={editFolderFormRef}
        visible={editFormVisible}
        isEditFolder={editFormVisible}
        handleSaveFolder={() => {
          handleRenameFolder(docItem)
          editFolderForm.props.form.resetFields()
        }}
        handleCancel={() => {
          setEditFormVisible(false)
          editFolderForm.props.form.resetFields()
        }}
        folderName={docItem.name}
        folders={activeFolders}
        breadcrumb={docItem.path?.slice(0, -(docItem.name?.length + 1))}
      />
      <RejectModal
        wrappedComponentRef={fr => (rejectFormRef = fr)}
        visible={rejectModalVisible}
        handleOk={rejectDocument}
        handleCancel={() => setRejectModalVisible(false)}
        rejecting={rejecting}
      />
    </Spin>
  )
}

export default PendingList

import React, { useContext } from 'react'
import { P, StatusText } from '../override/Typography'
import styled, { ThemeContext } from 'styled-components'
import SimpleListItem from './SimpleListItem'

function SimpleList({
  header,
  extra,
  data,
  itemKey,
  icon,
  mainInfo,
  subInfo,
  action,
  empty,
  actionVisible,
  selectedItem,
  setSelectedItem,
  className
}) {
  const theme = useContext(ThemeContext)

  return (
    <div className={className}>
      <div className="list-header">
        <span className="header-main">{header}</span>
        <span className="header-extra">{extra}</span>
      </div>

      {data.map(item => (
        <SimpleListItem
          key={item[itemKey]}
          isSelected={selectedItem === item[itemKey]}
          handleClick={() =>
            setSelectedItem &&
            setSelectedItem(selectedItem !== item[itemKey] ? item[itemKey] : '')
          }
        >
          {icon(item)}
          <div className="item-info">
            <P className="item-name">{mainInfo(item)}</P>
            {subInfo && (
              <StatusText color={theme.dark2}>{subInfo(item)}</StatusText>
            )}
          </div>
          {action && (
            <div
              className={`item-action ${actionVisible ? 'action-visible' : ''}`}
            >
              {action(item)}
            </div>
          )}
        </SimpleListItem>
      ))}
      {!data.length && empty}
    </div>
  )
}

const StyledSimpleList = styled(SimpleList)`
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-extra {
    flex-grow: 1;
    text-align: right;
  }
  .item {
    .item-info {
      flex-grow: 1;
      margin-left: 14px;
      height: 40px;
      width: calc(100% - 50px);
    }
    .item-name {
      padding-top: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .item-action:not(.action-visible) {
      display: none;
    }
    &:hover,
    &.selected {
      .item-info {
        p {
          color: ${props => props.theme.primary};
        }
        span {
          color: ${props => props.theme.darkBg};
        }
      }
      .item-action {
        display: block;
      }
    }
  }
`

export default StyledSimpleList

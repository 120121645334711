import {
  CSV_TEMPLATES,
  CORE_TYPES,
  ASSET_TYPES,
  SAMPLE_TEMPLATE_EXCLUDED_COLUMNS
} from './Constants'
import { isEqual } from 'lodash'
import { getAssetLiabilityColumns } from '../components/assets-liabilities/assetLiabilityHelpers'
import i18next from 'i18next'

export default {
  IB: {
    name: CSV_TEMPLATES.IB,
    statement: {
      header: {
        startWith: ['Statement', 'Header']
      },
      data: {
        startWith: ['Statement', 'Data'],
        columns: [
          {
            key: 'fieldName',
            title: i18next.t('FIELD_NAME')
          },
          {
            key: 'fieldValue',
            title: i18next.t('FIELD_VALUE')
          }
        ]
      }
    },
    header: {
      startWith: ['Open Positions', 'Header']
    },
    data: {
      startWith: ['Open Positions', 'Data'],
      columns: [
        {
          key: 'currency',
          title: i18next.t('CURRENCY')
        },
        {
          key: 'title',
          title: i18next.t('SYMBOL')
        },
        {
          key: 'quantity',
          title: i18next.t('QUANTITY')
        },
        {
          key: 'valuationInAssetLiabilityCurrency',
          title: i18next.t('VALUE')
        }
      ]
    },
    default: {
      type: CORE_TYPES.ASSET,
      subType: ASSET_TYPES.EQUITY_LISTED
    }
  },
  DBS: {
    name: CSV_TEMPLATES.DBS,
    header: {
      rowNumber: 0
    },
    data: {
      rows: { from: 1 },
      columns: [
        {
          key: 'currency',
          title: i18next.t('CCY')
        },
        {
          key: 'title',
          title: i18next.t('SECURITY_CODE')
        },
        {
          key: 'quantity',
          title: i18next.t('QUANTITY')
        },
        {
          key: 'valuationInAssetLiabilityCurrency',
          title: i18next.t('MARKET_VALUE')
        },
        {
          key: 'description',
          title: i18next.t('DESCRIPTION')
        },
        {
          key: 'valuationDate',
          title: i18next.t('LAST_PRICE_DATE')
        }
      ]
    },
    default: {
      type: CORE_TYPES.ASSET,
      subType: ASSET_TYPES.EQUITY_LISTED
    }
  },
  VB: {
    name: CSV_TEMPLATES.VB,
    header: {
      rowNumber: 0
    },
    data: {
      rows: { from: 1 },
      columns: getAssetLiabilityColumns().filter(
        col => !SAMPLE_TEMPLATE_EXCLUDED_COLUMNS.includes(col.key)
      )
    }
  }
}

export const getHeaderRow = (lines, mHeader) => {
  let headerRow
  if ('startWith' in mHeader) {
    const headerStart = mHeader.startWith
    if (typeof headerStart === 'string') {
      headerRow = lines.find(line => line[0] === headerStart)
    } else if (typeof headerStart === 'object' && headerStart.length) {
      headerRow = lines.find(line => {
        const lineStart = line.slice(0, headerStart.length)
        return isEqual(lineStart, headerStart)
      })
    }
  } else if ('rowNumber' in mHeader) {
    headerRow = [...lines[mHeader.rowNumber]]
  }
  return headerRow
}

export const getDataRows = (lines, mData) => {
  let dataRows = []
  if ('startWith' in mData) {
    const dataStart = mData.startWith
    if (typeof dataStart === 'string') {
      dataRows = lines.filter(line => line[0] === dataStart)
    } else if (typeof dataStart === 'object' && dataStart.length) {
      dataRows = lines.filter(line => {
        const lineStart = line.slice(0, dataStart.length)
        return isEqual(lineStart, dataStart)
      })
    }
  } else if ('rows' in mData) {
    dataRows = lines
      .slice(mData.rows.from, mData.rows.to || lines.length)
      .filter(line => line.some(col => !!col))
  }
  return dataRows
}

export const getColumnIndexes = (mDataCols, headerRow) => {
  return mDataCols.reduce((indexes, col) => {
    const value = headerRow.findIndex(header => header === col.title)
    return { ...indexes, [col.key]: value }
  }, {})
}

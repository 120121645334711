import React, { useEffect, useState, useContext } from 'react'
import { Collapse, Checkbox, Row, Col, Tooltip, message } from 'antd'
import Button from '../override/Button'
import { CORE_TYPES } from '../../share/Constants'
import api from '../../lib/api'
import VaultContext from '../../contexts/VaultContext'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse

export default function AssetLiabilityShowHideColumns({
  allColumns,
  selectedColumns,
  setSelectedColumns,
  defaultSelectedColumns,
  allDefaultColumns,
  setDefaultColumns,
  type
}) {
  const { userId } = useContext(VaultContext)
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [isSavingColumns, setIsSavingColumns] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIndeterminate(
      !!selectedColumns.length && selectedColumns.length < allColumns.length
    )
    setIsSelectedAll(selectedColumns.length === allColumns.length)
  }, [selectedColumns, allColumns])

  const onCheckboxChange = value => {
    setSelectedColumns(value)
  }

  const onSelectedAllChange = e => {
    setSelectedColumns(e.target.checked ? allColumns.map(c => c.key) : [])
  }

  const handleSaveDefaultColumns = () => {
    setIsSavingColumns(true)
    const data = {
      asset:
        type === CORE_TYPES.ASSET ? selectedColumns : allDefaultColumns.asset,
      liability:
        type === CORE_TYPES.LIABILITY
          ? selectedColumns
          : allDefaultColumns.liability,
      group: !type ? selectedColumns : allDefaultColumns.group
    }

    api
      .saveRegistryColumns(userId, data)
      .then(res => {
        message.success(t('SUCCESSFULLY_SAVED_COLUMNS'))
        setDefaultColumns(data)
      })
      .catch(err => {
        onError(err)
        message.error(t('FAILED_TO_SAVE_COLUMNS'))
      })
      .finally(() => {
        setIsSavingColumns(false)
      })
  }

  return (
    <Collapse style={{ marginBottom: 10 }}>
      <Panel header={t('SHOW_HIDE_COLUMNS')}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onSelectedAllChange}
          checked={isSelectedAll}
          style={{ marginBottom: 16 }}
        >
          {t('ALL')}
        </Checkbox>
        <Checkbox.Group
          indeterminate="false"
          style={{ width: '100%' }}
          onChange={onCheckboxChange}
          value={selectedColumns}
        >
          <Row>
            {allColumns.map(c => (
              <Col key={c.key} md={12} lg={8} xxl={6}>
                <Checkbox value={c.key}>{c.title}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
        <div style={{ marginTop: 16 }}>
          <Tooltip title={t('SAVE_COLUMNS_TOOLTIP')}>
            <Button
              onClick={handleSaveDefaultColumns}
              loading={isSavingColumns}
            >
              {t('SAVE_COLUMNS')}
            </Button>
          </Tooltip>
          <Tooltip title={t('RESET_TOOLTIP')}>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                setSelectedColumns(defaultSelectedColumns)
              }}
            >
              {t('RESET')}
            </Button>
          </Tooltip>
        </div>
      </Panel>
    </Collapse>
  )
}

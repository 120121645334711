import React, { useState, useContext, useEffect } from 'react'
import { Modal, Icon, Radio, message } from 'antd'
import { H4, Span, StatusText } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../lib/api'
import { checkSelectedLevel } from '../../share/helpers'
import { ACCESS_LEVEL } from './../../share/Constants'
import { fetchAccessLevel } from './../../features/settings/settingsSlice'

const ProfessionalDeputyAccessLevelModal = props => {
  const theme = useContext(ThemeContext)
  const { user } = props
  const { accessLevel } = useSelector(state => state.settings)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [level, setLevel] = useState()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setLevel(accessLevel || ACCESS_LEVEL.DENY)
  }, [accessLevel])

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await api.updateAccessLevel(
        user.username,
        JSON.stringify({
          accessLevel: level
        })
      )

      dispatch(fetchAccessLevel(user.username))
      setIsSaving(false)
      handleCancel()
    } catch (error) {
      setIsSaving(false)
      message.error(t('FAILED_TO_UPDATE_ACCESS_LEVEL'))
    }
  }

  const handleCancel = () => {
    setVisible(false)
    setLevel(accessLevel || ACCESS_LEVEL.DENY)
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H4>
          <Span>{t('PROFESSIONAL_DEPUTY_ACCESS_LEVEL')}</Span>
        </H4>
        <Span
          color={theme.primary}
          style={{ cursor: 'pointer' }}
          onClick={() => setVisible(true)}
        >
          <Icon type="login" style={{ marginRight: 8 }} />
          {t('CHANGE_ACCESS_LEVEL')}
        </Span>
      </div>
      <StatusText color={theme.dark2}>
        {t('PROFESSIONAL_DEPUTY_ACCESS_LEVEL')}:{' '}
        <b>{checkSelectedLevel(accessLevel)}</b>
      </StatusText>
      <Modal
        width={550}
        visible={visible}
        title={t('CHANGE_ACCESS_LEVEL')}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleSave}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isSaving,
          disabled: level === accessLevel
        }}
        maskClosable={false}
      >
        <div style={{ marginBottom: 10 }}>
          <Radio.Group
            name="accessLevel"
            value={level}
            onChange={e => setLevel(e.target.value)}
          >
            <Radio style={radioStyle} value={ACCESS_LEVEL.ALLOW}>
              {t('ALLOW')}
            </Radio>
            <Radio style={radioStyle} value={ACCESS_LEVEL.NEED_APPROVAL}>
              {t('NEED_APPROVAL')}
            </Radio>
            <Radio style={radioStyle} value={ACCESS_LEVEL.DENY}>
              {t('DENY')}
            </Radio>
          </Radio.Group>
        </div>
      </Modal>
    </>
  )
}

export default ProfessionalDeputyAccessLevelModal

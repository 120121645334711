import React, { useState, useEffect, useContext } from 'react'
import { Checkbox, Icon, Modal } from 'antd'
import { H4, Span, StatusText } from '../override/Typography'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../lib/api'
import { fetchUser } from '../../features/user/userSlice'
import { onError } from '../../lib/sentry'
import { ThemeContext } from 'styled-components'

const MarketingMaterials = props => {
  const { user } = props
  const marketingFromVaultbox =
    useSelector(state => state.user).user.marketingFromVaultbox || false
  const marketingFromThirdParties =
    useSelector(state => state.user).user.marketingFromThirdParties || false
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [marketingFromVaultboxValue, setMarketingFromVaultboxValue] =
    useState(false)
  const [marketingFromThirdPartiesValue, setMarketingFromThirdPartiesValue] =
    useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [visible, setVisible] = useState(false)

  const theme = useContext(ThemeContext)

  useEffect(() => {
    setMarketingFromVaultboxValue(marketingFromVaultbox)
    setMarketingFromThirdPartiesValue(marketingFromThirdParties)
  }, [marketingFromVaultbox, marketingFromThirdParties])

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await api.updateMarketingMaterials(
        user.username,
        JSON.stringify({
          marketingFromVaultbox: marketingFromVaultboxValue,
          marketingFromThirdParties: marketingFromThirdPartiesValue
        })
      )

      dispatch(fetchUser(user.username))
      setIsSaving(false)
      setVisible(false)
    } catch (error) {
      onError(error)
    }
  }

  const handleCancel = () => {
    setMarketingFromVaultboxValue(marketingFromVaultbox)
    setMarketingFromThirdPartiesValue(marketingFromThirdParties)
    setVisible(false)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H4>
          <Span>{t('MARKETING_MATERIALS')}</Span>
        </H4>
        <Span
          color={theme.primary}
          style={{ cursor: 'pointer' }}
          onClick={() => setVisible(true)}
        >
          <Icon type="setting" style={{ marginRight: 8 }} />
          {t('CHANGE')}
        </Span>
      </div>
      <StatusText color={theme.dark2}>
        <Trans i18nKey="MARKETING_MATERIALS_STATUS_TEXT"></Trans>
      </StatusText>
      <Modal
        visible={visible}
        title={t('MARKETING_MATERIALS')}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleSave}
        onCancel={handleCancel}
        okButtonProps={{
          disabled:
            marketingFromVaultbox === marketingFromVaultboxValue &&
            marketingFromThirdParties === marketingFromThirdPartiesValue,
          loading: isSaving
        }}
      >
        <div>
          <Checkbox
            checked={marketingFromVaultboxValue}
            onChange={e => setMarketingFromVaultboxValue(e.target.checked)}
          >
            <Trans i18nKey="MARKETING_CONSENT_FROM_VAULTBOX"></Trans>
          </Checkbox>
        </div>
        <div>
          <Checkbox
            checked={marketingFromThirdPartiesValue}
            onChange={e => setMarketingFromThirdPartiesValue(e.target.checked)}
          >
            <Trans i18nKey="MARKETING_CONSENT_FROM_THIRT_PARTY"></Trans>
          </Checkbox>
        </div>
      </Modal>
    </>
  )
}

export default MarketingMaterials

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from 'react'
import {
  Alert,
  Steps,
  Row,
  Col,
  Descriptions,
  Checkbox,
  message,
  Modal,
  Radio
} from 'antd'
//import { StringResources } from '../../share/StringResources'
import AddMultipleFields from '../common/AddMultipleFields'
import FormItem from '../override/FormItem'
import { P, H4 } from '../override/Typography'
import watigaTrustLogo from '../../assets/watiga-logo.jpeg'
import WrappedForm from '../common/WrappedForm'
import { uniqBy, flatten } from 'lodash'
import api from '../../lib/api'
import { useDispatch } from 'react-redux'
import {
  fetchDeputies,
  distributeShareKeys
} from '../../features/deputies/deputiesSlice'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import Button from '../override/Button'
import { ThemeContext } from 'styled-components'
import NodeRSA from 'node-rsa'
import { s3Put, s3Get } from '../../lib/awsSDK'
import {
  compareWithCurrentUserEmail,
  compareWithProfessionalDeputyEmails
} from '../../share/formHelpers'
import { randomBytes } from 'crypto'
import { encryptFilePromise, decryptFilePromise } from '../../lib/crypto'
import { AES } from 'crypto-js'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import config from '../../config'
import UserDetailForm from './UserDetailForm'
import { onError } from '../../lib/sentry'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import TextInput from '../common/TextInput'
import {
  getFileContent,
  getExternalUserAttributes,
  sanitizeValue
} from './../../share/helpers'
import { revokeDeputy } from '../../features/deputies/deputiesSlice'
const utf8 = require('utf8')

const DEPUTY_TYPES = {
  WATIGA: 'watiga',
  OTHER: 'other',
  NORMAL: 'normal',
  SEARCH: 'search'
}

const getPdEmails = (professionalDeputy, userId) => [
  ...(professionalDeputy.accounts?.map(d => d.email) || []),
  ...(professionalDeputy.delegatedAccounts
    // filter out the account with userId same as current user,
    // just in case the current user is also 1 of the delegated accounts
    ?.filter(dpd => dpd.userId !== userId && dpd.isAccepted)
    .map(da => da.email) || [])
]

function SpecifyDeputyModal(props) {
  const {
    existingEmails,
    visible,
    setVisible,
    userInfo,
    deputies,
    isTransfer,
    requestedDeputy
  } = props

  const dispatch = useDispatch()
  const { user } = useContext(AuthContext)
  const { masterKey, fullName } = useContext(VaultContext)
  const theme = useContext(ThemeContext)

  const [currentStep, setCurrentStep] = useState(0)
  const [type, setType] = useState(DEPUTY_TYPES.WATIGA)
  const [addedEmails, setAddedEmails] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [errMsg, setErrMsg] = useState('')
  const [hasAgreed, setHasAgreed] = useState(false)
  const [idPassportData, setIdPassportData] = useState({})
  const [addressProofData, setAddressProofData] = useState({})
  const [step1Loading, setStep1Loading] = useState(false)
  const [step1Error, setStep1Error] = useState('')
  const [step3Error, setStep3Error] = useState('')
  const [professionalDeputies, setProfessionalDeputies] = useState([])
  const [defaultProfessionalDeputy, setDefaultProfessionalDeputy] = useState({})
  const [otherProfessionalDeputies, setOtherProfessionalDeputies] = useState([])
  const [otherProfessionalDeputy, setOtherProfessionalDeputy] = useState({})
  const [professionalDeputyEmails, setProfessionalDeputyEmails] = useState([])
  const [otherProfessionalDeputyId, setOtherProfessionalDeputyId] = useState('')
  const [selectedOtherProfessionalDeputy, setSelectedOtherProfessionalDeputy] =
    useState({})
  const { sharesThreshold, mainUserId } = useSelector(state => state.user).user
  const { t } = useTranslation()
  const externalUser = localStorage.getItem('External_User')

  const { Step } = Steps

  const userDetailFormRef = useRef()
  let formRef

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userId = user.username

        const detailsRes = await s3Get(
          userId,
          `identification/details`,
          {},
          { responseType: 'blob' }
        )

        const data = await decryptFilePromise(detailsRes, masterKey)
        const userDetails = JSON.parse(
          utf8.decode(String.fromCharCode(...data))
        )

        if (userDetails.addressProof && userDetails.addressProofFileId) {
          const addressProofContent = await getFileContent(
            userId,
            userDetails.addressProofFileId,
            masterKey
          )

          if (addressProofContent) {
            setAddressProofData({
              id: userDetails.addressProofFileId,
              name: userDetails.addressProof,
              content: addressProofContent
            })
          }
        }

        if (userDetails.idPassport && userDetails.idPassportFileId) {
          const idPassportContent = await getFileContent(
            userId,
            userDetails.idPassportFileId,
            masterKey
          )

          if (idPassportContent) {
            setIdPassportData({
              id: userDetails.idPassportFileId,
              name: userDetails.idPassport,
              content: idPassportContent
            })
          }
        }

        setUserDetails(userDetails)
      } catch (error) {
        setErrMsg(t('FAILDED_TO_LOAD_USER_DETAILS'))
        onError(error)
      }
    }

    if (
      visible &&
      masterKey &&
      isTransfer &&
      requestedDeputy?.accounts?.length
    ) {
      fetchUserDetails()
    }
  }, [user, visible, masterKey, t, isTransfer, requestedDeputy])

  useEffect(() => {
    api
      .getProfessionalDeputies()
      .then(res => {
        if (res.data.message) throw Error(res.data.message)

        if (res.data?.length) {
          const professionalDeputies = res.data
          const defaultDeputy = professionalDeputies.find(
            item => item.isDefault
          )
          if (defaultDeputy) setDefaultProfessionalDeputy(defaultDeputy)

          const otherProfessionalDeputies = professionalDeputies.filter(
            item => !item.isDefault
          )
          setOtherProfessionalDeputies(otherProfessionalDeputies)

          const professionalDeputyEmails = professionalDeputies.reduce(
            (emails, pd) => emails.concat(pd.accounts?.map(a => a.email) || []),
            []
          )
          setProfessionalDeputyEmails(professionalDeputyEmails)
        }
      })
      .catch(err => onError(err))
  }, [])

  const fetchUserData = useCallback(() => {
    if (!user || !user.getUserData) return

    getUserData(
      user,
      async (err, data) => {
        if (err) {
          onError(err)
          return
        }
        const referredProfessionalDeputyId = getUserAttributeValue(
          data.UserAttributes,
          'custom:referred_pd'
        )
        if (referredProfessionalDeputyId) {
          const opd = otherProfessionalDeputies.find(
            deputy => deputy.id === referredProfessionalDeputyId
          )
          if (opd) setOtherProfessionalDeputy(opd)
          setType(DEPUTY_TYPES.OTHER)
        }
      },
      { bypassCache: true }
    )
  }, [user, otherProfessionalDeputies])

  const fetchExternalUserData = useCallback(() => {
    const userAttributes = getExternalUserAttributes()
    const referredProfessionalDeputyId = userAttributes.referred_pd
    if (referredProfessionalDeputyId) {
      const opd = otherProfessionalDeputies.find(
        deputy => deputy.id === referredProfessionalDeputyId
      )
      if (opd) setOtherProfessionalDeputy(opd)
      setType(DEPUTY_TYPES.OTHER)
    }
  }, [otherProfessionalDeputies])

  useEffect(() => {
    if (externalUser) {
      fetchExternalUserData()
    } else if (user) {
      fetchUserData()
    }
  }, [externalUser, fetchUserData, fetchExternalUserData, user])

  const compareWithExistingEmails = (rule, value, callback, existingEmails) => {
    if (value && existingEmails.find(d => d === value)) {
      callback(t('ENTER_A_NEW_EMAIL_WHICH_WAS_NOT_SPECIFIED_BEFORE'))
    } else {
      callback()
    }
  }

  const handleOk = async () => {
    setErrMsg('')
    setStep3Error('')

    const deputyData = () => {
      switch (type) {
        case DEPUTY_TYPES.WATIGA:
          return {
            emails: getPdEmails(defaultProfessionalDeputy, user.username),
            professionalDeputyId: defaultProfessionalDeputy.id
          }
        case DEPUTY_TYPES.OTHER:
          return {
            emails: getPdEmails(otherProfessionalDeputy, user.username),
            professionalDeputyId: otherProfessionalDeputy.id
          }
        case DEPUTY_TYPES.SEARCH:
          return {
            emails: getPdEmails(selectedOtherProfessionalDeputy, user.username),
            professionalDeputyId: selectedOtherProfessionalDeputy.id
          }
        default:
          return {
            emails: addedEmails.map(e => e.email),
            professionalDeputyId: undefined
          }
      }
    }

    const selectedDeputyData = deputyData()

    const requestDeputyData = {
      emails: selectedDeputyData.emails,
      professionalDeputyId: selectedDeputyData.professionalDeputyId,
      primaryUserName: userInfo.fullname,
      mainUserId
    }

    try {
      setIsSubmitting(true)
      if (isTransfer) {
        await Promise.all([
          dispatch(
            revokeDeputy(
              user.username,
              requestedDeputy,
              fullName,
              masterKey,
              true
            )
          )
        ])
      }

      if (professionalDeputies.length && type !== DEPUTY_TYPES.NORMAL) {
        let idPassportFileKeys = []
        let addressProofFileKeys = []

        await Promise.all(
          professionalDeputies.map(async deputy => {
            const { id, publicKey } = deputy
            const key = new NodeRSA()
            key.importKey(publicKey, 'public')

            let putPromises = []
            const encryptedUserDetails = key.encrypt(
              JSON.stringify(userDetails),
              'base64'
            )

            putPromises.push(
              s3Put(
                id,
                `identification/${user.username}/details`,
                encryptedUserDetails.toString()
              )
            )

            const idPassportFileKey = randomBytes(20).toString('hex')
            idPassportFileKeys.push({
              deputyId: id,
              key: key.encrypt(idPassportFileKey, 'base64')
            })

            const encryptedIdPassport = await encryptFilePromise(
              idPassportData.content,
              idPassportFileKey
            )

            putPromises.push(
              s3Put(
                id,
                `identification/${user.username}/idPassport`,
                encryptedIdPassport
              )
            )

            if (addressProofData.content) {
              const addressProofFileKey = randomBytes(20).toString('hex')
              addressProofFileKeys.push({
                deputyId: id,
                key: key.encrypt(addressProofFileKey, 'base64')
              })

              const encryptedAddressProof = await encryptFilePromise(
                addressProofData.content,
                addressProofFileKey
              )

              putPromises.push(
                s3Put(
                  id,
                  `identification/${user.username}/addressProof`,
                  encryptedAddressProof
                )
              )
            }

            await Promise.all(putPromises)
          })
        )
        const fileKeys = [
          {
            fileId: `identification/${user.username}/idPassport`,
            keys: idPassportFileKeys
          }
        ]
        if (addressProofFileKeys.length) {
          fileKeys.push({
            fileId: `identification/${user.username}/addressProof`,
            keys: addressProofFileKeys
          })
        }

        const fileKeysRes = await api.saveFileKeys(
          user.username,
          JSON.stringify({ fileKeys })
        )
        if (fileKeysRes.data.message) throw Error(fileKeysRes.data.message)

        const encryptedUserDetails = AES.encrypt(
          JSON.stringify({
            ...userDetails,
            idPassportFileId: idPassportData.id,
            addressProofFileId: addressProofData.id
          }),
          masterKey
        ).toString()
        await s3Put(
          user.username,
          `identification/details`,
          encryptedUserDetails
        )
      }

      const response = await api.requestDeputy(
        user.username,
        JSON.stringify(requestDeputyData)
      )

      if (response.data && response.data.success) {
        // distribute share keys to deputies if they are existing vaultbox users
        const res = await api.getUsersByEmails(
          requestDeputyData.emails.join(',')
        )

        const deputiesInfo = res?.data?.map(d => {
          return {
            userId: d.id,
            email: d.email,
            publicKey: d.publicKey
          }
        })

        if (deputiesInfo?.length) {
          dispatch(
            distributeShareKeys(
              user.username,
              masterKey,
              sharesThreshold,
              deputiesInfo
            )
          )
        }

        dispatch(fetchDeputies(user.username))
        setIsSubmitting(false)
        setCurrentStep(0)
        formRef && formRef.props.form.resetFields()
        setVisible(false)
        message.success(t('SUCCESSFULLY_SPECIFIED_DEPUTIES'))
      }
    } catch (err) {
      setIsSubmitting(false)
      setStep3Error(t('FAILED_TO_SPECIFY_DEPUTY'))
      onError(err)
    }
  }

  const handleAppointOtherPD = selectedProfessionalDeputyId => {
    setStep1Error('')
    setSelectedOtherProfessionalDeputy({})
    if (selectedProfessionalDeputyId) {
      const allProfessionalDeputies = flatten(
        otherProfessionalDeputies.concat(defaultProfessionalDeputy) || []
      )
      const selectedPD = allProfessionalDeputies.find(
        pd => pd.id === selectedProfessionalDeputyId
      )
      if (!selectedPD) {
        setStep1Error(t('PROFESSIONAL_DEPUTY_NOT_FOUND'))
        return
      }
      if (
        otherProfessionalDeputy.id
          ? selectedProfessionalDeputyId === otherProfessionalDeputy.id
          : selectedProfessionalDeputyId === defaultProfessionalDeputy.id
      ) {
        setStep1Error(t('THE_PROFESSIONAL_DEPUTY_IS_DEFAULT'))
        return
      }
      setSelectedOtherProfessionalDeputy(selectedPD)
    }
  }

  const handleStep2Next = () => {
    if (
      type === DEPUTY_TYPES.WATIGA ||
      type === DEPUTY_TYPES.OTHER ||
      type === DEPUTY_TYPES.SEARCH
    ) {
      userDetailFormRef.current.validateFields((err, values) => {
        if (err) return
        setUserDetails({ ...values })
        setCurrentStep(2)
      })
    } else if (type === DEPUTY_TYPES.NORMAL) {
      formRef.props.form.validateFields((err, values) => {
        if (err) return
        if (!values.emails?.length) {
          setErrMsg(t('PLEASE_ADD_AT_LEAST_ONE_DEPUTY'))
          return
        }
        setAddedEmails(uniqBy(values.emails, e => e.email))
        setCurrentStep(2)
      })
    }
  }

  const steps = [
    {
      title: t('TYPE'),
      content: (
        <>
          <div style={{ marginBottom: '1em' }}>
            <H4 style={{ marginBottom: '0.5em' }}>
              {t('SELECT_A_DEPUTY_TYPE')}:
            </H4>
            <Radio.Group
              onChange={e => {
                setType(e.target.value)
                setStep1Error('')
              }}
              value={type}
            >
              {otherProfessionalDeputy.id ? (
                <Radio value={DEPUTY_TYPES.OTHER}>
                  {otherProfessionalDeputy.professionalDeputyName}
                </Radio>
              ) : (
                <Radio value={DEPUTY_TYPES.WATIGA}>{t('WATIGA_TRUST')}</Radio>
              )}
              <Radio value={DEPUTY_TYPES.SEARCH}>
                {t('OTHER_PROFESSIONAL_DEPUTY')}
              </Radio>
              <Radio value={DEPUTY_TYPES.NORMAL}>
                {t('APPOINT_SOMEBODY_ELSE')}
              </Radio>
            </Radio.Group>
          </div>

          {type === DEPUTY_TYPES.WATIGA && (
            <Row gutter={20}>
              <Col span={8}>
                <img
                  src={watigaTrustLogo}
                  alt="Watiga Trust logo"
                  style={{ width: '100%' }}
                />
              </Col>
              <Col span={16}>
                <P>{t('WATIGA_TRUST_FIRST_SUMMARY')}</P>
                <P>
                  <Trans i18nKey="WATIGA_TRUST_SECOND_SUMMARY"></Trans>
                </P>
                <P>
                  <a
                    href="/wt-pd-terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('WATIGA_TRUST_TERMS_OF_SERVICE')}
                  </a>
                </P>
              </Col>
            </Row>
          )}
          {type === DEPUTY_TYPES.SEARCH && (
            <>
              <Row gutter={20}>
                <Col span={12}>
                  <FormItem>
                    <TextInput
                      value={otherProfessionalDeputyId}
                      onChange={e =>
                        setOtherProfessionalDeputyId(e.target.value)
                      }
                      onBlur={e => {
                        setOtherProfessionalDeputyId(
                          sanitizeValue(e.target.value).trim()
                        )
                      }}
                      placeholder={t('ENTER_PROFESSIONAL_DEPUTY_REFERRAL_CODE')}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <Button
                    onClick={() =>
                      handleAppointOtherPD(otherProfessionalDeputyId)
                    }
                    type="primary"
                    size="large"
                  >
                    Search
                  </Button>
                </Col>
              </Row>
              {selectedOtherProfessionalDeputy.id && (
                <Row gutter={20}>
                  <Col span={8}>
                    <div>
                      <b>
                        {selectedOtherProfessionalDeputy.professionalDeputyName}
                      </b>
                    </div>
                    <img
                      src={
                        selectedOtherProfessionalDeputy.id ===
                        defaultProfessionalDeputy.id
                          ? watigaTrustLogo
                          : `https://s3-${config.aws.REGION}.amazonaws.com/${config.s3.PUBLIC_BUCKET}/logo/${selectedOtherProfessionalDeputy.id}`
                      }
                      alt={
                        selectedOtherProfessionalDeputy.id ===
                        defaultProfessionalDeputy.id
                          ? 'Watiga Trust logo'
                          : 'Other Professional Deputy logo'
                      }
                      style={{ width: '100%' }}
                    />
                  </Col>
                  {selectedOtherProfessionalDeputy.id ===
                  defaultProfessionalDeputy.id ? (
                    <Col span={16}>
                      <P>{t('WATIGA_TRUST_FIRST_SUMMARY')}</P>
                      <P>
                        <Trans i18nKey="WATIGA_TRUST_SECOND_SUMMARY"></Trans>
                      </P>
                      <P>
                        <a
                          href="/wt-pd-terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('WATIGA_TRUST_TERMS_OF_SERVICE')}
                        </a>
                      </P>
                    </Col>
                  ) : (
                    <Col span={16}>
                      <P style={{ whiteSpace: 'pre-wrap' }}>
                        {selectedOtherProfessionalDeputy.description}
                      </P>
                      <P>
                        <a
                          href="/pd-terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('OTHER_PD_TERMS_OF_SERVICE')}
                        </a>
                      </P>
                    </Col>
                  )}
                </Row>
              )}
            </>
          )}
          {type === DEPUTY_TYPES.OTHER && (
            <Row gutter={20}>
              <Col span={8}>
                <img
                  src={`https://s3-${config.aws.REGION}.amazonaws.com/${config.s3.PUBLIC_BUCKET}/logo/${otherProfessionalDeputy.id}`}
                  alt="Other Professional Deputy logo"
                  style={{ width: '100%' }}
                />
              </Col>
              <Col span={16}>
                <P style={{ whiteSpace: 'pre-wrap' }}>
                  {otherProfessionalDeputy.description}
                </P>
                <P>
                  <a
                    href="/pd-terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('OTHER_PD_TERMS_OF_SERVICE')}
                  </a>
                </P>
              </Col>
            </Row>
          )}

          {step1Error && (
            <Alert
              type="error"
              message={step1Error}
              showIcon
              afterClose={() => setStep1Error('')}
              closable
              style={{ marginTop: '1em' }}
            />
          )}
        </>
      ),
      actions: (
        <div className="steps-actions">
          {/* <Button
            size="large"
            onClick={e => {
              setType(DEPUTY_TYPES.NORMAL)
              setCurrentStep(1)
            }}
          >
            Appoint somebody else
          </Button> */}
          <Button
            disabled={
              type === DEPUTY_TYPES.SEARCH &&
              !selectedOtherProfessionalDeputy?.id
            }
            size="large"
            type="primary"
            loading={step1Loading}
            onClick={async e => {
              try {
                if (
                  type === DEPUTY_TYPES.WATIGA ||
                  type === DEPUTY_TYPES.OTHER ||
                  type === DEPUTY_TYPES.SEARCH
                ) {
                  setStep1Error('')
                  setStep1Loading(true)
                  const selectedDeputy =
                    type === DEPUTY_TYPES.WATIGA
                      ? defaultProfessionalDeputy
                      : type === DEPUTY_TYPES.SEARCH
                      ? selectedOtherProfessionalDeputy
                      : otherProfessionalDeputy

                  if (!selectedDeputy.accounts?.length) {
                    setStep1Loading(false)
                    setStep1Error(
                      t('PROFESSIONAL_DEPUTY_SETUP_IS_NOT_YET_COMPLETED')
                    )
                    return
                  }

                  if (isTransfer && selectedDeputy.id === requestedDeputy.id) {
                    setStep1Loading(false)
                    setStep1Error(t('THE_PD_IS_WAITING_FOR_DISCONNECTION'))
                    return
                  }

                  const hasNormalDeputies = deputies?.some(
                    d => !d.professionalDeputyId
                  )

                  if (
                    hasNormalDeputies &&
                    (!isTransfer || deputies?.length > 1)
                  ) {
                    setStep1Loading(false)
                    setStep1Error(t('YOU_CANT_HAVE_BOTH_NOMARL_AND_PD_DEPUTY'))
                    return
                  }

                  const allProfessionalDeputyEmails = flatten(
                    otherProfessionalDeputies
                      .concat(defaultProfessionalDeputy)
                      ?.map(pd => pd.accounts?.map(a => a.email) || [])
                  )

                  if (
                    existingEmails.some(email =>
                      allProfessionalDeputyEmails.includes(email)
                    ) &&
                    !isTransfer
                  ) {
                    setStep1Loading(false)
                    setStep1Error(t('YOU_HAVE_ADDED_PD'))
                    return
                  }

                  const delegatedAccounts =
                    selectedDeputy.delegatedAccounts?.filter(
                      a => a.isAccepted
                    ) || []
                  const res = await api.getUsersByEmails(
                    [
                      ...selectedDeputy.accounts.map(a => a.email),
                      ...delegatedAccounts.map(a => a.email)
                    ].join(',')
                  )

                  const professionalDeputies = res.data
                  setStep1Loading(false)

                  if (
                    professionalDeputies.length <
                    selectedDeputy.accounts.length + delegatedAccounts.length
                  ) {
                    setStep1Error(t('PD_ACCOUNTS_ARE_NOT_YET_AVAILABLE'))
                    return
                  }

                  setProfessionalDeputies(professionalDeputies)
                  setCurrentStep(1)
                } else if (type === DEPUTY_TYPES.NORMAL) {
                  const hasProfessionalDeputies = deputies?.some(
                    d => d.professionalDeputyId
                  )
                  if (hasProfessionalDeputies && !isTransfer) {
                    setStep1Loading(false)
                    setStep1Error(t('YOU_CANT_HAVE_BOTH_PD_AND_NOMARL_DEPUTY'))
                    return
                  }

                  setCurrentStep(1)
                }
              } catch (err) {
                setStep1Loading(false)
                onError(err)
              }
            }}
          >
            {t('NEXT')}
          </Button>
        </div>
      )
    },
    {
      title: t('DETAILS'),
      content:
        type === DEPUTY_TYPES.WATIGA ||
        type === DEPUTY_TYPES.OTHER ||
        type === DEPUTY_TYPES.SEARCH ? (
          <>
            {type === DEPUTY_TYPES.WATIGA && (
              <>
                <P style={{ marginBottom: '1em' }}>
                  {t('WATIGA_TRUST_DETAIL_FIRST_SUMMARY')}
                </P>
                <P style={{ marginBottom: '1em' }}>
                  {t('WATIGA_TRUST_DETAIL_SECOND_SUMMARY')}:
                </P>
              </>
            )}
            {(type === DEPUTY_TYPES.OTHER || type === DEPUTY_TYPES.SEARCH) && (
              <P style={{ marginBottom: '1em' }}>
                <Trans
                  i18nKey="OTHER_PD_SUMMARY"
                  values={{
                    professionalDeputyName:
                      type === DEPUTY_TYPES.SEARCH
                        ? selectedOtherProfessionalDeputy.professionalDeputyName
                        : otherProfessionalDeputy.professionalDeputyName
                  }}
                ></Trans>
              </P>
            )}

            <UserDetailForm
              ref={userDetailFormRef}
              userInfo={userInfo}
              userDetails={userDetails}
              idPassportData={idPassportData}
              setIdPassportData={setIdPassportData}
              addressProofData={addressProofData}
              setAddressProofData={setAddressProofData}
            />

            {type === DEPUTY_TYPES.WATIGA && (
              <P>
                <Trans i18nKey="WATIGA_TRUST_DETAIL_THIRD_SUMMARY">
                  We will store this information and files in your{' '}
                  <b>vaultbox</b> and transmit to Watiga Trust securely. Watiga
                  Trust will keep your information confidential and use them for
                  due diligence verification purposes in accordance with{' '}
                  <a
                    href="/wt-pd-terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Watiga Trust Professional Deputy Terms of Service
                  </a>
                  . If the documents or information are inaccurate or
                  incomplete, Watiga Trust may contact you to verify that.
                </Trans>
              </P>
            )}
          </>
        ) : (
          <>
            <P style={{ marginBottom: 20 }}>
              <Trans i18nKey="NORMAL_DEPUTY_SUMMARY"></Trans>
            </P>

            <WrappedForm wrappedComponentRef={fr => (formRef = fr)}>
              {getFieldDecorator => (
                <FormItem label={t('EMAIL_ADDRESS_OF_DEPUTY')}>
                  <AddMultipleFields
                    name="emails"
                    title={t('DEPUTY')}
                    fields={[
                      { key: 'email', placeholder: 'email@example.com' }
                    ]}
                    getFieldDecorator={getFieldDecorator}
                    value={addedEmails}
                    customRules={[
                      {
                        fieldKey: 'email',
                        rules: [
                          {
                            type: 'email',
                            message: t('INVALID_EMAIL_MSG')
                          },
                          {
                            validator: (rule, value, callback) =>
                              compareWithExistingEmails(
                                rule,
                                value,
                                callback,
                                existingEmails
                              )
                          },
                          {
                            validator: (rule, value, callback) =>
                              compareWithProfessionalDeputyEmails(
                                rule,
                                value,
                                callback,
                                professionalDeputyEmails
                              )
                          },
                          {
                            validator: (rule, value, callback) =>
                              compareWithCurrentUserEmail(
                                rule,
                                value,
                                callback,
                                userInfo.email
                              )
                          }
                        ]
                      }
                    ]}
                  />
                </FormItem>
              )}
            </WrappedForm>
            {errMsg && (
              <Alert
                message={errMsg}
                type="error"
                closable
                afterClose={() => setErrMsg('')}
              />
            )}
          </>
        ),
      actions: (
        <div className="steps-actions">
          <Button size="large" onClick={e => setCurrentStep(0)}>
            {t('BACK')}
          </Button>
          <Button size="large" type="primary" onClick={handleStep2Next}>
            {t('NEXT')}
          </Button>
        </div>
      )
    },
    {
      title: t('REVIEW'),
      content: (
        <>
          {type === DEPUTY_TYPES.WATIGA ||
          type === DEPUTY_TYPES.OTHER ||
          type === DEPUTY_TYPES.SEARCH ? (
            <>
              <div style={{ marginBottom: '1em' }}>
                <H4 display="inline-block">{t('DEPUTY')}:</H4>
                <H4
                  display="inline-block"
                  style={{ marginLeft: '0.5em', color: theme.dark1 }}
                >
                  {type === DEPUTY_TYPES.WATIGA
                    ? 'Watiga Trust'
                    : type === DEPUTY_TYPES.SEARCH
                    ? selectedOtherProfessionalDeputy.professionalDeputyName
                    : otherProfessionalDeputy.professionalDeputyName}
                </H4>
              </div>
              <H4 style={{ marginBottom: '1em' }}>{t('DETAILS')}:</H4>
              <Descriptions column={1}>
                <Descriptions.Item label={t('FULLNAME')}>
                  {userDetails.fullname}
                </Descriptions.Item>
                <Descriptions.Item label={t('CONTACT_NUMBER')}>
                  {userDetails.contactNumberPrefix} {userDetails.contactNumber}
                </Descriptions.Item>
                <Descriptions.Item label={t('RESIDENTIAL_ADDRESS')}>
                  {userDetails.address}
                </Descriptions.Item>
                <Descriptions.Item label={t('NATIONALITY')}>
                  {userDetails.nationality}
                </Descriptions.Item>
                <Descriptions.Item label={t('ID_PASSPORT_NUMBER')}>
                  {userDetails.idPassportNumber}
                </Descriptions.Item>
                <Descriptions.Item label={t('DOCUMENTS_UPLOADED')}>
                  <span>{idPassportData.name}</span>
                  {addressProofData.name && (
                    <span>, {addressProofData.name}</span>
                  )}
                </Descriptions.Item>
                {userDetails.idContainsAddress && (
                  <Descriptions.Item>
                    {t('MY_ID_CONTAINS_MY_RESIDENTIAL_ADDRESS')}
                  </Descriptions.Item>
                )}
              </Descriptions>
              {(type === DEPUTY_TYPES.WATIGA ||
                selectedOtherProfessionalDeputy.id ===
                  defaultProfessionalDeputy.id) && (
                <Checkbox
                  onChange={e => setHasAgreed(e.target.checked)}
                  checked={hasAgreed}
                >
                  <Trans i18nKey="WATIGA_TRUST_REVIEW_SUMMARY">
                    I agree to appoint Watiga Trust as my Deputy in accordance
                    with the{' '}
                    <a
                      href="/wt-pd-terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Watiga Trust Professional Deputy Terms of Service
                    </a>
                  </Trans>
                </Checkbox>
              )}
              {(type === DEPUTY_TYPES.OTHER || type === DEPUTY_TYPES.SEARCH) &&
                selectedOtherProfessionalDeputy.id !==
                  defaultProfessionalDeputy.id && (
                  <Checkbox
                    onChange={e => setHasAgreed(e.target.checked)}
                    checked={hasAgreed}
                  >
                    <Trans
                      i18nKey="OTHER_PD_REVIEW_SUMMARY"
                      values={{
                        professionalDeputyName:
                          type === DEPUTY_TYPES.OTHER
                            ? otherProfessionalDeputy.professionalDeputyName
                            : selectedOtherProfessionalDeputy.professionalDeputyName
                      }}
                    >
                      I agree to appoint{' '}
                      {type === DEPUTY_TYPES.OTHER
                        ? otherProfessionalDeputy.professionalDeputyName
                        : selectedOtherProfessionalDeputy.professionalDeputyName}
                      as my Deputy in accordance with the{' '}
                      <a
                        href="/pd-terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Professional Deputy Terms of Service
                      </a>
                    </Trans>
                  </Checkbox>
                )}
            </>
          ) : (
            <>
              <H4 style={{ marginBottom: '0.5em' }}>
                {t('EMAIL_ADDRESS_OF_DEPUTY')}:
              </H4>
              <ul style={{ color: theme.dark }}>
                {addedEmails?.map(record => (
                  <li key={record.email}>{record.email}</li>
                ))}
              </ul>
              <P>{t('WE_WILL_EMAIL_YOUR_DEPUTY_FOR_THEIR_ACCEPTANCE')}</P>
            </>
          )}
          {step3Error && (
            <Alert
              message={step3Error}
              type="error"
              closable
              afterClose={() => setStep3Error('')}
            />
          )}
        </>
      ),
      actions: (
        <div className="steps-actions">
          <Button size="large" onClick={e => setCurrentStep(1)}>
            {t('BACK')}
          </Button>
          <Button
            size="large"
            type="primary"
            loading={isSubmitting}
            onClick={handleOk}
            disabled={
              (type === DEPUTY_TYPES.WATIGA ||
                type === DEPUTY_TYPES.OTHER ||
                type === DEPUTY_TYPES.SEARCH) &&
              !hasAgreed
            }
          >
            {t('SUBMIT')}
          </Button>
        </div>
      )
    }
  ]

  const handleCancel = e => {
    formRef && formRef.props.form.resetFields()
    setErrMsg('')
    setStep1Error('')
    setStep3Error('')
    setCurrentStep(0)
    setVisible(false)
    setIdPassportData({})
    setAddressProofData({})
  }

  return (
    <Modal
      title={t('ADD_A_DEPUTY')}
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      width="80vw"
      className="add-deputy-modal"
      footer={null}
    >
      <Steps direction="horizontal" current={currentStep}>
        {steps.map(step => (
          <Step title={step.title} key={step.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ margin: '30px 0' }}>
        {steps[currentStep].content}
      </div>
      {steps[currentStep].actions}
    </Modal>
  )
}

export default SpecifyDeputyModal

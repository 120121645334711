import React, { useContext } from 'react'
import { CORE_TYPES, ASSET_TYPES, LIABILITY_TYPES } from '../../share/Constants'
import CustomIcon from '../override/Icon'
import { Icon } from 'antd'
import { P } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Flex from '../override/Flex'
import { useTranslation } from 'react-i18next'

export const assetSubtypes = [
  {
    name: ASSET_TYPES.CASH,
    color: 'secondaryRed',
    background: 'dark4',
    icon: style => <CustomIcon type="cash" style={style} />
  },
  {
    name: ASSET_TYPES.PROPERTY,
    color: 'secondaryGreenMedium',
    background: 'dark4',
    icon: style => <CustomIcon type="realEstate" style={style} />
  },
  {
    name: ASSET_TYPES.VEHICLE,
    color: 'darkYellow',
    background: 'dark4',
    icon: style => <CustomIcon type="vehicle" style={style} />
  },
  {
    name: ASSET_TYPES.BROKERAGE_ACCOUNT,
    color: 'red',
    background: 'dark4',
    icon: style => <Icon type="team" style={style} />
  },
  {
    name: ASSET_TYPES.EQUITY_LISTED,
    color: 'secondaryOrange',
    background: 'dark4',
    icon: style => <CustomIcon type="equity" style={style} />
  },
  {
    name: ASSET_TYPES.EQUITY_UNLISTED,
    color: 'secondaryLightGreen',
    background: 'dark4',
    icon: style => <CustomIcon type="equity" style={style} />
  },
  {
    name: ASSET_TYPES.INSURANCE,
    color: 'secondaryCyan',
    background: 'dark4',
    icon: style => <CustomIcon type="insurance" style={style} />
  },
  {
    name: ASSET_TYPES.LOAN_TO_OTHERS,
    color: 'secondaryPurple',
    background: 'dark4',
    icon: style => <CustomIcon type="loan" style={style} />
  },
  {
    name: ASSET_TYPES.RETIREMENT_ACCOUNT,
    color: 'primaryDark',
    background: 'dark4',
    icon: style => <CustomIcon type="retirement" style={style} />
  },
  {
    name: ASSET_TYPES.CRYPTO,
    color: 'darkBlue',
    background: 'dark4',
    icon: style => <Icon type="dollar" style={style} />
  },
  {
    name: ASSET_TYPES.TRUST,
    color: 'darkPurple',
    background: 'dark4',
    icon: style => <Icon type="safety" style={style} />
  },
  {
    name: ASSET_TYPES.OTHER_ASSETS,
    color: 'secondaryBlue',
    background: 'dark4',
    icon: style => <CustomIcon type="general" style={style} />
  }
]

export const liabilitySubtypes = [
  {
    name: LIABILITY_TYPES.PROPERTY_LOAN,
    color: 'secondaryGreenMedium',
    background: 'dark4',
    icon: style => <CustomIcon type="mortgage" style={style} />
  },
  {
    name: LIABILITY_TYPES.VEHICLE_LOAN,
    color: 'darkYellow',
    background: 'dark4',
    icon: style => <CustomIcon type="vehicle" style={style} />
  },
  {
    name: LIABILITY_TYPES.CREDIT_CARD,
    color: 'secondaryRed',
    background: 'dark4',
    icon: style => <Icon type="credit-card" style={style} />
  },
  {
    name: LIABILITY_TYPES.LOAN_FROM_OTHERS,
    color: 'secondaryPurple',
    background: 'dark4',
    icon: style => <CustomIcon type="loan" style={style} />
  },
  {
    name: LIABILITY_TYPES.OTHER_LIABILITY,
    color: 'secondaryBlue',
    background: 'dark4',
    icon: style => <CustomIcon type="general" style={style} />
  }
]

export function AssetLiabilitySubtypeIcon({
  subtype,
  size,
  iconSize,
  extraStyle
}) {
  const theme = useContext(ThemeContext)

  return (
    <div
      style={{
        borderRadius: '50%',
        width: size,
        height: size,
        minWidth: size,
        backgroundColor: size <= 16 ? 'transparent' : theme[subtype.background],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(extraStyle || {})
      }}
    >
      {subtype.icon({
        color: theme[subtype.color],
        fontSize: iconSize
      })}
    </div>
  )
}

export function AssetLiabilityTitleWithIcon({ record }) {
  const theme = useContext(ThemeContext)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const allSubtypes = [...assetSubtypes, ...liabilitySubtypes]
  const subtypeMap = allSubtypes.find(subtype =>
    record.subType
      ? subtype.name === record.subType
      : subtype.name === ASSET_TYPES.OTHER_ASSETS ||
        subtype.name === LIABILITY_TYPES.OTHER_LIABILITY
  )
  return (
    <div className="asset-liability-title">
      <Flex>
        <AssetLiabilitySubtypeIcon
          subtype={subtypeMap}
          size={isSmUp ? 36 : 16}
          iconSize={16}
        />
        <span className="main-title">{record.title}</span>
      </Flex>
    </div>
  )
}

export default function AssetLiabilitySubtypes({
  className,
  selectedType,
  selectedSubType,
  setSelectedSubType,
  form
}) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const handleChangeSubtype = (subtypeName) => {
    if((selectedSubType === ASSET_TYPES.CRYPTO || subtypeName === ASSET_TYPES.CRYPTO) && selectedSubType !== subtypeName){
      form.setFieldsValue({
        currency: ''
      })
    }
    setSelectedSubType(subtypeName)
  }

  return (
    <div className={className}>
      {(selectedType === CORE_TYPES.ASSET
        ? assetSubtypes
        : liabilitySubtypes
      ).map(subtype => (
        <div
          className="subtype-container"
          style={{
            display: 'inline-block',
            cursor: 'pointer',
            marginBottom: 50
          }}
          key={subtype.name}
          onClick={() => handleChangeSubtype(subtype.name)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <AssetLiabilitySubtypeIcon
              subtype={subtype}
              size={64}
              iconSize={20}
              theme={theme}
              extraStyle={{
                margin: '0 25px 16px',
                border:
                  selectedSubType === subtype.name
                    ? `3px solid ${theme.darkBg}`
                    : 'none'
              }}
            />
            <P
              style={{
                textAlign: 'center',
                color:
                  selectedSubType === subtype.name ? theme.primary : theme.dark,
                width: 115
              }}
            >
              {t(subtype.name)}
            </P>
          </div>
        </div>
      ))}
    </div>
  )
}

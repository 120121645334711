import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Select, Tooltip } from 'antd'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import ContactModal from '../contacts/ContactModal'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { ACCESS_LEVEL } from './../../share/Constants'

export default function ContactSelect({
  label,
  placeholder,
  required,
  getFieldDecorator,
  contactType,
  fieldName,
  initialValue,
  onDeselect,
  mode,
  onAddComplete,
  disabled
}) {
  const { isReadonly } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { activeContacts, pendingContacts } = useSelector(state =>
    isProfessionalDeputy || (isDelegateByPD && isReadonly)
      ? state.otherContacts
      : state.contacts
  )
  const { accessLevel } = useSelector(state => state.settings)
  const [contactModalVisibile, setContactModalVisibile] = useState(false)
  const { t } = useTranslation()

  const data =
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? [...activeContacts, ...pendingContacts]
      : activeContacts
  const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div>
      <FormItem label={label}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 'calc(100% - 58px)' }}>
            {getFieldDecorator(fieldName, {
              initialValue,
              rules: [
                {
                  required: required,
                  message: required && label && `${t('ENTER')} ${label.toLowerCase()}`
                }
              ]
            })(
              <Select
                mode={mode}
                placeholder={
                  placeholder || `${t('SELECT')} ${label.toLowerCase()}`
                }
                allowClear={!mode}
                showSearch
                optionFilterProp="children"
                showAction={['click', 'focus']}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(input.trim().toLowerCase())
                }
                onDeselect={onDeselect}
                disabled={disabled}
              >
                {sortedData
                  .filter(
                    contact => !contactType || contact.type === contactType
                  )
                  .map(contact => (
                    <Select.Option
                      disabled={!isReadonly && !!contact.status}
                      key={contact._id}
                      value={contact._id}
                    >
                      {contact.name}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </div>
          <Tooltip title={t('ADD_NEW')}>
            <Button
              size="large"
              onClick={() => setContactModalVisibile(true)}
              className={`${label ? '' : 'no-label'}`}
              style={{ marginLeft: 8 }}
              icon="plus"
              disabled={disabled}
            />
          </Tooltip>
        </div>
      </FormItem>
      <ContactModal
        visible={contactModalVisibile}
        setVisible={setContactModalVisibile}
        selectedRecord={{}}
        onAddComplete={onAddComplete}
        contactType={contactType}
      />
    </div>
  )
}

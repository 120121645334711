import React, { useContext } from 'react'
import { Table, Empty, message } from 'antd'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { AES, enc } from 'crypto-js'
import NodeRSA from 'node-rsa'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import api from '../../lib/api'
import Button from '../override/Button'
import { ThemeContext } from 'styled-components'
import sss from 'shamirs-secret-sharing'
import { randomBytes } from 'crypto'
import { s3Get, s3Put } from '../../lib/awsSDK'
import TableHeader from '../common/TableHeader'
import { decryptFilePromise } from '../../lib/crypto'
import { onError } from '../../lib/sentry'
import {
  addPDNameColumn,
  getExternalUserAttributes
} from './../../share/helpers'
import { useSelector } from 'react-redux'
import { flatten } from 'lodash'
import { useTranslation } from 'react-i18next'

export default function Requests({
  professionalDeputies,
  resetRequests,
  resetRequestsLoading,
  setResetRequestsLoading,
  unlockRequests,
  unlockRequestsLoading,
  setUnlockRequestLoading,
  updateRequests,
  updateRequestsLoading,
  fetchUpdateRequests,
  fetchResetRequests,
  fetchUnlockRequests
}) {
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const externalUser = localStorage.getItem('External_User')
  const { delegatedByProfessionalDeputies } = useSelector(
    state => state.user
  ).user
  const { t } = useTranslation()

  const resetRequestsColumns = [
    {
      title: t('FROM'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('APPROVAL_SATUS'),
      key: 'approvalStatus',
      render: (text, record) =>
        `${record.recoverShares?.length || 0}/${record.sharesThreshold || 2}`
    },
    {
      key: 'action',
      render: (text, record) => (
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => handleApprove(record)}>{t('APPROVE')}</Button>
          <Button
            onClick={() => handleResetRequest(false, record.key, record.email)}
            style={{ color: theme.red, marginLeft: 8 }}
          >
            {t('REJECT')}
          </Button>
        </div>
      )
    }
  ]

  const updateRequestsColumns = [
    {
      title: t('FROM'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('NEW_PHONE_NUMBER_DEPUTY'),
      key: 'newPhoneNumber',
      render: (text, record) =>
        `${record.newPhoneNumber.prefix}${record.newPhoneNumber.phone}`
    },
    {
      key: 'action',
      render: (text, record) => (
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => {
              const prefix = record.newPhoneNumber.prefix.replace(/ /g, '')
              const phoneNumber = `${prefix}${record.newPhoneNumber.phone}`
              handleUpdateRequest(
                true,
                record.key,
                record.email,
                phoneNumber,
                prefix
              )
            }}
          >
            {t('APPROVE')}
          </Button>
          <Button
            onClick={() => handleUpdateRequest(false, record.key)}
            style={{ color: theme.red, marginLeft: 8 }}
          >
            {t('REJECT')}
          </Button>
        </div>
      )
    }
  ]

  const unlockRequestsColumns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('FROM'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      key: 'action',
      render: (text, record) => (
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => handleUnlockRequest(record, true)}>
            {t('APPROVE')}
          </Button>
          <Button
            onClick={() => handleUnlockRequest(record)}
            style={{ color: theme.red, marginLeft: 8 }}
          >
            {t('REJECT')}
          </Button>
        </div>
      )
    }
  ]

  if (delegatedByProfessionalDeputies?.length) {
    addPDNameColumn(
      unlockRequestsColumns,
      professionalDeputies,
      delegatedByProfessionalDeputies
    )
    addPDNameColumn(
      updateRequestsColumns,
      professionalDeputies,
      delegatedByProfessionalDeputies
    )
    addPDNameColumn(
      resetRequestsColumns,
      professionalDeputies,
      delegatedByProfessionalDeputies
    )
  }

  const handleUpdateRequest = (
    isApproved,
    primaryUserId,
    primaryUserEmail?,
    newPhoneNumber?,
    prefix?
  ) => {
    const handleData = {
      isApproved,
      primaryUserId,
      primaryUserEmail,
      newPhoneNumber,
      prefix
    }

    api
      .handleUpdatePhoneRequest(user.username, JSON.stringify(handleData))
      .then(response => {
        if (response.data && response.data.success) {
          message.success(
            isApproved ? t('REQUEST_APPROVED') : t('REQUEST_REJECTED')
          )
          fetchUpdateRequests()
        }
      })
      .catch(err => {
        message.error(
          isApproved
            ? t('FAILED_TO_APPROVE_REQUEST')
            : t('FAILED_TO_REJECT_REQUEST')
        )
        console.log('Failed to handle reset request: ', err)
        onError(err)
      })
  }

  const handleApprove = record => {
    const onApprove = encryptedPrivateKey => {
      // decrypt private key with current master key
      const privateKey = AES.decrypt(encryptedPrivateKey, masterKey).toString(
        enc.Latin1
      )

      const key = new NodeRSA()

      // decrypt current user's shareKey
      key.importKey(privateKey, 'pkcs8')
      const currentDeputyShare = key.decrypt(record.shareKey, 'ascii')

      // if recoverShares includes a share from another deputy, then:
      // - decrypt that share with current user's private key
      // - get masterKey by combining 2 pieces
      if (record.recoverShares?.length === (record.sharesThreshold || 2) - 1) {
        // const encryptedOtherDeputyShare =
        //   record.recoverShares[0]?.shares[0]?.share
        // const otherDeputyShare = key.decrypt(encryptedOtherDeputyShare, 'ascii')

        const shares = flatten(record.recoverShares?.map(s => s.shares)).filter(
          sh => sh.deputyId === user.username
        )

        const otherDeputyShares = shares.map(s => key.decrypt(s.share, 'ascii'))

        const masterKey = sss
          .combine([...otherDeputyShares, currentDeputyShare])
          .toString()

        // encrypt raw master key with generated temp password
        const tempPassword = randomBytes(8).toString('hex')
        const encryptedMasterKey = AES.encrypt(
          masterKey,
          tempPassword
        ).toString()

        handleResetRequest(
          true,
          record.key,
          record.email,
          null,
          encryptedMasterKey,
          tempPassword
        )
      } else {
        const encryptKey = new NodeRSA()

        const encryptedShares = record.otherPublicKeys?.map(pk => {
          // TODO: check again whether there is any issue with importKey multiple times
          encryptKey.importKey(pk.publicKey, 'public')

          return {
            deputyId: pk.deputyId,
            share: encryptKey.encrypt(
              currentDeputyShare.toString('hex'),
              'base64'
            )
          }
        })

        handleResetRequest(true, record.key, record.email, encryptedShares)
      }
    }

    if (externalUser) {
      setResetRequestsLoading(true)
      const userAttributes = getExternalUserAttributes()
      // get private key of current user
      const encryptedPrivateKey = userAttributes.private_key
      onApprove(encryptedPrivateKey)
    } else {
      getUserData(user, (err, data) => {
        if (err) {
          onError(err)
          return
        }

        setResetRequestsLoading(true)
        // get private key of current user
        const encryptedPrivateKey = getUserAttributeValue(
          data.UserAttributes,
          'custom:private_key'
        )
        onApprove(encryptedPrivateKey)
      })
    }
  }

  const handleResetRequest = async (
    isApproved,
    primaryUserId,
    primaryUserEmail,
    shares?,
    masterKey?,
    tempPassword?
  ) => {
    const handleData = {
      isApproved,
      primaryUserId,
      shares,
      masterKey,
      tempPassword
    }

    api
      .handleResetRequest(user.username, JSON.stringify(handleData))
      .then(response => {
        if (response.data && response.data.success) {
          message.success(
            isApproved ? t('REQUEST_APPROVED') : t('REQUEST_REJECTED')
          )
          fetchResetRequests()
        }
      })
      .catch(err => {
        message.error(
          isApproved
            ? t('FAILED_TO_APPROVE_REQUEST')
            : t('FAILED_TO_REJECT_REQUEST')
        )
        console.log('Failed to handle reset request: ', err)
        onError(err)
      })
  }

  const handleUnlockRequest = async (record, isApproved = false) => {
    setUnlockRequestLoading(true)
    try {
      if (isApproved) {
        const resBody = await s3Get(
          record.userId,
          `${record.fileId}/${user.username}`,
          { sub: record.sub },
          { responseType: 'blob' }
        )

        const onUnlockRequest = async privateKey => {
          const decryptedPrivateKey = AES.decrypt(
            privateKey,
            masterKey
          ).toString(enc.Latin1)

          const key = new NodeRSA()
          key.importKey(decryptedPrivateKey, 'pkcs8')
          const fileKeyData = {
            fileId: record.fileId,
            deputyId: user.username
          }
          const fileKeyRes = await api.getFileKey(
            record.userId,
            JSON.stringify(fileKeyData)
          )
          if (fileKeyRes.data.message) throw Error(fileKeyRes.data.message)

          const encryptedFileKey = fileKeyRes.data.key
          const fileKey = key.decrypt(encryptedFileKey, 'ascii')

          const uint8Array = await decryptFilePromise(resBody, fileKey)
          const blob = new Blob([uint8Array])
          await s3Put(record.userId, record.fileId, blob, {
            sub: record.sub
          })
        }

        if (externalUser) {
          const userAttributes = getExternalUserAttributes()
          const privateKey = userAttributes.private_key
          await onUnlockRequest(privateKey)
        } else {
          getUserData(user, async (err, userData) => {
            if (err) {
              onError(err)
              return
            }

            const privateKey = getUserAttributeValue(
              userData.UserAttributes,
              'custom:private_key'
            )
            await onUnlockRequest(privateKey)
          })
        }
      }

      const handleData = {
        isApproved,
        deputyUserId: user.username
      }

      await api.handleUnlockRequest(
        record.userId,
        record.fileId,
        JSON.stringify(handleData)
      )

      message.success(
        isApproved ? t('REQUEST_APPROVED') : t('REQUEST_REJECTED')
      )
    } catch (e) {
      message.error(
        isApproved
          ? t('FAILED_TO_APPROVE_REQUEST')
          : t('FAILED_TO_REJECT_REQUEST')
      )
      onError(e)
    }

    fetchUnlockRequests()
  }

  return (
    <>
      <TableHeader
        title={t('REQUESTS_TO_RESET_THEIR_PASSWORD')}
        count={resetRequests.length}
      />
      <Table
        columns={resetRequestsColumns}
        dataSource={resetRequests}
        scroll={{ x: true }}
        loading={resetRequestsLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_REQUESTS')}
            />
          )
        }}
        showHeader={!!resetRequests.length}
      />
      <TableHeader
        title={t('REQUEST_TO_UNLOCK_THEIR_FILES')}
        count={unlockRequests.length}
      />
      <Table
        columns={unlockRequestsColumns}
        dataSource={unlockRequests}
        scroll={{ x: true }}
        rowKey="fileId"
        loading={unlockRequestsLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_REQUESTS')}
            />
          )
        }}
        showHeader={!!unlockRequests.length}
      />

      <TableHeader
        title={t('REQUEST_TO_CHANGE_THEIR_PHONE_NUMBER')}
        count={updateRequests.length}
      />
      <Table
        columns={updateRequestsColumns}
        dataSource={updateRequests}
        scroll={{ x: true }}
        rowKey="key"
        loading={updateRequestsLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_REQUESTS')}
            />
          )
        }}
        showHeader={!!updateRequests.length}
      />
    </>
  )
}

import React from 'react'
import { H4 } from '../override/Typography'
import logo from '../../assets/dark.svg'
import { Link } from 'react-router-dom'
import {
  Li,
  NumberedList,
  LowercaseList,
  LowerRomanList,
  Item
} from './TermsOfService'
export default function WatigaTrustPDTermsOfService() {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Link to="/login">
          <img
            alt="vaultbox logo"
            src={logo}
            style={{ margin: '10px 0 20px' }}
          />
        </Link>
        <H4>
          <u>Watiga Trust Professional Deputy Terms of Service</u>
        </H4>
        <span style={{ fontSize: 13 }}>Version as at 26 August 2020</span>
      </div>

      <div
        style={{
          paddingRight: 20,
          margin: '20px 0',
          textAlign: 'justify',
          maxHeight: 600,
          overflowY: 'scroll',
          fontSize: 13
          // border: 'solid 1px #E5E7EF',
          // borderRadius: 5
        }}
      >
        <ol type="A">
          {/* A.	Important */}
          <Li>Important</Li>
          <NumberedList start="1">
            <Item>
              These terms and conditions relate to your appointment of Selected
              PD as your Professional Deputy. For an explanation of defined
              terms, please refer to the Definitions section at the end of these
              terms and conditions.
            </Item>
            <Item>
              The VAULTBOX terms of service (as updated from time to time) shall
              apply to you as a user of VAULTBOX. The rights and obligations
              under the custodian trust agreement between Sircured Pte Ltd and
              Selected PD (as updated from time to time) shall apply, with you
              in the capacity as the Primary User referred to therein. A
              reference and redacted copy of the prevailing custodian trust
              agreement may be provided to you upon request at your cost upon
              the execution of a formal written undertaking of confidentiality.
            </Item>
          </NumberedList>

          {/* B.	Disclaimer */}
          <Li>Appointment</Li>
          <NumberedList start="3">
            <Item>
              On the terms and subject to the conditions herein, you appoint
              Selected PD as an independent, non-exclusive, authorised
              Professional Deputy for VAULTBOX, and Selected PD hereby accept
              such appointment. You must be on a paid VAULTBOX subscription to
              appoint Selected PD as your Professional Deputy.
            </Item>
            <Item>
              You hereby agree to indemnify and hold Selected PD harmless
              against all damages, losses, expenses and costs suffered or
              incurred by Selected PD in connection with or arising from (i)
              your appointment of Selected PD as Professional Deputy on
              VAULTBOX, or (ii) Selected PD’s acts (or refraining to act) in
              reliance of instructions from you (whether actual or purported,
              and whether retrieved from VAULTBOX or otherwise), or (iii) your
              breach of any of these terms and conditions, or (iv) any other
              party’s breach of any of these terms and conditions using your
              login details.
            </Item>
            <Item>
              Under no circumstances shall Selected PD be liable regardless of
              the form of action for any failure of performance, system, server
              or connection failure, error, omission, interruption, breach of
              security, computer virus, malicious code, corruption, delay in
              operation or transmission, transmission error or unavailability of
              access in connection with your accessing or using VAULTBOX even if
              Selected PD had been advised as to the possibility.
            </Item>
            <Item>
              In no event shall Selected PD be liable to you or any other party
              for any damages, losses, expenses or costs whatsoever (including,
              without limitation, any direct, indirect, special, incidental or
              consequential damages, loss of profits or loss opportunity)
              arising in connection with your accessing or using VAULTBOX,
              regardless of the form of action and even if Selected PD had been
              advised as to the possibility of such damages.
            </Item>
          </NumberedList>

          {/* C.Indemnity */}
          <Li>Pricing</Li>
          <NumberedList start="7">
            <Item>
              Selected PD’s retainer fee to be your Professional Deputy is as
              stated on the VAULTBOX platform from time to time, payable in
              advance. You acknowledge that Selected PD has the right at any
              time to modify the fee.
            </Item>
            <Item>
              The retainer fee shall be charged to you in line with and via your
              VAULTBOX subscription and unless otherwise indicated will
              automatically renew for another period of the same length unless
              cancelled by Selected PD. If Selected PD do not receive payment
              from you on time for whatever reason, you shall not take any
              benefit of these terms.
            </Item>
            <Item>
              You are permitted to cancel the renewal of Selected PD’s
              appointment as your Professional Deputy at any time. However, you
              will not receive a refund for the fees you already paid for your
              current subscription period except where required by law.
            </Item>
            <Item>
              Any work done (whether within VAULTBOX or otherwise) for you or
              any other person based on your instructions or as you may require
              through VAULTBOX (including but not limited to unlocking or
              resetting such features in VAULTBOX) shall be chargeable at such
              rates as Selected PD may determine.
            </Item>
            <Item>You shall be responsible for paying all taxes.</Item>
          </NumberedList>

          {/* D.Hyperlinks */}
          <Li>Instructions</Li>
          <NumberedList start="12">
            <Item>
              Selected PD shall be entitled to rely upon your electronic
              instructions, directions or other notes as Selected PD may
              retrieve via VAULTBOX (the “Instructions”). Selected PD are under
              no obligation to investigate the authenticity or authority of
              persons effecting the Instructions or to verify the accuracy and
              completeness of the Instructions. Accordingly, Selected PD may (a)
              treat the Instructions as valid and binding on you; and/or (b)
              reveal your information to such persons notwithstanding any
              unauthorised access to the website or online services, error,
              fraud, forgery, lack of clarity or misunderstanding in the terms
              of the Instructions. Any loss, damage or expense resulting from
              the use of the website or online services by you or your
              Authorised Users are entirely at your own risk and Selected PD
              shall not be liable therefor.
            </Item>
            <Item>
              All Instructions will be deemed to be irrevocable and
              unconditional upon transmission through the website or online
              services and Selected PD shall be entitled (but not obliged) to
              effect, perform or process such Instruction(s) without your
              further consent and without any further reference or notice to
              you. Nevertheless, in certain circumstances you may request to
              cancel or amend the Electronic Instructions which Selected PD will
              endeavour to give effect to on a commercially reasonable effort
              basis. However, notwithstanding the foregoing, Selected PD are not
              obliged to give effect to any request to cancel or amend any
              Instruction.
            </Item>
            <Item>
              Instructions may not have been received by Selected PD and
              accordingly, may not be carried out or processed, and Selected PD
              will not be liable for any loss, damage or expense which thereby
              arises. You are therefore advised to check on such Instructions
              via the website or online services or otherwise. Unless otherwise
              stated or determined in Selected PD’s absolute discretion any
              Instruction received by Selected PD after the relevant cut-off
              time on a business day (as determined by Selected PD from time to
              time) or on a non-business day in Singapore will be treated as an
              Instruction received on the next business day.
            </Item>
            <Item>
              You acknowledge and agree that Selected PD may at any time at
              Selected PD’s sole discretion and without stating reasons:
            </Item>

            <LowercaseList>
              <Item>
                require that you identify yourself by alternative means;
              </Item>

              <Item>
                require any Instructions to be confirmed through alternative
                means;
              </Item>

              <Item>
                refrain from acting promptly upon any Instructions in order to
                verify the authenticity of any Instructions or your identity;
              </Item>

              <Item>
                determine the order of priority in effecting the Instructions,
              </Item>

              <Item>
                decline to act on the Instructions at any time without prior
                notice or giving any reason, where:
                <LowerRomanList>
                  <Item>
                    the Instructions are ambiguous, incomplete or inconsistent
                    with your other Instructions or instructions, information
                    and/or data;
                  </Item>
                  <Item>
                    the Instructions have lapsed, been rendered invalid due to
                    failure to comply with applicable conditions or are
                    cancelled by the relevant regulatory or governmental body;
                  </Item>
                  <Item>
                    the Instructions cannot be processed due to any disruptions
                    that are beyond Selected PD’s reasonable control;
                  </Item>
                </LowerRomanList>
                without incurring any responsibility for loss, liability or
                expense arising out of so declining to act.
              </Item>
            </LowercaseList>
            <Item>
              You agree and acknowledge that Instructions may not be processed
              immediately, around the clock or in a timely manner, and that
              Selected PD will not thereby be liable for any loss, damage or
              expense suffered by you as a result of any delay.
            </Item>
            <Item>
              You shall be solely responsible for ensuring the accuracy,
              adequacy and completeness of the Instructions and Selected PD
              shall not be obliged to verify the accuracy, adequacy and
              completeness of such instructions. You agree that Selected PD
              shall not be liable for any loss, damage or expense suffered by
              you as a result of any Instructions being inaccurate, inadequate
              or incomplete in any way.
            </Item>
          </NumberedList>

          {/* E. Subscription and Payment */}
          <Li>Know Your Customer Compliance</Li>
          <NumberedList start="18">
            <Item>
              Selected PD’s obligations under this Agreement are conditional
              upon receipt of all such documentation and other evidence as is
              reasonably requested from time to time necessary for Selected PD
              to carry out and be satisfied with the results of all necessary
              "know your customer" due diligence, including with respect to (i)
              anti-money laundering/countering the financing of terrorism or
              other similar checks and (ii) where applicable, tax reporting
              (including without limitation under Foreign Account Tax Compliance
              Act (FATCA) and Common Reporting Standards under the Automatic
              Exchange of Financial Account Information regulations (CRS), as
              relevant) under all applicable laws and regulations pursuant to
              the transactions and actions contemplated in this Agreement
              (collectively, the <b>“KYC Information”</b>).
            </Item>

            <Item>
              Notwithstanding anything else to the contrary herein, Selected PD
              are hereby authorised to at any time do, or refrain from doing,
              any act without prior reference to you which Selected PD may
              consider necessary or desirable to enable Selected PD to comply
              with the terms of this Agreement and all applicable laws and
              regulations.
            </Item>
          </NumberedList>
          {/* F.Access and Use */}
          <Li>Severability</Li>
          <NumberedList start="20">
            <Item>
              To the extent that any provisions in these terms and conditions
              are found by any competent court or authority to be invalid,
              unlawful or unenforceable in any jurisdiction, that provision
              shall be deemed to be severed from these terms and conditions but
              such finding shall not affect the validity, lawfulness or
              enforceability of these terms and conditions in any other
              jurisdiction.
            </Item>
          </NumberedList>

          {/* G.Authorised User */}
          <Li>Consent to Disclosure and Use of Information</Li>
          <NumberedList start="21">
            <Item>
              By accessing the website and/or using the online services, you
              acknowledge and accept the disclosure of your information to
              Selected PD, Selected PD’s affiliates or such other third party
              for the purpose of providing you access to the website and/or use
              of the online services, and sending you notices and communications
              relating to your access to the website and/or use of the online
              services, or to your business with Selected PD. Failure to allow
              such disclosure, access to or use of your information may result
              in Selected PD’s inability to offer or continue to offer the
              online services to you.
            </Item>
            <Item>
              You will provide Selected PD with any information or documentation
              that Selected PD may reasonably request relating to your access of
              the website or use of the online services and shall cooperate with
              Selected PD in any related investigation or litigation.
            </Item>
            <Item>
              Selected PD’s authority to collect, use or disclose your
              information shall survive the termination of this Agreement, and
              Selected PD’s rights and abilities under this section shall be in
              addition to and without prejudice to Selected PD’s other rights of
              disclosure under and pursuant to any other agreement you have with
              Selected PD, or any other laws and nothing herein is to be
              construed as limiting any of those other rights.
            </Item>
            <Item>
              Neither Selected PD nor any of Selected PD’s personnel shall be
              liable for any loss or damage suffered by you as a result of any
              disclosure of any information which you have consented to Selected
              PD collecting, using or disclosing or where such collection, use
              or disclosure is allowed under the applicable laws.
            </Item>
            <Item>
              For information on how Selected PD collect, use and disclose
              personal data, please request Selected PD’s Personal Data
              Protection Policy Statement from Selected PD. Unless any specific
              contact information of Selected PD has been provided to you, you
              may send any request or query relating to this via the vaultbox
              contact form on{' '}
              <a
                href="https://www.vaultbox.tech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.vaultbox.tech
              </a>{' '}
              or via email at{' '}
              <a href="mailto:hello@vaultbox.tech">hello@vaultbox.tech</a>.
            </Item>
          </NumberedList>

          {/* H. Instruction */}
          <Li>Notices</Li>
          <NumberedList start="26">
            <Item>
              All notices or other communications given by Selected PD to you
              if:
            </Item>
            <LowercaseList>
              <Item>
                sent by post or left at your last known address or sent to your
                last known email will be deemed to be received by you on the day
                following such posting or transmission or on the day when it was
                so left; or
              </Item>
              <Item>
                communicated through any print or electronic media (including
                but not limited to via this website or online services) as
                Selected PD may select will be deemed to be notified to you on
                the date of publication or broadcast.
              </Item>
            </LowercaseList>
          </NumberedList>

          {/* I.Security */}
          <Li>Records</Li>
          <NumberedList start="27">
            <Item>
              Selected PD’s records of information or transactions shall be
              conclusive against and binding on you except in the case of
              manifest error. You hereby agree that all such records are
              admissible in evidence and that you shall not challenge or dispute
              the admissibility, reliability, accuracy or the authenticity of
              the contents of such records merely on the basis that such records
              were incorporated and/or set out in electronic form or are
              produced by or were the output of a computer system, and you
              hereby waive any of your rights (if any) to so object.
            </Item>
          </NumberedList>

          {/* J.Copyright */}
          <Li>No Waiver</Li>
          <NumberedList start="28">
            <Item>
              No failure to exercise, nor any delay in exercising, on Selected
              PD’s part any right or remedy under this Agreement will operate as
              a waiver thereof, nor shall any single or partial exercise of any
              right or remedy prevent any further or other exercise thereof or
              the exercise of any other right or remedy. Selected PD’s rights
              and remedies in this Agreement are cumulative and not exclusive of
              any other rights or remedies provided by law.
            </Item>
          </NumberedList>

          {/* K.	Severability */}
          <Li>Miscellaneous</Li>
          <NumberedList start="29">
            <Item>
              Selected PD reserve the right to use any providers, subcontractors
              and/or agents on such terms as Selected PD deem appropriate.
            </Item>
            <Item>
              Except for Selected PD’s affiliates, providers, subcontractors and
              agents, no person or entity who is not a party to this Agreement
              shall have any right under the Contracts (Rights of Third Parties)
              Act (Cap. 53B) to enforce any term of this Agreement, regardless
              of whether such person or entity has been identified by name, as a
              member of a class or as answering a particular description. For
              the avoidance of doubt, this shall not affect the rights of any
              permitted assignee or transferee of this Agreement.
            </Item>
            <Item>
              The information and materials contained in or accessed through
              this website or the online services are subject to change
              (including, without limitation, modification, deletion or
              replacement thereof) without notice.
            </Item>
            <Item>
              Selected PD may without giving any reason vary this Agreement at
              any time with or without prior notice to you. Selected PD will
              post the most current version of this Agreement within VAULTBOX or
              provide you a copy (whether electronically or otherwise at
              Selected PD’s discretion) upon request. Your continued appointment
              of Selected PD as Professional Deputy means you are consenting to
              the updated Agreement.
            </Item>
            <Item>
              Selected PD, Selected PD’s affiliates, providers, subcontractors
              and agents shall not be liable for any non-performance, error,
              interruption or delay in the performance of any obligations under
              this Agreement or in the website’s or online services’ operation,
              or for any inaccuracy, unreliability or unsuitability of the
              online services if this is due, in whole or in part, directly or
              indirectly to an event or failure which is beyond its reasonable
              control (which includes acts of God, natural disasters, acts of
              terrorism, embargoes, epidemics, acts of any government or
              authority, power failures, and the acts or a party for whom
              Selected PD, Selected PD’s affiliates, providers, subcontractors
              and agents are not responsible for).
            </Item>
          </NumberedList>

          <Li>Governing Law and Jurisdiction</Li>
          <NumberedList start="34">
            <Item>
              Nothing herein shall be construed as a representation by Selected
              PD that the information and materials contained in or accessed
              through this website is appropriate or available for use in
              jurisdictions other than Singapore. By accessing this website and
              using the online services, you agree that such access to this
              website and use of its online services, as well as these terms and
              conditions shall be governed by, and construed in accordance with,
              the laws of the Republic of Singapore, and you agree to submit to
              the exclusive jurisdiction of the Singapore courts.
            </Item>
          </NumberedList>

          {/* M. Personal Data Protection Statement */}
          <Li>Definitions</Li>
          <NumberedList start="35">
            <Item>
              In this Agreement, unless the context otherwise requires:
            </Item>
            <LowercaseList>
              <Item>
                "affiliate" means, in relation to any person, a person that
                directly, or indirectly through one or more intermediaries,
                controls or is controlled by, or is under common control with,
                such person;
              </Item>
              <Item>
                “Instructions” means any communication, instruction, order,
                message, data, information or other materials received by
                Selected PD via the website, online services or any other
                channel and referable to your information, Security Codes or
                those of your Authorised Users (including use of your
                information or Security Codes or those of your Authorised Users
                by any person, whether authorised or unauthorised by you or your
                Authorised Users), from you or purporting to come from you or
                from your Authorised Users or purporting to come from your
                Authorised Users;
              </Item>
              <Item>
                “Professional Deputy” means the third party person or entity
                which is displayed and listed on the VAULTBOX platform as a
                preapproved deputy which may be appointed by you;
              </Item>
              <Item>
                “VAULTBOX” means the software application known as vaultbox
                which is developed by Sircured Pte Ltd and made available on the
                internet at{' '}
                <a
                  href="https://www.vaultbox.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.vaultbox.tech
                </a>{' '}
                or such other website;
              </Item>
              <Item>
                “Security Codes” means personal or login identification details,
                Security Devices and other codes (including but not limited to
                passcodes, whether selected by you, generated for one-time use
                or otherwise) and access procedures for use in connection with
                access to and use of the website or online services;
              </Item>
              <Item>
                “this Agreement” means these terms and conditions as amended or
                supplemented;
              </Item>
              <Item>
                “Selected PD” (which may also be referred to as “we”, “us” or
                “our”) means the Professional Deputy (and/or its affiliates)
                which you had selected within the VAULTBOX platform to be
                appointed as your deputy;
              </Item>
              <Item>
                “you” means the individual accessing or using VAULTBOX and
                appointing Selected PD as Professional Deputy, or the company or
                legal entity on behalf of which such individual is accessing or
                using VAULTBOX and appointing Selected PD as Professional
                Deputy, as applicable;
              </Item>
              <Item>
                words importing the singular include the plural and vice versa;
              </Item>
              <Item>
                words importing the masculine gender include the feminine or
                neuter gender and vice versa;
              </Item>
              <Item>
                references to persons are to be construed as references to an
                individual, company or trust as the context requires; and
              </Item>
              <Item>
                headings are for ease of reference and shall not affect the
                interpretation of any provision herein.
              </Item>
            </LowercaseList>
          </NumberedList>
        </ol>
      </div>
    </>
  )
}

import React, { useContext, useState, useEffect, useCallback } from 'react'
import {
  Empty,
  Spin,
  Dropdown,
  Menu,
  Alert,
  Table,
  Icon,
  Tooltip,
  Modal
} from 'antd'
import CustomIcon from '../override/Icon'
import { P, H4 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import SpecifyDeputy from '../settings/SpecifyDeputy'
import { useSelector, useDispatch } from 'react-redux'
//import { StringResources } from '../../share/StringResources'
import {
  distributeShareKeys,
  revokeDeputy,
  resendDeputyRequest,
  resendUnlockRequest
} from '../../features/deputies/deputiesSlice'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import Button from '../override/Button'
import TableHeader from '../common/TableHeader'
import api from '../../lib/api'
import { groupBy } from 'lodash'
import config from '../../config'
import watigaTrustLogo from '../../assets/watiga-logo.jpeg'
import Overlay from '../override/Overlay'
import UpdateUserDetailsModal from './UpdateUserDetailsModal'
import { onError } from '../../lib/sentry'
import SharesThresholdModal from './SharesThresholdModal'
import { useTranslation, Trans } from 'react-i18next'
import {
  checkSelectedLevel,
  getExternalUserAttributes
} from './../../share/helpers'
import { withRouter } from 'react-router-dom'

const renderDeputyColumn = (text, record) =>
  record.professionalDeputyName ? (
    <div>
      <img
        src={
          record.isDefault
            ? watigaTrustLogo
            : `https://s3-${config.aws.REGION}.amazonaws.com/${config.s3.PUBLIC_BUCKET}/logo/${record.id}`
        }
        alt={
          record.isDefault
            ? 'Watiga Trust logo'
            : 'Other Professional Deputy logo'
        }
        style={{ width: 120, marginRight: 10 }}
      />
      {record.professionalDeputyName}
    </div>
  ) : (
    text
  )

function UpdateInfoButton({ handleClick, isPendingUpdateUserDetails }) {
  const theme = useContext(ThemeContext)
  return (
    <Tooltip
      title={
        isPendingUpdateUserDetails ? (
          <Trans i18nKey="UPDATE_PENDING_ACCEPTANCE_FROM_DEPUTY"></Trans>
        ) : null
      }
      arrowPointAtCenter
      placement="top"
    >
      <Button type="link">
        <Icon type={'edit'} /> <Trans i18nKey="UPDATE_MY_INFO"></Trans>
        {isPendingUpdateUserDetails && (
          <Icon type={'warning'} style={{ color: theme.darkYellow }} />
        )}
      </Button>
    </Tooltip>
  )
}

function Deputies(props) {
  const { disabled, history } = props
  const theme = useContext(ThemeContext)
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const { deputies, isLoading, error, distributeError } = useSelector(
    state => state.deputies
  )
  const { pendingUnlockFiles } = useSelector(state => state.documents)
  const externalUser = localStorage.getItem('External_User')
  const [acceptedDeputies, setAcceptedDeputies] = useState([])
  const [pendingDeputies, setPendingDeputies] = useState([])
  const [userDetailsModalVisible, setUserDetailsModalVisible] = useState(false)
  const [sharesThresholdModalVisible, setSharesThresholdModalVisible] =
    useState(false)

  const { t } = useTranslation()

  const { isPendingUpdateUserDetails, sharesThreshold } = useSelector(
    state => state.user
  ).user

  const { accessLevel } = useSelector(state => state.settings)
  const hasProfessionalDeputy = deputies.some(d => d.professionalDeputyId)

  useEffect(() => {
    const getProfessionalDeputies = async () => {
      try {
        const rs = await api.getProfessionalDeputies()
        const professionalDeputies = rs.data

        const groupedDeputies = groupBy(deputies, d => d.professionalDeputyId)
        const profGroupKey = Object.keys(groupedDeputies)?.find(
          gp => gp !== 'undefined'
        )

        const accepted =
          groupedDeputies['undefined']?.filter(d => d.publicKey) || []
        const pending =
          groupedDeputies['undefined']?.filter(d => !d.publicKey) || []

        if (profGroupKey) {
          const matchingPD = professionalDeputies?.find(
            pd => pd.id === profGroupKey
          )
          if (groupedDeputies[profGroupKey]?.every(d => d.publicKey)) {
            accepted.push(matchingPD)
          } else {
            // add email here just to fix the unique key error thrown by Ant's Table component
            pending.push({ ...matchingPD, email: matchingPD.accounts[0].email })
          }
        }
        setAcceptedDeputies(accepted)
        setPendingDeputies(pending)
      } catch (err) {
        onError(err)
      }
    }
    getProfessionalDeputies()
  }, [user, deputies])

  const dispatch = useDispatch()

  const [modalVisible, setModalVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({
    fullname: '',
    email: '',
    phone: '',
    prefix: ''
  })

  const acceptedDeputiesColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('DEPUTY'),
      render: renderDeputyColumn
    },
    {
      key: 'status',
      title: t('STATUS'),
      render: (text, record) =>
        deputies.some(
          d => d.professionalDeputyId === record.id && d.pendingDisconect
        )
          ? t('WAITING_FOR_DISCONNECTION')
          : record.shareKey ||
            deputies
              .filter(d => d.professionalDeputyId === record.id)
              .every(d => d.shareKey)
          ? t('BACKUP_KEY_DISTRIBUTED')
          : t('ACCEPTED')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              {/* Assumption: there will be only one PD record, so only need to show the modal, don't need to specify the selected record */}
              {record.professionalDeputyName && (
                <Menu.Item onClick={() => setUserDetailsModalVisible(true)}>
                  <UpdateInfoButton
                    isPendingUpdateUserDetails={isPendingUpdateUserDetails}
                  />
                </Menu.Item>
              )}

              <Menu.Item
                onClick={() => {
                  sharesThreshold === acceptedDeputies.length &&
                  sharesThreshold > 2
                    ? Modal.warning({
                        title: t('CANNOT_REVOKE_DEPUTY'),
                        content: (
                          <div>
                            <Trans
                              i18nKey="CANNOT_REVOKE_DEPUTY_SUMMARY"
                              values={{ sharesThreshold }}
                            ></Trans>
                          </div>
                        )
                      })
                    : Modal.confirm({
                        content: t('ARE_YOU_SURE_TO_REVOKE_THIS_DEPUTY'),
                        okText: t('YES'),
                        cancelText: t('NO'),
                        onOk: () => handleRevokeDeputy(record)
                      })
                }}
              >
                <Button style={{ color: theme.red }} type="link">
                  <CustomIcon type="trash" style={{ fontSize: 12 }} />{' '}
                  {t('REVOKE')}
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <CustomIcon type="more" />
        </Dropdown>
      )
    }
  ]

  const pendingDeputiesColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('DEPUTY'),
      render: renderDeputyColumn
    },
    {
      key: 'status',
      title: t('STATUS'),
      render: () => t('PENDING_ACCEPTANCE_OF_DEPUTY')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              {record.professionalDeputyName && (
                <Menu.Item onClick={() => setUserDetailsModalVisible(true)}>
                  <UpdateInfoButton
                    isPendingUpdateUserDetails={isPendingUpdateUserDetails}
                  />
                </Menu.Item>
              )}

              <Menu.Item>
                <Button
                  type="link"
                  icon="reload"
                  onClick={() => {
                    const emails = record?.accounts?.length
                      ? record.accounts.map(e => e.email)
                      : [record.email]
                    handleResend(emails)
                  }}
                >
                  {t('RESEND')}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  type="link"
                  icon="minus-circle"
                  style={{ color: theme.red }}
                  onClick={() => handleRevokeDeputy(record)}
                >
                  {t('REVOKE')}
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <CustomIcon type="more" />
        </Dropdown>
      )
    }
  ]

  const deputyAccessColumn = {
    key: 'accessLevel',
    dataIndex: 'accessLevel',
    title: t('DEPUTY_ACCESS'),
    render: record => (
      <Button
        style={{ padding: 0 }}
        onClick={() => history.push('/settings')}
        type="link"
      >
        {checkSelectedLevel(accessLevel)}
      </Button>
    )
  }

  const pendingFileUnlocksColumns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t('NAME')
    },
    {
      key: 'status',
      title: t('STATUS'),
      render: () => t('PENDING_ACCEPTANCE_OF_DEPUTY')
    },
    {
      key: 'actions',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Button
                  type="link"
                  icon="reload"
                  onClick={() => handleResendUnlockRequest(record)}
                >
                  {t('RESEND')}
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <CustomIcon type="more" />
        </Dropdown>
      )
    }
  ]

  const fetchUserData = useCallback(() => {
    getUserData(
      user,
      async (err, data) => {
        if (err) {
          onError(err)
          return
        }
        const prefix = getUserAttributeValue(
          data.UserAttributes,
          'custom:prefix'
        )
        const phoneNumber = getUserAttributeValue(
          data.UserAttributes,
          'phone_number'
        )

        setUserInfo({
          fullname: getUserAttributeValue(
            data.UserAttributes,
            'custom:full_name'
          ),
          phone: phoneNumber.slice(prefix.length),
          prefix,
          email: getUserAttributeValue(data.UserAttributes, 'email')
        })
      },
      { bypassCache: true }
    )
  }, [user])

  const fetchExternalUserData = useCallback(() => {
    const userAttributes = getExternalUserAttributes()
    const fullname = userAttributes.full_name
    const prefix = userAttributes.prefix
    const phoneNumber = userAttributes.phone_number
    setUserInfo({
      fullname: fullname,
      phone: phoneNumber.slice(prefix ? prefix.length : 3),
      prefix: prefix || phoneNumber.slice(0, 3),
      email: userAttributes.email
    })
  }, [])

  useEffect(() => {
    if (externalUser) {
      fetchExternalUserData()
    } else if (user) {
      fetchUserData()
    }
  }, [externalUser, fetchUserData, fetchExternalUserData, user])

  useEffect(() => {
    // automatically distribute share keys if an accepted deputy exists without shareKey
    if (deputies.find(d => d.publicKey && !d.shareKey) && masterKey) {
      dispatch(distributeShareKeys(user.username, masterKey, sharesThreshold))
    }
  }, [deputies, user, dispatch, masterKey, sharesThreshold])

  const handleRevokeDeputy = async deputy =>
    dispatch(revokeDeputy(user.username, deputy, userInfo.fullname, masterKey))

  const handleDistributeShareKeys = () =>
    dispatch(distributeShareKeys(user.username, masterKey, sharesThreshold))

  const handleResend = emails =>
    dispatch(resendDeputyRequest(user.username, emails, userInfo.fullname))

  const handleResendUnlockRequest = async record => {
    const pendingDeputies =
      pendingUnlockFiles.find(puf => puf.fileId === record.fileId)
        ?.pendingDeputies || []

    const deputiesCanUnlock = deputies
      .filter(d => pendingDeputies.includes(d.id))
      .map(d => d.id)

    const requestData = {
      fileId: record.fileId,
      fileName: record.name,
      userFullname: userInfo.fullname,
      email: userInfo.email,
      deputiesCanUnlock
    }

    dispatch(resendUnlockRequest(user.username, requestData))
  }

  const disableAccessOfDeputy = (event, disableClick) => {
    if (disableClick) {
      event.target.addEventListener('click', function (e) {
        e.stopPropagation()
        e.preventDefault()
      })
    }
    return
  }

  if (hasProfessionalDeputy) {
    acceptedDeputiesColumns.splice(2, 0, deputyAccessColumn)
  }

  return (
    <Spin spinning={isLoading} style={{ position: 'relative' }}>
      {disabled && <Overlay />}
      <div
        style={{ pointerEvents: disabled && 'none' }}
        onMouseDown={event => disableAccessOfDeputy(event, disabled)}
      >
        <div className="page-description">
          <div>
            <H4>{t('WHY_DO_I_NEED_TO_APPOINT_DEPUTIES')}</H4>
            <P>
              <Trans i18nKey="DEPUTY_SUMMARY"></Trans>
            </P>
          </div>
          <Button
            className="page-description-action"
            type="primary"
            onClick={() => setModalVisible(true)}
          >
            {t('ADD_A_DEPUTY')}
          </Button>
        </div>
        <div>
          {acceptedDeputies.length > 2 && (
            <P>
              {t('SHARES_THRESHOLD_SUMMARY')}: <b>{sharesThreshold || 2}</b>
              <Button
                type="link"
                onClick={() => setSharesThresholdModalVisible(true)}
              >
                <Icon type="edit" />
              </Button>
            </P>
          )}
        </div>
        {error && (
          <Alert
            message={error}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
        {distributeError && (
          <Alert
            message={
              <>
                <div>{distributeError}</div>
                <Button
                  type="link"
                  onClick={handleDistributeShareKeys}
                  style={{ padding: 0, height: 22 }}
                >
                  {t('CLICK_HERE_TO_RETRY')}
                </Button>
              </>
            }
            type="warning"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
        <div className="tour-current-deputies">
          <TableHeader
            title={t('MY_CURRENT_DEPUTIES')}
            count={acceptedDeputies.length}
          />
          <Table
            rowKey="id"
            dataSource={acceptedDeputies}
            scroll={{ x: true }}
            columns={acceptedDeputiesColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('NO_DEPUTIES')}
                />
              )
            }}
            showHeader={!!acceptedDeputies.length}
          />
        </div>

        <div className="tour-pending-deputies">
          <TableHeader
            title={t('MY_PENDING_DEPUTIES')}
            count={pendingDeputies.length}
          />
          <Table
            rowKey="email"
            dataSource={pendingDeputies}
            scroll={{ x: true }}
            columns={pendingDeputiesColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('NO_PENDING_DEPUTIES')}
                />
              )
            }}
            showHeader={!!pendingDeputies.length}
          />
        </div>

        <div className="tour-pending-file-unlock">
          <TableHeader
            title={t('MY_PENDING_FILE_UNLOCKS')}
            count={pendingUnlockFiles.length}
          />
          <Table
            rowKey="name"
            dataSource={pendingUnlockFiles}
            scroll={{ x: true }}
            columns={pendingFileUnlocksColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('NO_PENDING_FILE_UNLOCKS')}
                />
              )
            }}
            showHeader={!!pendingUnlockFiles.length}
          />
        </div>

        <SpecifyDeputy
          existingEmails={deputies.map(d => d.email)}
          visible={modalVisible}
          setVisible={setModalVisible}
          userInfo={userInfo}
          deputies={deputies}
        />
        <UpdateUserDetailsModal
          visible={userDetailsModalVisible}
          setVisible={setUserDetailsModalVisible}
          isPendingUpdateUserDetails={isPendingUpdateUserDetails}
        />
        <SharesThresholdModal
          visible={sharesThresholdModalVisible}
          setVisible={setSharesThresholdModalVisible}
          maxSharesThreshold={acceptedDeputies.length}
        />
      </div>
    </Spin>
  )
}

export default withRouter(Deputies)

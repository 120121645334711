import React, { useState } from 'react'
import { Form, Alert, Modal, Input } from 'antd'
//import { StringResources } from '../../share/StringResources'
import { validateInputCode, compareToPassword } from '../../share/formHelpers'
import { P1 } from '../override/Typography'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import PasswordInputWithRules from '../common/PasswordInputWithRules'
import {
  getAuthenticationDetails,
  getUserAttributeValue
} from '../../lib/cognito'
import api, { clearApiInterceptor, setApiInterceptor } from '../../lib/api'
import { resetAccount } from '../../share/helpers'
import MfaForm from '../common/MfaVerificationForm'
import { AUTH_FLOW, MFA_TYPES } from '../../share/Constants'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import TextInput from './../common/TextInput'

let formRef, callbacks, cognitoUser, authDetails

function ResetPasswordForm(props) {
  const {
    user,
    form,
    errMsg,
    setErrMsg,
    setCurrentStep,
    setWithDeputyApprovals,
    setIsAuthenticated,
    setUser,
    isLoading,
    setIsLoading
  } = props

  const { getFieldDecorator } = form
  const [confirmDirty, setConfirmDirty] = useState(false)
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [totpErr, setTotpErr] = useState('')
  const [mfaType, setMfaType] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)
  const [emailOTPLoading, setEmailOTPLoading] = useState(false)

  const { t } = useTranslation()

  const handleConfirmBlur = e => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  const confirmAction = async () => {
    try {
      setIsLoading(true)
      const res = await api.checkAuthFlow(cognitoUser.username)

      if (res.data) {
        if (res.data === AUTH_FLOW.CUSTOM_FLOW) {
          cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
        }
      }

      authenticateUser()
    } catch (err) {
      setErrMsg(t('FAILED_TO_GET_AUTHENTICATION_FLOW_TYPE'))
    }
  }

  const authenticateUser = () => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )
    setIsResendEmailCode(true)
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (session) {
        cognitoUser.getUserData(async (err, data) => {
          if (err) {
            setIsLoading(false)
            return
          }

          setApiInterceptor(user)

          // 1. With Deputies approvals (checked = true):
          // - User has at least 2 deputies (already received share key): send reset request emails to the deputies
          // - User has less than 2 deputies: reset account
          // 2. Without approvals (checked = false): reset account
          try {
            const deputiesRes = await api.getDeputies(data.Username)
            const approvedDeputies = deputiesRes.data?.deputies?.filter(
              d => d.shareKey
            ).length

            if (approvedDeputies >= 2) {
              const fullname = getUserAttributeValue(
                data.UserAttributes,
                'custom:full_name'
              )
              await api.sendResetRequestEmails(
                data.Username,
                JSON.stringify({ fullname: fullname })
              )
              cognitoUser.signOut()
              clearApiInterceptor()
              setWithDeputyApprovals(true)
            } else {
              await resetAccount(
                data,
                authDetails.password,
                user,
                setIsAuthenticated,
                setUser
              )
            }
            setMfaModalVisible(false)
            setCurrentStep(2)
            setIsLoading(false)
            setIsSubmitting(false)
          } catch (err) {
            onError(err)
            setIsLoading(false)
            setIsSubmitting(false)
          }
        })
      },
      onFailure: function (err) {
        setTotpErr(t('FAILED_TO_AUTHENTICATE_USER'))
        setIsSubmitting(false)
        setIsLoading(false)
      },
      selectMFAType: function () {
        cognitoUser.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      totpRequired: function () {
        callbacks = this
        setIsLoading(false)
        setMfaType(MFA_TYPES.TOTP)
        setMfaModalVisible(true)
      },
      mfaRequired: function () {
        callbacks = this
        setIsLoading(false)
        setMfaType(MFA_TYPES.SMS)
        setMfaModalVisible(true)
      }
    })
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    mfaModalVisible && setMfaModalVisible(false)
    setIsSubmitting(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrMsg('')
    cognitoUser = user
    form.validateFields((err, values) => {
      if (err) {
        return
      } else {
        setIsLoading(true)

        authDetails = {
          username: cognitoUser.getUsername(),
          password: values.password
        }

        cognitoUser.confirmPassword(values.verificationCode, values.password, {
          onSuccess: () => {
            confirmAction()
          },
          onFailure: err => {
            setIsLoading(false)
            setErrMsg(t('FAILED_TO_RESET_PASSWORD'))
            form.resetFields()
          }
        })
      }
    })
  }

  return (
    <>
      <P1 style={{ margin: '10px 0 10px' }}>{t('RESET_PASSWORD_TITLE')}</P1>
      <Form layout="vertical" hideRequiredMark>
        <FormItem label={t('VERIFICATION_CODE')}>
          {getFieldDecorator('verificationCode', {
            rules: [
              {
                required: true,
                message: t('INPUT_VERIFICATION_CODE_MSG')
              },
              {
                validator: validateInputCode
              }
            ]
          })(<TextInput type="text" />)}
        </FormItem>

        <PasswordInputWithRules
          confirmDirty={confirmDirty}
          title={t('NEW_PASSWORD')}
          form={form}
        />
        <FormItem label={t('CONFIRM_NEW_PASSWORD')} hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              { required: true, message: t('CONFIRM_PASSWORD_MSG') },
              {
                validator: (rule, value, callback) =>
                  compareToPassword(rule, value, callback, form)
              }
            ]
          })(
            <Input.Password
              onBlur={handleConfirmBlur}
              placeholder=""
              maxLength={30}
            />
          )}
        </FormItem>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
      </Form>
      <div className="steps-actions">
        <Button
          size="large"
          type="primary"
          onClick={handleSubmit}
          loading={isLoading}
        >
          Next
        </Button>
      </div>

      {/* mfa authentication modal */}
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={user}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={totpErr}
          setErrMsg={setTotpErr}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
          isReset={true}
          emailOTPLoading={emailOTPLoading}
          setEmailOTPLoading={setEmailOTPLoading}
        />
      </Modal>
    </>
  )
}
const WrappedResetPasswordForm = Form.create({ name: 'resetPassword' })(
  ResetPasswordForm
)
export default WrappedResetPasswordForm

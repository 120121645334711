import React, { useState } from 'react'
import { Input, Form, Modal, Alert, message } from 'antd'
import { getUserPool, getAuthenticationDetails } from '../../lib/cognito'
import api, { setApiInterceptor } from '../../lib/api'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { useLocation, withRouter } from 'react-router-dom'
import { H3, P1 } from '../override/Typography'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { AES, enc } from 'crypto-js'
import MfaForm from '../common/MfaVerificationForm'
import { AUTH_FLOW, MFA_TYPES } from '../../share/Constants'
import { logAccessAttempt } from '../../share/logs'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

let formRef, cognitoUser, callbacks, authDetails

function RecoverData(props) {
  const { form, history, setIsAuthenticated, setUser } = props
  const { getFieldDecorator } = form
  const [errMsg, setErrMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [mfaType, setMfaType] = useState()
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)
  const { t } = useTranslation()

  const urlSearchParams = new URLSearchParams(useLocation().search)
  const email = urlSearchParams.get('email')
  const tempPassword = urlSearchParams.get('temp')
  const primaryUserId = urlSearchParams.get('username')

  const authenticateUser = async () => {
    setIsResendEmailCode(true)
    setIsLoading(true)
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )
    try {
      const res = await api.checkAuthFlow(cognitoUser.username)

      if (res.data) {
        if (res.data === AUTH_FLOW.CUSTOM_FLOW) {
          cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
        }
      }
    } catch (err) {
      setErrMsg(t('FAILED_TO_GET_AUTHENTICATION_FLOW_TYPE'))
    }
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async session => {
        try {
          setApiInterceptor(cognitoUser)
          // Get master key encrypted by temp password from db
          const response = await api.getUser(primaryUserId)
          if (response?.data?.masterKey) {
            // decrypt result with temp password
            const masterKey = AES.decrypt(
              response.data.masterKey,
              tempPassword
            ).toString(enc.Latin1)

            // encrypt master key again with current password
            const newEncryptedMasterKey = AES.encrypt(
              masterKey,
              authDetails.password
            ).toString()

            // update new encrypted master key to user pool
            cognitoUser.updateAttributes(
              [
                {
                  Name: 'custom:master_key',
                  Value: newEncryptedMasterKey
                }
              ],
              async err => {
                if (err) {
                  throw err
                } else {
                  // clear all 'reset data' after successfully updated new master key
                  // TODO: handle in case this request failed
                  await api.clearResetData(primaryUserId)
                  setIsLoading(false)
                  resetFields()
                  message.success(t('SUCCESSFULLY_RECOVERED_DATA'))

                  const extraKeyRes = await api.getUser(primaryUserId)
                  const { extraKey } = extraKeyRes.data
                  const encryptedKey = AES.encrypt(
                    masterKey,
                    extraKey
                  ).toString()
                  localStorage.setItem(cognitoUser.username, encryptedKey)
                  setIsAuthenticated(true)
                  setUser(cognitoUser)

                  cognitoUser.getUserData(async (err, data) => {
                    if (err) {
                      return
                    }

                    logAccessAttempt(
                      data.Username,
                      data.UserAttributes,
                      data.Username
                    )
                  })

                  history.push('/')
                }
              }
            )
          }
        } catch (err) {
          onError(err)
          setIsLoading(false)
          setIsSubmitting(false)
          message.error(t('FAILED_TO_RECOVER_DATA'))
        }
      },

      onFailure: err => {
        setErrMsg(t('FAILED_TO_AUTHENTICATE_USER'))
        setIsSubmitting(false)
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      selectMFAType: function () {
        cognitoUser.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },

      mfaRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.SMS)
        setMfaModalVisible(true)
      },

      totpRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.TOTP)
        setMfaModalVisible(true)
      }
    })
  }

  const handleSubmit = e => {
    setErrMsg('')
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (err) return

      authDetails = { username: email, password: values.password }

      cognitoUser = new CognitoUser({
        Username: email,
        Pool: getUserPool()
      })
      authenticateUser()
    })
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    mfaModalVisible && setMfaModalVisible(false)
    setIsSubmitting(false)
  }

  return (
    <>
      <Form
        className="password-form"
        onSubmit={handleSubmit}
        layout="vertical"
        hideRequiredMark
      >
        <div className="form-header">
          <H3>{t('DATA_RECOVERY')}</H3>
          <P1>{t('DATA_RECOVERY_SUMMARY')}</P1>
        </div>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
        <FormItem>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: t('INPUT_PASSWORD_MSG')
              }
            ]
          })(
            <Input.Password
              placeholder={t('INPUT_PASSWORD_MSG')}
              maxLength={30}
            />
          )}
        </FormItem>
        <FormItem>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
          >
            {t('SUBMIT')}
          </Button>
        </FormItem>
      </Form>
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={cognitoUser}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
        />
      </Modal>
    </>
  )
}

const WrappedRecoverData = Form.create({ name: 'recoverData' })(RecoverData)
export default withRouter(WrappedRecoverData)

import React from 'react'
import { Form, Radio, Tooltip, Icon, Modal } from 'antd'
import FormItem from '../override/FormItem'
import ContactSelect from '../common/ContactSelect'
import { LEGACY_ACCESSED_BY } from '../../share/Constants'
import Button from '../override/Button'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const LegacyAccessedBy = props => {
  const {
    form,
    accessedBy,
    setAccessedBy,
    otherPersonId,
    setOtherPersonId,
    resetModal,
    nextStep
  } = props
  const { getFieldDecorator, setFieldsValue } = form
  const { activeContacts } = useSelector(state => state.contacts)
  const { t } = useTranslation()

  const handleAccessedByChange = e => {
    setAccessedBy(e.target.value)
  }

  const handleLegacyInfoNext = () => {
    if (accessedBy === LEGACY_ACCESSED_BY.OTHER_SPECIFIC_PERSON) {
      form.validateFieldsAndScroll((err, values) => {
        if (err) return

        const otherPerson = activeContacts.find(
          ac => ac._id === values.otherPersonId
        )
        if (
          !!otherPerson &&
          otherPerson.emails?.length &&
          otherPerson.phoneNumbers?.length
        ) {
          setOtherPersonId(values.otherPersonId)
          nextStep()
        } else {
          Modal.warning({
            title: t('WARNING_MSG'),
            content: t('OTHER_PERSON_WARNING_MSG')
          })
        }
      })
    } else {
      nextStep()
    }
  }

  const radioStyle = {
    display: 'block',
    height: 40,
    lineHeight: '40px'
  }

  return (
    <>
      <b> {t('WHO_WILL_RECEIVE_THE_ACCESS_TO_YOUR_VAULTBOX')}</b>

      <Form>
        <FormItem style={{ marginBottom: 0 }}>
          <Radio.Group onChange={handleAccessedByChange} value={accessedBy}>
            <Radio
              style={radioStyle}
              value={LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY}
            >
              {LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY}{' '}
              <Tooltip title={t('LEGACY_ACCESSED_BY_PROFESSIONAL_DEPUTY')}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </Radio>
            <Radio
              style={radioStyle}
              value={LEGACY_ACCESSED_BY.OTHER_SPECIFIC_PERSON}
            >
              {LEGACY_ACCESSED_BY.OTHER_SPECIFIC_PERSON}{' '}
              <Tooltip title={t('LEGACY_ACCESSED_BY_OTHER_SPECIFIC_PERSON')}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </Radio>
          </Radio.Group>
        </FormItem>
        {accessedBy === LEGACY_ACCESSED_BY.OTHER_SPECIFIC_PERSON && (
          <ContactSelect
            label={t('OTHER_PERSON')}
            getFieldDecorator={getFieldDecorator}
            fieldName="otherPersonId"
            required={true}
            initialValue={otherPersonId}
            onAddComplete={otherPersonId =>
              setFieldsValue({
                otherPersonId
              })
            }
          />
        )}
      </Form>
      <div className="steps-actions">
        <Button size="large" onClick={() => resetModal()}>
          {t('CANCEL')}
        </Button>
        <div style={{ float: 'right' }}>
          <Button size="large" type="primary" onClick={handleLegacyInfoNext}>
            {t('NEXT')}
          </Button>
        </div>
      </div>
    </>
  )
}

const WrapLegacyAccessedBy = Form.create({ name: 'LegacyAccessedBy' })(
  LegacyAccessedBy
)
export default WrapLegacyAccessedBy

import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class LoanToOthers extends Asset {
  /**
   * Construct a new LoanToOthers instance
   * @param {object} params - LoanToOthers creation options:
   * @param {string} [params.interestRate]
   * @param {string} [params.loanPeriod]
   * @param {string} [params.numberOfRepayments]
   * @param {string} [params.borrower]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    currency,
    valuationDate,
    valuationDateInBaseCurrency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    interestRate,
    loanPeriod,
    numberOfRepayments,
    borrower,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords,
    tags
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationDateInBaseCurrency,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords,
      tags
    })

    this.subType = ASSET_TYPES.LOAN_TO_OTHERS
    this.interestRate = interestRate
    this.loanPeriod = loanPeriod
    this.numberOfRepayments = numberOfRepayments
    this.borrower = borrower
  }
}

export default LoanToOthers

export const loanToOthersColumns = () => [
  ...assetColumns(),
  {
    key: 'purchaseDate',
    title: i18next.t('ASSET_PURCHASE_DATE'),
    type: IMPORT_COL_TYPES.DATE,
    required: true
  },
  {
    key: 'disposalDate',
    title: i18next.t('DISPOSAL_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'interestRateValue',
    title: i18next.t('INTEREST_RATE_VALUE'),
    dataIndex: 'interestRate',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.PERCENTAGE
  },
  {
    key: 'interestRateUnit',
    title: i18next.t('INTEREST_RATE_UNIT'),
    dataIndex: 'interestRate',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'loanPeriodValue',
    title: i18next.t('LOAN_PERIOD_VALUE'),
    dataIndex: 'loanPeriod',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.NUMBER
  },
  {
    key: 'loanPeriodUnit',
    title: i18next.t('LOAN_PERIOD_UNIT'),
    dataIndex: 'loanPeriod',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'numberOfRepayments',
    title: i18next.t('NUMBER_OF_REPAYMENTS'),
    type: IMPORT_COL_TYPES.NUMBER
  },
  ...referenceColumns()
]

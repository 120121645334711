import {
  renderDate,
  renderLinkedItemsPlainText
} from '../../share/helpers'
import i18next from 'i18next'
import PouchDB from 'pouchdb'
import { getRecords, uploadEncryptedData } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

export const eventColumns = (
  contacts = [],
  assetsLiabilities = [],
  documents = [],
  passwords = []
) => {
  return [
    {
      key: 'description',
      title: i18next.t('DESCRIPTION'),
      exportWidth: 40,
      dataIndex: 'description',
      style: { alignment: { wrapText: true } },
      render: description => description
    },
    {
      key: 'date',
      title: i18next.t('DATE'),
      exportWidth: 40,
      dataIndex: 'date',
      style: { alignment: { wrapText: true } },
      render: renderDate
    },
    {
      key: 'tags',
      title: i18next.t('TAGS'),
      exportWidth: 40,
      dataIndex: 'tags',
      style: { alignment: { wrapText: true } },
      render: tags => tags?.join(',')
    },
    {
      key: 'contacts',
      title: i18next.t('CONTACTS'),
      exportWidth: 40,
      dataIndex: 'contacts',
      style: { alignment: { wrapText: true } },
      render: items => {
        return renderLinkedItemsPlainText(items, contacts, 'name')
      }
    },
    {
      key: 'assetsLiabilities',
      title: i18next.t('ASSETS').concat('/').concat(i18next.t('LIABILITIES')),
      exportWidth: 40,
      dataIndex: 'assetsLiabilities',
      style: { alignment: { wrapText: true } },
      render: items =>
        renderLinkedItemsPlainText(items, assetsLiabilities, 'title')
    },
    {
      key: 'documents',
      title: i18next.t('DOCUMENTS'),
      exportWidth: 40,
      dataIndex: 'documents',
      style: { alignment: { wrapText: true } },
      render: items => renderLinkedItemsPlainText(items, documents, 'fileName')
    },
    {
      key: 'passwords',
      title: i18next.t('PASSWORDS'),
      exportWidth: 40,
      dataIndex: 'passwords',
      style: { alignment: { wrapText: true } },
      render: items => renderLinkedItemsPlainText(items, passwords, 'title')
    }
  ]
}

export const unlinkEventFromItems = async (
  userId,
  masterKey,
  event,
  fieldName,
  dbName
) => {
  try {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const linkedList = event[fieldName]?.length
      ? (await getRecords(userId, dbName, masterKey)).filter(item =>
          event[fieldName].includes(item._id)
        )
      : []

    if (linkedList.length) {
      const updatedList = linkedList.map(item => {
        return {
          ...item,
          events: item.events.filter(eventId => eventId !== event._id)
        }
      })

      await db.bulkDocs(updatedList)
      await uploadEncryptedData(db, userId, dbName)
    }
  } catch (error) {
    onError(error)
  }
}

export const unlinkEventFromDbs = async (
  event,
  userId,
  masterKey
) => {
  await unlinkEventFromItems(
    userId,
    masterKey,
    event,
    'assetsLiabilities',
    'assetsLiabilities'
  )

  await unlinkEventFromItems(
    userId,
    masterKey,
    event,
    'assetsLiabilities',
    'pendingAssetsLiabilities'
  )

  await unlinkEventFromItems(userId, masterKey, event, 'documents', 'documents')

  await unlinkEventFromItems(
    userId,
    masterKey,
    event,
    'documents',
    'pendingDocuments'
  )

  await unlinkEventFromItems(userId, masterKey, event, 'contacts', 'contacts')

  await unlinkEventFromItems(
    userId,
    masterKey,
    event,
    'contacts',
    'pendingContacts'
  )

  await unlinkEventFromItems(userId, masterKey, event, 'passwords', 'passwords')

  await unlinkEventFromItems(
    userId,
    masterKey,
    event,
    'passwords',
    'pendingPasswords'
  )
}

import React, { useState, useEffect, useContext } from 'react'
import {
  Modal,
  Form,
  Input,
  Alert,
  message,
  InputNumber,
  Select,
  Row,
  Col
} from 'antd'
import { getUserData, getUserAttributeValue } from '../../lib/cognito'
import AuthContext from '../../contexts/AuthContext'
import usePlans from '../../hooks/usePlans'
import FormItem from '../override/FormItem'
//import { StringResources } from '../../share/StringResources'
import api from '../../lib/api'
import shortid from 'shortid'
import { onError } from '../../lib/sentry'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'
import { useTranslation } from 'react-i18next'
import TextInput from './../common/TextInput'
import {
  sanitizeValue,
  getExternalUserAttributes,
  getPlanNickname
} from '../../share/helpers'

const SendGiftModal = props => {
  const { visible, setVisible, giftsSent, form } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } =
    form
  const { user } = useContext(AuthContext)
  const [errMsg, setErrMsg] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [userInfo, setUserInfo] = useState({
    fullname: '',
    email: ''
  })
  const { t } = useTranslation()
  const externalUser = localStorage.getItem('External_User')

  const plans = usePlans('', true)
  const { defaultPlans } = plans
  const dispatch = useDispatch()

  useEffect(() => {
    if (externalUser) {
      const userAttributes = getExternalUserAttributes()
      const fullname = userAttributes.full_name
      const email = userAttributes.email
      setUserInfo({ fullname, email })
    } else {
      getUserData(user, async (err, data) => {
        if (err) {
          console.log(err)
          return
        }
        const fullname = getUserAttributeValue(
          data.UserAttributes,
          'custom:full_name'
        )
        const email = getUserAttributeValue(data.UserAttributes, 'email')

        setUserInfo({ fullname, email })
      })
    }
  }, [user, externalUser])

  const handleOk = () => {
    setErrMsg('')
    setIsSaving(true)
    validateFields(async (err, values) => {
      try {
        if (err) {
          setIsSaving(false)
          return
        }

        if (giftsSent.some(gc => gc.receiver === values.email && !gc.status)) {
          setErrMsg(t('YOU_HAVE_SENT_A_GIFT_TO_THIS_EMAIL'))
          setIsSaving(false)
          return
        }

        // TODO: should be able to send gift for existing user
        const res = await api.getUsersByEmails(values.email)

        if (res.data?.length) {
          setErrMsg(t('EXISTING_VAULTBOX_USER_CAN_NOT_RECEIVE_GIFT'))
          setIsSaving(false)
          return
        }

        const data = {
          id: shortid.generate(),
          ...values,
          planId: defaultPlans.find(p => p.id === values.plan).id,
          senderEmail: userInfo.email,
          senderName: userInfo.fullname
        }

        await api.sendGift(user.username, JSON.stringify(data))
        message.success(t('SUCCESS_PURCHASED'))

        resetModal()
        dispatch(fetchUser(user.username))
      } catch (error) {
        setErrMsg(t('FAILED_TO_PURCHASE_GIFT'))
        onError(error)
      }
      setIsSaving(false)
    })
  }
  const resetModal = () => {
    resetFields()
    setVisible(false)
  }

  return (
    <Modal
      title={t('SEND_GIFT')}
      visible={visible}
      okText={t('PURCHASE')}
      cancelText={t('CANCEL')}
      onOk={handleOk}
      onCancel={resetModal}
      style={{ maxWidth: 900 }}
      width={'90vw'}
      okButtonProps={{ loading: isSaving }}
      maskClosable={false}
    >
      <Form>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            afterClose={() => setErrMsg('')}
            closable
            style={{ marginBottom: 16 }}
          />
        )}
        <Row gutter={20}>
          <Col sm={12}>
            <FormItem label={t('NAME')}>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: t('INPUT_NAME_MSG') }]
              })(<TextInput name="name" setFieldsValue={setFieldsValue} />)}
            </FormItem>
          </Col>
          <Col sm={12}>
            <FormItem label={t('EMAIL')}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: t('INVALID_EMAIL_MSG')
                  },
                  { required: true, message: t('ENTER_EMAIL_ADDRESS') }
                ]
              })(<TextInput />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col sm={12}>
            <FormItem label={t('SUBSCRIPTION_PLAN')}>
              {getFieldDecorator('plan', {
                initialValue: defaultPlans?.length
                  ? defaultPlans[0].id
                  : undefined,
                rules: [{ required: true, message: t('SELECT_A_PLAN') }]
              })(
                <Select>
                  {defaultPlans.map(plan => (
                    <Select.Option key={plan.id} value={plan.id}>
                      {getPlanNickname(
                        plan.nickname,
                        plan.metadata?.membersCode
                      )}{' '}
                      {plan.nickname} - ${plan.amount / 100}{' '}
                      {plan.currency.toUpperCase()}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>

          <Col sm={12}>
            <FormItem label={t('QUANTITY')}>
              {getFieldDecorator('quantity', {
                initialValue: 1,
                rules: [{ required: true, message: t('ENTER_QUANTITY') }]
              })(<InputNumber min={1} precision={0} />)}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem label={t('GIFT_MESSAGE')}>
              {getFieldDecorator('giftMessage', {
                initialValue: t('GIFT_MESSAGE_SUMMARY'),
                rules: [{ required: true, message: t('INPUT_GIFT_MESSAGE') }]
              })(
                <Input.TextArea
                  onBlur={e =>
                    setFieldsValue({
                      giftMessage: sanitizeValue(e.target.value)
                    })
                  }
                  maxLength={2000}
                />
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const WrappedSendGiftModal = Form.create({ name: 'SendGiftModal' })(
  SendGiftModal
)
export default WrappedSendGiftModal

import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class Trust extends Asset {
  /**
   * Construct a new Trust instance
   * @param {object} params - Trust creation options:
   * @param {object} [params.typeOfTrustInterest]
   * @param {string} [params.trustType]
   * @param {string} [params.trustRevocability]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    currency,
    valuationDate,
    valuationDateInBaseCurrency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    trustRevocability,
    trustType,
    typeOfTrustInterest,
    status,
    events,
    passwords,
    tags
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationDateInBaseCurrency,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords,
      tags
    })

    this.subType = ASSET_TYPES.TRUST
    this.trustRevocability = trustRevocability
    this.trustType = trustType
    this.typeOfTrustInterest = typeOfTrustInterest
  }
}

export default Trust

export const trustColumns = () => [
  ...assetColumns(),
  {
    key: 'typeOfTrustInterestKey',
    title: i18next.t('TYPE_OF_TRUST_INTEREST'),
    dataIndex: 'typeOfTrustInterest',
    objectKey: 'key',
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: true
  },
  {
    key: 'trustRevocability',
    title: i18next.t('TRUST_REVOCABILITY'),
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: false
  },
  {
    key: 'trustType',
    title: i18next.t('TRUST_TYPE'),
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: true
  },
  ...referenceColumns()
]

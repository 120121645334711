import React from 'react'
import { Form, Alert, Steps, Icon, Collapse } from 'antd'
//import { StringResources } from '../../share/StringResources'
import QRCode from 'qrcode.react'
import CodeInput from '../common/CodeInput'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import { P1, H3 } from '../override/Typography'
import AuthenticatorAppsTable from './AuthenticatorAppsTable'
import AuthenticatorAppsInstructions from './AuthenticatorAppsInstructions'
import TextInput from '../common/TextInput'
import { useTranslation } from 'react-i18next'

const { Step } = Steps

function SetupMFA({
  username,
  secretCode,
  errMsg,
  setErrMsg,
  isSubmitting,
  verifySoftwareToken,
  form,
  currentStep,
  setCurrentStep,
  handleCancel,
  handleFinish
}) {
  const { getFieldDecorator } = form
  const { Panel } = Collapse
  const { t } = useTranslation()

  let secretCodeRef

  const steps = [
    {
      title: t('AUTHENTICATOR_APP'),
      content: (
        <>
          <P1>{t('SETUP_MFA_AUTHENTICATION_APP_FISRT_CONTENT')}</P1>
          <P1>{t('SETUP_MFA_AUTHENTICATION_APP_SECOND_CONTENT')}</P1>
          <Collapse
            style={{ margin: '10px 0' }}
            expandIcon={({ isActive }) => (
              <Icon type="caret-right" rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header={<u>{t('SETUP_MFA_AUTHENTICATION_APP_HEADER')}</u>}>
              <AuthenticatorAppsTable />
            </Panel>
          </Collapse>
          <P1>{t('SETUP_MFA_AUTHENTICATION_APP_THIRD_CONTENT')}</P1>

          <div className="steps-actions">
            {handleCancel && (
              <Button size="large" onClick={handleCancel}>
                {t('CANCEL')}
              </Button>
            )}
            <Button
              size="large"
              type="primary"
              onClick={e => setCurrentStep(1)}
            >
              {t('NEXT')}
            </Button>
          </div>
        </>
      )
    },
    {
      title: t('ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP'),
      content: (
        <>
          <P1 style={{ marginBottom: 10 }}>
            {t('USE_YOUR_AUTHENTICATOR_APP_TO_SCAN_THIS_QR_CODE')}
          </P1>

          <QRCode
            value={`otpauth://totp/${username}?secret=${secretCode}&issuer=vaultbox`}
            fgColor="#006D3E"
          />

          <div>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => setCurrentStep(0)}
            >
              {t('NEED_AN_AUTHENTICATOR_APP')}
            </Button>
          </div>

          <Collapse
            expandIcon={({ isActive }) => (
              <Icon type="caret-right" rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header={
                <u>
                  {t('HOW_DO_I_SCAN_THE_QR_CODE_FROM_MY_AUTHENTICATOR_APP')}
                </u>
              }
            >
              <AuthenticatorAppsInstructions />
            </Panel>
            <Panel header={<u>{t('SHOW_CODE_FOR_MANUAL_CONFIGURATION')}</u>}>
              <FormItem>
                <TextInput
                  ref={ref => (secretCodeRef = ref)}
                  value={secretCode}
                  style={{
                    marginRight: 10,
                    width: 'calc(100% - 60px)'
                  }}
                />
                <Button
                  size="large"
                  onClick={e => {
                    secretCodeRef.select()
                    document.execCommand('copy')
                  }}
                  icon="copy"
                />
              </FormItem>
            </Panel>
          </Collapse>

          <P1 style={{ margin: '10px 0' }}>
            {t('ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_FIRST_CONTENT')}
          </P1>
          <P1>{t('ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_SECOND_CONTENT')}</P1>
          <CodeInput
            getFieldDecorator={getFieldDecorator}
            style={{ marginTop: 10 }}
          />

          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              afterClose={() => setErrMsg('')}
              closable
              style={{ marginBottom: 16 }}
            />
          )}

          <div className="steps-actions">
            <Button size="large" onClick={e => setCurrentStep(0)}>
              {t('BACK')}
            </Button>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              onClick={e => {
                form.validateFields((err, values) => {
                  if (err) return
                  verifySoftwareToken(values.code)
                })
              }}
              loading={isSubmitting}
            >
              {t('NEXT')}
            </Button>
          </div>
        </>
      )
    },
    {
      title: t('REVIEW'),
      content: (
        <>
          <P1>{t('SETUP_MFA_REVIEW_SUMMARY')}</P1>

          <div className="steps-actions">
            <Button size="large" type="primary" onClick={handleFinish}>
              {t('FINISH')}
            </Button>
          </div>
        </>
      )
    }
  ]

  return (
    <Form className="setup-mfa-form">
      <H3 style={{ marginBottom: '0.5em' }}>{t('SETUP_MFA')}</H3>
      <P1>{t('SETUP_MFA_SUMMARY')}</P1>
      <a
        href="https://support.vaultbox.tech/support/solutions/articles/51000024123-setting-up-multi-factor-authentication"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('LEARN_MORE_ABOUT_SETTING_UP_MULTI_FACTOR_AUTHENTICATION')}
      </a>
      <Steps current={currentStep} className="setup-mfa-steps">
        {steps.map(step => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>
    </Form>
  )
}

const WrappedSetupMfaForm = Form.create({ name: 'setupMfa' })(SetupMFA)
export default WrappedSetupMfaForm

import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class BrokerageAccount extends Asset {
  /**
   * Construct a new BrokerageAccount
   * @param {object} params - BrokerageAccount creation options:
   * @param {string} [params.brokerageName]
   * @param {string} [params.accountNumber]
   * @param {string} [params.accountType]
   * @param {boolean} [params.jointAccount]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    valuationDate,
    valuationDateInBaseCurrency,
    currency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    brokerageName,
    accountNumber,
    accountType,
    jointAccount,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords,
    tags
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationDateInBaseCurrency,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords,
      tags
    })

    this.subType = ASSET_TYPES.BROKERAGE_ACCOUNT
    this.brokerageName = brokerageName
    this.accountNumber = accountNumber
    this.accountType = accountType
    this.jointAccount = jointAccount
  }
}

export default BrokerageAccount

export const brokerageAccountColumns = () => [
  ...assetColumns(),
  {
    key: 'brokerageName',
    title: i18next.t('BROKERAGE_NAME')
  },
  {
    key: 'accountNumber',
    title: i18next.t('ACCOUNT_NUMBER')
  },
  {
    key: 'accountType',
    title: i18next.t('ACCOUNT_TYPE')
  },
  {
    key: 'jointAccount',
    title: i18next.t('JOINT_ACCOUNT'),
    type: IMPORT_COL_TYPES.BOOLEAN
  },
  ...referenceColumns()
]

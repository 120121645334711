import React, { useState, useEffect, useContext } from 'react'
import api from '../lib/api'
import AuthContext from './AuthContext'
import { Modal } from 'antd'
import Button from '../components/override/Button'
import ReactJoyride, { ACTIONS, EVENTS } from 'react-joyride'
import { ThemeContext } from 'styled-components'
import { onError } from '../lib/sentry'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'

const TourContext = React.createContext()

const TOUR_STEPS = [
  {
    target: 'body',
    content: (
      <div>
        <Trans i18nKey="TOUR_INTRO"></Trans>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    key: 'TOUR_INTRO',
    firstStepOfSection: true,
    sidebarOpened: true
  },
  {
    target: '.tour-menu-step',
    content: (
      <div>
        <Trans i18nKey="GENERAL_MENU"></Trans>
      </div>
    ),
    disableBeacon: true,
    placement: 'right',
    key: 'GENERAL_MENU',
    sidebarOpened: true
  },
  {
    target: '.tour-menu-registry',
    content: (
      <div>
        <Trans i18nKey="ASSET_MENU"></Trans>
      </div>
    ),
    disableBeacon: true,
    placement: 'right',
    key: 'ASSET_MENU',
    firstStepOfSection: true,
    sidebarOpened: true
  },
  {
    target: '.tour-asset-group-buttons',
    content: (
      <div>
        <Trans i18nKey="CREATE_NEW_ASSET_BUTTON"></Trans>
      </div>
    ),
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'CREATE_NEW_ASSET_BUTTON'
  },
  {
    target: '.tour-create-asset-select-type',
    content: (
      <div>
        <Trans i18nKey="CREATE_ASSET_SELECT_TYPE"></Trans>
      </div>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    placement: 'right',
    styles: {
      options: {
        width: 280
      }
    },
    key: 'CREATE_ASSET_SELECT_TYPE'
  },
  {
    target: '.tour-create-asset-form',
    content: (
      <div>
        <Trans i18nKey="CREATE_ASSET_FORM"></Trans>
      </div>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    key: 'CREATE_ASSET_FORM'
  },
  {
    target: '.tour-action-buttons',
    content: (
      <div>
        <Trans i18nKey="SAVE_ASSET_BUTTON"></Trans>
      </div>
    ),
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'SAVE_ASSET_BUTTON'
  },
  {
    target: 'body',
    content: <Trans i18nKey="SAVE_ASSET_SUCCESS"></Trans>,
    disableBeacon: true,
    hideBackButton: true,
    placement: 'center',
    key: 'SAVE_ASSET_SUCCESS'
  },
  {
    target: '.tour-menu-files',
    content: (
      <div>
        <Trans i18nKey="FILE_MENU"></Trans>
      </div>
    ),
    disableBeacon: true,
    hideBackButton: true,
    placement: 'right',
    key: 'FILE_MENU',
    firstStepOfSection: true,
    sidebarOpened: true
  },
  {
    target: '.tour-create-file-button',
    content: <Trans i18nKey="CREATE_FILE_BUTTON"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'CREATE_FILE_BUTTON'
  },
  {
    target: '.tour-file-upload',
    content: (
      <div>
        <Trans i18nKey="ADD_FILE_UPLOAD"></Trans>
      </div>
    ),
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: true,
    key: 'ADD_FILE_UPLOAD'
  },
  {
    target: '.tour-file-info',
    content: (
      <div>
        <Trans i18nKey="ADD_FILE_INFO"></Trans>
      </div>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    key: 'ADD_FILE_INFO'
  },
  {
    target: '.tour-save-file-button',
    content: <Trans i18nKey="SAVE_FILE_BUTTON"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'SAVE_FILE_BUTTON'
  },
  {
    target: 'body',
    content: <Trans i18nKey="UPLOAD_FILE_SUCCESS"></Trans>,
    disableBeacon: true,
    hideBackButton: true,
    placement: 'center',
    key: 'UPLOAD_FILE_SUCCESS'
  },
  {
    target: '.tour-menu-contacts',
    content: (
      <div>
        <Trans i18nKey="CONTACT_MENU"></Trans>
      </div>
    ),
    disableBeacon: true,
    hideBackButton: true,
    placement: 'right',
    key: 'CONTACT_MENU',
    firstStepOfSection: true,
    sidebarOpened: true
  },
  {
    target: '.tour-create-contact-button',
    content: <Trans i18nKey="CREATE_CONTACT_BUTTON"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'CREATE_CONTACT_BUTTON'
  },
  {
    target: '.tour-create-contact-form',
    content: (
      <div>
        <Trans i18nKey="CREATE_CONTACT_FORM"></Trans>
      </div>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    placement: 'right',
    styles: {
      options: {
        width: 300
      }
    },
    key: 'CREATE_CONTACT_FORM'
  },
  {
    target: '.tour-action-buttons',
    content: <Trans i18nKey="SAVE_CONTACT_BUTTON"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'SAVE_CONTACT_BUTTON'
  },
  {
    target: 'body',
    content: <Trans i18nKey="SAVE_CONTACT_SUCCESS"></Trans>,
    disableBeacon: true,
    hideBackButton: true,
    placement: 'center',
    key: 'SAVE_CONTACT_SUCCESS'
  },
  {
    target: '.tour-menu-registry',
    content: (
      <div>
        <Trans i18nKey="REVIEW_REGISTRY"></Trans>
      </div>
    ),
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: true,
    placement: 'right',
    key: 'REVIEW_REGISTRY',
    firstStepOfSection: true,
    sidebarOpened: true
  },
  {
    target: '.tour-created-asset',
    content: <Trans i18nKey="CREATED_ASSET"></Trans>,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    key: 'CREATED_ASSET'
  },
  {
    target: '.tour-asset-overview',
    content: <Trans i18nKey="ASSET_OVERVIEW"></Trans>,
    disableBeacon: true,
    key: 'ASSET_OVERVIEW'
  },
  {
    target: '.tour-edit-asset-button',
    content: <Trans i18nKey="EDIT_ASSET_BUTTON"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'EDIT_ASSET_BUTTON'
  },
  // {
  //   target: '.tour-create-asset-form',
  //   content: `You can edit the information which you have entered before`,
  //   disableBeacon: true,
  //   spotlightClicks: true,
  //   key: 'EDIT_ASSET_FORM'
  // },
  {
    target: '.tour-existed-docs-link',
    content: <Trans i18nKey="LINK_EXISTED_DOCS"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'LINK_EXISTED_DOCS'
  },
  {
    target: '.tour-select-docs-to-link',
    content: (
      <div>
        <Trans i18nKey="SELECT_DOCS_TO_LINK"></Trans>
      </div>
    ),
    disableBeacon: true,
    hideBackButton: true,
    spotlightClicks: true,
    key: 'SELECT_DOCS_TO_LINK'
  },
  {
    target: '.tour-save-selected-docs',
    content: <Trans i18nKey="SAVE_SELECTED_DOCS"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'SAVE_SELECTED_DOCS'
  },
  {
    target: '.tour-existed-docs-link',
    content: <Trans i18nKey="NEW_DOCS_LINK"></Trans>,
    disableBeacon: true,
    hideBackButton: true,
    key: 'NEW_DOCS_LINK'
  },
  {
    target: '.tour-existed-contacts-link',
    content: <Trans i18nKey="LINK_EXISTED_CONTACTS"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'LINK_EXISTED_CONTACTS'
  },
  {
    target: '.tour-contacts-link-modal',
    content: (
      <div>
        <Trans i18nKey="CONTACTS_LINK_MODAL"></Trans>
      </div>
    ),
    disableBeacon: true,
    hideBackButton: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'CONTACTS_LINK_MODAL'
  },
  {
    target: '.tour-existed-contacts-link',
    content: <Trans i18nKey="NEW_CONTACTS_LINK"></Trans>,
    disableBeacon: true,
    hideBackButton: true,
    key: 'NEW_CONTACTS_LINK'
  },
  {
    target: '.tour-action-buttons',
    content: <Trans i18nKey="SAVE_LINKED_ASSET"></Trans>,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none'
      }
    },
    spotlightClicks: true,
    key: 'SAVE_LINKED_ASSET'
  },
  {
    target: 'body',
    content: <Trans i18nKey="SAVE_LINKED_ASSET_SUCCESS"></Trans>,
    disableBeacon: true,
    hideBackButton: true,
    placement: 'center',
    key: 'SAVE_LINKED_ASSET_SUCCESS'
  },
  {
    target: '.tour-menu-deputies',
    content: (
      <div>
        <Trans i18nKey="DEPUTIES_MENU"></Trans>
      </div>
    ),
    disableBeacon: true,
    hideBackButton: true,
    placement: 'right',
    key: 'DEPUTIES_MENU',
    firstStepOfSection: true,
    sidebarOpened: true
  },
  {
    target: '.tour-current-deputies',
    content: <Trans i18nKey="CURRENT_DEPUTIES"></Trans>,
    disableBeacon: true,
    key: 'CURRENT_DEPUTIES'
  },
  {
    target: '.tour-pending-deputies',
    content: <Trans i18nKey="PENDING_DEPUTIES"></Trans>,
    disableBeacon: true,
    key: 'PENDING_DEPUTIES'
  },
  {
    target: '.tour-pending-file-unlock',
    content: (
      <div>
        <Trans i18nKey="PENDING_FILE_UNLOCK"></Trans>
      </div>
    ),
    disableBeacon: true,
    key: 'PENDING_FILE_UNLOCK'
  },
  {
    target: 'body',
    placement: 'center',
    content: (
      <div>
        <Trans i18nKey="COMPLETE_TOUR_MODAL"></Trans>
      </div>
    ),
    disableBeacon: true,
    key: 'COMPLETE_TOUR'
  }
]

export const TOUR_STEP_INDEXES = TOUR_STEPS.reduce((indexes, step, index) => {
  return { ...indexes, [step.key]: index }
}, {})

export const TourContextProvider = ({
  children,
  history,
  tourModalVisible,
  setTourModalVisible,
  tourRun,
  setTourRun,
  setSiderCollapsed
}) => {
  const { user, isProfessionalDeputy, showedMobilePromptModal } =
    useContext(AuthContext)
  const [tourStepIndex, setTourStepIndex] = useState(0)
  const [hasCompletedTour, setHasCompletedTour] = useState(true)
  const { t } = useTranslation()

  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const nextTourStep = () => {
    setTourStepIndex(tourStepIndex + 1)
  }

  const prevTourStep = () => {
    setTourStepIndex(tourStepIndex - 1)
  }

  const handleSidebarCollapse = index => {
    if (isMdUp) return
    setTourRun(false)
    setSiderCollapsed(!TOUR_STEPS[index]?.sidebarOpened)
    setTimeout(() => {
      setTourRun(true)
    }, 400)
  }

  useEffect(() => {
    if (isProfessionalDeputy === false) {
      api
        .getUser(user.username)
        .then(res => {
          const { hasCompletedTour } = res.data
          setHasCompletedTour(hasCompletedTour)
          setTourRun(!hasCompletedTour)
        })
        .catch(err => onError(err))

      const localTourState = JSON.parse(localStorage.getItem('tourState'))
      if (localTourState && localTourState.currentStep) {
        setTourStepIndex(localTourState.currentStep)
      }
    }
  }, [user, setTourRun, isProfessionalDeputy])

  const updatedLanguage = localStorage.getItem('updatedLanguage')

  const handleJoyrideCallback = data => {
    const { action, type, index, step } = data
    //save current step to localStorage
    if (step.firstStepOfSection) {
      localStorage.setItem(
        'tourState',
        JSON.stringify({
          currentStep: tourStepIndex,
          currentLink: history.location.pathname
        })
      )
    }

    if (type === EVENTS.STEP_BEFORE) {
      if (tourStepIndex === TOUR_STEP_INDEXES.ASSET_MENU) {
        history.push('/')
      } else if (tourStepIndex === TOUR_STEP_INDEXES.FILE_MENU) {
        history.push('/files')
      } else if (tourStepIndex === TOUR_STEP_INDEXES.CONTACT_MENU) {
        history.push('/contacts')
      } else if (tourStepIndex === TOUR_STEP_INDEXES.REVIEW_REGISTRY) {
        history.push('/')
      } else if (tourStepIndex === TOUR_STEP_INDEXES.DEPUTIES_MENU) {
        history.push('/deputy')
      }
    }

    // Handle action "NEXT"
    if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
      if (tourStepIndex !== TOUR_STEP_INDEXES.COMPLETE_TOUR) {
        handleSidebarCollapse(index + 1)
      }

      if (tourStepIndex === TOUR_STEPS.length - 1) {
        setTourCompletionStatus(true)
        setTourRun(false)
        localStorage.setItem(
          'tourState',
          JSON.stringify({
            currentStep: 0,
            currentLink: '/'
          })
        )
        return
      }

      setTourStepIndex(index + 1)
    }

    // Handle action "BACK"
    else if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
      handleSidebarCollapse(index - 1)

      if (tourStepIndex === TOUR_STEP_INDEXES.CREATE_ASSET_SELECT_TYPE) {
        history.push('/')
      } else if (tourStepIndex === TOUR_STEP_INDEXES.CREATE_CONTACT_FORM) {
        history.push('/contacts')
      } else if (tourStepIndex === TOUR_STEP_INDEXES.ASSET_OVERVIEW) {
        history.push('/')
        return
      } else if (tourStepIndex === TOUR_STEP_INDEXES.LINK_EXISTED_DOCS) {
        history.goBack()
        return
      }

      setTourStepIndex(index - 1)
    }

    // Handle action "SKIP"
    else if (action === ACTIONS.SKIP && type === EVENTS.TOUR_END) {
      Modal.info({
        title: t('VAULTBOX_TOUR_STOPPED'),
        content: t('VAULTBOX_TOUR_STOPPED_SUMMARY'),
        keyboard: false,
        onOk() {
          setTourCompletionStatus(true)
          setHasCompletedTour(true)
          setTourRun(false)
        }
      })
    }
  }

  const startFromBegin = () => {
    setTourCompletionStatus(false)
    setHasCompletedTour(false)
    setTourModalVisible(false)
    setTourStepIndex(0)
    history.push('/')
    setTourRun(true)
  }

  const continueTour = () => {
    const localTourState = JSON.parse(localStorage.getItem('tourState'))
    setTourCompletionStatus(false)
    setHasCompletedTour(false)
    setTourModalVisible(false)
    setTourStepIndex(localTourState ? localTourState.currentStep : 0)
    history.push(localTourState ? localTourState.currentLink : '/')
    setTourRun(true)
  }

  const setTourCompletionStatus = value => {
    api
      .setTourCompletionStatus(
        user.username,
        JSON.stringify({
          hasCompletedTour: value
        })
      )
      .catch(err => onError(err))
  }

  return (
    <TourContext.Provider
      value={{
        setTourRun,
        nextTourStep,
        prevTourStep,
        tourStepIndex,
        tourRun
      }}
    >
      {!isProfessionalDeputy &&
        !hasCompletedTour &&
        tourRun &&
        updatedLanguage &&
        !showedMobilePromptModal && (
          <ReactJoyride
            callback={handleJoyrideCallback}
            steps={TOUR_STEPS}
            continuous={true}
            disableOverlayClose={true}
            disableCloseOnEsc={true}
            run={tourRun}
            stepIndex={tourStepIndex}
            showSkipButton={true}
            locale={{
              last: t('COMPLETE_TOUR'),
              next: tourStepIndex === 0 ? t('START_TOUR') : t('NEXT'),
              skip: tourStepIndex === 0 ? t('SKIP') : t('STOP_TOUR')
            }}
            disableScrolling={(isMdUp
              ? [
                  TOUR_STEP_INDEXES.GENERAL_MENU,
                  TOUR_STEP_INDEXES.ASSET_MENU,
                  TOUR_STEP_INDEXES.SAVE_LINKED_ASSET,
                  TOUR_STEP_INDEXES.CREATE_ASSET_FORM,
                  TOUR_STEP_INDEXES.CREATE_ASSET_SELECT_TYPE,
                  TOUR_STEP_INDEXES.SAVE_ASSET_BUTTON,
                  TOUR_STEP_INDEXES.LINK_EXISTED_CONTACTS,
                  TOUR_STEP_INDEXES.NEW_CONTACTS_LINK,
                  TOUR_STEP_INDEXES.CREATE_CONTACT_FORM,
                  TOUR_STEP_INDEXES.REVIEW_REGISTRY
                ]
              : [
                  TOUR_STEP_INDEXES.CREATE_ASSET_SELECT_TYPE,
                  TOUR_STEP_INDEXES.CREATE_FILE_BUTTON,
                  TOUR_STEP_INDEXES.ADD_FILE_UPLOAD,
                  TOUR_STEP_INDEXES.NEW_DOCS_LINK,
                  TOUR_STEP_INDEXES.LINK_EXISTED_CONTACTS,
                  TOUR_STEP_INDEXES.NEW_CONTACTS_LINK,
                  TOUR_STEP_INDEXES.SAVE_LINKED_ASSET,
                  TOUR_STEP_INDEXES.CONTACTS_LINK_MODAL
                ]
            ).includes(tourStepIndex)}
            disableScrollParentFix={
              !(
                isMdUp
                  ? [
                      TOUR_STEP_INDEXES.CREATE_FILE_BUTTON,
                      TOUR_STEP_INDEXES.CURRENT_DEPUTIES,
                      TOUR_STEP_INDEXES.PENDING_DEPUTIES,
                      TOUR_STEP_INDEXES.PENDING_FILE_UNLOCK,
                      TOUR_STEP_INDEXES.CREATE_ASSET_FORM,
                      TOUR_STEP_INDEXES.CREATED_ASSET,
                      TOUR_STEP_INDEXES.CREATE_CONTACT_FORM,
                      TOUR_STEP_INDEXES.CREATE_ASSET_SELECT_TYPE
                    ]
                  : [
                      TOUR_STEP_INDEXES.CREATE_NEW_ASSET_BUTTON,
                      TOUR_STEP_INDEXES.CREATE_FILE_BUTTON,
                      TOUR_STEP_INDEXES.CREATE_CONTACT_BUTTON,
                      TOUR_STEP_INDEXES.CREATED_ASSET,
                      TOUR_STEP_INDEXES.CURRENT_DEPUTIES,
                      TOUR_STEP_INDEXES.PENDING_FILE_UNLOCK,
                      TOUR_STEP_INDEXES.PENDING_DEPUTIES
                    ]
              ).includes(tourStepIndex)
            }
            styles={{
              options: {
                backgroundColor: theme.white,
                primaryColor: theme.primary,
                textColor: theme.dark,
                width: 550,
                zIndex: 1000
              },
              buttonClose: {
                display: 'none'
              },
              buttonBack: {
                color: theme.dark,
                fontSize: 14,
                outline: 'none'
              },
              buttonNext: {
                fontSize: 14,
                outline: 'none',
                padding: '11px 15px'
              },
              tooltip: {
                fontSize: 14
              }
            }}
          />
        )}
      <Modal
        title={t('VAULTBOX_TOUR')}
        visible={tourModalVisible}
        onCancel={() => setTourModalVisible(false)}
        footer={null}
      >
        <div
          style={{
            display: 'grid',
            gridGap: 20,
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))'
          }}
        >
          <Button onClick={continueTour}>
            {t('CONTINUE_WHERE_YOU_LEFT_OFF')}
          </Button>
          <Button type="primary" onClick={startFromBegin}>
            {t('START_FROM_BEGINNING')}
          </Button>
        </div>
      </Modal>
      {children}
    </TourContext.Provider>
  )
}

export default TourContext

export const StringResourcesCn = {
  INPUT_TOTP_CODE: '请输入您的身份验证器应用程序中的身份验证码',
  INPUT_SMS_CODE: '请输入发送到您注册电话号码的短信中的验证码',
  SETUP_MFA: '双重身份验证设置',
  SAME_PASSWORD_MSG: '新密码必须与旧密码不同！',
  PASSWORDS_DO_NOT_MATCH_MSG: '您输入的密码不匹配',
  INPUT_CURRENT_PASSWORD_MSG: '请输入您当前的密码',
  INPUT_NEW_PASSWORD_MSG: '请输入您的新密码',
  CONFIRM_NEW_PASSWORD_MSG: '请确认您的新密码！',
  CHANGE_PASSWORD: '请更改密码',
  CURRENT_PASSWORD: '当前密码',
  NEW_PASSWORD: '新密码',
  CONFIRM_NEW_PASSWORD: '确认新密码',
  CLOSE_ACCOUNT: '删除帐户',
  CONFIRM_CLOSE_ACCOUNT_MSG:
    '您确定要删除您的帐户吗？ 您所有的资料将会被删除，以后将无法取回',
  SETUP_MFA_INSTRUCTION: '您需要设置双重身份验证来保护您的vaultbox',
  INPUT_PASSWORD_OR_EMAIL_MSG: '请输入您的用户名或电邮地址！',
  INPUT_PASSWORD_MSG: '请输入您的密码！',
  INPUT_EXISTING_DEPUTY_EMAIL_MSG: '此电邮地址已存在于副手列表中！',
  INPUT_CURRENT_USER_EMAIL_MSG:
    '请输入另一个电邮地址，而不是您自己的电邮地址！',
  FORGOT_PASSWORD: '忘记密码',
  LOGIN: '登录',
  REMEMBER_ME: '记住我',
  SECRET_CODE: '秘密代码',
  FRIENDLY_DEVICE_NAME: '我的TOTP装置',
  USERNAME_OR_EMAIL: '用户名 / 电邮地址',
  SETTINGS: '设置',
  FILES: '文件',
  SIGN_OUT: '登出',
  INVALID_CODE_TYPE: '代码必须是6位数字！',
  PASSWORD_RULES:
    '密码必须至少有八个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符',
  ACTION: '行动',
  PENDING_ACCEPTANCE_OF_DEPUTY: '等待接受',
  DEPUTY_MANAGAMENT: '副管理层',
  PROFILE_AND_SETTINGS: '账户个人设置',
  REQUESTS: '请求',
  PRIMARY_USERS: '主要用户',
  RESEND: '重新发送',
  REVOKE: '撤销',
  REACTIVATE: '重新激活',
  PAYMENT: '付款',
  SIGN_IN: '登录',
  INPUT_FULLNAME_MSG: '请输入您的全名！',
  USE_SAME_NAME_AS_PASSPORT_TOOLTIP: '请按照身份证 / 护照使用全名',
  INPUT_PHONE_NUMBER_MSG: '请输入您的手机号码！',
  INVALID_EMAIL_MSG: '请输入一个有效的电邮地址！',
  INPUT_EMAIL_MSG: '请输入您的电邮地址！',
  CONFIRM_PASSWORD_MSG: '请确认您的密码！',
  FULLNAME: '全名',
  PREFERRED_NAME: '首选名称',
  PHONE_NUMBER: '手机号码',
  EMAIL: '电邮地址',
  SHARE_KEY_STATUS: '共享密钥状态',
  UNLOCK_STATUS: '解锁状态',
  PASSWORD: '密码',
  CONFIRM_PASSWORD: '确认密码',
  VERIFICATION_CODE: '验证码',
  CONTACT_NUMBER: '联系电话',
  ID_PASSPORT_NUMBER: '身份证 / 护照号码',
  NATIONALITY: '国籍',
  RESIDENTIAL_ADDRESS: '居住地址',
  COUNTRY_TERRITORY: '国家 / 地区',
  RESET_PASSWORD: '重置密码',
  RESET_PASSWORD_INSTRUCTION:
    '若要重置您的密码，请输入发送到您的电子邮件的验证码，然后输入您的新密码',
  INPUT_VERIFICATION_CODE_MSG: '输入验证码',
  FILE_NAME: '文件名称',
  DESCRIPTION: '描述',
  TAGS: '标签',
  DOWNLOAD: '下载',
  VIEW: '查看',
  EDIT: '编辑',
  SAVE: '保存',
  CANCEL: '取消',
  UPLOAD: '上传',
  OR: '或',
  INPUT_FILE_NAME_MSG: '输入文件名称',
  SAVE_AS_TYPE: '保存为类型',
  INPUT_SHORT_DESCRIPTION_MSG: '输入文件的简短描述',
  FILE_DETAILS: '文件详细信息',
  PATH: '路径',
  RENAME: '重 新命名',
  SEARCH: '搜索',
  RESET: '重新设置',
  START_TIME: '开始时间',
  END_TIME: '结束时间',
  SELECT_START_DATE: '选择开始日期',
  SELECT_END_DATE: '选择结束日期',
  FILL_IN_SEARCH_FIELD_MSG: '请至少填写一个搜索字段',
  SEARCH_RESULTS: '搜索结果',
  UPLOAD_FILE: '上传文件',
  FILE: '文件',
  SELECT_FILE_TO_UPLOAD_MSG: '选择要上传的文件',
  UPLOAD_FILE_INSTRUCTION: '单击或将文件拖到此区域以上传 ',
  MOVE: '移动',
  MOVE_FILE: '移动文件',
  BROWSE: '浏览',
  DESTINATION: '目的地',
  LINK: '链接',
  UNLINK: '取消链接',
  FAVOURITES: '收藏夹',
  TITLE: '标题',
  TYPE: '类型',
  ASSET: '资产',
  LIABILITY: '负债',
  DESCRIPTION_COL: '描述',
  PERCENTAGE_OWNERSHIP: '拥有权百分比',
  PURCHASE_DATE: '购买日期',
  OK: '好的',
  ACTIONS: '行动',
  EDIT_ASSET_LIABILITY: '编辑资产 / 负债',
  REMOVE_ASSET_LIABILITY: '删除资产 / 负债',
  LINK_TO_ASSETS_LIABILITIES_TOOLTIP: '链接到一个或多个其他资产或负债',
  LINK_TO_DOCUMENTS_TOOLTIP: '链接到一个或多个文档',
  VIEW_PREVIOUS_VERSIONS_ASSET_LIABILITY: '查看之前版本的资产 / 负债',
  ADD_ASSET_LIABILITY_SUCCESS: '已成功添加资产 / 负债',
  UPDATE_ASSET_LIABILITY_SUCCESS: '已成功更新资产 / 负债',
  REACTIVATE_SUBSCRIPTION_SUCCESS: '已成功重新激活您的订阅',
  REACTIVATE_SUBSCRIPTION_FAIL: '无法重新激活订阅',
  ADD_ASSET_LIABILITY: '添加资产 / 负债',
  INPUT_TITLE_MSG: '请输入标题！',
  INPUT_DESCRIPTION_MSG: '请输入描述！',
  INPUT_PURCHASE_DATE_MSG: '请输入购买日期！',
  CURRENCY: '货币',
  CCY: 'Ccy',
  VALUATION_ASSET_LIABILITY_CURRENCY: '以资产 / 负债货币估值',
  ASSETS_CURRENCY: '资产货币',
  VALUATION_ASSET_CURRENCY: '以资产货币估值',
  VALUATION_BASE_CURRENCY: '以基础货币估值',
  START_DATE: '开始日期',
  INPUT_START_DATE: '输入开始日期！',
  MATURITY_DATE: '到期日',
  LIABILITIES_CURRENCY: '负债货币',
  SUCCESSFULLY_SAVED_COLUMNS: '已成功保存列',
  SHOW_HIDE_COLUMNS: '显示 / 隐藏列',
  FAILED_TO_SAVE_COLUMNS: '无法保存列',
  SAVE_COLUMNS_TOOLTIP: '将目前选定的列保存为默认视图',
  SAVE_COLUMNS: '保存列',
  RESET_TOOLTIP: '重置为默认列',
  OUTSTANDING_VALUE_LIABILITY_CURRENCY: '负债货币未偿金额',
  OUTSTANDING_VALUE_BASE_CURRENCY: '基础货币未偿金额',
  LINK_ASSETS_LIABILITIES: '链接资产 / 负债',
  LINK_DOCUMENTS: '链接文档',
  SELECT_ASSETS_LIABILITIES_TO_LINK: '选择要链接的资产 / 负债',
  ASSETS_LIABILITIES: '资产 / 负债',
  SELECT_ASSETS_LIABILITIES: '选择资产 / 负债',
  LINK_ASSETS_LIABILITIES_SUCCESS: '已成功更新资产 / 负债链接',
  VIEW_LINKED_RECORDS: '查看链接的记录',
  SET_BASE_CURRENCY: '设置基础货币',
  SELECT_CURRENCY: '选择货币',
  BASE_CURRENCY: '基础货币',
  GROSS_ASSET_VALUE: '资产总值',
  GROSS_OUTSTANDING_VALUE: '未偿总值',
  NET_VALUE: '净值',
  GROUP_BY_CURRENCY: '按货币分组',
  TIME: '时间',
  ASSET_PURCHASE_DATE: '资产购买日期',
  LIABILITY_START_DATE: '负债开始日期',
  LIABILITY_MATURITY_DATE: '负债到期日',
  VALUATION_IN_ASSET_LIABILITY_CURRENCY: '以资产 / 负债货币估值',
  OTHER_ASSET_TYPE: '其他资产类型',
  VALUATION_DATE: '估值日期',
  TYPE_OF_INTEREST_RATE: '利率类型',
  SECURITY_COLLATERAL: '提供给贷款人的担保 / 抵押品',
  RECURRING_PAYMENT_DUE_DAY: '定期付款到期日',
  ISSUING_BANK: '开证银行',
  CREDIT_LIMIT: '信用额度',
  STATEMENT_DATE: '报表日期',
  CLASS_OF_SHARES: '类别股份',
  SHARE_CERTIFICATE_NUMBER: '股份证书编号',
  PERCENTAGE_OF_SHARES: '此类股份的百分比',
  DISPOSAL_DATE: '处置日期',
  ACCOUNT_TYPE: '账户类型',
  BALANCE_AMOUNT: '余额',
  BUILDING_TYPE: '建筑类型',
  BUILD_DATE: '建造日期',
  FLOOR_SIZE: '面积',
  FLOOR_SIZE_VALUE: '面积价值',
  FLOOR_SIZE_UNIT: '单位面积',
  PROPERTY_ADDRESS: '房地产地址',
  PROPERTY_ADDRESS_MSG: '输入房地产地址',
  HOLDING_TYPE: '持有类型',
  LEASEHOLD_DURATION: '租赁期限',
  TENANT: '租户',
  RENT_AMOUNT: '租金金额',
  RENT_AMOUNT_VALUE: '租金金额价值',
  RENT_AMOUNT_UNIT: '租金金额单位',
  RENTAL_START_DATE: '租赁开始日期',
  RENTAL_END_DATE: '租赁结束日期',
  EXCHANGE: '交换',
  NUMBER_OF_SHARES: '股份数量',
  EXPIRY_DATE: '到期日',
  PAYMENT_DUE_DATE: '付款截止日期',
  LENDER: '贷款人',
  BORROWER: '借款人',
  LOAN_PERIOD: '贷款期限',
  LOAN_PERIOD_VALUE: '贷款期限价值',
  LOAN_PERIOD_UNIT: '贷款期限单位',
  LENGTH_OF_LOAN_VALUE: '贷款价值期限',
  LENGTH_OF_LOAN_UNIT: '贷款单位期限',
  NUMBER_OF_REPAYMENTS: '还款次数',
  MONTHLY_PAYMENT: '月付额',
  ACCOUNT_NUMBER: '帐号',
  LENGTH_OF_LOAN: '贷款期限',
  YEAR_OF_LOAN: '贷款年份',
  OTHER_NOTES: '其他注意事项',
  COMPANY: '公司',
  BENEFICIARIES: '受益人',
  INSURANCE_ADVISOR: '保险顾问',
  ENTITLEMENT: '权利百分比',
  POLICY_NUMBER: '保单号码',
  INSURER: '保险人',
  PREMIUM: '保险费',
  PREMIUM_VALUE: '保费价值',
  PREMIUM_UNIT: '保费单位',
  SUM_ASSURED_IN_BASE_CURRENCY: '以基础货币的保险金额',
  SUM_ASSURED_IN_ASSET_CURRENCY: '以资产货币的保险金额',
  TYPE_OF_POLICY: '保单类型',
  LIFE_ASSURED: '投保人姓名',
  LIFE_ASSURED_TOOLTIP: '在保单合同中获得承保的人',
  POLICY_OWNER: '保单所有者',
  POLICY_OWNER_TOOLTIP: '支付保费并拥有保单合同所有权的人',
  BANK: '银行',
  INTEREST_RATE: '利率',
  INTEREST_RATE_VALUE: '利率值',
  INTEREST_RATE_UNIT: '利率单位',
  SUBTYPE: '次级类型',
  AMOUNT_BORROWED: '借款金额',
  ASSET_LINKS: '资产链接',
  SEARCH_ASSETS: '搜索资产',
  LIABILITY_LINKS: '负债链接',
  SEARCH_LIABILITIES: '搜索负债',
  POSITION_DATE: '资产位置日期',
  BALANCE_DATE: '结帐日期',
  ASSETS: '资产',
  LIABILITIES: '负债',
  INPUT_BALANCE_DATE_MSG: '请输入资产余额日期',
  INPUT_POSITION_DATE_MSG: '请输入资产位置日期',
  QUANTITY: '数量',
  BROKERAGE_NAME: '经纪公司名称',
  JOINT_ACCOUNT: '联名帐户',
  VEHICLE_REGISTRATION_NUMBER: '车辆登记号码',
  RETIREMENT_ACCOUNT_TYPE: '退休账户类型',
  EXPORT_TO_PDF: '导出为PDF',
  EXPORT_TO_PDF_WARNING:
    '您选择了多过10列。为了在导出的PDF文件中很好地显示数据，建议选择少于10列',
  INCLUDE_IN_NETWORTH: '包括总净值中的价值',
  TRUST_TYPE: '信托类型',
  TYPE_OF_TRUST_INTEREST: '信托权益类型',
  TRUST_REVOCABILITY: '信托可撤销性',
  CONTACTS: '联系',
  ADD_CONTACT: '添加联系',
  NAME: '姓名',
  ADD_CONTACT_SUCCESS: '已成功添加联系',
  INPUT_NAME_MSG: '请输入名称！',
  INPUT_GIVEN_NAME_MSG: '请输入给定名称！',
  INPUT_DISPLAY_NAME_MSG: '请输入显示名称！',
  INPUT_LEGAL_NAME_MSG: '请输入法定名称！',
  ORGANISATION: '组织',
  INDIVIDUAL: '个人',
  GIVEN_NAME: '名',
  SURNAME: '姓',
  DATE_OF_BIRTH: '出生日期',
  DISPLAY_NAME: '显示名称',
  LEGAL_NAME: '法定名称',
  EMAILS: '电邮地址',
  CONTACT_INFO: '联系资料',
  ADDRESSES: '地址',
  REFERENCES: '参考',
  URL: '网址',
  EDIT_CONTACT: '编辑联系资料',
  REMOVE_CONTACT: '删除联系资料',
  EDIT_CONTACT_SUCCESS: '已成功编辑联系资料',
  REMOVE_CONTACT_SUCCESS: '已成功删除联系资料',
  REMOVE_CONTACT_CONFIRM: '您确定要删除此联系资料吗？',
  VIEW_PREVIOUS_VERSIONS_CONTACT: '查看之前的联系资料版本',
  CREATE_NEW_FOLDER: '创建新文件夹',
  RENAME_FOLDER: '重命名文件夹',
  INPUT_FOLDER_NAME_MSG: '输入文件夹名称',
  FOLDER_NAME: '文件夹名称',
  NEW_FOLDER: '新文件夹',
  DELETE: '删除',
  CONFIRM_DELETE: '确认删除',
  CONFIRM_DELETE_ITEM_MSG: '您确定要删除此项目吗？',
  CONFIRM_DELETE_ITEMS_MSG: '您确定要删除这些项目吗？',
  PERCENTAGE_OWNERSHIP_TOOLTIP: '输入总体所有权百分比',
  VALUATION_IN_BASE_CURRENCY_TOOLTIP:
    '如果您将此项保留为空白，它将根据您在设置中设置的汇率自动从资产货币转换为您的基础货币',
  OUTSTANDING_AMOUNT_IN_BASE_CURRENCY_TOOLTIP:
    '如果您将此项保留为空白，它将根据您在设置中设置的汇率自动从负债货币转换为您的基础货币',
  LIABILITY_VALUATION_DATE_TOOLTIP: '这是以下未付金额的具体日期',
  LOW_STORAGE_SPACE: '低存储空间',
  LOW_STORAGE_SPACE_CONTENT:
    '没有足够的存储空间。请删除不再需要的文件以清除一些存储空间，或者升级您的订阅套餐以增加存储空间',
  COUNTRY_TERRITORY_OF_INCORPORATION: '公司注册成立的国家 / 地区',
  COMPANY_REGISTRATION_NUMBER: '公司注册号码',
  PHONE: '电话',
  REGISTRY: '注册表',
  DEPUTY: '副手',
  TRASH: '垃圾箱',
  DOCUMENTS: '文档',
  DETAILS: '细节',
  EXPORT_TO_EXCEL: '导出到Excel',
  PASSPORT_NUMBER: '护照号码',
  PROFILE_INFORMATION: '账户个人设置',
  EXCHANGE_RATES: '汇率',
  MY_DEPUTIES: '我的副手',
  PHONE_NUMBERS: '电话号码',
  ALL_FILES: '所有文件',
  CRYPTO_CURRENCY: '加密货币',
  WALLET_ADDRESS: '钱包地址',
  PD_NAME: '由专业副手委托',
  PRICE: '价格',
  WALLET_PROVIDER: '钱包提供商',
  PENDING_RECOVERY: '等待恢复',
  RESET_ACCOUNT_NOW: '立即重置帐户',
  WILL_WAIT: '好的，我会等',
  RECOVER_DATA_FIRST_CONTENT: '你需要等待您的副手的批准才能恢复您的资料',
  RECOVER_DATA_SECOND_CONTENT:
    '如果您没有设置一个副手，或者您的副手不再可用，则无法恢复您现有的资料，因此您需要重设您的帐户才能重新启动',
  RESET_ACCOUNT_CONFIRMATION: '重置帐户确认',
  RESET_ACCOUNT_MODAL_CONTENT: '你确定要重设您的帐户吗？',
  SUCCESSFULLY_RESET_ACCOUNT: '成功重置帐户',
  FAILED_TO_RESET_ACCOUNT: '无法重置帐户',
  WELL_COME_TO_VAULTBOX: '欢迎回到',
  BY_CONTINUING: '若您继续使用本网站，则视为同意遵守',
  TERMS_OF_SERVICE: '服务条款',
  RESET_MFA: '重置双重身份验证',
  RESET_MFA_FIRST_CONTENT:
    '如果您丢失或重置了手机，并且无法再从身份验证器应用程序中获取身份验证器代码，则可以重置双重身份验证以再次进行设置',
  RESET_MFA_SECOND_CONTENT:
    '当您重置双重身份验证时，您将会在您的注册电话号码中收到一次性密码。请使用该代码以完成重置',
  RESET_MFA_WITH_PHONE_NUMBER: '使用新电话号码重置双重身份验证',
  RESET_MFA_WITH_PHONE_NUMBER_CONTENT:
    '如果您丢失了电话和电话号码，您可以用新的电话号码重置双重身份验证以重新进行设置。这项行动需要得到您副手的批准',
  FAIL_TO_GET_COUNTRY_CODE: '无法获取国家代码',
  GIFT_INFO_NOT_FOUND: '无法找寻礼物信息',
  GIFT_RECEIVER_EMAIL_MISSMATCH:
    '礼物接收者的电子邮件地址不匹配。 请注册收到礼物的电子邮件地址',
  GIFT_PLAN_MISSMATCH: '礼物计划不匹配',
  SUBSCRIPTION_PLAN_EMPTY_MSG: '请选择一项订阅套餐',
  INVALID_PROMOTION_CODE: '无效的促销代码',
  PROMOTION_CODE_MSG: '此促销代码不能应用于所选的订阅套餐',
  FAILED_TO_CHECK_CODE_VALIDATE: '无法查寻代码有效性',
  PERSONAL_INFO_DESCRIPTION:
    '请记住此密码，因为除了您之外，任何人都无法进入您的<strong>vaultbox</strong>，直到您登录并设定您的副手',
  SUBSCRIPTION_PLANS: '订阅套餐',
  REVIEW: '审查',
  PERSONAL_INFORMATION: '个人资料',
  SUBSCRIPTION: '订阅',
  PLAN: '套餐',
  FREE: '免费',
  APPLIED_REFERRAL_CODE: '已应用了推荐识别代码',
  APPLIED_PROMOTION: '应用促销',
  PROMOTION_NAME: '促销名称',
  PERCENTAGE_DISCOUNT: '百分比折扣',
  FIXED_AMOUNT_DISCOUNT: '固定金额折扣',
  DURATION: '持续时间',
  ONCE: '一次',
  FOREVER: '永久',
  READ_AND_AGREE_TERM_OF_SERVICE: '我已阅读并同意',
  MARKETING_CONSENT_FROM_VAULTBOX:
    '我同意从Sircured、其附属公司和合作伙伴处收到有关<strong>vaultbox</strong>的营销材料',
  MARKETING_CONSENT_FROM_THIRT_PARTY:
    '我同意从Sircured、其附属公司、合作伙伴和第三方处收到有关我可能感兴趣的营销材料',
  NOT_AGREE_TERMS_OF_SERVICE_MSG: '请同意服务条款',
  PREVIOUS: '之前',
  SUBMIT: '提交',
  NEXT: '下一个',
  REGISTER: '注册',
  REGISTER_DESCRIPTION: '管理和保护所有重要的事情',
  CREATE_A_NEW_ACCOUNT: '创建一个新账户',
  ACCOUNT_CREATED: '已创建帐户',
  ACCOUNT_CREATED_SUCCESSFULLY_MSG:
    '一封电子邮件已发送到<strong>{{email}}</strong>，以验证您的电子邮件地址。 请按照电子邮件中的指示激活您的帐户',
  RETURN_TO_HOMEPAGE: '返回主页',
  INPUT_PASSPORT_NUMBER_MSG: '请输入您的护照号码！',
  MOBLIE_NUMBER_WITH_CODE: '手机号码（选择国家代码）',
  INPUT_BASE_CURRENCY_MSG: '选择您的基础货币！',
  BASE_CURRENCY_TOOLTIP:
    '这是您在注册表中的资产和负债记录的默认货币。您也可以稍后在设置中更改此设置',
  MONTHS: '月',
  SELECT_COUNTRY_CODE: '选择国家代码',
  INVALID_MOBLIE_NUMBER: '无效的手机号码',
  iS_LONG_ENOUGHT: '至少8个字符',
  HAS_NUMBER: '至少1个号码',
  HAS_SPECIAL_CHAR: '至少1个特殊字符',
  HAS_UPPERCASE: '至少1个大写字母',
  HAS_LOWERCASE: '至少1个小写字母',
  INVALID_PASSWORD: '无效密码',
  THANKS_AND_GOODBYE: '谢谢，再见',
  DELETED_ACCOUNT_SUCCESSFULLY_MSG:
    '您的 <strong>vaultbox</strong> 帐户现在已删除',
  RESET_PASSWORD_BY_EAMIL: '通过电子邮件重置密码',
  COMPLETE: '完成',
  ENTER_YOUR_EMAIL_ADDRESS: '输入您的电子邮件地址',
  RESET_PASSWORD_WITH_DEPUTIES_DESCRIPTION:
    '您的帐户恢复请求已发送给您的副手。当他们两个都解锁了您的帐户后，您将能够使用您的新密码登录',
  RESET_PASSWORD_DESCRIPTION:
    '由于您没有足够的副手来恢复您的资料，因此您的帐户已被重置',
  CLICK_TO_GO_TO_HOMEPAGE: '单击<i>完成</i>转到主页',
  ACCOUNT_RECOVERY: '帐户恢复',
  RECOVER_ACCESS_TO_YOUR_ACCOUNT: '恢复您的账户',
  FINISH: '完成',
  ABOUT_US: '关于我们',
  HELP: '帮助',
  PRIVACY: '隐私',
  ALREADY_HAVE_AN_ACCOUNT: '已经有帐号了？',
  NEW_TO_VAULTBOX: '<strong>vaultbox</strong>的新用户吗？',
  RESET_PASSWORD_TITLE: '输入发送到您的电子邮件的验证，然后输入您的新密码',
  ACCOUNT_ACTIVATION_FAILED: '无法激活帐户',
  ACCOUNT_ACTIVATION_SUCCESSED: '您的<strong>vaultbox</strong> 帐户已激活',
  ACTIVATING: '正在激活….',
  DATA_RECOVERY: '资料恢复',
  DATA_RECOVERY_SUMMARY: '输入您的密码以更新资料加密',
  SUCCESSFULLY_RECOVERED_DATA: '已成功恢复资料',
  FAILED_TO_RECOVER_DATA: '无法恢复资料',
  UPDATE_PHONE_NUMBER_REQUEST_SUCCESSFULLY:
    '您的请求已发送给您的副手了。 请等待批准',
  YOU_DO_NOT_HAVE_ANY_DEPUTIES: '您没有任何副手',
  RESET_PHONE_NUMBER: '重置电话号码',
  RESET_PHONE_NUMBER_SUMMARY: '请寻求您的副手的帮助来更改您的电话号码',
  NEW_PHONE_NUMBER: '新的手机号码（选择国家代码）',
  SUCCESSFULLY_RESTORED_ITEMS: '已成功恢复项目',
  FAILED_TO_RESTORE_ITEMS: '无法恢复项目',
  PERMANENTLY_DELETE: '永久删除？',
  PERMANENTLY_DELETE_ITEM_CONFIRM: '您确定要永久删除此项目吗？',
  PERMANENTLY_DELETE_ITEMS_CONFIRM: '您确定要永久删除这些项目吗？',
  SUCCESSFULLY_DELETED_ITEM: '已成功删除项目',
  SUCCESSFULLY_DELETED_ITEMS: '已成功删除{{count}}项目',
  FAILED_TO_DELETE_ITEM: '无法删除项目',
  FAILED_TO_DELETE_ITEMS: '无法删除项目',
  PERMANENTLY_DELETE_ALL: '永久删除所有项目？',
  PERMANENTLY_DELETE_ALL_CONFIRM: '您确定要永久删除所有项目吗？',
  SUCCESSFULLY_DELETED_ALL_ITEMS: '已成功删除所有项目',
  RESTORE_ALL: '恢复所有项目？',
  RESTORE_ALL_CONFIRM: '您确定要恢复所有项目吗？',
  DELETE_ASSETS_AND_LIABILITIES: '已删除资产和负债',
  DELETED_CONTACTS: '已删除的联系资料',
  DELETED_FILE_AND_FOLDERS: '已删除的文件和文件夹',
  SELECTED_RECORDS: '选定的记录',
  BULK_DELETE: '批量删除',
  SEARCH_FIL_OR_FOLDER: '搜索文件或文件夹',
  SEARCH_CONTACT: '搜寻联系资料',
  SEARCH_ASSET_OR_LIABILITY: '搜索资产或负债',
  TOUR_INTRO:
    '<strong>vaultbox</strong> - 您的数码保险箱。列出你的资产和负债，追踪你的资产净值，上传关键文件等等。让我们开始管理和保护对您重要的一切',
  GENERAL_MENU:
    '您可以轻松地在注册表中添加资产和负债，将文件上传到文档，在联系中添加您的朋友、服务提供商和家庭成员的详细资料，管理在过世时或在副手丧失能力期间您将委托的人进入您的vaultbox，并使用遗产管理来规划您的遗产',
  ASSET_MENU:
    '游览到<strong>注册表</strong>以合并、查看和管理您的所有资产和负债',
  CREATE_NEW_ASSET_BUTTON:
    '单击“新建”以添加新的资产或负债。 或者，您可以单击右下角的绿色+按钮来执行此操作',
  CREATE_ASSET_SELECT_TYPE:
    '选择适用的资产或负债类别以填充您的<strong>注册表</strong>',
  CREATE_ASSET_FORM:
    '您可以参考已预先填写在相关字段中的样本资料。尝试输入自己的资料，完成后单击“下一步”',
  SAVE_ASSET_BUTTON:
    '当您准备好将您的资产保存到<strong>注册表</strong>，请单击“保存”。 如果您要编辑您的详细资料，请单击“返回”',
  SAVE_ASSET_SUCCESS: '您已成功添加一个新资产',
  FILE_MENU: '游览到<strong>文件</strong>以安全和有顺序的方式储存和整理文件',
  CREATE_FILE_BUTTON: '点击“上传”开始上传一份新文件',
  ADD_FILE_UPLOAD: '单击或将您的文件拖到此区域以上传到<strong>文件</strong>库',
  ADD_FILE_INFO:
    '轻松地标签，分类和标记文件或创建新的文件夹。 <strong>文件档</strong>中的默认文件夹可帮助您快速整理文件',
  SAVE_FILE_BUTTON:
    '当您准备好保存文件后，请单击“保存”。如果您想更改详细资料，请单击“返回”',
  UPLOAD_FILE_SUCCESS: '您已成功上传新文件',
  CONTACT_MENU:
    '游览到<strong>联系</strong>以添加您的家庭成员、业务伙伴或服务提供商的联系资料',
  CREATE_CONTACT_BUTTON: '请单击“新建”以创建新联系资料',
  CREATE_CONTACT_FORM:
    '在此处填写所有您的个人或组织的重要联系资料。 您可以在<strong>注册表</strong>中将联系人标记为特定的资产或负债',
  SAVE_CONTACT_BUTTON:
    '您准备好保存联系资料后，单击“保存”。 如果您需要更改详细资料，请单击“返回”',
  SAVE_CONTACT_SUCCESS: '您已成功添加一个新联系资料',
  REVIEW_REGISTRY: '游览到<strong>注册表</strong>以查看您的资产并更新任何资料',
  CREATED_ASSET: '选择您的任何资产以查看和更新任何资料',
  ASSET_OVERVIEW: '在快照中清楚地看到您的资产在一段时间内的表现',
  EDIT_ASSET_BUTTON: '单击“编辑”以修改任何资料',
  LINK_EXISTED_DOCS: '单击“ +”按钮链接与此资产相关的任何文件',
  SELECT_DOCS_TO_LINK:
    '从<strong>文件</strong>库中，选择要链接的任何文件。单击“下一步”链接它们',
  SAVE_SELECTED_DOCS: '准备好后，单击“选择”。 要选择另一个文件，单击“返回”',
  NEW_DOCS_LINK: '文件到资产的链接现已完成',
  LINK_EXISTED_CONTACTS:
    '要将一个联系人链接到特定资产，请单击“ +”以链接与此资产相关的任何联系',
  CONTACTS_LINK_MODAL: '从<strong>联系</strong>中选择一个联系，然后单击“保存”',
  NEW_CONTACTS_LINK: '现在已完成与此资产的联系链接',
  SAVE_LINKED_ASSET: '准备好后，单击“保存”',
  SAVE_LINKED_ASSET_SUCCESS: '更改已保存',
  DEPUTIES_MENU:
    '您可以选择任命副手进入您的<strong>vaultbox</strong>。 拥有副手可以帮助您进行遗产管理',
  CURRENT_DEPUTIES: '在此处查看、撤销或更新您分配的副手',
  PENDING_DEPUTIES: '在此处检查您所有的副手是否都接受了您的副手请求',
  PENDING_FILE_UNLOCK:
    '您可以在文件中将所有文件标记为秘密。 他们只有在副手解锁后才能打开。 在此处查看最新的文件解锁状态',
  COMPLETE_TOUR_MODAL:
    '您已经游览到了<strong>vaultbox</strong>之旅的终点。您可以从“快速访问”菜单重复此游览，然后重新开始vaultbox之旅',
  VAULTBOX_TOUR_STOPPED: 'Vaultbox游览已停止',
  VAULTBOX_TOUR_STOPPED_SUMMARY:
    '您已停止了vaultbox游览。 您可以随时在“快速访问菜单> Vaultbox之旅”中再次进行游览',
  COMPLETE_TOUR: '完成游览',
  START_TOUR: '开始游览',
  STOP_TOUR: '停止游览',
  SKIP: '跳过',
  VAULTBOX_TOUR: 'Vaultbox游览',
  CONTINUE_WHERE_YOU_LEFT_OFF: '从上次您停下的地方继续',
  START_FROM_BEGINNING: '从头开始',
  FAILED_TO_LOAD_YOUR_VAULTBOX_DATA: '无法加载您的vaultbox数据',
  VIEW_DETAILS: '查看详情',
  PLEASE_LOG_IN_AGAIN_TO_RETRY: '请重新登录以重试',
  LOG_OUT: '注销',
  SHOWED_MOBILE_PROMPT:
    '感谢您在手机上打开保险箱。要获得最佳体验和更详细的信息，请通过桌面浏览器登录',
  BACK_DATED_AMEND_CONFIRMATION: '回溯修正确认',
  BACK_DATED_AMEND_SUMMARY: '资产净值和估值更改将记录于选定的',
  ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE: '您确定您要继续吗？',
  UPLOAD_DOCUMENT: '上传文档',
  INFORMATION: '信息',
  CREATE_NEW: '创建新的',
  ASSET_LIABILITY_RECORD_NOT_FOUND: '未找到资产 / 负债记录',
  SUCCESSFULLY_LINKED_OR_UNLINKED_DOCUMENT: '已成功{{linkedOrUnlinked}}文档',
  LINKED: '链接',
  UNLINKED: '取消连接',
  SUCCESSFULLY_LINKED_ASSET_LIABILITY: '已成功链接资产 / 负债',
  SUCCESSFULLY_UNLINKED_ASSET_LIABILITY: '已成功取消资产 / 负债链接',
  SUCCESSFULLY_UNLINKED_CONTACT: '已成功取消链接联系人',
  FAILED_TO_UNLINK_CONTACT: '无法取消链接联系人',
  SUCCESSFULLY_LINKED_CONTACT: '已成功链接联系人',
  FAILED_TO_LINK_CONTACT: '无法链接联系人',
  DETAIL: '细节',
  ASSET_NAME: '资产名称',
  LIABILITY_NAME: '责任名称',
  OVERVIEW: '概述',
  FAILED_TO_EXPORT_TO_PNG: '无法导出到PNG',
  EXPORT_TO_PNG: '导出到PNG',
  TIME_RANGE: '时间范围',
  DATA: '数据',
  FILES_AND_FOLDERS: '文件和文件夹',
  FOLDERS_COUNT_AND_FILES_COUNT: '{{foldersCount}}文件夹和{foldersCount}文件',
  SEARCH_FILE_OR_FOLDER_NAME: '搜索文件或文件夹名称',
  LINK_NEW_FILE: '链接新文件',
  LINK_ASSETS: '链接资产',
  LINK_LIABILITIES: '链接责任',
  CUSTOM: '定制',
  SELECT_EXCHANGE: '选择交易所',
  EXCHANGE_NAME: '交易所名称',
  EXCHANGE_CODE: '交易所代码',
  CURRENCY_NAME: '货币名称',
  CURRENCY_CODE: '货币代码',
  ASSET_LIABILITY_CURRENCY: '资产 / 负债货币',
  TYPE_CURRENCY: '{{type}}货币',
  LINK_TO_DOCUMENTS: '链接到文档',
  LINK_TO_ASSETS_LIABILITIES: '链接到资产 / 负债',
  INCLUDE_VAULE_IN_NETWORTH: '在净值中包含价值',
  BACK_DATED_AMEND: '回溯修正',
  FAILED_TO_GET_REGISTRY_COLUMNS: '无法获取注册表列',
  DELETE_VALUATION_CHANGE: '删除估值更改',
  DELETE_VALUATION_CHANGE_CONFIRM: '您确定要删除此项目的估值更改吗？',
  DELETE_VALUATION_BEFORE_THIS_DATE: '在此日期之<strong>前</strong>也删除估值',
  DELETE_VALUATION_AFTER_THIS_DATE: '在此日期之<strong>后</strong>也删除估值',
  SUCCESSFULLY_DELETED_ASSET_LIABILITY: '已成功删除资产 / 负债',
  FAILED_TO_DELETE_ASSET_LIABILITY: '无法删除资产 / 负债',
  VALUATION_IN_BASE_CURRENCY_WITH_PERCENTAGE_OWNERSHIP_TOOLTIP:
    '以您的基础货币表示的估值总和，并考虑所有权百分比',
  TOTAL: '总计',
  NO_ASSETS: '没有资产',
  ENTER_THE_SEARCH_KEYWORD: '输入搜索关键字',
  CREATE: '创建',
  NO_LIABILITIES: '没有负债',
  BALANCE_SHEET: '资产负债表',
  GROUP: '组',
  GROUP_BY: '分组依据',
  NET_WORTH: '净值',
  NO_ASSETS_LIABILITIES: '没有资产 / 负债',
  REPORTS: '报告',
  NO_DOCUMENTS: '没有文档',
  ENTER_LABEL: '输入{{label}}',
  SELECT_LABEL: '选择{{label}}',
  ADD_NEW: '添加新的',
  TO_EXCEL: '到Excel',
  TO_PDF: '到PDF',
  EXPORT: '导出',
  TOTAL_LIABILITIES: '负债总额',
  EXCLUDED_FROM_THE_TOTAL_NET_WORTH: '从总净值中排除',
  CONTACT_LINKS: '联系链接',
  UNLINK_CONTACT_CONFIRM: '您确定要取消此联系人的链接吗？',
  YES: '是的',
  NO: '不',
  LINK_CONTACTS: '链接联系人',
  SELECT_CONTACT: '选择联系人',
  ADD_NEW_CONTACT: '添加新联系人',
  DOCUMENT_LINKS: '文档链接',
  UNLINK_DOCUMENT_CONFIRM: '您确定取消链接此文档吗？',
  Select: '选择',
  MORE_THAN_TEN_COLUMNS_SELECTED: '选择了10列以上',
  NO_DATA: '没有数据',
  INVALID_FILE_MISSING_THE_HEADER_ROW: '无效文件：缺少标题行',
  INVALID_FILE_MISSING_THE_STATEMENT_HEADER_ROW: '缺少语句标题行',
  INVALID_FILE_MISSING_COLUMN: '无效文件：缺少列{{name}}',
  INVALID_FILE_MISSING_VALUATION_DATE: '无效文件：缺少估值日期',
  UPDATING_ASSETS_LIABILITIES: '更新资产 / 负债？',
  UPDATING_ASSETS_LIABILITIES_SUMMARY:
    '{{updatedRecordsCount}} 注册表中的项将被更新',
  SUCCESSFULLY_IMPORTED_DATA: '已成功导入数据',
  FAILED_TO_IMPORT_DATA: '无法导入数据',
  IMPORT_FROM_CSV: '从CSV导入',
  TEMPLATE: '模板',
  DOWNLOAD_SAMPLE_TEMPLATE: '下载样品模板',
  SELECT_A_TEMPLATE: '选择一个模板',
  FROM_CSV: '来自CSV',
  FROM_EXCEL: '来自Excel',
  IMPORT: '导入',
  IMPORT_FROM_EXCEL: '从Excel导入',
  INVALID_FILE_TYPE: '无效的文件类型',
  ASSET_LIABILITY_RECORDS: '资产 / 负债记录',
  CONTACT_RECORDS: '联系',
  EVENT_RECORDS: '事件',
  DOCUMENT_RECORDS: '文档',
  FILE_RECORDS: '文件',
  WILL_BE_UNLINKED_FROM_THIS_ITEM: '将与此项目取消链接',
  DELETE_ALL_HISTORICAL_VALUATIONS_DATA: '删除所有历史估值数据',
  ENTER: '输入',
  SELECT: '选择',
  SELECTED: '已选择',
  ADD: '添加',
  AUTHENTICATOR_APP: '身份验证器应用程序',
  BRIEF_STEPS: '简单步骤',
  GOOGLE_AUTHENTICATOR: '谷歌身份验证器',
  OPEN_THE_APP: '打开应用程序',
  TAP_BEGIN_SETUP: '点击“开始设置”',
  TAP_SCAN_BARCODE: '点击“扫描条形码”',
  SCAN_THE_QR_CODE: '扫描二维码',
  TAP_PLUS_BUTTON: '点击“ +”按钮',
  TAP_OTHER: '点击“其他（谷歌、脸书等）”',
  ENTER_THE_AUTHENTICATION_CODE: '在此屏幕下方的框中输入身份验证码',
  LASTPASS_AUTHENTICATOR: 'LastPass身份验证器',
  MICROSOFT_AUTHENTICATOR: '微软身份验证器',
  MFA_FIRST_CONTENT:
    '以下是使用一些常用的身份验证器应用程序扫描二维码的简单步骤',
  MFA_SECOND_CONTENT: '以上的信息纯为方便您而提供，可能与实际使用有所不同',
  IPHONE: '苹果手机',
  ANDROID: '安卓',
  APP_STORE: '应用程序商店',
  PLAY_STORE: 'Play store',
  MFA_TABLE_SUMMARY:
    '这些应用程序和链接纯为方便与参考为设，并非对其适用性的认可或保证',
  CODE: '代码',
  CODE_IS_REQUIRED: '必需提供代码！',
  FAILED_TO_ADD_OTHER_CRYPTO: '无法添加其他加密货币',
  SELECT_CRYPTOCURRENCY: '选择加密货币',
  OTHER: '其他',
  AN_ERROR_HAS_OCCURRED: '已发生一个错误',
  MULTI_FACTOR_AUTHENTICATION: '多重身份验证',
  DID_NOT_RECEIVE_THE_SMS: '没有收到短信？',
  RESEND_SMS: '重新发送短信',
  SETUP_MFA_AUTHENTICATION_APP_FISRT_CONTENT:
    '要在vaultbox上设置多重身份验证，您的手机上需要有一个兼容的身份验证器应用程序',
  SETUP_MFA_AUTHENTICATION_APP_SECOND_CONTENT:
    '如果您没有，您可以考虑从特定于您的手机类型的应用程序商店安装一个',
  SETUP_MFA_AUTHENTICATION_APP_THIRD_CONTENT:
    '当您准备好安装身份验证器应用程序后，请单击下一步',
  SETUP_MFA_AUTHENTICATION_APP_HEADER: '查看常用的身份验证器应用程序列表',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP:
    '将vaultbox添加到您的身份验证器应用程序',
  USE_YOUR_AUTHENTICATOR_APP_TO_SCAN_THIS_QR_CODE:
    '使用您的身份验证器应用程序扫描此二维码',
  NEED_AN_AUTHENTICATOR_APP: '需要一个身份验证器应用程序吗？',
  HOW_DO_I_SCAN_THE_QR_CODE_FROM_MY_AUTHENTICATOR_APP:
    '如何从我的身份验证器应用程序扫描二维码？',
  SHOW_CODE_FOR_MANUAL_CONFIGURATION: '显示用于手动配置的代码',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_FIRST_CONTENT:
    '确保您的身份验证器应用程序中只有一个vaultbox配置文件。如果您有多过一个，我们建议您删除它们，然后重新开始',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_SECOND_CONTENT:
    '若要继续，请从您的身份验证器应用程序中输入身份验证码。 此代码将自动刷新，因此请输入此代码，然后单击下一步，以便我们可以验证它',
  BACK: '返回',
  SETUP_MFA_REVIEW_SUMMARY:
    '您现在已为vaultbox设置了您的身份验证器应用程序。当您下次登录时，除了您的电子邮件地址和密码外，系统还会要求您输入来自身份验证器应用程序的身份验证码',
  SETUP_MFA_SUMMARY:
    '为了保护您的vaultbox，您需要先设置多重身份验证，然后再继续',
  LEARN_MORE_ABOUT_SETTING_UP_MULTI_FACTOR_AUTHENTICATION:
    '了解有关设置多重身份验证的更多信息',
  MINOR_EDIT: '次要编辑',
  MINOR_EDIT_TOOLTIP: '如果编辑是次要的，那么它不会显示在版本列表中',
  GENERAL_INFO: '一般信息',
  ADDRESS: '地址',
  LABEL: '标签',
  DOCUMENTS_DETAIL: '{{foldersCount}}文件夹和{filescont}文件',
  CONTACT_USED_IN_LEGACY: '无法删除此联系人·，因为它用于遗产管理',
  FAILED_TO_DELETE_CONTACT: '无法删除联系人',
  WILL_BE_UNLINKED_FROM_THIS_CONTACT: '将与此联系人取消链接',
  GROUP_INFORMATION: '组信息',
  PEOPLE: '人',
  CONTACT_INFORMATION: '联系资料',
  NOTE: '注意',
  VERSION: '版本',
  SELECT_CONTACTS_TO_IMPORT: '选择要导入的联系人',
  FAILED_TO_IMPORT_CONTACTS: '无法导入联系人',
  INVALID_RECORDS_CANT_BE_IMPORTED: '无法导入无效记录（缺少名字）',
  ALL: '全部',
  NO_CONTACTS_TO_IMPORT: '没有要导入的联系人',
  REFERENCE_LABEL: '参考标签',
  REFERENCE_INFO: '参考资料',
  FROM_VCARD: '来自vCard',
  FAILED_TO_EXPORT_TO_EXCEL: '无法导出到Excel',
  FAILED_TO_EXPORT_TO_PDF: '无法导出到PNG',
  TAG: '标签',
  NO_CONTACTS_RESULTS: '没有联系人结果',
  SEARCH_CONTACT_FROM_LEFT_SIDEBAR:
    '请搜索联系人姓名或从左侧边栏中选择联系人以获取资料',
  SEARCH_CONTACT_FROM_CONTACTS_LIST:
    '请搜索联系人姓名或从联系人列表中选择联系人以获取资料',
  NO_CONTACTS: '没有联系人',
  INVALID_TEMPLATE: '无效的模板',
  SHEET: '工作表',
  ROW: '行',
  MISSING_COLUMN: '缺少列',
  IS_MANDATORY: '是强制性的',
  SUCCESSFULLY_IMPORT_CONTACTS: '已成功导入{{selectedContacts}} 联系人',
  IMPORT_FROM_VCARD: '从vCard导入',
  UPDATE_PENDING_ACCEPTANCE_FROM_DEPUTY: '更新等待副手的接受',
  UPDATE_MY_INFO: '更新我的资料',
  STATUS: '状态',
  BACKUP_KEY_DISTRIBUTED: '备份密钥已分发',
  ACCEPTED: '已接受',
  CANNOT_REVOKE_DEPUTY: '无法撤消副手',
  ARE_YOU_SURE_TO_REVOKE_THIS_DEPUTY: '你确定要撤销这个副手吗？',
  CANNOT_REVOKE_DEPUTY_SUMMARY:
    '您需要<strong>{sharesThreshold}</strong>个副手一起才能解锁您的vaultbox。 为了能够撤消一个副手，您需要减少此数量',
  WHY_DO_I_NEED_TO_APPOINT_DEPUTIES: '我为什么要委任副手？',
  DEPUTY_SUMMARY:
    '副手是可选的。但是，如果您丢失您的密码或无法进入您的<strong>vaultbox</strong>账户，则需要让副手重置您的密码或恢复您的<strong>vaultbox</strong>账户。 我们会生成一个恢复密钥让您的副手安全地保存该恢复密钥',
  ADD_A_DEPUTY: '添加一个副手',
  SHARES_THRESHOLD_SUMMARY: '需要在一起才能解锁您的vaultbox的副手数量',
  CLICK_HERE_TO_RETRY: '单击此处重试',
  MY_CURRENT_DEPUTIES: '我目前的副手',
  NO_DEPUTIES: '没有副手',
  MY_PENDING_DEPUTIES: '我待定的副手',
  NO_PENDING_DEPUTIES: '没有待定的副手',
  MY_PENDING_FILE_UNLOCKS: '我的待定文件已解锁',
  NO_PENDING_FILE_UNLOCKS: '没有待定的文件解锁',
  DEPUTIES: '副手',
  PRIMARY_USERS_WHO_I_AM_A_DEPUTY_FOR: '我作为一个副手的主要用户',
  YOU_HAVE_BEEN_ENTRUSTED_AS_DEPUTY: '您已被委托为以下主要用户的副手',
  LEARN_MORE_ABOUT_BEING_A_DEPUTY: '了解更多有关成为一个副手的信息',
  ID_CONTAINS_RESIDENTIAL_ADDRESS: '身份证包含居住地址',
  ADDRESS_PROOF: '地址证明',
  ID_PASSPORT: '身份证 / 护照',
  PRIMARY_USER_DETAILS: '主要用户详细信息',
  DECLINE: '谢绝',
  ACCEPT: '接受',
  REJECT: '拒绝',
  APPROVE: '批准',
  CURRENT_DETAILS: '当前详细信息',
  PENDING_UPDATE_DETAILS: '待更新详细信息',
  LEGACY_MANAGEMENT: '遗产管理',
  INSTRUCTION: '指令',
  RECEIVED_BACKUP_KEY: '已收到备份密钥',
  NOT_YET_RECEIVED_BACKUP_KEY: '尚未收到备份密钥',
  LEGACY_MANAGEMENT_ENABLED: '已启用遗产管理',
  FROM: '来自',
  PENDING: '待定',
  REQUEST_ACCEPTED: '请求已接受',
  REQUEST_REJECTED: '请求被拒绝',
  FAILED_TO_ACCEPT_REQUEST: '无法接受请求',
  FAILED_TO_APPROVE_REQUEST: '无法批准请求',
  FAILED_TO_REJECT_REQUEST: '无法拒绝请求',
  FAILED_TO_HANDLE_DEPUTY_REQUEST: '无法处理副手请求',
  FAILED_TO_HANDLE_UPDATE_USER_DETAILS_REQUEST: '无法处理更新用户详细信息请求',
  PROFESSIONAL_DEPUTY_ACCESS_DELEGATIONS: '专业副手访问代表团',
  PENDING_MY_ACCEPTANCE_TO_BE_DEPUTY: '等待我接受担任副手',
  NO_PENDING_REQUESTS: '没有待定的请求',
  I_AM_CURRENTLY_A_DEPUTY_FOR_THESE_PRIMARY_USERS: '我目前是这些主要用户的副手',
  NO_PRIMARY_USERS: '没有主要用户',
  REQUEST_TO_UPDATE_THEIR_DETAILS: '请求更新其详细信息',
  NO_REQUESTS: '没有请求',
  APPROVAL_SATUS: '批准状态',
  NEW_PHONE_NUMBER_DEPUTY: '新电话号码',
  REQUEST_APPROVED: '请求已批准',
  REQUESTS_TO_RESET_THEIR_PASSWORD: '请求重置他们的密码',
  REQUEST_TO_UNLOCK_THEIR_FILES: '请求解锁他们的文件',
  REQUEST_TO_CHANGE_THEIR_PHONE_NUMBER: '请求更改他们的电话号码',
  SUCCESSFULLY_UPDATED_NUMBER_OF_DEPUTIES_REQUIRED:
    '已成功更新了所需的副手数量',
  FAILED_TO_UPDATE_NUMBER_OF_DEPUTIES_REQUIRED: '无法更新所需的副手数量',
  NUMBER_OF_DEPUTIES_REQUIRED_TO_UNLOCK_VAULTBOX: '解锁vaultbox所需的副手数量',
  ENTER_NUMBER_OF_DEPUTIES: '输入副手数量',
  FAILDED_TO_LOAD_USER_DETAILS: '无法加载用户详细资料',
  SUCCESSFULLY_UPDATE_DETAILS: '成功更新详细资料',
  FAILED_TO_UPDATE_DETAILS: '无法更新详细资料',
  USER_DETAILS: '用户详细资料',
  OWNER: '所有者',
  UNLOCK: '解锁',
  LOCK: '锁',
  SUCCESSFULLY_UNLOCKED_VAULT: '已成功解锁保险库',
  FAILED_TO_UNLOCK_VAULT: '无法解锁保险库',
  SUCCESSFULLY_LOCKED_VAULT: '已成功锁定保管库',
  FAILED_TO_LOCK_VAULT: '无法锁定保险库',
  VAULTBOXES_OF_PRIMARY_USERS: '主要用户的Vaultboxes',
  NO_VAULTBOXES: '没有vaultboxes',
  FOLDER_NAME_EXISTS: '文件夹名称存在',
  FAILED_TO_GET_FILE: '无法获取文件',
  MARKING_FILE_AS_SECRET: '将文件标记为秘密？',
  MARKING_FILE_AS_SECRET_SUMMARY:
    '一旦标记为秘密，一个文件只能在副手解锁后才能查看或下载',
  SUCCESSFULLY_MARKED_DOCUMENT_AS_SECRET: '已成功将文档标记为机密',
  FAILED_TO_MARK_DOCUMENT_AS_SECRET: '无法将文档标记为机密',
  REQUEST_TO_UNLOCK_HAS_BEEN_SENT: '已发送解锁请求',
  RESEND_UNLOCK_REQUEST: '重新发送解锁请求',
  REQUEST_TO_UNLOCK: '请求解锁',
  MARK_SECRET_FILE_FIRST_TOOLTIP:
    '如果一个文件被标记为秘密，则只能在副手解锁文件后才能查看或下载该文件',
  MARK_SECRET_FILE_SECOND_TOOLTIP:
    '为了能够将一个文件标记为秘密，需要至少有一个副手',
  MARK_SECRET: '标记秘密',
  SECRET_FILE: '秘密文件',
  SECRET_FILE_SUMMARY:
    '此文件已标记为秘密，无法查看或下载。要恢复文件访问权限，请请求副手帮助解锁',
  PENDING_UNLOCK: '待解锁',
  PENDING_UNLOCK_SUMMARY: '文件解锁请求已发送给副手',
  FILE_INFORMATION: '文件资料',
  ORIGINAL_PATH: '原始路径',
  CREATED: '已创建',
  SIZE: '尺寸',
  SUCCESSFULLY_UPLOADED_FILE: '已成功上传文件',
  FAILED_TO_UPLOAD_FILE: '无法上传文件',
  REMOVE_FROM_FAVOURITES: '从收藏夹中删除',
  ADD_TO_FAVOURITES: '添加到收藏夹',
  FILE_USED_IN_LEGACY_MANAGEMENT: '无法删除此文件，因为它用于遗产管理',
  SUCCESSFULLY_DELETED: '成功删除',
  FAILED_TO_DELETE: '无法删除',
  FOLDER: '文件夹',
  CANNOT_DELETE_FOLDERS_WITH_FILES_INSIDE: '无法删除包含文件的文件夹',
  CANNOT_BE_DELETED_BECAUSE_IT_USED_IN_LEGACY_MANAGEMENT:
    '无法删除文件{{name}}，因为它用于遗产管理',
  SUCCESSFULLY_RENAMED_FOLDER: '已成功重命名文件夹',
  FAILED_TO_RENAME_FOLDER: '无法重命名文件夹',
  FAILED_TO: '无法',
  THE_DESTINATION_IS_A_SUBFOLDER: '目标文件夹是源文件夹的子文件夹',
  SUCCESSFULLY_COPIED: '已成功复制',
  ITEM: '项目',
  ITEMS: '项目',
  FAILED_TO_COPY: '无法复制',
  SUCCESSFULLY_MOVED_ITEM: '已成功移动项目',
  FAILED_TO_MOVE_ITEM: '无法移动项目',
  SUCCESSFULLY_MOVED_ITEMS: '已成功移动项目',
  FAILED_TO_MOVE_ITEMS: '无法移动项目',
  COPY: '复制',
  EDIT_CONTENT: '编辑内容',
  CANT_DELETE_FOLDER_WITH_FILES_INSIDE: '无法删除包含文件的文件夹',
  FOLDERS: '文件夹',
  SUCCESSFULLY_CREATED_FOLDER: '已成功创建文件夹',
  FAILED_TO_CREATE_FOLDER: '无法创建文件夹',
  TEXT_FILE: '文本文件',
  HOME: '家',
  SELECT_FOLDER: '选择文件夹',
  SHOW_FOLDER_TREE: '显示文件夹树',
  STORAGE: '储存',
  YOUR_STORAGE_INCLUDES_IN_TRASH:
    '您的存储包括垃圾箱中的{{deletedStorage}}。清空你的垃圾以释放更多的存储空间',
  OF: '的',
  USED: '已使用',
  SELECT_FILE_OR_FOLDER_TO_SEE_DETAILS:
    '选择一个文件或一个文件夹以查看详细信息',
  NEW_TAG: '新标签',
  CANT_BE_ENCRYPTED_AND_UPLOADED: '无法加密和上传',
  FILE_SIZE_LIMIT_IS: '文件大小限制为',
  SAVE_TO_VAULTBOX_FOLDER: '保存到<strong>vaultbox</strong>文件夹',
  SAVE_TEXT_FILE: '保存文本文件',
  FILE_NAME_IS_REQUIRED: '文件名是必需的！',
  SUCCESSFULLY_CREATED_FILE: '已成功创建文件',
  FAILED_TO_CREATE_FILE: '无法创建文件',
  THE_SELECTED_FILE_IS_LOCK: '选定的文件已锁定，无法使用',
  REMOVE_FILE: '删除文件',
  SELECT_FROM_UPLOADED_FILES: '从上传的文件中选择',
  UPLOAD_OR_SELECT_FROM_UPLOADED_FILES:
    '上传{{labelForErrorMsg}}或从上传的文件中选择',
  QUICK_ACCESS: '快速访问',
  DOWNLOAD_VAULTBOX: '下载vaultbox',
  IMPORT_TEMPLATE: '导入模板',
  FAILED_TO_DOWNLOAD_VAULTBOX: '无法下载vaultbox',
  SESSION_TIMEOUT: '登录超时',
  SESSION_TIMEOUT_SUMMARY: '请再次登录以继续',
  FAILE_TO_GET_USER_DATA: '无法获取用户数据',
  FAILE_TO_GET_PRIMARY_USERS: '无法获取主要用户',
  ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT: '您确定要注销吗？',
  BACK_TO_MY_VAULTBOX: '回到我的vaultbox',
  VIEWING_VAULTBOX_OF: '正在查看___的vaultbox',
  PLEASE_APPOINT_YOUR_DEPUTIES:
    '请委任您的副手，如果您忘记您的密码，他们可以帮助您解锁您的<strong>vaultbox</strong>。当您重置密码时，您所有的<strong>vaultbox</strong>内容都将被删除',
  APPOINT_DEPUTIES_NOW: '立即委任副手',
  REMIND_ME_LATER: '稍后提醒我',
  DO_NOT_REMIND_ME_AGAIN: '别再提醒我了',
  YOU_HAVENT_SET_BASE_CURRENCY: '你还没有设置您的基础货币。请设置它',
  HERE: '这里',
  SUBSCRIPTION_HAS_BEEN_CANCEL_AT:
    '您的<strong>vaultbox</strong>订阅已取消。你的 {{nickname}} 订阅将在<strong>{{cancel_at}}</strong>结束',
  YOU_STILL_CAN_REACTIVATE_SUBSCRIPTION:
    '您仍然可以在订阅结束前<2>重新激活</2>订阅',
  REACTIVATE_SUBSCRIPTION: '重新激活订阅',
  REACTIVATE_SUBSCRIPTION_CONFIRM: '您确定要重新激活您的{{nickname}}订阅吗？',
  THE_PAYMENT_BECAME_PAST_DUE:
    '您的订阅付款失败，并已在{{billing_cycle_anchor}}过期',
  UPDATE_PAYMENT_INFORMATION:
    '请在一星期内更新您的<2>付款信息</2>，否则您目前的订阅将被取消并降级为免费计划',
  YOU_HAVENT_ADDED_ANY_PAYMENT_METHOD:
    '您尚未添加任何<2>付款方式</2>。 请在试用期到期前至少添加一种<5>付款方式</5>',
  YOU_NEED_TO_ADD_MORE_DEPUTIES:
    '您需要<strong>{{sharesThreshold}}</strong>个副手来解锁您的vaultbox，但您当前只有<strong>{{acceptedDeputies}}</strong>副手。你需要增加更多的副手或减少所需的副手数量',
  UPDATE: '更新',
  WHO_WILL_RECEIVE_THE_ACCESS_TO_YOUR_VAULTBOX:
    '谁将获得您vaultbox账户的访问权？',
  LEGACY_ACCESSED_BY_PROFESSIONAL_DEPUTY:
    '您的专业副手将解锁您的vaultbox，并可以访问全部内容。他们将根据您的指示决定如何处理内容（在“内容权限”步骤中上传',
  LEGACY_ACCESSED_BY_OTHER_SPECIFIC_PERSON:
    '您的专业副手将解锁您的vaultbox，并向此人释放“内容权限”步骤中指定的内容。此人将需要证明其身份和与您的关系',
  OTHER_PERSON: '其他人',
  THE_SELECTED_FILE_IS_LOCK_AND_CANNOT_BE_USED: '选定的文件已锁定，无法使用',
  SUCCESSFULLY_SAVED_LEGACY_MANAGEMENT_CONFIGURATION: '已成功保存遗产管理配置',
  SUMMARY: '摘要',
  HERE_IS_A_SUMMARY_OF_YOUR_CONFIGURATION: '这是您的配置摘要',
  LEGACY_MANAGEMENT_CONFIGURATION: '遗产管理配置',
  REQUEST_BY_USER: '用户请求',
  REQUEST_BY_YOU: '您的请求',
  USER_DEATH_OR_INCAPACITATED_STATE: '用户死亡或丧失行为能力状态',
  YOUR_DEATH_OR_INCAPACITATED_STATE: '你的死亡或丧失行为能力状态',
  WE_WILL_SHARE_USER: '我们将分享用户的：',
  WE_WILL_SHARE_YOUR: '我们将分享您的：',
  FULL_VAULTBOX: '完整vaultbox',
  FINAL_REGISTRY_SNAPSHOT_AND_SELECTED_FILES_FOLDERS:
    '最终注册表快照和选定的文件 / 文件夹',
  FINAL_REGISTRY_SNAPSHOT: '最终注册表快照',
  FINAL_SNAPSHOT_OF_YOUR_REGISTRY: '您的注册表的最终快照',
  SELECTED_FILES_FOLDERS: '选定的文件 / 文件夹',
  WITH: '与',
  UPON: '应',
  USER_PROFESSIONAL_DEPUTY: '用户的专业副手',
  YOUR_PROFESSIONAL_DEPUTY: '您的专业副手',
  FAILED_TO_GET_LEGACY_MANAGEMENT_INFO: '无法获取遗产管理信息',
  FAILED_TO_GET_LEGACY_MANAGEMENT_STATUS: '无法获取遗产管理状态',
  FAILED_TO_CHANGE_LEGACY_MANAGEMENT: '无法更改遗产管理',
  DISABLE_THE_LEGACY_MANAGEMENT: '禁用遗产管理？',
  ENABLE_THE_LEGACY_MANAGEMENT: '启用遗产管理',
  SUCCESSFULLY_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    '已成功发送释放您的vaultbox的请求',
  FAILED_TO_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    '无法发送释放您的vaultbox的请求',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX: '请求释放您的vaultbox？',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX_SUMNARY:
    '您的专业副手将会收到解锁您vaultbox账户的要求，并按照您当前的遗产管理配置释放其内容',
  LEGACY_MANAGEMENT_SUMMARY:
    '作为一个<strong>vaultbox</strong>客户，您可以指定一组执行指令，让您委派的专业副手知道当您在死亡或丧失行为能力时如何继续处理您的<strong>vaultbox</strong>内容',
  START: '开始',
  TOGGLE_THE_LEGACY_MANAGEMENT: '切换遗产管理',
  REQUEST_TO_RELEASE_NOW: '请求立即释放',
  UPDATE_CONFIGURATION: '更新配置',
  YOU_ARE_PENDING_ACCEPTANCE_FROM_YOUR_DEPUITES: '你正在等待您的副手的接受请求',
  YOU_NEED_TO_HAVE_A_PROFESSIONAL_DEPUTY_TO_USE_THIS_FEATURE:
    '您需要有一个专业的副手才能使用这个功能',
  APPOINT_PROFESSIONAL_DEPUTY_NOW: '立即委任专业副手',
  PLEASE_SELECT_AT_LEAST_ONE_OPTION: '请至少选择一个选项',
  PLEASE_SELECT_AT_LEAST_ONE_FILE_FOLDER: '请至少选择一个文件 / 文件夹',
  CONTENT_PERMISSION: '内容权限',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_FIRST_SUMMARY:
    '您已选择提供<strong>其他特定人员</strong>账户的访问权',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_SECOND_SUMMARY:
    '您可以提供您的<strong>vaultbox</strong>账户全部的访问权，也可以仅提供您的注册表内容和/或选定的文件和文件夹的访问权',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_FIRST_SUMMARY:
    '您已选择为<strong>专业副手</strong>提供账户的访问权',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_SECOND_SUMMARY:
    '您的专业副手将拥有您的<strong>vaultbox</strong>账户全部的访问权',
  CHOOSE_FILES_OR_FOLDERS_TO_SHARE: '选择要共享的文件或文件夹',
  ADD_FILE_FOLDERS: '添加文件 / 文件夹',
  INSTRUCTIONS: '指令',
  INSTRUCTIONS_SUMMARY:
    '为您的专业副手上传任何其他指示的一份书面文档。例如README.TXT指示特定文件的位置，专业副手应将这些文件交给谁',
  INSTRUCTION_FILE: '指令文件',
  WHEN_DO_YOU_WANT_TO_RELEASE_YOUR_VAULTBOX_CONTENT:
    '您何时要释放您的vaultbox内容？',
  UPON_REQUEST_BY_ME: '应我的请求',
  UPON_MY_DEATH_OR_INCAPACITATED_SATE: '在我死亡或丧失行为能力时',
  INACTIVITY_STATE_CONFIGURATION: '要选择此选项，您需要完成无活动状态配置',
  CONFIGURE: '配置',
  CHOSSE_FILES_FOLDERS: '选择文件 / 文件夹',
  NEW_CRYPTOCURRENCY: '新的加密货币',
  THE_SAME_NAME_ALREADY_EXISTS: '同一个名字已经存在！',
  SYMBOL: '符号',
  ENTER_SYMBOL: '输入符号',
  THE_SAME_SYMBOL_ALREADY_EXISTS: '相同的符号已经存在！',
  NEW_ITEM: '新的{{item}}',
  ENTER_NEW_ITEM: '输入新的{{item}}',
  THE_SAME_ITEM_ALREADY_EXISTS: '相同的{{item}}已经存在！',
  SUCCESSFULLY_ADDED_CARD: '已成功添加卡',
  FAILED_TO_ADD_CARD: '无法添加卡',
  ADD_A_CARD: '添加一张卡',
  SUPPORTED_CARD_TYPES: '支持的卡类型：',
  TESTING_NOTE: '测试注意事项：您可以输入以下卡号进行测试',
  SUCCESSFULLY_DELETED_CARD: '已成功删除卡',
  FAILED_TO_DELETED_CARD: '无法删除卡',
  ARE_YOU_SURE_TO_DELETE_THIS_CARD: '您确定要删除此卡吗？',
  DELETE_CARD: '删除卡',
  FAILED_TO_GET_INVOICES: '无法获取收据',
  AMOUNT: '金额',
  VIEW_INVOICE: '查看发票',
  VIEW_RECEIPT: '查看收据',
  CARD_NO: '卡号',
  EXPIRES_ON: '在____到期',
  CURRENT_SUBSCRIPTION: '目前的订阅',
  CURRENT_SUBSCRIPTION_APPLIED_PROMOTION_CODE:
    '您目前的订阅已应用了一个促销代码，无法应用其他促销代码',
  PROMOTION_CODE: '促销代码',
  CHANGE_SUBSCRIPTION: '更改订阅',
  APPLIED_DATE: '应用日期',
  PAYMENT_METHOD: '付款方法',
  NO_CARDS: '没有卡',
  PROMOTION_CODE_CANT_APPLIED: '此促销代码不能应用于目前的订阅套餐',
  SUCCESSFULLY_APPLIED_PROMOTION_CODE: '已成功应用促销代码',
  FAILED_TO_APPLY_PROMOTION_CODE: '无法应用促销代码',
  PROMOTION_CODE_IS_REQUIRED: '需要促销代码',
  ENTER_PROMOTION_CODE: '输入促销代码',
  SUCCESSFULLY_SET_DEFAULT_CARD: '已成功设置默认卡',
  FAIL_TO_SET_DEFAULT_CARD: '无法设置默认卡',
  ARE_YOU_SURE_TO_SET_THIS_CARD_AS_DEFAULT: '您确定将此卡设置为默认卡吗？',
  SET_DEFAULT_CARD: '设置默认卡',
  INVALID_SUBSCRIPTION: '无效的订阅',
  INVALID_SUBSCRIPTION_SUMMARY:
    '您使用的存储空间已超出了此套餐允许的存储空间。 请删除不再需要的文件来清除一些空间',
  INVALID_REFERRAL_CODE: '无效的推荐识别代码',
  CODE_USED: '您之前曾经使用过此代码。 请尝试另一个，或将其留空以继续',
  TRY_ANOTHER_ONE: '请尝试另一个，或将其留空以继续',
  FAILED_TO_SUBSCRIBE: '无法订阅',
  FAILED_TO_GET_PROMOTION_CODE_INFO: '无法获取促销代码信息',
  CHANGE_SUBSCRIPTION_PLAN: '更改订阅计划',
  CHANGE_SUBSCRIPTION_PLAN_FIRST_SUMMARY:
    '您应继续目前的订阅套餐，直到{{current_period_end}}，并在更新订阅时切换到新套餐',
  CHANGE_SUBSCRIPTION_PLAN_SECOND_SUMMARY:
    '您的有效促销代码<strong>{{removedPromoCode}}</strong>只能应用于<strong>{{nickname}}</strong>计划。 如果您更改订阅计划，促销折扣将被取消',
  FAILED_TO_GET_DEPUTIES_LIST_TO_CHECK_PROFESSTIONAL_DEPUTY_PLAN:
    '无法获取副手列表以检查专业副手计划。请重试',
  FAILED_TO_SAVE_SUBSCRIPTION: '无法保存订阅',
  CANCEL_SUBSCRIPTION: '取消订阅？',
  CANCEL_SUBSCRIPTION_FIRST_SUMMARY:
    ' 此操作将在目前订阅期结束时将您的帐户降级为一个免费计划',
  CANCEL_SUBSCRIPTION_SECOND_SUMMARY:
    '订阅期结束后，您将不再具有访问注册表和文件的权限。 <2><strong>请记住先下载一个副本。</strong></2>',
  YOU_CAN_STILL_USE_YOUR_ACCOUNT_AS_DEPUTY:
    '您仍然可以使用您的帐户作为其他<strong>vaultbox</strong>用户的副手',
  ARE_YOU_SURE_YOU_WISH_TO_CANCEL_SUBSCRIPTION: '您确定要取消订阅吗？',
  KEEP_SUBSCRIPTION: '保留订阅',
  SUBSCRIPTION_CANCELLED: '订阅已取消',
  SUBSCRIPTION_CANCELLED_SUMMARY:
    '您的{{nickname}}订阅将在{{current_period_end}}结束',
  FAILED_TO_CANCEL_SUBSCRIPTION: '无法取消订阅',
  SUCCESSFULLY_CANCELLED_SUBSCRIPTION_CHANGE: '已成功取消订阅更改',
  FAILED_TO_CANCEL_SUBSCRIPTION_CHANGE: '无法取消订阅更改',
  CANCEL_CHANGE: '取消更改',
  SUBSCRIPTION_TESTING_NOTE:
    '测试注意事项：以下计划仅是模拟数据。 您可以选择其中任何一个继续进行，而无需付款',
  SUBSCRIBE: '订阅',
  CHANGE_SUBSCRIPTION_PLAN_ON:
    '您的订阅将在{{changeDate}}更改为此计划。 如果您要保留目前的订阅计划，请取消此更改',
  REFERRAL_CODE: '推荐识别代码',
  REFERRAL_CODE_TOOLTIP_FIRST_SUMMARY:
    '如果您是由另一个vaultbox用户推荐的，请在此处输入他们的推荐识别代码',
  REFERRAL_CODE_TOOLTIP_SECOND_SUMMARY:
    '如果注册成功，您将获得 4.99美元的折扣，而您的推荐人的帐户将获得4.99美元的抵免额，让他们可在下一次续订中应用',
  THIS_IS_NOT_APPLIED_TO_THE_FREE_PLAN: '这不适用于免费计划（仅限副手）',
  ENTER_REFERRAL_CODE: '输入推荐识别代码（可选）',
  PLEASE_SELECT_A_CURRENCY: '请选择一种货币！',
  SUCCESSFULLY_CHANGED_PASSWORD: '已成功更改密码',
  SUCCESSFULLY_SAVED_INACTIVIY_STATE: '已成功保存无活动状态配置',
  FAILED_TO_SAVE_INACTIVIY_STATE: '无法保存无活动状态配置',
  INACTIVITY_STATE_CONFIG: '无活动状态配置',
  WHEN_YOU_ARE_INACTIVE_FOR: '当无活动时',
  WE_WILL_SEND_YOU_REMINDER: '我们会给您发送提醒',
  BUT_IF_YOU_DONOT_RESPOND_WITHIN: '但是，如果您未在提醒之内做出回应',
  OF_THE_REMINDER: '提醒之内',
  WE_WILL_INFORM_YOUR_DEPUTIES_ABOUT_YOUR_INACTIVITY:
    '我们将通知您的副手关于您的账户无活动',
  INACTIVITY_STATE_CONFIGURATION_TITLE: '无活动状态配置',
  INACTIVITY_STATE_CONFIGURATION_FIRST_SUMMARY:
    '当您的账户长时间无活动时通知您的副手的配置',
  INACTIVITY_STATE_CONFIGURATION_SECOND_SUMMARY:
    '我们建议您偶尔在人生事件发生重大更改时复查您的配置，或至少每年复查一次',
  YOU_NEED_TO_HAVE_DEPUTIES_TO_ABLE_TO_USE_THIS_FEATURE:
    '您需要有副手才能使用这个功能',
  EMAIL_ADDRESS_UPDATED_AND_VERIFIED:
    '您的电子邮件地址已更新和验证。 请再次设置多重身份验证，以同时更新您的身份验证器应用程序中显示的电子邮件地址',
  EMAIL_ADDRESS_VERIFICATION: '电子邮件地址验证',
  ENTER_THE_VERIFICATION_CODE: '输入发送到您的新电子邮件地址的验证码',
  RECEIVER: '接收者',
  SUBSCRIPTION_PLAN: '订阅计划',
  SENT_DATE: '发送日期',
  RECEIVED: '已收到',
  GIFTS_I_HAVE_SENT: '我已发送的礼物',
  YOU_NEED_TO_ADD_A_PAYMENT_METHOD_TO_SENT_GIFT:
    '您需要添加一个付款方式才能发送礼物',
  SEND_GIFT: '发送礼物',
  NO_GIFTS: '没有礼物',
  SETUP_AGAIN: '再次设置',
  MULTI_FACTOR_AUTHENTICATION_FIRST_SUMMARY:
    '您已在<strong>{{setupMfaDate}}</strong>使用您的身份验证器应用程序设置了vaultbox多重身份验证',
  MULTI_FACTOR_AUTHENTICATION_SECOND_SUMMARY:
    '如果您更换手机、身份验证器应用程序或多重身份验证类型，您需要重新设置。之后，您旧的身份验证码将无法使用',
  RESEND_CODE: '重新发送代码',
  ENTER_YOUR_PASSWORD_TO_PROCEED: '输入您的密码以继续',
  FAILED_TO_EDIT_PROFILE: '无法编辑配置文件',
  SUCCESSFULLY_EDITED_PROFILE: '已成功编辑配置文件',
  VERIFY_EMAIL_ADDRESS: '验证电子邮件地址',
  SET_A_STRONG_PASSWORD_TO_PROTECT_YOUR_ACCOUNT:
    '设置一个强健的密码以保护您的帐户',
  QUOTE_CURRENCY: '报价货币',
  RATE: '率',
  LAST_UPDATE: '上次更新',
  FAILE_TO_SET_CURRENCY: '无法设置货币',
  FAILED_TO_UPDATE_BASE_CURRENCY: '无法更新基础货币',
  BASE_CURRENCY_MEANS_YOUR_REPORTING_CURRENCY_OR_FUNCTIONAL_CURRENCY:
    '基础货币是指您的报告货币或功能货币',
  SELECT_QUOTE_CURRENCY: '选择报价货币',
  PLEASE_ENTER_AT_LEAST_ONE_EMAIL_ADDRESS: '请输入至少一个电子邮件地址',
  SUCCESSFULLY_SENT_INVITATION: '已成功发送邀请',
  THIS_EMAIL_HAS_APPLIED_YOUR_REFERRAL_CODE:
    '具有此电子邮件地址的vaultbox帐户已应用了您的推荐识别代码',
  REFER: '参考',
  REFERRAL_CODE_SUMMARY:
    '分配给您的帐户的推荐识别代码是独特的。您可以推荐vaultbox给您的业务伙伴、家人和朋友给并获得奖励。如果注册成功，他们将获得5.00美元的折扣，而您将获得5.00美元的信用额度到您的帐户，让您可以在下一次续订中应用',
  SEND_REFERRAL_LINK: '发送推荐链接',
  SEND: '发送',
  EMAIL_ADDRESSES: '电子邮件地址',
  YOU_HAVE_SENT_A_GIFT_TO_THIS_EMAIL:
    '您之前已向此电子邮件地址发送了一份礼物，但仍在等待接受',
  EXISTING_VAULTBOX_USER_CAN_NOT_RECEIVE_GIFT:
    '具有此电子邮件地址的vaultbox用户已存在。 现有的vaultbox用户无法收到订阅礼物',
  SUCCESS_PURCHASED: '购买成功！ 礼物在路上！',
  FAILED_TO_PURCHASE_GIFT: '无法购买礼物',
  PURCHASE: '购买',
  ENTER_EMAIL_ADDRESS: '输入电子邮件地址',
  SELECT_A_PLAN: '选择一个计划',
  ENTER_QUANTITY: '输入数量',
  GIFT_MESSAGE: '礼物信息',
  GIFT_MESSAGE_SUMMARY:
    '我正在使用vaultbox，我认为它对您也非常有用！ 这是我送你的vaultbox订阅，可帮助您开始使用',
  INPUT_GIFT_MESSAGE: '输入礼物信息',
  GIFTS: '礼物',
  RESET_REGISTRY: '重置注册表',
  SUCCESSFULLY_RESET_REGISTRY: '已成功重置注册表',
  FAILED_TO_DELETE_USER: '无法删除用户',
  FAILED_TO_RESET_REGISTRY: '无法重置注册表',
  INVALID_FORM: '无效的表格',
  ACTIVITIES: '活动',
  DEPUTY_ACCESSES_ONLY: '仅副手访问',
  ACCESSED_YOUR_VAULTBOX: '{{name}} ({{email}}) 已访问您的vaultbox',
  DELETE_ACCOUNT: '删除帐户',
  DOWNLOAD_DATA_AND_DELETE_ACCOUNT: '下载数据并删除帐户',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_YOUR_REGISTRY:
    '您确定要重置注册表吗？ 您的所有注册表数据将被删除，以后将无法检索',
  ENTER_PASSWORD: '输入一个密码',
  ENTER_A_NEW_EMAIL_WHICH_WAS_NOT_SPECIFIED_BEFORE:
    '输入一个未指定过的新电子邮件地址',
  SUCCESSFULLY_SPECIFIED_DEPUTIES: '已成功指定副手',
  FAILED_TO_SPECIFY_DEPUTY: '无法指定副手',
  PLEASE_ADD_AT_LEAST_ONE_DEPUTY: '请至少添加一个副手',
  SELECT_A_DEPUTY_TYPE: '选择一个副手类型',
  APPOINT_SOMEBODY_ELSE: '委任其他人',
  WATIGA_TRUST: 'Watiga Trust',
  WATIGA_TRUST_FIRST_SUMMARY:
    '我们已与新加坡持牌信托公司Watiga Trust Ltd（“Watiga Trust”）合作，为您提供私人和专业的副手服务',
  WATIGA_TRUST_SECOND_SUMMARY:
    'Watiga Trust将妥善保管恢复密钥，并且在您去世后，他们可以为您或法定代表人恢复对您的<strong>vaultbox</strong>的访问权限',
  WATIGA_TRUST_TERMS_OF_SERVICE: 'Watiga Trust专业副手服务条款',
  OTHER_PD_TERMS_OF_SERVICE: '专业副手服务条款',
  PROFESSIONAL_DEPUTY_SETUP_IS_NOT_YET_COMPLETED: '专业副手设置尚未完成',
  YOU_CANT_HAVE_BOTH_NOMARL_AND_PD_DEPUTY:
    '您不能同时有一个普通的副手和一个专业的副手。为了能够添加一个专业的副手，请撤销您所有的普通副手',
  YOU_HAVE_ADDED_PD: '您之前已经添加了一个专业的副手，因此无法添加另一个',
  PD_ACCOUNTS_ARE_NOT_YET_AVAILABLE: '专业副手帐户尚不可用',
  YOU_CANT_HAVE_BOTH_PD_AND_NOMARL_DEPUTY:
    '你不能同时有一个普通的副手和一个专业的副手。为了能够添加一个普通的副手，请撤销您的专业副手',
  WATIGA_TRUST_DETAIL_FIRST_SUMMARY:
    'Watiga Trust是一家受新加坡金融管理局监管的持牌信托公司。作为新加坡的一家持牌金融机构，Watiga Trust必须遵守严格的合规要求，包括反洗钱和打击资助恐怖主义方面的合规要求',
  WATIGA_TRUST_DETAIL_SECOND_SUMMARY:
    '为让Watiga Trust担任您的副手，请提供以下信息',
  OTHER_PD_SUMMARY:
    '为了让<strong>{{professionalDeputyName}}</strong>担任您的副手，请提供以下信息：',
  WATIGA_TRUST_DETAIL_THIRD_SUMMARY:
    '我们将把这些信息和文件存储在您的vaultbox中，并安全地传输给Watiga Trust。Watiga Trust将会对您的信息保密，并根据Watiga Trust 专业副手服务条款将其用于尽职调查验证目的。如果文件或信息不准确或不完整，Watiga Trust可能会联系您以确认',
  NORMAL_DEPUTY_SUMMARY: {
    richText: [
      {
        text: '若要指定其他人作为您的副手，请在下面输入他们的电子邮件地址。他们将 会收到一个链接来设置副手'
      },
      {
        font: {
          strike: true,
          size: 11,
          name: 'Calibri',
          family: 2,
          scheme: 'minor'
        },
        text: ' '
      },
      {
        font: { size: 11, name: 'Calibri', family: 2, scheme: 'minor' },
        text: '委任。如果他们已经有一个vaultbox帐户，他们的vaultbox中将会保存此委任。否则，他们将能够创建一个免费的有限帐户作为您的副手。你需要至少两个副手'
      }
    ]
  },
  EMAIL_ADDRESS_OF_DEPUTY: '副手的电子邮件地址',
  DOCUMENTS_UPLOADED: '上传的文档',
  MY_ID_CONTAINS_MY_RESIDENTIAL_ADDRESS: '我的身份证上包含我的居住地址',
  WATIGA_TRUST_REVIEW_SUMMARY:
    '我同意根据<2>Watiga Trust专业副手服务条款</2>委任Watiga Trust为我的副手',
  OTHER_PD_REVIEW_SUMMARY:
    '我同意根据<5>专业副手服务条款</5>任委任{{professionalDeputyName}}为我的副手',
  WE_WILL_EMAIL_YOUR_DEPUTY_FOR_THEIR_ACCEPTANCE:
    '我们将发送电子邮件给您的副手让他们接受',
  ENTER_FULL_NAME: '输入全名',
  ENTER_CONTACT_NUMBER: '输入联系电话',
  ENTER_ID_PASSPORT_NUMBER: '输入身份证 / 护照号码',
  SELECT_NATIONALITY: '选择国籍',
  ENTER_RESIDENTIAL_ADDRESS: '输入居住地址',
  YOUR_ID_PASSPORT_FILENAME_IS: '您的身份证 / 护照的文件名是 ',
  YOUR_PROOF_OF_ADDRESS_FILENAME_IS: '您的地址证明文件名是',
  UPLOAD_ADDRESS_PROOF_DATED_WITHIN_THE_LAST_SIX_MONTHS:
    '上传最近六（6）个月内的地址证明：',
  ID_CARD: '身份证卡',
  UTILITY_BILL: '公用事业账单',
  CORRESPONDENCE_FROM_TAX_OFFICE_OR_OTHER_GOVERMENT_AUTHORITY:
    '来自税务局或其他政府部门的信件',
  USERNAME_IS_NULL: '用户名为空。 无法检索新会话',
  INVALID_SESSION: '会话无效。请验证',
  LOCAL_STORAGE_IS_MISSING_AN_ID_TOKEN: '本地存储缺少一个身份象征。请验证',
  ENTER_ANOTHER_EAMIL_NOT_YOUR_OWN_ONE:
    '请输入另一个电邮地址，而不是您自己的电邮地址',
  ENTER_AN_EMAIL_WHICH_IS_DEFFETENT_FROM_PD_EMAIL:
    '请输入与专业副手不同的电邮地址',
  FILE_NAME_ALREADY_EXISTS: '文件名称已存在于当前文件夹中，请重命名',
  VALUE_MUST_BE_GREATER_THAN: '值必须大于{{min}}',
  NRIC_IS_INVALID: '身份证号码无效',
  FIN_IS_INVALID: '外国身份证号码无效',
  REFERENCES_TOOLTIP_SUMMARY:
    '参考可以用于多种目的。 每个参考都有一个标签及其匹配信息',
  ASSET_LIABILITY_USE_CASE_EXAMPLES: '资产 / 负债用案例',
  EQITY_LISTED_INFORMATION_OF_TICKER_ISIN: '上市股票：股票代码或ISIN信息',
  INSURANCE_SURRENDER_VALUE: '保险：退保价值',
  CONTACT_USE_CASE_EXAMPLES: '联系用案例',
  INDIVIDUAL_CONTACT_INFORMATION:
    '个人联系：可以用于紧急联系的家庭成员或同事的信息（例如，标签：妻子，信息：+6512345678）',
  ORGANISATION_CONTACT_INFORMATION:
    '组织联系：负责人的信息（例如，标签：John（PIC），信息：+6512345678-john@example.com）',
  ENTER_ALPHANUMERIC_CHARACTERS_AND_DASH_ONLY: '仅输入字母数字字符和破折号',
  INVALID_CODE_PLEASE_TRY_AGAIN: '无效的代码。请再试一次',
  INVALID_CODE_FAILED_TO_SETUP_MFA:
    '无效代码。无法设置多重身份验证。请再试一次',
  USER_IS_NOT_CONFIRMED: '未确认用户 - 请检查您的激活电子邮件',
  UNKNOWN_ERROR_DETECTED: '检测到未知错误',
  DOWNLOADING_VAULTBOX: '正在下载vaultbox',
  DOWNLOAD_STEP: '已下载{{index}}个{{size}}文件',
  SAVING_ZIP_PART: '正在保存zip部分',
  SAVING_ZIP: '正在保存zip',
  FAILED_TO_DOWNLOAD_THE_FOLLOWING_FILES: '无法下载以下文件',
  PLEASE_DOWNLOAD_THEM_MANUALLY: '请手动下载它们',
  DOWNLOADED_VAULTBOX: '已下载vaultbox',
  FAILED_TO_DOWNLOAD_ALL_VAULTBOX: '无法下载所有vaultbox',
  FILE_NAMES: '文件名称',
  ALREADY_EXISTS_IN_THE_CURRENT_FOLDER: '目前文件夹中已存在，请重命名',
  ASSETS_AND_LIABILITY_REGISTRY: '资产和负债登记处',
  TRACKING_NETWORTH: '追踪净值',
  QUANTITY_OF_SECURE_FILE_STORAGE: '50 GB的安全文件存储',
  SAVE_YOUR_IMPORTANT_CONTACT: '保存您的重要联系资料',
  APPOINT_DEPUTIES: '委任副手',
  BE_APPOINTED_AS_A_DEPUTY: '被委任为一个副手',
  ABILITY_TO_APPOINT_PD_AS_ADDON_AT: '能够委任专业副手作为一个附加组件的能力',
  INCLUDE_PROPERTY_LOAN: '包括房地产贷款',
  PROPERTY_LOAN_INFORMATION: '房地产贷款信息',
  LINKED_DOCUMENTS: '链接的文档',
  SECRET_FILE_ALERT_CONTENT:
    '此文件已标记为秘密，无法查看或下载。要恢复文件访问权限，请请求副手帮助解锁',
  LINKS: '链接',
  TOTAL_ASSETS: '总资产',
  TODAY: '今天',
  ALL_TIME: '任何时候',
  W_AS_WEEK: 'W',
  M_AS_MONTH: 'M',
  Y_AS_YEAR: 'Y',
  SECURITY_CODE: '安全代码',
  MARKET_VALUE: '市场价值',
  LAST_PRICE_DATE: '最后价格日期',
  REFERENCE_NAME_WITH_INDEX: '参考{{index}}名称',
  REFERENCE_LABEL_WITH_INDEX: '参考{{index}}标签',
  REFERENCE_VALUE_WITH_INDEX: '参考{{index}}值',
  EMAIL_LABEL_WITH_INDEX: '电子邮件{{index}}标签',
  EMAIL_VALUE_WITH_INDEX: '电子邮件{{index}}值',
  ADDRESS_LABEL_WITH_INDEX: '地址{{index}}标签',
  ADDRESS_VALUE_WITH_INDEX: '地址{{index}}值',
  PHONE_NUMBER_LABEL_WITH_INDEX: '电话号码{{index}}标签',
  PHONE_NUMBER_VALUE_WITH_INDEX: '电话号码{{index}}值',
  FIELD_NAME: '栏位名',
  FIELD_VALUE: '栏位值',
  OTHER_PROFESSIONAL_DEPUTY: '其他专业副手',
  ENTER_PROFESSIONAL_DEPUTY_REFERRAL_CODE: '输入专业副手推荐识别代码',
  PROFESSIONAL_DEPUTY_NOT_FOUND: '找不到专业副手',
  THE_PROFESSIONAL_DEPUTY_IS_DEFAULT:
    '您正在寻找的专业副手已经是您的默认人选了',
  FAILED_TO_LOAD_DEPUTIES: '无法加载副手',
  FAILED_TO_LOAD_DISTRIBUTE_KEYS: '无法将密钥分发给您的副手',
  SUCCESSFULLY_REVOKED_DEPUTY: '已成功撤销副手',
  FAILED_TO_REVOKE_DEPUTY: '无法撤销副手',
  SUCCESSFULLY_RESENT_DEPUTY_REQUEST: '已成功重新发送副手请求',
  FAILED_TO_RESENT_DEPUTY_REQUEST: '无法重新发送副手请求',
  SUCCESSFULLY_RESENT_UNLOCK_REQUEST: '已成功重新发送解锁请求',
  FAILED_TO_RESENT_UNLOCK_REQUEST: '无法重新发送解锁请求',
  FETCHING_LASTEST_EXCHANGE_RATES: '正在获取最新汇率…',
  FAILED_TO_FETCH_LASTEST_EXCHANGE_RATES: '无法获取最新汇率',
  RETRY: '重试',
  SUCCESSFULLY_FETCHED_LASTEST_EXCHANGE_RATES: '已成功获取最新汇率',
  FAILED_TO_FETCH_SAVE_EXCHANGE_RATES: '无法保存最新汇率',
  UPDATING_VALUATION_IN_BASE_CURRENCY:
    '正在更新以基础货币表示的资产 / 负债估值…',
  SUCCESS_UPDATED_VALUATION_IN_BASE_CURRENCY:
    '已成功更新了以基础货币表示的资产 / 负债估值',
  FAILED_TO_UPDATE_VALUATION_IN_BASE_CURRENCY:
    '无法以基础货币更新资产 / 负债估值',
  FAILED_TO_GET_INSTRUCTION_FILE: '无法获取指令文件',
  FAILED_TO_SAVE_LEGACY_MANAGEMENT_CONFIGURATION: '无法保存遗产管理配置',
  FAILED_TO_SET_USER_MFA_PREFERENCE: '无法设置用户多重身份验证首选项',
  FAILED_TO_RESET_PASSWORD: '无法重置密码',
  FAILED_TO_REGISTER_USER: '无法注册用户',
  FAILED_TO_VERIFY_EMAIL_ADDRESS: '无法验证电子邮件地址',
  FAILED_TO_AUTHENTICATE_USER: '无法验证用户',
  FAILED_TO_GET_PLAN: '无法获取计划',
  FAILED_TO_SENT_INVITATION: '无法发送邀请',
  FAILED_TO_UPDATE_USER_ATTRIBUTES: '无法更新用户属性',
  FAILED_TO_CHANGE_PASSWORD: '无法更改密码',
  INVALID_PARAMETER_EXCEPTION: '无效参数。请再试一次',
  DID_NOT_RECEIVE_THE_EMAIL: '没有收到电子邮件吗？',
  RESEND_EMAIL: '重新发送电子邮件',
  INPUT_EMAIL_CODE: '输入发送到您电子邮件地址的验证码',
  FAILED_TO_UPDATE_AUTH_FLOW: '无法更新多重身份验证类型',
  AUTHENTiCATION_FLOW_TYPE: '多重身份验证类型',
  SELECT_A_TYPE_TO_SETUP_FLOW: '选择多重身份验证类型。 ',
  CHANGE_DEFAULT_LANGUAGE: '更改默认语言',
  CHANGE: '更改',
  FAIILED_TO_CHANGE_DEFAULT_LANGUAGE: '无法更改默认语言',
  YOUR_DEFAULT_LANGUAGE_IS: '您的默认语言是',
  CHANGE_DEFAULT_LANGUAGE_SUMMARY:
    '您的默认语言是{{defaultLanguage}}。但是当前选择的语言是{{selectedLanguage}}。 您要使用哪种语言？',
  ENGLISH: '英语',
  VIETNAMESE: '越南语',
  SELECTED_LANGUAGE: '选定的语言',
  DEFAULT_LANGUAGE: '默认语言',
  MFA_CODE: '数码OTP',
  EMAIL_CODE: '电子邮件OTP',
  FAILED_TO_GET_AUTHENTICATION_FLOW_TYPE: '无法获取多重身份验证类型',
  INCORRECT_USERNAME_OR_PASSWORD: '用户名或密码不正确',
  EMAIL_OTP_SUMMARY:
    '您已在<strong>{{setupMfaDate}}</strong>使用您的电子邮件地址设置了vaultbox多重身份验证',
  CHANGE_AUTH_FLOW_TYPE: '更改多重身份验证类型',
  PENDING_ASSETS: '待定资产',
  PENDING_LIABILITIES: '待定负债',
  ACTIVE: '活跃',
  REJECTED_LIABILITIES: '被拒绝的负债',
  REJECTED_ASSETS: '被拒绝的资产',
  REJECTED: '被拒绝',
  REJECTED_REASON: '被拒绝的原因',
  REJECTED_REASON_INPUT_MSG: '请输入拒绝的原因',
  BAHASA_INDONESIA: '印度尼西亚语',
  RESTORE: '恢复',
  PENDING_CONTACT_WARNING_COUNT:
    '此{{type}}具有{{contactCount}}链接的待定联系人',
  DELETE_ASSET_LIABILITY: '删除资产 / 负债',
  ARE_YOU_SURE_TO_DELETE_THIS_RECORD: '您确定要删除此记录吗？',
  FAILED_TO_UPDATE_ACCESS_LEVEL: '无法更新访问级别',
  PROFESSIONAL_DEPUTY_ACCESS_LEVEL: '专业副手访问级别',
  CHANGE_ACCESS_LEVEL: '更改访问级别',
  REJECTION_REASON: '拒绝原因',
  ALLOW: '已预先批准对我的资料进行所有更改',
  NEED_APPROVAL: '需要批准',
  DENY: '不允许做任何更改',
  DEPUTY_ACCESS: '副手访问',
  CALENDAR: '日历',
  FAILED_TO_SAVE_THE_EVENT: '无法保存事件',
  EVENTS: '事件',
  ADD_EVENT: '添加事件',
  EVENT_DETAILS: '事件详细信息',
  EDIT_EVENT: '编辑事件',
  SUCCESSFULLY_SAVE_THE_EVENT: '已成功保存事件',
  EVENT_LINKS: '事件链接',
  EVENT: '事件',
  UNLINK_EVENT_CONFIRM: '您确定要取消此事件的链接吗？',
  SELECT_DOCUMENTS: '选择文档',
  PENDING_FOLDES: '待定的',
  PENDING_FILES: '待定的文件',
  SUCCESSFULLY_DELETED_DOCUMENTS: '已成功删除文档',
  FAILED_TO_DELETE_DOCUMENTS: '无法删除文档',
  FAILED_TO_UNLINK_FROM_FILE: '无法取消与文件的链接',
  SUCCESSFULLY_UPDATED_PENDING_DOCUMENTS: '已成功更新待定的文档',
  FAILED_TO_UPDATE_PENDING_DOCUMENTS: '无法更新待定的文档',
  SUCCESSFULLY_REJECTED_DOCUMENTS: '已成功拒绝文档',
  FAILED_TO_REJECT_DOCUMENTS: '无法拒绝文档',
  SUCCESSFULLY_APPROVED_DOCUMENTS: '已成功批准文档',
  FAILED_TO_APPROVE_DOCUMENTS: '无法批准文档',
  FREE_ACCOUNT_SUMMARY:
    '免费帐户只能充当其他<strong>vaultbox</strong>用户的副手。如果您要使用<strong>vaultbox</strong>的其他功能，请升级您的订阅计划',
  NOT_NOW: '不是现在',
  UPGRADE: '升级',
  PENDING_DOCUMENT_WARNING_COUNT:
    '此{{type}}具有{{documentCount}}链接的待定文档',
  PENDING_ASSET_LIABILITY_WARNING_COUNT:
    '此{{type}}具有{{assetLiabilityCount}}链接的待定资产 / 负债',
  CONTACT: '联系人',
  UNLINK_CONFIRM: '取消链接确认',
  DATE: '日期',
  ASSET_LIABILITY: '资产 / 负债',
  REMINDER_DAYS: '提醒天数',
  SUCCESSFULLY_UNLINKED_EVENT: '已成功取消链接事件',
  RECURRING_EVENT: '重复性事件',
  END_DATE: '结束日期',
  ACCESS_LEVEL: '访问级别',
  UPCOMING_EVENTS: '即将来临的事件',
  DONT_SHOW_AGAIN: '不再显示',
  RECURRING_TIMES: '重复时间',
  END_DATE_OF_RECURRING: '重复的结束日期',
  SELECT_DATE: '请选择一个日期',
  SELECT_RECURRING_TIMES: '请选择重复时间',
  THIS_EVENT: '此事件',
  THIS_AND_FOLLOWING_EVENTS: '此事件和后续事件',
  ALL_EVENTS: '所有事件',
  EDIT_RECURRING_EVENT: '编辑重复性事件',
  DELETE_RECURRING_EVENT: '删除重复性事件',
  CONFIRM_DOWNLOAD_VAULTBOX: '确认下载vaultbox',
  CONFIRM_DOWNLOAD_VAULTBOX_MSG: '您确定要下载vaultbox吗？',
  WARNING_DOWNLOAD_VAULTBOX_MSG: '下载过程可能需要几分钟',
  TOTAL_SIZE: '文件大小',
  YOUR_PASSWORD_HAS_BEEN_RESET: '您的密码已重置.',
  PASSWORDS: '密码',
  USERNAME: '用户名',
  INPUT_USERNAME_MSG: '输入你的用户名!',
  INPUT_URL_MSG: '请输入有效的网址（例如：http://example.com）',
  EDIT_PASSWORD: '修改密码',
  REMOVE_PASSWORD: '删除密码',
  SUCCESSFULLY_SAVED_THE_PASSWORD: '成功保存密码',
  FAILED_TO_SAVE_THE_PASSWORD: '未能保存密码',
  PASSWORD_LINKS: '密码链接',
  SELECT_PASSWORD: '选择密码',
  ADD_NEW_PASSWORD: '添加新密码',
  LINK_PASSWORD: '链接密码',
  SUCCESSFULLY_DELETED_THE_PASSWORD: '成功删除密码',
  FAILED_TO_DELETE_THE_PASSWORD: '删除密码失败',
  UNLINK_PASSWORD: '取消链接密码',
  SUCCESSFULLY_UNLINKED_PASSWORD: '成功取消链接密码',
  FAILED_TO_UNLINK_PASSWORD: '无法取消链接密码',
  PASSWORD_DETAILS: '密码详情',
  UNLINK_PASSWORD_CONFIRM: '你确定要取消链接密码吗?',
  PASSWORD_RECORDS: '密码',
  SELECT_PASSWORDS: '选择密码',
  TOO_SHORT: '太短',
  TOO_WEAK: '太弱',
  WEAK: '弱',
  FAIR: '合理',
  GOOD: '好',
  STRONG: '非常好',
  WAITING_FOR_DISCONNECTION: '等待疏离',
  FAILED_TO_DISCONNECT_USER: '无法断开用户连接',
  ARE_YOU_SURE_TO_DISCONNECT_THIS_USER: '您确定要取消与该用户的联系吗?',
  DISCONNECT: '断开',
  DISCONNECT_USER_REQUESTS: '断开用户请求',
  REQUEST_TO_DISCONNECT_ACCOUNT: '您的副手已收到断开您帐户的请求',
  APPROVE_TO_DISCONNECT: '批准取消',
  TRANSFER_TO_AN_OTHER_DEPUTY: '转移到另一位副手',
  THE_PD_IS_WAITING_FOR_DISCONNECTION: '专业代表正在等待断开连接',
  NO_OF_YEARS_REPAYMENT_COMPLETED: '年数 / 还款完成',
  USE_EMAIL_OTP_CODE: '使用电子邮件 OTP 代码',
  COLOR: '颜色',
  INCORRECT_PASSWORD: '密码错误!',
  RELEASE_PASSWORD: '释放密码',
  SUCCESSFULLY_RELEASE_PASSWORD: '成功释放密码',
  FAILED_TO_RELEASE_PASSWORD: '无法释放密码',
  WARNING_MSG: '警告信息',
  PERMANENTLY_DELETE_PRIVATE_FOLDER_WARNING:
    '在删除私人文件夹之前，您必须删除所有私人文件',
  PASSWORD_OF_THE_PRIVATE_FOLDER: '私人文件夹的密码',
  DOWNLOAD_PRIVATE_FOLDER_MSG:
    '请输入<strong>Private Folder的密码</strong>下载Vaultbox，包括Private Folder',
  SET_PASSWORD: '设置密码',
  SUCCESSFULLY_SET_PASSWORD: '成功设置密码',
  FAILED_TO_SET_PASSWORD: '设置密码失败',
  OTHER_PERSON_WARNING_MSG: '你不能选择没有电子邮件和电话号码的人',
  RELEASE_PASSWORD_NOTES: '如果用户有私人文件夹，则助理必须解锁用户以释放密码',
  YOU_CANNOT_NAME_THE_FOLDER_PRIVATE: '您不能将文件夹命名为"Private Folder”',
  SUN: '星期日',
  MON: '星期一',
  TUE: '星期二',
  WED: '星期三',
  THU: '星期四',
  FRI: '星期五',
  SAT: '星期六',
  SUN_MD: '星期日',
  MON_MD: '星期一',
  TUE_MD: '星期二',
  WED_MD: '星期三',
  THU_MD: '星期四',
  FRI_MD: '星期五',
  SAT_MD: '星期六',
  JAN: '一月',
  FEB: '二月',
  MAR: '三月',
  APR: '四月',
  MAY: '五月',
  JUN: '六月',
  JUL: '七月',
  AUG: '八月',
  SEP: '九月',
  OCT: '十月',
  NOV: '十一月',
  DEC: '十二月',
  JAN_MD: '一月',
  FEB_MD: '二月',
  MAR_MD: '三月',
  APR_MD: '四月',
  MAY_MD: '五月',
  JUN_MD: '六月',
  JUL_MD: '七月',
  AUG_MD: '八月',
  SEP_MD: '九月',
  OCT_MD: '十月',
  NOV_MD: '十一月',
  DEC_MD: '十二月',
  MONTH: '月',
  YEAR: '年',
  SELECT_EVENT: '选择事件',
  ADD_NEW_EVENT: '添加新事件',
  TO: '至',
  LOGIN_WITH_GOOGLE: '使用 Google 登录',
  LOGIN_WITH_FACEBOOK: '使用 Facebook 登录',
  VALUATION_DATE_IN_BASE_CURRENCY: '以基础货币计价的日期',
  CREATE_TEXT_FILE: '创建新的文本文件',
  EDIT_TEXT_FILE: '编辑文本文件',
  PASSWORD_COPIED: '密码已复制!',
  COPY_PASSWORD: '复制密码',
  WEBSITE: '网站',
  UPGRAGE_PLAN_SUMMARY:
    '已达到 20 个项目的基本计划限制。请升级您的订阅计划以保存更多记录',
  LIMITED_STORAGE_VAULTBOX_LITE: '2 GB的安全文件存储',
  LIMITED_RECORD_VAULTBOX_LITE:
    '20项记录是总数的限制: asset/liability, files, contacts, passwords 和 events',
  MARKETING_MATERIALS: '营销材料',
  MARKETING_MATERIALS_STATUS_TEXT: '您可以选择接收或不接收营销材料',
  INPUT_COUNTRY_MSG: '选择你的国家!',
  APPLY: '申请',
  WAITING_FOR_REDIRECT: '等待重定向',
  ACCESS_DENIED: '拒绝访问',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER:
    '您确定要重置Private Folder密码吗？',
  RESET_PASSWORD_OF_PRIVATE_FOLDER: '重置Private Folder的密码',
  SUCCESSFULLY_RESET_PASSWORD_OF_PRIVATE_FOLDER: '成功重置Private Folder密码',
  FAILED_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER: '重置Private Folder密码失败！',
  SKIP_PRIVATE_FOLDER: '跳过Private Folder',
  CANNOT_MOVE_LOCK_FILE_TO_PRIVATE_FOLDER:
    '您无法将锁定的文件移动到Private Folder',
  CANNOT_COPY_LOCK_FILE_TO_PRIVATE_FOLDER:
    '您无法将锁定的文件复制到Private Folder',
  CANNOT_CHOOSE_FILE_FROM_PRIVATE_FOLDER: '您不能选择Private Folder中的文件',
  RESET_PASSWORD_MSG: '输入您的保管箱帐户的密码以继续',
  LOCK_PRIVATE_FOLDER_MSG:
    '您Private Folder的访问权将被拒绝 24 小时，请稍后再试。',
  RECORD: '记录',
  YOU_NEED_TO_UPGRADE_YOUR_PLAN:
    '您即将用完<strong>vaultbox Essential</strong>的记录/存储限制，请升级以获得更好的体验',
  DIGITAL_VAULT: '数字保险库',
  DIGITAL_VAULT_CONTENT:
    '使用银行质量加密存储您的遗嘱、保险单和其他关键文件。 2GB 免费存储满足大多数用户的基本需求。',
  ASSETS_AND_LIABILITY_REGISTRY_CONTENT: '追踪您的净资产并生成财务报告',
  PASSWORD_VAULT: '密码库',
  PASSWORD_VAULT_CONTENT: '将所有密码集中在一个位置',
  CONTACT_MANAGEMENT: '联系人管理',
  CONTACT_MANAGEMENT_CONTENT:
    '存储所有重要联系人（例如律师、保险代理人、银行职员）',
  CALENDAR_FEATURE: '日历功能',
  CALENDAR_FEATURE_CONTENT: '跟踪您的付款和订阅',
  ESSENTIAL_VAULT_STORAGE: '2GB 存储空间（最多 20 个项目）',
  INCLUDE_EVERYTHING_IN_VAULTBOX_ESSENTIAL:
    '在 vaultbox Essential 中包含所有内容',
  DEPUTIES_CONTENT: '委任家庭成员和受信任的个人作为“智能遗产管理”的一部分',
  PROFESSIONAL_DEPUTY: '专业副手',
  PRO_STORAGE: '50GB 存储空间',
  ADD_ON_THIS_SERVICE_AT: '在此服务上添加',
  FREE_TO_USE_GET_STARTED_NOW_UPGRADE_ANYTIME:
    '免费使用。现在就开始。随时升级。',
  ULTIMATE_FINANCIAL_AND_LEGACY_PLAN: '终极财务和遗产计划。',
  IDEAL_FOR_SMALL_ENTERPRISES_AND_FAMILY: '适合小型企业和家庭。',
  RECORDS: '记录',
  AVATAR: '阿凡达',
  SUCCESSFULLY_UPLOADED_AVATAR: '头像上传成功',
  EDIT_AVATAR: '编辑头像',
  UPLOAD_AVATAR: '上传头像',
  FAILED_UPLOAD_AVATAR_MSG:
    '图片上传失败。仅支持最大为 2MB 的 PNG、JPEG。请再试一次',
  DRAG_AND_DROP_IMAGE_HERE_OR: '将图像拖放到此处或',
  BROWSE_FROM_YOUR_COMPUTER: '从您的计算机浏览',
  ZOOM: '飞涨',
  ADD_MEMBERS: '添加成员',
  SUCCESSFULLY_ADDED_MEMBERS: '已成功添加成员',
  SUCCESSFULLY_LEFT_GROUP: '成功离开群组',
  SUCCESSFULLY_REMOVED_MEMBERS: '已成功移除成员',
  FAILED_TO_ADD_MEMBERS: '添加成员失败',
  FAILED_TO_LEAVE_GROUP: '无法离开群组',
  FAILED_TO_REMOVE_MEMBER: '删除成员失败',
  PLEASE_ADD_AT_LEAST_ONE_EMAIL: '请添加至少一封电子邮件',
  LIMIT_MEMBER_MSG: '最多可添加 5 个用户（包括最小用户）。请检查并重试',
  ONE_OR_MORE_EMAIL_ALREADY_EXIST:
    '群组中已存在一封或多封电子邮件。请选择其他邮箱',
  ARE_YOU_SURE_TO_REMOVE_THIS_MEMBER: '您确定要删除此成员吗？',
  ARE_YOU_SURE_TO_LEFT_GROUP: '你确定要退群吗？',
  LEAVE_GROUP: '离开组',
  MEMBERS: '成员',
  NOT_BE_INVITED_TO_GROUP_MSG: '您需要被邀请加入此保管箱组计划',
  //CONSTANT
  REQUESTED_TO_RELEASE: 'R要求释放',
  ADD_PASSWORD: '添加密码',
  COPY_USERNAME: '复制用户名',
  USERNAME_COPIED: '用户名已复制!',
  MAIN_USER_CHANGE_SUBSCRIPTION_MSG: '当群组中仍有成员时，您无法更改订阅',
  LOADING: '加载',
  SUCCESSFULLY_ACCESSED_YOUR_PRIVATE_FOLDER: '{{name}} ({{email}}) 成功访问您的Private Folder',
  ATTEMPT_TO_OPEN_THE_LOCKED_PRIVATE_FOLDER: '{{name}} ({{email}}) 尝试打开锁定的Private Folder',
  PLEASE_MAKE_SURE_THAT_YOUR_FACEBOOK_ACCOUNT_HAS_AN_EMAIL: '请确保您的 Facebook 帐户有电子邮件',
  SUCCESSFULLY_SET_COLOR: '设置颜色成功',
  FAILED_TO_SET_COLOR: '设置颜色失败',
  COLORS_CUSTOMIZATION: '颜色自定义',
  RESET_TO_DEFAULT: '重置为默认值',
  FAILED_TO_RESET_COLOR: '重置颜色失败',
  PENDING_PASSWORDS: '待定密码',
  REJECTED_PASSWORDS: '拒绝密码',
  PENDING_EVENTS: '待定事件',
  REJECTED_EVENTS: '被拒绝的事件',
  UNLINK_EVENT: '取消链接事件',
  CHANGE_VAULTBOX_PRO_TO_VAULTBOX_GROUP_MSG:
    '您将立即切换到保险柜组。您确定要更改订阅吗？',
  INVITATIONS: '邀请',
  SUCCESSFULLY_ACCEPTED_INVITATION: '成功接受邀请',
  FAILED_TO_ACCEPT_INVITATION: '接受邀请失败!',
  SUCCESSFULLY_DECLINED_INVITATION: '成功拒绝邀请',
  FAILED_TO_DECLINE_INVITATION: '拒绝邀请失败!',
  GROUP_OWNER: '群主',
  LOGOUT_AND_ADD_CARD_LATER: '退出并稍后添加卡',
  INCLUDE_EVERYTHING_IN_VAULTBOX_PRO: '在 vaultbox Pro 中包含所有内容',
  DEPUTIES_FOR_ALL_MEMBERS: '所有成员的代表',
  FOR_UP_TO_MEMBERS: '最多 5 名成员',
  None: '没有',
  Weekly: '每星期',
  Monthly: '每月',
  Quarterly: '每季度',
  Yearly: '每年',
  Annually: '每年',
  day: '天',
  month: '月',
  year: '年',
  days: '天',
  Months: '月',
  months: '月',
  Years: '年',
  years: '年',
  Cash: '现金',
  Property: '房地产',
  Vehicle: '车辆',
  Insurance: '保险',
  Crypto: '加密货币',
  Trust: '信托',
  NRIC: '身份证号码',
  FIN: '外国身份证号码',
  Passport: '护照',
  Personal: '个人的',
  Other: '其他',
  Mobile: '手机',
  Work: '工作',
  Home: '家',
  Hospitalisation: '住院',
  Travel: '旅行',
  House: '房子',
  Apartment: '公寓',
  Commercial: '商业',
  Freehold: '永久产权',
  Leasehold: '租赁产权',
  name: '名称',
  value: '价值',
  Currency: '货币',
  Items: '项目',
  Valuation: '估值',
  Ticker: '股票代码',
  ISIN: 'ISIN',
  Individual: '个人',
  Organisation: '组织',
  Savings: '积蓄',
  Current: '目前',
  Jewellery: '首饰',
  Furnishing: '家具',
  Antiques: '古董',
  Art: '艺术',
  Collectible: '收藏品',
  Settlor: '委托人',
  Trustee: '受托人',
  Beneficiary: '受益人',
  Protector: '保护者',
  Settlors: '委托人',
  Trustees: '受托人',
  Beneficiaries: '受益人',
  Protectors: '保护者',
  Irrevocable: '不可撤销',
  Revocable: '可撤销',
  Private: '私人',
  Bare: '被动信托',
  Charitable: '慈善',
  Investment: '投资',
  Fixed: '固定',
  Variable: '可变',
  Ordinary: '普通',
  Preference: '优先',
  Convertible: '可换',
  CPF: '中央公积金',
  SRS: '退休辅助计划',
  Superannuation: '养老金',
  Pension: '退休金',
  Education: '教育',
  'Total and Permanent Disability': '永久残疾',
  'Investment-Linked Policy': '投资连系保险',
  'Income/Endowment/Savings': '收入/基金/储蓄',
  'Annuity/Retirement': '年金/退休金',
  'Life – Whole': '人寿保险',
  'Personal Accident': '个人意外',
  'Precious stone': '宝石',
  'Structured deposit': '结构性存款',
  'Fixed deposit': '定期存款',
  'Plain Text': '纯文本',
  'Word Document': 'Word文档',
  'Title with icon': '带图标的标题',
  'Other medical': '其他医疗',
  'Critical Illness': '危重病',
  'Life - Universal': '人寿 - 万用',
  'Life - Term': '人寿 - 定期',
  'Other assets': '其他资产',
  'Property loan': '房地产贷款',
  'Vehicle loan': '车辆贷款',
  'Credit card': '信用卡',
  'Loan from others': '来自他人的贷款',
  'Loan to others': '贷款给他人',
  'Other liability': '其他负债',
  'Retirement account': '退休账户',
  'Brokerage account': '经纪公司账户',
  'Equity - listed': '股票 - 已上市',
  'Equity - unlisted': '股票 - 未上市',
  'per month': '每月',
  'per year': '每年',
  'Free Sign Up': '免费报名',
  'No Credit Card Required': '无需信用卡'
}

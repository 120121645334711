import React from 'react'
import { H4 } from '../override/Typography'
import logo from '../../assets/dark.svg'
import { Link } from 'react-router-dom'

// TODO: custom lowercase/lower-roman list item from 'a.' to '(a)'

export function Li({ children }) {
  return (
    <li style={{ fontWeight: 'bold', paddingLeft: 10, margin: '1em 0 0.5em' }}>
      {children}
    </li>
  )
}

export function NumberedList({ start, children }) {
  return (
    <ol type="1" start={start} style={{ paddingLeft: 0 }}>
      {children}
    </ol>
  )
}

export function LowercaseList({ children }) {
  return (
    <ol type="a" style={{ paddingLeft: 22 }}>
      {children}
    </ol>
  )
}

export function LowerRomanList({ children }) {
  return (
    <ol type="i" style={{ paddingLeft: 10 }}>
      {children}
    </ol>
  )
}

export function Item({ children }) {
  return <li style={{ paddingLeft: 10 }}>{children}</li>
}

export default function TermsOfService() {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Link to="/login">
          <img
            alt="vaultbox logo"
            src={logo}
            style={{ margin: '10px 0 20px' }}
          />
        </Link>
        <H4>
          <u>Terms of Service</u>
        </H4>
        <span style={{ fontSize: 13 }}>Version as at 25 August 2020</span>
      </div>

      <div
        style={{
          paddingRight: 20,
          margin: '20px 0',
          textAlign: 'justify',
          maxHeight: 600,
          overflowY: 'scroll',
          fontSize: 13
          // border: 'solid 1px #E5E7EF',
          // borderRadius: 5
        }}
      >
        <ol type="A">
          {/* A.	Important */}
          <Li>Important</Li>
          <NumberedList start="1">
            <Item>
              Please read these terms and conditions carefully before accessing
              this website and using the online services. You agree to be bound
              by the following terms and conditions. If you do not accept any of
              these terms and conditions, please immediately discontinue your
              access to this website and use of the online services. For an
              explanation of defined terms, please refer to the Definitions
              section at the end of these terms and conditions.
            </Item>
          </NumberedList>

          {/* B.	Disclaimer */}
          <Li>Disclaimer</Li>
          <NumberedList start="2">
            <Item>
              The information and materials contained in or accessed through
              this website are provided on an “as is” and “as available” basis.
              We do not warrant the accuracy, adequacy, completeness or
              reasonableness of the information and materials contained in or
              accessed through this website and expressly disclaims liability
              from any errors in, or omissions from, such information and
              materials. No warranty of any kind, implied, express or statutory
              (including, but not limited to, warranties of title,
              merchantability, satisfactory quality, non-infringement of third
              party intellectual property rights, fitness for a particular
              purposes and freedom from computer virus and other malicious
              code), is given in conjunction with such information and
              materials, or this website in general.
            </Item>
            <Item>
              Under no circumstances shall we be liable regardless of the form
              of action for any failure of performance, system, server or
              connection failure, error, omission, interruption, breach of
              security, computer virus, malicious code, corruption, delay in
              operation or transmission, transmission error or unavailability of
              access in connection with your accessing this website and using
              the online services even if we had been advised as to the
              possibility.
            </Item>
            <Item>
              In no event shall we be liable to you or any other party for any
              damages, losses, expenses or costs whatsoever (including, without
              limitation, any direct, indirect, special, incidental or
              consequential damages, loss of profits or loss opportunity)
              arising in connection with your accessing this website and using
              the online services, regardless of the form of action and even if
              we had been advised as to the possibility of such damages.
            </Item>
          </NumberedList>

          {/* C.Indemnity */}
          <Li>Indemnity</Li>
          <NumberedList start="5">
            <Item>
              You hereby agree to indemnify and hold us harmless against all
              damages, losses, expenses and costs suffered or incurred by us in
              connection with or arising from (i) your access to this website
              and use of its online services, or (ii) any other party’s access
              to this website and use of its online services using your login
              details, or (iii) your breach of any of these terms and
              conditions, or (iv) any other party’s breach of any of these terms
              and conditions using your login details.
            </Item>
          </NumberedList>

          {/* D.Hyperlinks */}
          <Li>Hyperlinks</Li>
          <NumberedList start="6">
            <Item>
              For your convenience, we may include hyperlinks to websites that
              are owned and operated by third parties. Such linked websites are
              not under the control of us and we cannot accept responsibility
              for the contents of or the consequences of accessing any linked
              website or hyperlink contained in a linked website. Furthermore,
              the hyperlinks provided in this website shall not be considered or
              construed as an endorsement or verification of such linked website
              or its contents by us. You agree that your access to and use of
              such linked websites is entirely at your own risk and subject to
              the terms and conditions contained therein.
            </Item>
          </NumberedList>

          {/* E. Subscription and Payment */}
          <Li>Subscriptions and Payments</Li>
          <NumberedList start="7">
            <Item>Subscription period</Item>
            <LowercaseList>
              <Item>
                The online services or some parts of the online services are
                available only with a paid Subscription. You will be billed in
                advance on a recurring and periodic basis (such as but not
                limited to daily, weekly, monthly or annually), depending on the
                type of Subscription plan you select when purchasing the
                Subscription.
              </Item>
              <Item>
                At the end of each period, your Subscription will automatically
                renew for the same period at the prevailing rates unless
                cancelled by you or us.
              </Item>
            </LowercaseList>

            <Item>Subscription cancellation</Item>
            <LowercaseList>
              <Item>
                You may cancel your Subscription renewal through the relevant
                settings page in your Account.
              </Item>
              <Item>
                You will not receive a refund for the fees you already paid for
                your current Subscription period and you will be able to access
                the paid online services until the end of your current
                Subscription period.
              </Item>
            </LowercaseList>

            <Item>Billing</Item>
            <LowercaseList>
              <Item>
                All benefit of the online services are provided solely to you
                and are strictly non-transferable, non-refundable and
                non-exchangeable for cash, credit or other items and/or
                benefits.
              </Item>
              <Item>
                You shall provide us accurate and complete billing and payment
                method information.
              </Item>
              <Item>
                Should automatic billing fail to occur for any reason, or if
                your owings to us are not settled on time, your access to the
                online services requiring payment may be restricted and/or
                suspended until full payment is made. We reserve the right to
                downgrade your Subscription to a free plan (if any), cancel your
                Subscription or delete your accounts and data if full payment is
                not received when due.
              </Item>
              <Item>
                We may bill you for associated services consumed through the
                online services (including but not limited to those of
                professional deputies) on behalf of such service providers. We
                shall not be liable for their services. Your personal
                information may be transmitted to such service providers for
                billing purposes. If you cease to be our customer, you will no
                longer be able to be billed for the associated services through
                us, but you shall remain responsible for all charges incurred
                prior to such cessation.
              </Item>
            </LowercaseList>

            <Item>Fee changes</Item>
            <LowercaseList>
              <Item>
                We may at any time and at our sole discretion change the
                Subscription fees, which will then become effective at the end
                of your then-current Subscription period.
              </Item>
              <Item>
                Your continued use of the online services after the Subscription
                fee change comes into effect constitutes your agreement to pay
                the modified Subscription fee amount.
              </Item>
            </LowercaseList>

            <Item>Refunds</Item>
            <LowercaseList>
              <Item>
                Except when required by law, paid Subscription fees are
                non-refundable.
              </Item>
            </LowercaseList>

            <Item>Free trial</Item>
            <LowercaseList>
              <Item>
                We may, at our sole discretion, offer a Subscription with a free
                trial for a limited period of time.
              </Item>
              <Item>
                You may be required to enter your billing and payment
                information in order to sign up for the free trial.
              </Item>
              <Item>
                If you do enter your billing information when signing up for a
                free trial, you will not be charged until the free trial has
                expired. On the last day of the free trial period, unless you
                cancelled your Subscription, you will be automatically charged
                the applicable Subscription fees for the type of Subscription
                you have selected.
              </Item>
              <Item>
                At any time and without notice, we reserve the right to (i)
                modify the terms and conditions of the free trial offer, or (ii)
                cancel such free trial offer.
              </Item>
            </LowercaseList>

            <Item>Promotions</Item>
            <LowercaseList>
              <Item>
                Any promotions (including but not limited to referral
                promotions) made available through the online services may be
                governed by additional rules separate from these terms. Unless
                otherwise specified, we reserve the right to modify the terms
                and conditions of any promotion, offer or discount (including to
                cancel), and a specific promotion, offer or discount is not
                valid with and may not be used with any other promotion, offer
                or discount.
              </Item>
              <Item>
                If your use of a specific promotion, offer or discount is
                conditional upon your fulfilment of certain criteria (such as
                but not limited to keeping your account in good standing for a
                certain period of time, using a personalised promotional code
                from a referrer known to you or being a customer of an affiliate
                or partner), and at any point in time you fail to satisfy such
                criteria or are otherwise not entitled to such promotion, offer
                or discount, we shall be entitled to clawback the benefit in
                full and charge you an equivalent value as determined by us.
              </Item>
              <Item>
                Notwithstanding anything to the contrary, any credit or amounts
                on your account in your favour are non-refundable and
                non-exchangeable for cash, credit or other items and/or
                benefits, but may be used by you to offset future payments to
                us.
              </Item>
            </LowercaseList>
          </NumberedList>

          {/* F.Access and Use */}
          <Li>Access and Use</Li>
          <NumberedList start="14">
            <Item>
              You agree and undertake, in connection with the website and online
              services, and whether by yourself or in assisting others, to:
            </Item>
            <LowercaseList>
              <Item>
                not use any equipment, device, software or material which you
                know or have reason to suspect contains any viruses, malicious
                code or damaging components which may interfere with the
                operation of the website or online services, or corrupt data or
                software on or provided through the website or online services;
              </Item>
              <Item>
                not transmit any materials or information through the website or
                online services which are or may be offensive, indecent,
                defamatory, fraudulent, criminal or which may not be lawfully
                disseminated under applicable laws or which you know or have
                reason to suspect contains any viruses, malicious code or
                damaging components;
              </Item>
              <Item>
                not probe, scan or test the vulnerability of the website or any
                online services without our prior express written consent;
              </Item>
              <Item>
                not breach or otherwise circumvent any security or
                authentication measures;
              </Item>
              <Item>
                not abuse referrals or promotions to get more features than
                deserved or to sell features received from referrals or
                promotions;
              </Item>
              <Item>not circumvent feature limits;</Item>
              <Item>
                not sell the online services unless specifically authorised to
                do so;
              </Item>
              <Item>
                not harass or abuse our personnel or representatives or agents
                performing services on behalf of us;
              </Item>
              <Item>
                not violate the privacy of or infringe the rights of others;
              </Item>
              <Item>
                access the website and use the online services in conformity
                with all applicable laws, the acceptable use policies of any
                connected computer networks and any applicable Internet
                standards.
              </Item>
            </LowercaseList>

            <Item>
              We may at any time without notice to you determine and vary the
              (a) frequency and operating hours of, and manner of access to, the
              website and use of its online services, and (b) the types of
              information, facilities and services available.
            </Item>

            <Item>
              You will be responsible for all transactions made by the use or
              purported use of the website and its online services by any
              person, with or without your authority, knowledge or consent, and
              may not claim against us in respect of any such use or purported
              use.
            </Item>

            <Item>
              We may at any time, without notice to you and without assigning
              any reason therefor, and without liability for any inconvenience,
              loss, damage or injury suffered by you or any third party:
            </Item>
            <LowercaseList>
              <Item>
                limit, suspend or terminate any or all of the website and/or its
                online services;
              </Item>
              <Item>
                disallow any transaction or allow a transaction subject to such
                conditions as we see fit; or
              </Item>
              <Item>
                revoke or suspend your, or any person’s, right and authority to
                access the website and use the online services.
              </Item>
            </LowercaseList>
          </NumberedList>

          {/* G.Authorised User */}
          <Li>Authorised User</Li>
          <NumberedList start="18">
            <Item>
              You acknowledge and confirm that your deputies as appointed under
              the online services and any persons that you have authorised or
              are deemed to have authorised to access and/or use the online
              services directly or indirectly in relation to you (all such
              persons to be collectively referred to as the{' '}
              <b>"Authorised Users"</b>), are, subject to any restrictions that
              we may impose, severally empowered and authorised to give orders
              or instructions through the online services on your behalf and
              shall act, without prejudice to any other capacity which such
              person may be transacting under, as your agent when accessing
              and/or using the website or online services, directly or
              indirectly in relation to you.
            </Item>
            <Item>
              You acknowledge and confirm that we shall be authorised to
              disclose your information to your Authorised Users or any persons
              whom we reasonably believe in good faith to be your Authorised
              Users.
            </Item>
            <Item>
              You acknowledge and agree that all use and/or access of the
              website and online services by your Authorised Users shall be
              deemed your use. All references to your access or use of the
              website or online services shall be deemed to include the
              Authorised User's use and/or access where applicable. You shall
              procure and ensure that each of your Authorised Users is aware of
              and complies with the terms of this Agreement in relation to you.
            </Item>
            <Item>
              Where you are accessing this website or using the online services
              as an Authorised User, you agree that:
            </Item>
            <LowercaseList>
              <Item>
                the terms and conditions of this Agreement shall apply to any
                such access or use; and
              </Item>
              <Item>
                you shall be bound by and shall comply with all other terms and
                conditions or restrictions applicable to you in connection with
                such use.
              </Item>
            </LowercaseList>
          </NumberedList>

          {/* H. Instruction */}
          <Li>Instructions</Li>
          <NumberedList start="22">
            <Item>
              You agree to be bound by any access or use of the website or
              online services (whether such access or use are authorised by you
              or not) which are referable to your information or Security Codes
              or those of your Authorised User. You agree and acknowledge that
              any use of or access to the website or online services referable
              to your information or Security Codes or those of your Authorised
              Users and any Instructions shall be deemed to be, as the case may
              be:
            </Item>
            <LowercaseList>
              <Item>
                use of or access to the website or online services by you or
                your Authorised Users; or
              </Item>
              <Item>
                Instructions transmitted or validly issued by you or your
                Authorised Users.
              </Item>
            </LowercaseList>
            <Item>
              You further agree that we shall be entitled (but not obliged) to
              act upon, rely on or hold you solely responsible and liable in
              respect thereof as if the same were carried out or transmitted by
              you or your Authorised Users and you waive all rights and remedies
              against us in respect of any loss, damage or expense thereby
              arising. You will be responsible and shall be liable for all
              Instructions.
            </Item>
            <Item>
              We are under no obligation to investigate the authenticity or
              authority of persons effecting the Instructions or to verify the
              accuracy and completeness of the Instructions. Accordingly, we may
              (a) treat the Instructions as valid and binding on you; and/or (b)
              reveal your information to such persons notwithstanding any
              unauthorised access to the website or online services, error,
              fraud, forgery, lack of clarity or misunderstanding in the terms
              of the Instructions. Any loss, damage or expense resulting from
              the use of the website or online services by you or your
              Authorised Users are entirely at your own risk and we shall not be
              liable therefor.
            </Item>
            <Item>
              All Instructions will be deemed to be irrevocable and
              unconditional upon transmission through the website or online
              services and we shall be entitled (but not obliged) to effect,
              perform or process such Instruction(s) without your further
              consent and without any further reference or notice to you.
              Nevertheless, in certain circumstances you may request to cancel
              or amend the Electronic Instructions which we will endeavour to
              give effect to on a commercially reasonable effort basis. However,
              notwithstanding the foregoing, we are not obliged to give effect
              to any request to cancel or amend any Instruction.
            </Item>
            <Item>
              Instructions may not have been received by us and accordingly, may
              not be carried out or processed, and we will not be liable for any
              loss, damage or expense which thereby arises. You are therefore
              advised to check on such Instructions via the website or online
              services or otherwise. Unless otherwise stated or determined in
              our absolute discretion any Instruction received by us after the
              relevant cut-off time on a business day (as determined by us from
              time to time) or on a non-business day in Singapore will be
              treated as an Instruction received on the next business day.
            </Item>
            <Item>
              You acknowledge and agree that we may at any time at our sole
              discretion and without stating reasons:
            </Item>
            <LowercaseList>
              <Item>
                require that you identify yourself by alternative means;
              </Item>
              <Item>
                require any Instructions to be confirmed through alternative
                means;
              </Item>
              <Item>
                refrain from acting promptly upon any Instructions in order to
                verify the authenticity of any Instructions or your identity;
              </Item>
              <Item>
                determine the order of priority in effecting the Instructions,
              </Item>
              <Item>
                decline to act on the Instructions at any time without prior
                notice or giving any reason, where:
                <LowerRomanList>
                  <Item>
                    the Instructions are ambiguous, incomplete or inconsistent
                    with your other Instructions or instructions, information
                    and/or data;
                  </Item>
                  <Item>
                    the Instructions have lapsed, been rendered invalid due to
                    failure to comply with applicable conditions or are
                    cancelled by the relevant regulatory or governmental body;
                  </Item>
                  <Item>
                    the Instructions cannot be processed due to any disruptions
                    that are beyond our reasonable control;
                  </Item>
                </LowerRomanList>
                without incurring any responsibility for loss, liability or
                expense arising out of so declining to act.
              </Item>
            </LowercaseList>
            <Item>
              You agree and acknowledge that Instructions may not be processed
              immediately, around the clock or in a timely manner, and that we
              will not thereby be liable for any loss, damage or expense
              suffered by you as a result of any delay.
            </Item>
            <Item>
              You shall be solely responsible for ensuring the accuracy,
              adequacy and completeness of the Instructions and we shall not be
              obliged to verify the accuracy, adequacy and completeness of such
              instructions. You agree that we shall not be liable for any loss,
              damage or expense suffered by you as a result of any Instructions
              being inaccurate, inadequate or incomplete in any way.
            </Item>
          </NumberedList>

          {/* I.Security */}
          <Li>Security</Li>
          <NumberedList start="30">
            <Item>
              Use of the online services may require the use of Security Codes.
              Your Security Codes may be dispatched by us, at your risk: by mail
              to your last known address, by email to your last known email
              address, or in such other manner as we may prescribe. You agree to
              hold us harmless if any other person obtains possession of any of
              your Security Codes or Security Device.
            </Item>
            <Item>
              Separate Security Codes for your Authorised Users may be issued
              directly to your Authorised User on such conditions as determined
              by us from time to time and such issuance will be subject to the
              terms of this Agreement to be accepted in full by the Authorised
              User in their personal capacity.
            </Item>
            <Item>
              You are responsible for the confidentiality and use of your
              Security Codes. It is vital that you take steps to prevent
              disclosure or discovery of the Security Codes provided to you as
              you are liable for all losses incurred or transactions carried out
              in connection with any unauthorised use of the Security Codes or
              Security Devices and you may not claim against us in respect of
              any such use or purported use.
            </Item>
            <Item>
              You must notify us by contacting us in the manner stipulated in
              the online services immediately if you have lost any of your
              Security Codes, Security Devices or have knowledge or have reason
              for suspecting that the confidentiality of the Security Codes have
              been compromised or if there has been any unauthorised use of the
              Security Codes (such as where you notice any unauthorised
              transactions or unauthorised access to your Accounts through the
              online services). Following the occurrence of any event referred
              to in this Clause, we may at our discretion issue replacement
              Security Codes and/or Security Devices and charge a replacement
              fee.
            </Item>
            <Item>
              We may at our sole discretion require you to use and shall issue
              to you, replacement Security Codes or Security Devices.
            </Item>
            <Item>
              All Security Devices provided to you by us are and will remain our
              property and you must return all Security Devices promptly upon
              our request or upon cancellation or termination of the online
              services. Security Devices must not be altered, tampered with,
              disassembled or in any way copied or modified, and must not be
              dealt with or exploited in any way without our written
              authorisation.
            </Item>
            <Item>
              You shall notify us immediately upon receipt of any data or
              information which is not intended for you and you shall delete
              such data or information from your telecommunications device or
              equipment immediately.
            </Item>
            <Item>
              If any Authorised User to whom the Security Codes and/or Security
              Devices are given is no longer authorised to use the online
              services in relation to your Account, you shall immediately notify
              us.
            </Item>
          </NumberedList>

          {/* J.Copyright */}
          <Li>Copyright</Li>
          <NumberedList start="38">
            <Item>
              Unless accompanied by an explicit statement to the contrary, all
              information and materials contained in or accessed through this
              website are protected by copyrights, trademarks, database rights
              or other intellectual property rights, and is the property of
              ours.
            </Item>
            <Item>
              Except as otherwise specifically agreed in writing or to the
              extent necessary for your access to this website and use of its
              online services in accordance with these terms and conditions, you
              shall not (i) copy the website in whole or in part, (ii) display,
              reproduce, create derivative works from, transmit, sell,
              distribute, rent, lease, sublicense, timeshare, lend or transfer
              or in any way exploit the website in whole or in part, (iii) embed
              the website into other products, (iv) use the website in any
              timesharing arrangement, (v) create function calls or other
              embedded links from any software program to the website, (vi)
              remove or obscure any copyright notice of ours or any of its third
              party suppliers, (vii) use any trademarks, service markets, domain
              names, logos or other identifiers of ours or of any of its third
              party suppliers, or (viii) save to the extent permitted by law,
              reverse engineer, decompile, disassemble or access the source code
              of the website.
            </Item>
            <Item>
              Nothing on the website is designed to grant any license or right
              to use any image, trademark or logo. No act of downloading or
              otherwise copying from the website will transfer any legal
              entitlement to any software, information or materials on the
              website to you. We reserve all intellectual property rights
              (including but not limited to copyrights, trademarks or database
              rights) to all information and materials on the website, and will
              enforce such rights to the full extent of the law.
            </Item>
          </NumberedList>

          {/* K.	Severability */}
          <Li>Severability</Li>
          <NumberedList start="41">
            <Item>
              To the extent that any provisions in these terms and conditions
              are found by any competent court or authority to be invalid,
              unlawful or unenforceable in any jurisdiction, that provision
              shall be deemed to be severed from these terms and conditions but
              such finding shall not affect the validity, lawfulness or
              enforceability of these terms and conditions in any other
              jurisdiction.
            </Item>
          </NumberedList>

          <Li>Consent to Disclosure and Use of Information</Li>
          <NumberedList start="42">
            <Item>
              By accessing the website and/or using the online services, you
              acknowledge and accept the disclosure of your information to us,
              our affiliates or such other third party for the purpose of
              providing you access to the website and/or use of the online
              services, and sending you notices and communications relating to
              your access to the website and/or use of the online services, or
              to your business with us. Failure to allow such disclosure, access
              to or use of your information may result in our inability to offer
              or continue to offer the online services to you.
            </Item>
            <Item>
              You will provide us with any information or documentation that we
              may reasonably request relating to your access of the website or
              use of the online services and shall cooperate with us in any
              related investigation or litigation.
            </Item>
            <Item>
              Our authority to collect, use or disclose your information shall
              survive the termination of this Agreement, and our rights and
              abilities under this section shall be in addition to and without
              prejudice to our other rights of disclosure under and pursuant to
              any other agreement you have with us, or any other laws and
              nothing herein is to be construed as limiting any of those other
              rights.
            </Item>
            <Item>
              Neither we nor any of our personnel shall be liable for any loss
              or damage suffered by you as a result of any disclosure of any
              information which you have consented to us collecting, using or
              disclosing or where such collection, use or disclosure is allowed
              under the applicable laws.
            </Item>
            <Item>
              For information on how we collect, use and disclose personal data,
              please request our Personal Data Protection Policy Statement from
              us using the contact form on{' '}
              <a
                href="https://www.vaultbox.tech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.vaultbox.tech
              </a>{' '}
              or <a href="mailto:hello@vaultbox.tech">hello@vaultbox.tech</a>.
            </Item>
          </NumberedList>

          {/* M. Personal Data Protection Statement */}
          <Li>Personal Data Protection Statement</Li>
          <NumberedList start="47">
            <Item>
              This statement applies to any individual’s personal data which is
              accessible to us in the clear, and in our possession or under our
              control.
            </Item>
            <Item>
              In this statement, “personal data” refers to data, whether true or
              not, that can be used to uniquely identify a natural person.
              Personal data can be collected from various sources and processed
              by us. Such data includes but is not limited to your personal
              particulars, financial details, images and biometrics, personal
              opinions made known to us and information relating to your
              activities, preferences and interests arising from your use of the
              products and services of us, our affiliates or our partners or
              vendors, and other data or information relating to you through the
              usage of the online services or as part of the delivery of the
              online services to you.
            </Item>
            <Item>
              We may use your personal data for our core business purposes,
              which includes without limitation:
            </Item>
            <LowercaseList>
              <Item>
                developing and providing products or services (whether made
                available by us or through us);
              </Item>
              <Item>
                assessing and processing applications, Instructions or requests
                from you or any Authorised User;
              </Item>
              <Item>
                communicating with you, including providing you with updates on
                changes to products and services (whether made available by us
                or through us) including any additions, expansions, suspensions
                and replacements of or to such products and services and their
                terms and conditions;
              </Item>
              <Item>
                managing our infrastructure, business operations and complying
                with internal policies and procedures;
              </Item>
              <Item>responding to queries or feedback;</Item>
              <Item>
                addressing or investigating any complaints, claims or disputes;
              </Item>
              <Item>
                verifying your identity for the purposes of providing any
                products or services;
              </Item>
              <Item>
                conducting credit checks, screenings or due diligence checks as
                may be required under applicable law, regulation or directive;
              </Item>
              <Item>
                complying with all applicable laws, regulations, rules,
                directives, orders, instructions, guidance and requests from any
                local or foreign authorities, including regulatory,
                governmental, tax and law enforcement authorities or other
                authorities;
              </Item>
              <Item>
                monitoring products and services provided by or made available
                through us;
              </Item>
              <Item>
                financial reporting, regulatory reporting, management reporting,
                risk management (including monitoring credit exposures), audit
                and record keeping purposes;
              </Item>
              <Item>
                enabling any actual or proposed assignee or transferee,
                participant or sub-participant of our rights or obligations to
                evaluate any proposed transaction;
              </Item>
              <Item>enforcing obligations owed to us</Item>
              <Item>
                in connection with performance of duties when seeking
                consultancy or professional advice, including legal advice;
                and/or
              </Item>
              <Item>
                administering benefits or entitlements in connection with our
                relationship with you, including the administration of loyalty,
                rewards programmes, lucky draws, and/or sending gifts and
                awards.sent by post or left at your last known address or sent
                to your last known email will be deemed to be received by you on
                the day following such posting or transmission or on the day
                when it was so left; or
              </Item>
              <Item>
                for such purposes set out in the terms and conditions governing
                our relationship with you or Authorised User.
              </Item>
            </LowercaseList>
            <Item>
              We may use your personal data to offer you products or services,
              including special offers, promotions, contests or entitlements
              that may be of interest to you or for which you may be eligible.
              Such marketing messages may be sent to you in various modes
              including but not limited to electronic mail, direct mailers,
              short message service, telephone calls, facsimile and other mobile
              messaging services.
            </Item>
            <Item>
              If you are in Singapore, in respect of sending telemarketing
              messages to your Singapore telephone number via short message
              service, telephone calls (voice or video), facsimile and other
              mobile messaging services, we will only do so if
            </Item>
            <LowercaseList>
              <Item>
                you have provided your clear and unambiguous consent in writing
                or other recorded form for us to do so, which shall override any
                registration of that number with the Singapore National Do Not
                Call Registry; or
              </Item>
              <Item>
                you have not indicated to us in our ongoing relationship that
                you do not wish to receive telemarketing messages sent to your
                Singapore telephone number and you have not registered that
                number with the Singapore National Do Not Call Registry.
              </Item>
            </LowercaseList>
            <Item>
              We may from time to time and in compliance with all applicable
              laws on data privacy, disclose your personal data to any of our
              personnel or to third parties, whether located in Singapore or
              elsewhere, in order to carry out the purposes set out above.
            </Item>
            <Item>
              We may transfer, store, process and/or deal with your personal
              data outside Singapore. In doing so, we will comply with
              applicable data protection laws.
            </Item>
            <Item>
              Our products and services may use cookies. A cookie is a small
              text file placed on your computer or mobile device when you visit
              a web site or use an app. We use cookies and other technologies to
              facilitate your internet sessions and use of our products and
              services, offer you products and/or services according to your
              preferred settings, track use of our products and services and to
              compile statistics about activities carried out on our web sites
              and/or through our products and services. We may also use web
              beacons or similar technologies with or without cookies.
            </Item>
            <Item>
              You may set up your web browser to block cookies or you may also
              remove cookies stored from your computer or mobile device.
              However, this may limit certain features and functions in your use
              of the relevant products and services.
            </Item>
            <Item>
              Your personal data is retained as long as the purpose for which it
              was collected remains and until it is no longer necessary for any
              other legal or business purposes.
            </Item>
            <Item>
              You may request access or make corrections to your personal data
              held by us. We may charge a fee for processing your request for
              access. Such a fee depends on the nature and complexity of your
              access request. Information on the processing fee will be made
              available to you upon reasonable request.
            </Item>
            <Item>
              This statement may be amended by us from time to time. We will
              make available the statement on{' '}
              <a
                href="https://www.vaultbox.tech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.vaultbox.tech
              </a>
              . all communications, transactions and dealing with us shall be
              subject to the latest version of this statement in force at the
              time.
            </Item>
            <Item>
              To contact us on any aspect of this statement or your personal
              data, please contact us using the contact form on{' '}
              <a
                href="https://www.vaultbox.tech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.vaultbox.tech
              </a>{' '}
              or email us at{' '}
              <a href="mailto:hello@vaultbox.tech">hello@vaultbox.tech</a>.
            </Item>
          </NumberedList>
          {/* N. Notices */}
          <Li>Notices</Li>
          <NumberedList start="60">
            <Item>
              All notices or other communications given by us to you if:
            </Item>
            <LowercaseList>
              <Item>
                sent by post or left at your last known address or sent to your
                last known email will be deemed to be received by you on the day
                following such posting or transmission or on the day when it was
                so left; or
              </Item>
              <Item>
                communicated through any print or electronic media (including
                but not limited to via this website or online services) as we
                may select will be deemed to be notified to you on the date of
                publication or broadcast.
              </Item>
            </LowercaseList>
          </NumberedList>

          {/* O. Records */}
          <Li>Records</Li>
          <NumberedList start="61">
            <Item>
              Our records of information or transactions shall be conclusive
              against and binding on you except in the case of manifest error.
              You hereby agree that all such records are admissible in evidence
              and that you shall not challenge or dispute the admissibility,
              reliability, accuracy or the authenticity of the contents of such
              records merely on the basis that such records were incorporated
              and/or set out in electronic form or are produced by or were the
              output of a computer system, and you hereby waive any of your
              rights (if any) to so object.
            </Item>
          </NumberedList>

          {/* P.	No Waiver */}
          <Li>No Waiver</Li>
          <NumberedList start="62">
            <Item>
              No failure to exercise, nor any delay in exercising, on our part
              any right or remedy under this Agreement will operate as a waiver
              thereof, nor shall any single or partial exercise of any right or
              remedy prevent any further or other exercise thereof or the
              exercise of any other right or remedy. Our rights and remedies in
              this Agreement are cumulative and not exclusive of any other
              rights or remedies provided by law.
            </Item>
          </NumberedList>

          {/* Q.	Miscellaneous */}
          <Li>Miscellaneous</Li>
          <NumberedList start="63">
            <Item>
              We reserve the right to use any providers, subcontractors and/or
              agents on such terms as we deem appropriate.
            </Item>
            <Item>
              Except for our affiliates, providers, subcontractors and agents,
              no person or entity who is not a party to this Agreement shall
              have any right under the Contracts (Rights of Third Parties) Act
              (Cap. 53B) to enforce any term of this Agreement, regardless of
              whether such person or entity has been identified by name, as a
              member of a class or as answering a particular description. For
              the avoidance of doubt, this shall not affect the rights of any
              permitted assignee or transferee of this Agreement.
            </Item>
            <Item>
              The information and materials contained in or accessed through
              this website or the online services are subject to change
              (including, without limitation, modification, deletion or
              replacement thereof) without notice.
            </Item>
            <Item>
              We may without giving any reason vary this Agreement at any time
              with or without prior notice to you. We will post the most current
              version of this Agreement at{' '}
              <a
                href="https://www.vaultbox.tech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.vaultbox.tech
              </a>
              . You shall not hold us liable for any loss you may incur due to
              the discontinuance, suspension or substitution of any benefits or
              features you previously enjoyed. Your continued access of this
              website and use of the online services means you are consenting to
              the latest version of this Agreement in force at the time.
            </Item>
            <Item>
              We, our affiliates, providers, subcontractors and agents shall not
              be liable for any non-performance, error, interruption or delay in
              the performance of any obligations under this Agreement or in the
              website’s or online services’ operation, or for any inaccuracy,
              unreliability or unsuitability of the online services if this is
              due, in whole or in part, directly or indirectly to an event or
              failure which is beyond its reasonable control (which includes
              acts of God, natural disasters, acts of terrorism, embargoes,
              epidemics, acts of any government or authority, power failures,
              and the acts or a party for whom we, our affiliates, providers,
              subcontractors and agents are not responsible for).
            </Item>
          </NumberedList>

          {/* R.	Governing Law and Jurisdiction */}
          <Li>Governing Law and Jurisdiction</Li>
          <NumberedList start="68">
            <Item>
              Nothing herein shall be construed as a representation by us that
              the information and materials contained in or accessed through
              this website is appropriate or available for use in jurisdictions
              other than Singapore. By accessing this website and using the
              online services, you agree that such access to this website and
              use of its online services, as well as these terms and conditions
              shall be governed by, and construed in accordance with, the laws
              of the Republic of Singapore, and you agree to submit to the
              exclusive jurisdiction of the Singapore courts.
            </Item>
          </NumberedList>

          {/* S.	Definitions */}
          <Li>Definitions</Li>
          <NumberedList start="69">
            <Item>
              In this Agreement, unless the context otherwise requires:
            </Item>
            <LowercaseList>
              <Item>
                “Account” means the specific electronic vault or custody account
                or engagement which you maintain with and through us;
              </Item>
              <Item>
                "affiliate" means, in relation to any person, a person that
                directly, or indirectly through one or more intermediaries,
                controls or is controlled by, or is under common control with,
                such person;
              </Item>
              <Item>
                “Instructions” means any communication, instruction, order,
                message, data, information or other materials received by us via
                the website, online services or any other channel and referable
                to your information, Security Codes or those of your Authorised
                Users (including use of your information or Security Codes or
                those of your Authorised Users by any person, whether authorised
                or unauthorised by you or your Authorised Users), from you or
                purporting to come from you or from your Authorised Users or
                purporting to come from your Authorised Users;
              </Item>
              <Item>
                “online services” means any services offered or otherwise
                accessed in any manner through this website;.
              </Item>
              <Item>
                “Security Codes” means personal or login identification details,
                Security Devices and other codes (including but not limited to
                passcodes, whether selected by you, generated for one-time use
                or otherwise) and access procedures for use in connection with
                access to and use of the website or online services;
              </Item>
              <Item>
                "Security Devices" means any software, tokens, electronic
                devices, hardware or any other equipment required in conjunction
                with the Security Codes in order to enable you to access and/or
                use the website or online services;
              </Item>
              <Item>
                “Subscription” means the online services or access to the online
                services (or such add-on, component or part thereof) offered on
                a subscription basis to you;
              </Item>
              <Item>
                “this Agreement” means these terms and conditions as amended or
                supplemented;
              </Item>
              <Item>
                “Sircured” (which may also be referred to as “we”, “us” or
                “our”) means Sircured Pte. Ltd. and/or its affiliates;
              </Item>
              <Item>
                “you” means the individual accessing or using the website and/or
                online services, or the company or legal entity on behalf of
                which such individual is accessing or using the website and/or
                online services, as applicable;
              </Item>
              <Item>
                words importing the singular include the plural and vice versa;
              </Item>
              <Item>
                words importing the masculine gender include the feminine or
                neuter gender and vice versa;
              </Item>
              <Item>
                references to persons are to be construed as references to an
                individual, company or trust as the context requires; and
              </Item>
              <Item>
                headings are for ease of reference and shall not affect the
                interpretation of any provision herein.
              </Item>
            </LowercaseList>
          </NumberedList>
        </ol>
      </div>
    </>
  )
}

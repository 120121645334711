import { combineReducers, createAction } from '@reduxjs/toolkit'
import assetsLiabilitiesReducer from './features/assets-liabilities/assetsLiabilitiesSlice'
import otherAssetsLiabilitiesReducer from './features/assets-liabilities/otherAssetsLiabilitiesSlice'
import contactsReducer from './features/contacts/contactsSlice'
import eventsReducer from './features/events/eventsSlice'
import linkedEventsReducer from './features/events/linkedEventsSlice'
import otherContactsReducer from './features/contacts/otherContactsSlice'
import documentsReducer from './features/documents/documentsSlice'
import otherDocumentsReducer from './features/documents/otherDocumentsSlice'
import deputiesReducer from './features/deputies/deputiesSlice'
import customerReducer from './features/payment/customerSlice'
import settingsReducer from './features/settings/settingsSlice'
import userReducer from './features/user/userSlice'
import passwordsReducer from './features/passwords/passwordsSlice'
import otherPasswordsReducer from './features/passwords/otherPasswordsSlice'
import otherEventsReducer from './features/events/otherEventsSlice'

const combinedReducer = combineReducers({
  assetsLiabilities: assetsLiabilitiesReducer,
  otherAssetsLiabilities: otherAssetsLiabilitiesReducer,
  events: eventsReducer,
  otherEvents: otherEventsReducer,
  linkedEvents: linkedEventsReducer,
  contacts: contactsReducer,
  otherContacts: otherContactsReducer,
  documents: documentsReducer,
  otherDocuments: otherDocumentsReducer,
  deputies: deputiesReducer,
  customer: customerReducer,
  settings: settingsReducer,
  user: userReducer,
  passwords: passwordsReducer,
  otherPasswords: otherPasswordsReducer
})

export const storeReset = createAction('store/reset')

const rootReducer = (state, action) => {
  if (action.type === storeReset.toString()) {
    state = undefined
  }
  return combinedReducer(state, action)
}

export default rootReducer

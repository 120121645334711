import React, { useContext } from 'react'
import { Icon, Tooltip, message, Modal, Checkbox } from 'antd'
import { removeAssetLiabilityRecord } from '../../lib/pouchDb'
import { permanentlyDeleteItems, getRecords } from '../../lib/pouchDb'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { uniq } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'

export default function RemoveAssetLiability(props) {
  const { record } = props
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const userId = user.username
  const keys = record.links
  const documentIds = record.documents
  const passwordIds = record.passwords
  const eventIds = record.events
  const { t } = useTranslation()

  const [addS3Change] = useMutation(createS3Change)

  const contactIds = uniq([
    ...(record.contacts || []),
    ...(record.tenant || []),
    ...(record.insuranceAdvisor || []),
    ...(record.beneficiaries || []),
    ...(record.borrower ? [record.borrower] : []),
    ...(record.nameAssured ? [record.nameAssured] : []),
    ...(record.company ? [record.company] : []),
    ...(record.lender ? [record.lender] : [])
  ])

  const doDeleteValuationsHistory = async () => {
    const valuationRecords = await getRecords(
      userId,
      'assetsLiabilitiesValuations',
      masterKey,
      { startkey: record._id, endkey: `${record._id}\ufff0` }
    )

    await permanentlyDeleteItems(
      'assetsLiabilitiesValuations',
      userId,
      valuationRecords,
      masterKey
    )
  }

  const showConfirm = () => {
    let deleteValuationsHistory = false

    let linkedItemsMessages = []
    if (keys?.length)
      linkedItemsMessages.push(`${keys.length} ${t('ASSET_LIABILITY_RECORDS')}`)

    if (contactIds.length)
      linkedItemsMessages.push(`${contactIds.length} ${t('CONTACT_RECORDS')}`)
    if (documentIds?.length)
      linkedItemsMessages.push(
        `${documentIds.length} ${t('FILE_RECORDS').toLowerCase()}`
      )
    if (eventIds?.length)
      linkedItemsMessages.push(
        `${eventIds.length} ${t('EVENT_RECORDS').toLowerCase()}`
      )
    if (passwordIds?.length)
      linkedItemsMessages.push(
        `${passwordIds.length} ${t('PASSWORD_RECORDS').toLowerCase()}`
      )

    Modal.confirm({
      title: t('CONFIRM_DELETE_ITEM_MSG'),
      content: (
        <>
          <p>
            {!!linkedItemsMessages.length &&
              `${linkedItemsMessages.join(' & ')} ${t(
                'WILL_BE_UNLINKED_FROM_THIS_ITEM'
              )}.`}
          </p>
          <p>
            <Checkbox
              onChange={e => {
                deleteValuationsHistory = e.target.checked
              }}
            >
              {t('DELETE_ALL_HISTORICAL_VALUATIONS_DATA')}
            </Checkbox>
          </p>
        </>
      ),
      onOk: async () => {
        try {
          await removeAssetLiabilityRecord(userId, masterKey, record)
          if (deleteValuationsHistory) {
            await doDeleteValuationsHistory()
          }
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, contacts, documents, pendingDocuments, events, assetsLiabilitiesValuations ',
              userId: userId
            }
          })
          message.success(t('SUCCESSFULLY_DELETED_ASSET_LIABILITY'))
        } catch {
          message.error(t('FAILED_TO_DELETE_ASSET_LIABILITY'))
        }
      }
    })
  }

  return (
    <Tooltip
      title={t('REMOVE_ASSET_LIABILITY')}
      arrowPointAtCenter
      placement="topRight"
    >
      <Icon
        onClick={showConfirm}
        theme="twoTone"
        twoToneColor="#eb4444"
        type="delete"
      />
    </Tooltip>
  )
}

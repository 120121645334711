import React, { useState, useContext, useEffect } from 'react'
import { Modal, Form, Input, message, List, Icon } from 'antd'
import { useTranslation } from 'react-i18next'
import { uploadEncryptedData, getRecords } from '../../lib/pouchDb'
import Button from '../override/Button'
import PouchDB from 'pouchdb'
import uuidv4 from 'uuid/v4'
import VaultContext from '../../contexts/VaultContext'
import AssetLiabilitySelect from '../assets-liabilities/AssetLiabilitySelect'
import DocumentsLinksModal from '../assets-liabilities/DocumentsLinksModal'
import FormItem from '../override/FormItem'
import { fetchPasswords } from '../../features/passwords/passwordsSlice'
import { useDispatch } from 'react-redux'
import PasswordStrengthBar from 'react-password-strength-bar'
import { updatePasswordsOnDb } from './passwordHelpers'
import EventSelect from '../common/EventSelect'
import { onError } from '../../lib/sentry'
import { Span } from '../override/Typography'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import FileTags from '../file/FileTags'
import { useSelector } from 'react-redux'
import SubscriptionModal from '../payment/SubscriptionModal'
import { showUpgradeSubscriptionPlanConfirm } from '../../share/helpers'
import AuthContext from '../../contexts/AuthContext'
import { ACCESS_LEVEL } from '../../share/Constants'
import {
  fetchOtherPasswords,
  fetchOtherPendingPasswords
} from '../../features/passwords/otherPasswordsSlice'
import api from '../../lib/api'

const { TextArea } = Input

const PasswordModal = props => {
  const {
    isEditMode,
    setIsEditMode,
    setVisible,
    visible,
    selectedPassword,
    onAddComplete,
    form
  } = props
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const { userId, masterKey, limitedRecord, isReadonly } =
    useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [documentsLinksVisible, setDocumentsLinksVisible] = useState(false)
  const [passwordField, setPasswordField] = useState('')
  const [addS3Change] = useMutation(createS3Change)
  const [tags, setTags] = useState([])
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const { limit } = useSelector(state => state.customer)
  const { accessLevel } = useSelector(state => state.settings)
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activeDocuments, pendingDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))

  useEffect(() => {
    const fetchData = async () => {
      try {
        const record = selectedPassword
        const linkedDocuments =
          record.documents && record.documents.length
            ? [
                ...(await getRecords(userId, 'documents', masterKey)).filter(
                  c => record.documents?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingDocuments', masterKey)
                ).filter(c => record.documents?.includes(c._id))
              ]
            : []
        setLinkedDocuments(linkedDocuments)
      } catch (err) {
        onError(err)
      }
    }
    if (masterKey) {
      fetchData()
    }
  }, [
    userId,
    masterKey,
    accessLevel,
    isDelegateByPD,
    isProfessionalDeputy,
    isReadonly,
    selectedPassword
  ])



  const passwordState = isEditMode ? selectedPassword.password : ''
  useEffect(() => {
    if (isEditMode) {
      setPasswordField(passwordState)
    }
  }, [isEditMode, passwordState])

  const unlinkDocument = itemId => {
    setLinkedDocuments(linkedDocuments.filter(doc => doc._id !== itemId))
  }

  useEffect(() => {
    setTags(selectedPassword?.tags || [])
  }, [selectedPassword])

  const linkDocument = async documentId => {
    try {
      const documentIds = linkedDocuments.map(doc => doc._id).concat(documentId)
      const newLinkedDocuments =
        documentIds && documentIds.length
          ? [
              ...(await getRecords(userId, 'documents', masterKey)).filter(c =>
                documentIds?.includes(c._id)
              ),
              ...(
                await getRecords(userId, 'pendingDocuments', masterKey)
              ).filter(c => documentIds?.includes(c._id))
            ]
          : []
      setLinkedDocuments(newLinkedDocuments)
    } catch (err) {
      onError(err)
    }
  }
  const handelSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      if (limitedRecord >= limit && !isEditMode) {
        showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
        return
      }

      try {
        setIsSaving(true)
        const db =
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? new PouchDB(`${userId}_pendingPasswords`)
            : new PouchDB(`${userId}_passwords`)

        db.crypto(masterKey)

        const record = isEditMode
          ? await db.get(selectedPassword._id)
          : {
              _id: uuidv4(),
              url: '',
              title: '',
              username: '',
              password: '',
              note: '',
              assetsLiabilities: [],
              events: [],
              documents: []
            }
        const newRecord = {
          ...record,
          url: values.url,
          title: values.title,
          username: values.username,
          password: values.password,
          note: values.note,
          assetsLiabilities: values.assetsLiabilities,
          events: values.events,
          documents: linkedDocuments.map(doc => doc._id),
          tags,
          status:
            ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
              ? 'Draft'
              : undefined
        }

        //update AssetsLiabilities
        const pendingAssetLiabilityIds =
          values.assetsLiabilities.filter(id =>
            pendingAssetsLiabilities.map(c => c._id).includes(id)
          ) || []
        const activeAssetLiabilityIds =
          values.assetsLiabilities.filter(id =>
            activeAssetsLiabilities.map(pa => pa._id).includes(id)
          ) || []

        if (
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ) {
          await updatePasswordsOnDb(
            pendingAssetLiabilityIds,
            record.assetsLiabilities.filter(id =>
              pendingAssetsLiabilities.map(pa => pa._id).includes(id)
            ) || [],
            'pendingAssetsLiabilities',
            userId,
            masterKey,
            newRecord
          )
        }

        await updatePasswordsOnDb(
          activeAssetLiabilityIds,
          record.assetsLiabilities.filter(id =>
            activeAssetsLiabilities.map(pa => pa._id).includes(id)
          ) || [],
          'assetsLiabilities',
          userId,
          masterKey,
          newRecord
        )

        //update Events
        const pendingEventIds =
          values.events.filter(id =>
            pendingEvents.map(c => c._id).includes(id)
          ) || []
        const activeEventIds =
          values.events.filter(id => events.map(pa => pa._id).includes(id)) ||
          []

        if (
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ) {
          await updatePasswordsOnDb(
            pendingEventIds,
            record.events.filter(id =>
              pendingEvents.map(pa => pa._id).includes(id)
            ) || [],
            'pendingEvents',
            userId,
            masterKey,
            newRecord
          )
        }

        await updatePasswordsOnDb(
          activeEventIds,
          record.events.filter(id => events.map(pa => pa._id).includes(id)) ||
            [],
          'events',
          userId,
          masterKey,
          newRecord
        )

        //update Documents
        const pendingDocumentIds =
          linkedDocuments
            .map(d => d._id)
            .filter(id => pendingDocuments.map(c => c._id).includes(id)) || []
        const activeDocumentIds =
          linkedDocuments
            .map(d => d._id)
            .filter(id => activeDocuments.map(pa => pa._id).includes(id)) || []

        if (
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ) {
          await updatePasswordsOnDb(
            pendingDocumentIds,
            record.documents.filter(id =>
              pendingDocuments.map(pa => pa._id).includes(id)
            ) || [],
            'pendingDocuments',
            userId,
            masterKey,
            newRecord
          )
        }

        await updatePasswordsOnDb(
          activeDocumentIds,
          record.documents.filter(id =>
            activeDocuments.map(pa => pa._id).includes(id)
          ) || [],
          'documents',
          userId,
          masterKey,
          newRecord
        )

        await db.put(newRecord)

        await uploadEncryptedData(
          db,
          userId,
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'pendingPasswords'
            : 'passwords'
        )

        if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
          if (accessLevel === ACCESS_LEVEL.NEED_APPROVAL) {
            await api.sendAddRecordNotification(
              JSON.stringify({
                userId,
                recordType: 'password'
              })
            )
            dispatch(fetchOtherPendingPasswords(userId, masterKey))
          } else {
            dispatch(fetchOtherPasswords(userId, masterKey))
          }
        }

        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, events, pendingEvents, documents, pendingDocuments, passwords, pendingPasswords',
            userId: userId
          }
        })
        setTags([])
        onAddComplete && onAddComplete(newRecord._id)
        message.success(t('SUCCESSFULLY_SAVED_THE_PASSWORD'))
        dispatch(fetchPasswords(userId, masterKey))
      } catch (error) {
        message.success(t('FAILED_TO_SAVE_THE_PASSWORD'))
        onError(error)
      } finally {
        setVisible(false)
        form.resetFields()
        setIsSaving(false)
        setPasswordField('')
        if (isEditMode) {
          setIsEditMode(false)
        }
      }
    })
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
    if (isEditMode) {
      setIsEditMode(false)
    }
    setPasswordField('')
  }

  return (
    <>
      <Modal
        title={isEditMode ? t('EDIT_PASSWORD') : t('ADD_NEW_PASSWORD')}
        visible={visible}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="cancle" onClick={handleCancel}>
            {t('CANCEL')}
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={isSaving}
            onClick={handelSave}
          >
            {t('SAVE')}
          </Button>
        ]}
      >
        <Form>
          <FormItem label={t('TITLE')}>
            {getFieldDecorator('title', {
              initialValue: isEditMode ? selectedPassword.title : '',
              rules: [{ required: true, message: t('INPUT_NAME_MSG') }]
            })(<Input />)}
          </FormItem>
          <FormItem label={t('URL')}>
            {getFieldDecorator('url', {
              initialValue: isEditMode ? selectedPassword.url : 'http://',
              rules: [
                { required: false, type: 'url', message: t('INPUT_URL_MSG') }
              ]
            })(<Input />)}
          </FormItem>
          <FormItem label={t('USERNAME')}>
            {getFieldDecorator('username', {
              initialValue: isEditMode ? selectedPassword.username : ''
            })(<Input />)}
          </FormItem>
          <FormItem label={t('PASSWORD')}>
            {getFieldDecorator('password', {
              initialValue: isEditMode ? selectedPassword.password : '',
              rules: [{ required: true, message: t('INPUT_PASSWORD_MSG') }]
            })(
              <Input.Password
                onChange={e => {
                  setPasswordField(e.target.value)
                }}
              />
            )}
          </FormItem>
          {!!passwordField && (
            <PasswordStrengthBar
              password={passwordField}
              minLength={1}
              scoreWords={[
                t('TOO_WEAK'),
                t('WEAK'),
                t('FAIR'),
                t('GOOD'),
                t('STRONG')
              ]}
              shortScoreWord={t('TOO_SHORT')}
            />
          )}
          <FormItem label={t('TAGS')}>
            <FileTags tags={tags} setTags={setTags} />
          </FormItem>

          <FormItem label={t('NOTE')}>
            {getFieldDecorator('note', {
              initialValue: isEditMode ? selectedPassword.note : ''
            })(<TextArea rows={4} maxLength={2000} />)}
          </FormItem>

          <AssetLiabilitySelect
            label={t('ASSETS_LIABILITIES')}
            placeholder={t('SELECT_ASSETS_LIABILITIES')}
            required={false}
            getFieldDecorator={getFieldDecorator}
            fieldName="assetsLiabilities"
            initialValue={isEditMode ? selectedPassword.assetsLiabilities : []}
            mode="multiple"
            onAddComplete={alId =>
              setFieldsValue({
                assetsLiabilities: [
                  ...(getFieldValue('assetsLiabilities') || []),
                  alId
                ]
              })
            }
          />

          <EventSelect
            label={t('EVENT')}
            getFieldDecorator={getFieldDecorator}
            fieldName="events"
            linkedEvents={[]}
            fetchEvents={() => {}}
            setLinkedEvents={() => {}}
            initialValue={isEditMode ? selectedPassword.events : []}
            mode="multiple"
            onAddComplete={eventId =>
              setFieldsValue({
                events: [...(getFieldValue('events') || []), eventId]
              })
            }
          />
          <List
            header={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Span>{t('LINKED_DOCUMENTS')}:</Span>
                <Button
                  icon="plus"
                  onClick={() => setDocumentsLinksVisible(true)}
                >
                  {t('ADD')}
                </Button>
              </div>
            }
            dataSource={linkedDocuments}
            renderItem={item => (
              <List.Item className="linked-document-item">
                <span>
                  <Icon type="file-text" style={{ marginRight: 10 }} />
                  {item.fileName}
                </span>
                <Icon type="delete" onClick={() => unlinkDocument(item._id)} />
              </List.Item>
            )}
            locale={{ emptyText: t('NO_DOCUMENTS') }}
          />
        </Form>
      </Modal>
      <DocumentsLinksModal
        visible={documentsLinksVisible}
        setVisible={setDocumentsLinksVisible}
        record={{}}
        linkDocument={linkDocument}
        filteredDocIds={linkedDocuments.map(doc => doc._id)}
      />
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </>
  )
}

const WrappedPasswordForm = Form.create({ name: 'PasswordModal' })(
  PasswordModal
)

export default WrappedPasswordForm

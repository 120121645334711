import React, { useState, useContext, useEffect } from 'react'
import {
  Layout,
  Empty,
  Tag,
  Tooltip,
  Icon,
  Modal,
  message,
  Divider,
  Form,
  AutoComplete
} from 'antd'
import { H3, H4 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { permanentlyDeleteItems, uploadEncryptedData } from '../../lib/pouchDb'
import SimpleHeader from '../override/SimpleHeader'
import Button from '../override/Button'
import CustomTable from '../override/CustomTable'
import PasswordAddEdit from './PasswordAddEdit'
import VaultContext from '../../contexts/VaultContext'
import FileDetails from '../file/FileDetails'
import {
  fetchPasswords,
  fetchPendingPasswords
} from '../../features/passwords/passwordsSlice'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import { unlinkPasswordFromDbs } from './passwordHelpers'
import PasswordFolderModal from '../file/PasswordFolderModal'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import copy from 'copy-to-clipboard'
import { uniq } from 'lodash'
import { AES, enc } from 'crypto-js'
import { ACCESS_LEVEL } from '../../share/Constants'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThemeContext } from 'styled-components'
import AuthContext from '../../contexts/AuthContext'
import PouchDB from 'pouchdb'
import api from '../../lib/api'
import { fetchPendingAssetsLiabilities } from '../../features/assets-liabilities/assetsLiabilitiesSlice'
import RejectModal from '../modals/RejectModal'
import { fetchOtherPendingPasswords } from '../../features/passwords/otherPasswordsSlice'

const { Content } = Layout

function Passwords(props) {
  const { form } = props
  const { getFieldDecorator } = form
  const theme = useContext(ThemeContext)
  const [visible, setVisible] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedPassword, setSelectedPassword] = useState('')
  const [showPassword, setShowPassword] = useState('')
  const [docItem, setDocItem] = useState('')
  const [fileDetailsVisible, setFileDetailsVisible] = useState(false)
  const [isShowLinkedDocument, setIsShowLinkedDocument] = useState(false)
  const [passwordFolderModalVisible, setPasswordFolderModalVisible] =
    useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(null)
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [rejectRecord, setRejectRecord] = useState({})

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [addS3Change] = useMutation(createS3Change)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const {
    userId,
    masterKey,
    privateFolderKey,
    isLockPrivateFolder,
    isReadonly,
    fullName
  } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activeDocuments, pendingDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))
  const { accessLevel } = useSelector(state => state.settings)

  const {
    activePasswords,
    pendingPasswords,
    rejectedPasswords,
    passwordTags,
    isLoading
  } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const privateFolder = activeDocuments.find(folder => folder.isPrivate)
  const privateFolderPassword = !!privateFolder?.password
    ? AES.decrypt(privateFolder.password, privateFolderKey).toString(enc.Latin1)
    : undefined

  let rejectFormRef

  useEffect(() => {
    setSearchResults(activePasswords)
  }, [activePasswords])

  const copyToClipboard = (fieldName, record) => {
    try {
      fieldName === 'password' ? copy(record.password) : copy(record.username)
      message.success(
        t(fieldName === 'password' ? 'PASSWORD_COPIED' : 'USERNAME_COPIED')
      )
    } catch (error) {
      onError(error)
    }
  }

  const handleDeletePassword = record => {
    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: <>{t('CONFIRM_DELETE_ITEM_MSG')}</>,
      async onOk() {
        try {
          await permanentlyDeleteItems(
            isProfessionalDeputy || (isDelegateByPD && isReadonly)
              ? 'pendingPasswords'
              : 'passwords',
            userId,
            [record],
            masterKey
          )

          await unlinkPasswordFromDbs(record, userId, masterKey)

          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, pendingAssetsLiabilities, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
              userId: userId
            }
          })

          message.success(t('SUCCESSFULLY_DELETED_THE_PASSWORD'))
          dispatch(fetchPasswords(userId, masterKey))
          if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
            dispatch(fetchOtherPendingPasswords(userId, masterKey))
          }
        } catch (error) {
          onError(error)
          message.success(t('FAILED_TO_DELETE_THE_PASSWORD'))
        }
      },
      onCancel() {}
    })
  }

  const columns = [
    {
      key: 'title',
      width: 200,
      dataIndex: 'title',
      title: <span className="dragHandler">{t('TITLE')}</span>,
      render: (text, record) => <span>{record.title}</span>,
      sorter: (a, b) => a.title.localeCompare(b.title),
      defaultSortOrder: 'ascend'
    },
    {
      key: 'url',
      width: 200,
      dataIndex: 'url',
      title: <span className="dragHandler">{t('URL')}</span>,
      render: (text, record) => {
        const url = record.url
        if (url.length > 100) {
          const showURL = url.slice(0, 97).concat('...')
          return (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {showURL}
              </a>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip placement="bottom" title={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </Tooltip>
          )
        }
      },
      sorter: (a, b) => a.url.localeCompare(b.url)
    },
    {
      key: 'username',
      width: 250,
      dataIndex: 'username',
      title: <span className="dragHandler">{t('USERNAME')}</span>,
      render: (text, record) => (
        <span>
          <Tooltip
            title={t('COPY_USERNAME')}
            arrowPointAtCenter
            placement="topRight"
          >
            <Icon
              onClick={() => copyToClipboard('username', record)}
              type="copy"
            />
          </Tooltip>{' '}
          {record.username}
        </span>
      ),
      sorter: (a, b) => a.username.localeCompare(b.username)
    },
    {
      key: 'password',
      width: 250,
      dataIndex: 'password',
      title: <span className="dragHandler">{t('PASSWORD')}</span>,
      render: (text, record) => {
        const passwordLength = record.password.length
        let hidenPassword = ''
        for (let i = 0; i < passwordLength; i++) {
          hidenPassword += '*'
        }
        if (showPassword === record._id) {
          return (
            <>
              <span style={{ marginRight: '5px' }}>
                <Tooltip
                  title={t('COPY_PASSWORD')}
                  arrowPointAtCenter
                  placement="topRight"
                >
                  <Icon
                    onClick={() => copyToClipboard('password', record)}
                    type="copy"
                  />
                </Tooltip>{' '}
                {record.password}
              </span>
              <Icon onClick={() => setShowPassword('')} type="eye-invisible" />
            </>
          )
        } else {
          return (
            <>
              <span style={{ marginRight: '5px' }}>
                <Tooltip
                  title={t('COPY_PASSWORD')}
                  arrowPointAtCenter
                  placement="topRight"
                >
                  <Icon
                    onClick={() => copyToClipboard('password', record)}
                    type="copy"
                  />
                </Tooltip>{' '}
                {hidenPassword}
              </span>
              <Icon onClick={() => setShowPassword(record._id)} type="eye" />
            </>
          )
        }
      }
    },
    {
      key: 'note',
      width: 200,
      dataIndex: 'note',
      title: <span className="dragHandler">{t('NOTE')}</span>,
      render: (text, record) => <span>{record.note}</span>
    },
    {
      key: 'assetsLiabilities',
      width: 200,
      dataIndex: 'assetsLiabilities',
      title: <span className="dragHandler">{t('ASSETS_LIABILITIES')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.assetsLiabilities &&
            record.assetsLiabilities.map(alId =>
              (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? [...activeAssetsLiabilities, ...pendingAssetsLiabilities]
                : activeAssetsLiabilities
              ).map(al =>
                alId === al._id ? <Tag key={al._id}>{al.title}</Tag> : null
              )
            )}
        </span>
      )
    },
    {
      key: 'documents',
      width: 200,
      dataIndex: 'documents',
      title: <span className="dragHandler">{t('DOCUMENTS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.documents &&
            record.documents.map(docId =>
              (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? [...activeDocuments, ...pendingDocuments]
                : activeDocuments
              ).map(d => {
                if (docId === d._id) {
                  const item = {
                    id: d._id,
                    name: d.fileName,
                    ...d
                  }
                  return (
                    <Tag onClick={() => showFileDetails(item)} key={d._id}>
                      {d.fileName}
                    </Tag>
                  )
                } else {
                  return null
                }
              })
            )}
        </span>
      )
    },
    {
      key: 'events',
      width: 200,
      dataIndex: 'events',
      title: <span className="dragHandler">{t('EVENTS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.events &&
            record.events.map(eventId =>
              (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? [...events, ...pendingEvents]
                : events
              ).map(event =>
                eventId === event._id ? (
                  <Tag key={event._id}>{event.description}</Tag>
                ) : null
              )
            )}
        </span>
      )
    },
    {
      key: 'tags',
      width: 200,
      dataIndex: 'tags',
      title: <span className="dragHandler">{t('TAGS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.tags && record.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
        </span>
      )
    }
  ]

  const rejectColumn = [
    {
      key: 'reasonReject',
      width: 200,
      dataIndex: 'reasonReject',
      title: <span className="dragHandler">{t('REJECTED_REASON')}</span>,
      render: text => text
    }
  ]

  const actionsColumn =
    isReadonly && !isDelegateByPD && !isProfessionalDeputy
      ? []
      : [
          {
            key: 'actions',
            width: 300,
            dataIndex: 'actions',
            render: (text, record) => (
              <div
                style={{ textAlign: 'end', minWidth: 40 }}
                onClick={e => e.stopPropagation()}
              >
                {record.status === 'Draft' &&
                !isProfessionalDeputy &&
                (!isReadonly || !isDelegateByPD) ? (
                  <>
                    <Button
                      type="primary"
                      loading={loading === record._id}
                      onClick={() => handlePasswordRequest(record, true)}
                    >
                      {t('APPROVE')}
                    </Button>
                    <Divider type="vertical" />
                    <Button onClick={() => handlePasswordRequest(record)}>
                      {t('REJECT')}
                    </Button>
                  </>
                ) : !record.status &&
                  ((isDelegateByPD && isReadonly) || isProfessionalDeputy) ? (
                  []
                ) : (
                  <>
                    <Tooltip
                      title={t('EDIT_PASSWORD')}
                      arrowPointAtCenter
                      placement="topRight"
                    >
                      <Icon
                        theme="twoTone"
                        type="edit"
                        onClick={() => {
                          setIsEditMode(true)
                          setVisible(true)
                          setSelectedPassword(record)
                        }}
                      />
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip
                      title={t('REMOVE_PASSWORD')}
                      arrowPointAtCenter
                      placement="topRight"
                    >
                      <Icon
                        onClick={() => handleDeletePassword(record)}
                        theme="twoTone"
                        twoToneColor="#eb4444"
                        type="delete"
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            )
          }
        ]

  const handleSearch = () => {
    form.validateFields((err, values) => {
      if (err) return

      setSearchResults(
        activePasswords?.filter(password =>
          password?.tags.includes(values.tags)
        )
      )
    })
  }
  const resetFields = () => {
    form.resetFields()
    setSearchResults(activePasswords)
  }

  const showFileDetails = item => {
    if (
      !!privateFolder?.password &&
      item.path.slice(0, privateFolder?.path.length) === privateFolder?.path
    ) {
      if (isLockPrivateFolder) {
        Modal.warning({
          title: t('WARNING_MSG'),
          content: t('LOCK_PRIVATE_FOLDER_MSG')
        })
      } else if (
        !!privateFolderPassword &&
        localStorage.getItem('privateFolderPassword') === privateFolderPassword
      ) {
        setDocItem(item)
        setFileDetailsVisible(true)
      } else {
        setIsShowLinkedDocument(true)
        setDocItem(item)
        setPasswordFolderModalVisible(true)
      }
    } else {
      setDocItem(item)
      setFileDetailsVisible(true)
    }
  }

  const handlePasswordRequest = async (record, isApproved = false) => {
    setLoading(record._id)
    try {
      if (isApproved) {
        const db = new PouchDB(`${userId}_passwords`)
        db.crypto(masterKey)
        const newRecord = {
          ...record,
          status: undefined
        }
        delete newRecord._rev
        await db.put(newRecord)
        await uploadEncryptedData(db, userId, 'passwords')

        //remove pending password
        const pendingDb = new PouchDB(`${userId}_pendingPasswords`)
        const deletedRecord = {
          ...record,
          deleted: true
        }

        pendingDb.crypto(masterKey)
        await pendingDb.put(deletedRecord)
        const deletedRecords = await getRecords(
          userId,
          'pendingPasswords',
          masterKey,
          {
            startkey: record._id,
            endkey: `${record._id}\ufff0`
          }
        )

        await permanentlyDeleteItems(
          'pendingPasswords',
          userId,
          deletedRecords,
          masterKey
        )

        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'password'
          })
        )

        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'passwords, pendingPasswords',
            userId: userId
          }
        })
        dispatch(fetchPendingPasswords(userId, masterKey))
      } else {
        rejectFormRef.props.form.resetFields()
        setRejectModalVisible(true)
        setRejectRecord(record)
      }
    } catch (error) {
      message.error(
        isApproved
          ? t('FAILED_TO_APPROVE_REQUEST')
          : t('FAILED_TO_REJECT_REQUEST')
      )
    } finally {
      setLoading(null)
    }
  }

  const handleRejectRecord = () => {
    setRejecting(true)

    rejectFormRef.props.form.validateFields(async (err, values) => {
      try {
        if (err) {
          setRejecting(false)
          return
        }

        await unlinkPasswordFromDbs(rejectRecord, userId, masterKey)

        const pendingDb = new PouchDB(`${userId}_pendingPasswords`)
        pendingDb.crypto(masterKey)
        const newRecord = {
          ...rejectRecord,
          assetsLiabilities: [],
          documents: [],
          events: [],
          links: [],
          status: 'Rejected',
          reasonReject: values.reasonReject
        }
        await pendingDb.put(newRecord)
        await uploadEncryptedData(pendingDb, userId, 'pendingPasswords')

        dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
        dispatch(fetchPendingPasswords(userId, masterKey))
        setRejecting(false)
        setRejectModalVisible(false)
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
            userId: userId
          }
        })
      } catch (error) {
        setRejecting(false)
        onError(error)
        message.error(t('FAILED_TO_REJECT_REQUEST'))
      }
    })
  }

  return (
    <>
      <Layout
        style={{
          height: '100%',
          padding: '0 20px 20px',
          backgroundColor: '#fff'
        }}
      >
        <SimpleHeader
          title={<H4 display="inline-block">{t('PASSWORDS')}</H4>}
          extra={
            (!isReadonly ||
              (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
                accessLevel &&
                accessLevel !== ACCESS_LEVEL.DENY)) && (
              <Button icon="plus" onClick={() => setVisible(true)}>
                {t('CREATE_NEW')}
              </Button>
            )
          }
        />
        <Content>
          <Form
            style={{ marginBottom: 20 }}
            layout="inline"
            onKeyUp={e =>
              (e.keyCode === 13 || e.which === 13) && handleSearch()
            }
          >
            <Form.Item label={t('TAGS')}>
              {getFieldDecorator('tags')(
                <AutoComplete
                  dataSource={uniq(passwordTags.flat())}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleSearch}>
                {t('SEARCH')}
              </Button>
              &nbsp;
              <Button type="default" onClick={resetFields}>
                {t('RESET')}
              </Button>
            </Form.Item>
          </Form>
          {searchResults?.length ? (
            <CustomTable
              rowKey="_id"
              columns={[...columns, ...actionsColumn]}
              dataSource={searchResults}
              loading={isLoading}
              scroll={{ x: true }}
              size={isSmUp ? '' : 'small'}
            />
          ) : (
            <Empty />
          )}

          {!!pendingPasswords?.length &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
              <>
                <Divider type="horizontal" />
                <H3> {t('PENDING_PASSWORDS')} </H3>
                <CustomTable
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={pendingPasswords}
                  loading={isLoading}
                  columns={[...columns, ...actionsColumn]}
                  pagination={pendingPasswords.length > 10 && { pageSize: 10 }}
                  size={isSmUp ? '' : 'small'}
                />
              </>
            )}

          {!!rejectedPasswords?.length &&
            ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
              <>
                <Divider type="horizontal" />
                <H3> {t('REJECTED_PASSWORDS')} </H3>
                <CustomTable
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={rejectedPasswords}
                  loading={isLoading}
                  columns={[...columns, ...rejectColumn, ...actionsColumn]}
                  pagination={rejectedPasswords.length > 10 && { pageSize: 10 }}
                  size={isSmUp ? '' : 'small'}
                />
              </>
            )}
        </Content>
      </Layout>

      <PasswordAddEdit
        selectedPassword={selectedPassword}
        visible={visible}
        setVisible={setVisible}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
      />
      <FileDetails
        visible={fileDetailsVisible}
        setVisible={setFileDetailsVisible}
        docItem={docItem}
      />
      <PasswordFolderModal
        visible={passwordFolderModalVisible}
        setVisible={setPasswordFolderModalVisible}
        isShowLinkedDocument={isShowLinkedDocument}
        isDeletePrivateFolder={false}
        docItem={docItem}
      />
      <RejectModal
        wrappedComponentRef={fr => (rejectFormRef = fr)}
        visible={rejectModalVisible}
        handleOk={handleRejectRecord}
        handleCancel={() => setRejectModalVisible(false)}
        rejecting={rejecting}
      />
    </>
  )
}

const WrappePasswordListForm = Form.create({ name: 'passwordListForm' })(
  Passwords
)

export default WrappePasswordListForm

import React from 'react'
import { Form, Input, Upload, Icon, Modal } from 'antd'
import FileTags from './FileTags'
//import { StringResources } from '../../share/StringResources'
import { MentionsInput, Mention } from 'react-mentions'
import FormItem from '../override/FormItem'
import FileDirectory from './FileDirectory'
import Button from '../override/Button'
import ContactSelect from '../common/ContactSelect'
import EventSelect from '../common/EventSelect'
import PasswordSelect from '../common/PasswordSelect'
import AssetLiabilitySelect from '../assets-liabilities/AssetLiabilitySelect'
import PasswordFolderModal from '../file/PasswordFolderModal'
import { getFileParts, validateFilenames } from '../../share/formHelpers'
import { MAX_FILE_SIZE } from '../../share/Constants'
import { formatBytes, sanitizeValue } from '../../share/helpers'
import TextInput from '../common/TextInput'
import { withTranslation, Trans } from 'react-i18next'
import { AES, enc } from 'crypto-js'

const { TextArea } = Input
const { Dragger } = Upload

// Notes: Use class component since "function components cannot be given refs"
// To get form instance from function component, would need to combine forwardRef with useImperativeHandle
class UploadForm extends React.Component {
  state = {
    path: null,
    fileDirectoryModalVisibile: false,
    isUploadToPrivateFolder: false,
    passwordFolderModalVisible: false,
    filesCount: 0
  }

  normFile = e => {
    // Temporary only allow uploading a single file at once (the last selected file) for simplicity,
    // may consider adding support for uploading multiple files later
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  render() {
    const {
      form,
      tags,
      setTags,
      contactDataSource,
      descriptionValue,
      setDescriptionValue,
      folderKey,
      activeFiles,
      pendingFiles,
      isShowPending,
      activeFolders,
      privateFolderKey,
      t
    } = this.props
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form

    const handleContactDeselect = id => {
      const deselectedContact = contactDataSource.find(
        record => record._id === id
      )
      if (deselectedContact) {
        const markUp = new RegExp(`@\\[(((?!@\\[).)*)\\]\\(${id}\\)`, 'g')
        const newDescriptionValue = descriptionValue.replace(markUp, '$1')
        setDescriptionValue(newDescriptionValue)
      }
    }

    const privateFolder = activeFolders.find(folder => folder.isPrivate)
    const privateFolderPassword = !!privateFolder?.password
      ? AES.decrypt(privateFolder.password, privateFolderKey).toString(
          enc.Latin1
        )
      : undefined

    const handleDescriptionChange = (
      event,
      newValue,
      newPlainTextValue,
      mentions
    ) => {
      setFieldsValue({
        description: newPlainTextValue
      })
      setDescriptionValue(newValue)
    }

    const handleContactMention = (id, display) => {
      const selectedContacts = getFieldValue('contacts')
      if (!selectedContacts.includes(id)) {
        setFieldsValue({
          contacts: [...selectedContacts, id]
        })
      }
    }

    const draggerProps = {
      multiple: true,
      onRemove: file => {
        setFieldsValue({
          fileName: '',
          extension: ''
        })
      },
      beforeUpload: file => {
        if (this.state.filesCount < 2) {
          const fileParts = getFileParts(file.name)
          setFieldsValue({
            fileName: fileParts.name,
            extension: fileParts.extension
          })
        }

        // this is for preventing the upload action, so we can customize the upload flow and upload to S3 later when the form is saved
        return false
      }
    }

    const handleFolderSelect = path => {
      this.setState({ path })
    }

    const checkPrivateDestination = () => {
      if (
        !!privateFolder?.password &&
        !!privateFolderPassword &&
        localStorage.getItem('privateFolderPassword') !==
          privateFolderPassword &&
        this.state.path.slice(0, privateFolder?.path.length) ===
          privateFolder?.path
      ) {
        this.setState({
          isUploadToPrivateFolder: true,
          fileDirectoryModalVisibile: false,
          passwordFolderModalVisible: true
        })
      } else {
        handleOk()
      }
    }

    const closePasswordModal = () => {
      this.setState({ passwordFolderModalVisible: false })
    }

    const handleOk = () => {
      setFieldsValue({
        path: this.state.path,
        // set value for 'file' field to make sure it will be re-validated with the new path
        // (rule: no same file name in a folder)
        file: getFieldValue('file')
      })
      this.setState({ fileDirectoryModalVisibile: false })
    }

    return (
      <>
        <Form className="upload-form">
          <div className="tour-file-upload">
            <FormItem label={t('FILE')}>
              {getFieldDecorator('file', {
                valuePropName: 'fileList',
                getValueFromEvent: this.normFile,
                rules: [
                  {
                    required: true,
                    message: t('SELECT_FILE_TO_UPLOAD_MSG')
                  },
                  {
                    validator: (rule, value, callback) => {
                      const destination = folderKey || this.state.path || ''
                      const uploadedFileNames = value.map(v => v.name)
                      const existingFiles = (
                        isShowPending
                          ? [...activeFiles, ...pendingFiles]
                          : activeFiles
                      )
                        .filter(
                          af =>
                            uploadedFileNames.includes(af.fileName) &&
                            af.path === destination
                        )
                        .map(file => file.fileName)

                      validateFilenames(rule, existingFiles, callback)
                    }
                  },
                  {
                    validator: (rule, value, callback) => {
                      const filesExceedLimit = value.filter(
                        v => v.size > MAX_FILE_SIZE
                      )

                      if (filesExceedLimit?.length) {
                        callback(
                          `${t('FILE_RECORDS')} (${filesExceedLimit
                            .map(f => f.name)
                            .join(', ')}) ${t(
                            'CANT_BE_ENCRYPTED_AND_UPLOADED'
                          )}. ${t('FILE_SIZE_LIMIT_IS')} ${formatBytes(
                            MAX_FILE_SIZE
                          )}.`
                        )
                      } else {
                        callback()
                      }
                    }
                  }
                ]
              })(
                <Dragger
                  {...draggerProps}
                  onChange={e => {
                    if (e.fileList.length === 1) {
                      const fileParts = getFileParts(e.fileList[0].name)
                      setFieldsValue({
                        fileName: fileParts.name,
                        extension: fileParts.extension
                      })
                    } else {
                      setFieldsValue({
                        fileName: '',
                        extension: ''
                      })
                    }
                    this.setState({ filesCount: e.fileList.length })
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="upload" />
                  </p>
                  <p className="ant-upload-hint">
                    {t('UPLOAD_FILE_INSTRUCTION')}
                  </p>
                </Dragger>
              )}
            </FormItem>
          </div>
          <div className="tour-file-info">
            {getFieldDecorator('fileName')(<TextInput hidden />)}

            {getFieldDecorator('extension')(<TextInput hidden />)}

            {folderKey === undefined && (
              <FormItem
                label={
                  <span>
                    <Trans i18nKey="SAVE_TO_VAULTBOX_FOLDER"></Trans>
                  </span>
                }
              >
                {getFieldDecorator('path')(
                  <TextInput
                    readOnly={true}
                    style={{ width: 300, marginRight: 8 }}
                  />
                )}
                <Button
                  onClick={() =>
                    this.setState({ fileDirectoryModalVisibile: true })
                  }
                  size="large"
                >
                  {t('BROWSE')}
                </Button>
              </FormItem>
            )}
            <FormItem label={t('DESCRIPTION_COL')}>
              <MentionsInput
                value={descriptionValue}
                onChange={handleDescriptionChange}
                onBlur={e =>
                  setDescriptionValue(sanitizeValue(e.target.value).trim())
                }
                allowSpaceInQuery={true}
                className="mentions"
                placeholder={t('INPUT_SHORT_DESCRIPTION_MSG')}
                maxLength={2000}
              >
                <Mention
                  className="mentions__mention"
                  data={contactDataSource.map(record => ({
                    id: record._id,
                    display: record.name
                  }))}
                  onAdd={handleContactMention}
                />
              </MentionsInput>
              {getFieldDecorator('description')(
                <TextArea style={{ display: 'none' }} />
              )}
            </FormItem>
            <FormItem label={t('TAGS')}>
              <FileTags tags={tags} setTags={setTags} />
            </FormItem>
            <ContactSelect
              label={t('CONTACTS')}
              getFieldDecorator={getFieldDecorator}
              fieldName="contacts"
              initialValue={[]}
              onDeselect={handleContactDeselect}
              mode="multiple"
              onAddComplete={contactId =>
                setFieldsValue({
                  contacts: [...(getFieldValue('contacts') || []), contactId]
                })
              }
            />
            <AssetLiabilitySelect
              label={t('ASSETS_LIABILITIES')}
              placeholder={t('SELECT_ASSETS_LIABILITIES')}
              required={false}
              getFieldDecorator={getFieldDecorator}
              fieldName="assetsLiabilities"
              initialValue={[]}
              mode="multiple"
              onAddComplete={alId =>
                setFieldsValue({
                  assetsLiabilities: [
                    ...(getFieldValue('assetsLiabilities') || []),
                    alId
                  ]
                })
              }
            />
            <EventSelect
              label={t('EVENT')}
              getFieldDecorator={getFieldDecorator}
              fieldName="events"
              linkedEvents={[]}
              fetchEvents={() => {}}
              setLinkedEvents={() => {}}
              initialValue={[]}
              mode="multiple"
              onAddComplete={eventId =>
                setFieldsValue({
                  events: [...(getFieldValue('events') || []), eventId]
                })
              }
            />
            <PasswordSelect
              label={t('PASSWORDS')}
              placeholder={t('SELECT_PASSWORDS')}
              required={false}
              getFieldDecorator={getFieldDecorator}
              fieldName="passwords"
              initialValue={[]}
              mode="multiple"
              onAddComplete={passwordId =>
                setFieldsValue({
                  passwords: [...(getFieldValue('passwords') || []), passwordId]
                })
              }
            />
            <FormItem>
              {getFieldDecorator('uploadTime')(<TextInput hidden />)}
            </FormItem>
          </div>
          <Modal
            visible={this.state.fileDirectoryModalVisibile}
            title={t('SELECT_FOLDER')}
            onOk={checkPrivateDestination}
            onCancel={() =>
              this.setState({ fileDirectoryModalVisibile: false })
            }
            okButtonProps={{ disabled: !this.state.path }}
            width={348}
            maskClosable={false}
          >
            <FileDirectory onFolderSelect={handleFolderSelect} />
          </Modal>
        </Form>
        <PasswordFolderModal
          handleUploadFile={handleOk}
          visible={this.state.passwordFolderModalVisible}
          isUploadToPrivateFolder={this.state.isUploadToPrivateFolder}
          closePasswordModal={closePasswordModal}
        />
      </>
    )
  }
}

const WrappedUploadForm = Form.create({ name: 'upload' })(UploadForm)

export default withTranslation()(WrappedUploadForm)

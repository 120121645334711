import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Alert,
  Modal,
  message,
  Tooltip,
  Icon,
  Divider
} from 'antd'
import { withRouter } from 'react-router-dom'
//import { StringResources } from '../../share/StringResources'
import { CognitoUser } from 'amazon-cognito-identity-js'
import {
  getUserPool,
  getAuthenticationDetails,
  getUserAttributeValue
} from '../../lib/cognito'
import { AES, enc } from 'crypto-js'
import api, { setApiInterceptor, clearApiInterceptor } from '../../lib/api'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import { errorMessage, resetAccount } from '../../share/helpers'
import { P1, H3, Span1 } from '../override/Typography'
import SetupMFA from '../common/SetupMFA'
import MfaForm from '../common/MfaVerificationForm'
import { AUTH_FLOW, MFA_TYPES } from '../../share/Constants'
import ResetPhoneNumber from '../../components/pages/ResetPhoneNumber'
import { logAccessAttempt } from '../../share/logs'
import config from '../../config'
import { GAevent, GApageView } from '../../lib/ga'
import { onError } from '../../lib/sentry'
import {
  getCognitoUser,
  isValidSession,
  getUserSession
} from '../../lib/cognito'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import TextInput from './../common/TextInput'
import AuthFlowTypeForm from './SelectAuthFlowTypeForm'
import { useLocation } from 'react-router-dom'
// import { importAssetsLiabilities } from '../../lib/pouchDb'
// import { mappingRecords } from '../../share/helpers'
// import uuidv4 from 'uuid/v4'
import './login.scss'

const LOGIN_STEPS = {
  LOGIN: 'login',
  SETUP_MFA: 'setup_mfa',
  RESET_PHONE: 'reset_phone',
  SELECT_AUTH_FLOW: 'select_auth_flow',
  ...MFA_TYPES
}

let cognitoUser,
  authDetails,
  mfaSecretCode,
  callbacks,
  isCustomAuth,
  authenticationDetails,
  isSetupAuthFlow

// if user already logged in on another browser tab & has the valid session info,
// reload the current tab to have the states reflected correctly
const checkUserSession = () => {
  const user = getCognitoUser()
  if (!user) {
    return
  }

  getUserSession(user, (err, session) => {
    if (err || !isValidSession(session)) {
      return
    }

    if (!!localStorage.getItem(user.username)) {
      window.location.reload()
    }
  })
}

function LoginForm(props) {
  const { setIsAuthenticated, setUser, history, form } = props
  const { getFieldDecorator } = form
  const [errMsg, setErrMsg] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loginStep, setLoginStep] = useState(LOGIN_STEPS.LOGIN)
  const [currentStep, setCurrentStep] = useState(0)
  const [isResettingMfa, setIsResettingMFA] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)
  const [pendingCode, setPendingCode] = useState('')
  const [encryptKey, setEncryptKey] = useState('')
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const code = urlSearchParams.get('code')
  const key = urlSearchParams.get('key')
  const { t } = useTranslation()

  useEffect(() => {
    setPendingCode(code)
    setEncryptKey(key)
  }, [code, key])

  useEffect(() => {
    GApageView('login')

    window.addEventListener('focus', checkUserSession)
    return () => {
      window.removeEventListener('focus', checkUserSession)
    }
  }, [])

  useEffect(() => {
    if (errMsg) setIsSubmitting(false)
  }, [errMsg])

  const cognitoCallbacks = {
    onSuccess: function (result) {
      cognitoUser.getUserData(async (err, res) => {
        if (err) {
          setErrMsg(t('FAILE_TO_GET_USER_DATA'))
        } else {
          const bypassMFA = getUserAttributeValue(
            res.UserAttributes,
            'custom:bypass_mfa'
          )

          const pendingDataCode = getUserAttributeValue(
            res.UserAttributes,
            'custom:pending_code'
          )
          const encryptKey = getUserAttributeValue(
            res.UserAttributes,
            'custom:encrypt_key'
          )
          const oldUserId = getUserAttributeValue(
            res.UserAttributes,
            'custom:old_userid'
          )

          // Everytime logging in, MFA settings will be checked
          // If it does exist, redirect user to home page. Otherwise, show setup MFA pop-up
          setApiInterceptor(cognitoUser)

          // custom:old_userid only exist if the Cognito user migration trigger already fired
          // but the migration lambda (triggered via API) is not yet fired
          if (!!oldUserId) {
            const newUserId = res.Username

            try {
              // TODO: should separate this migrate endpoint into 3 smaller ones
              // (for DynamoDB, S3 & QLDB) so they can run in parallel and save time
              // (each will be a /migrate endpoint)
              await api.migrateUser(newUserId, { oldUserId })

              // log the user out, clear everything of the old session,
              // then redirect to show the instruction about migration
              // & let the user login again to re-configure the MFA if needed
              cognitoUser.signOut()
              clearApiInterceptor()
              localStorage.clear()
              setIsSubmitting(false)
              history.push('/account-migrated')
              return
            } catch (err) {
              onError(err)
              setErrMsg('Failed to migrate user')
            }
          }

          setAuthenticationUser(
            res,
            cognitoUser,
            async professionalDeputyId => {
              const resUser = await api.getUser(res.Username)
              const { rates, ratesLastUpdated } = resUser.data
              if (
                pendingDataCode &&
                encryptKey &&
                !rates &&
                !ratesLastUpdated
              ) {
                setPendingCode(pendingDataCode)
                setEncryptKey(encryptKey)
              }

              if (
                res?.UserMFASettingList?.includes(MFA_TYPES.TOTP) ||
                (config.env !== 'prod' && bypassMFA === 'true') ||
                isCustomAuth
              ) {
                await setMasterKey(res)
                if (isCustomAuth && isSetupAuthFlow) {
                  await api.updateAuthFlow(
                    JSON.stringify({
                      userId: cognitoUser.username,
                      currentFlow: AUTH_FLOW.CUSTOM_FLOW
                    })
                  )

                  await api.updateSetupMfaDate(
                    res.Username,
                    JSON.stringify({
                      setupMfaDate: Math.floor(new Date().getTime() / 1000)
                    })
                  )
                }

                setIsAuthenticated(true)
                logAccessAttempt(res.Username, res.UserAttributes, res.Username)
                isCustomAuth = false
                isSetupAuthFlow = false
                history.push(
                  professionalDeputyId
                    ? '/deputy'
                    : pendingDataCode &&
                      encryptKey &&
                      !rates &&
                      !ratesLastUpdated
                    ? `/?code=${pendingDataCode}&key=${encryptKey}`
                    : code && key
                    ? `/?code=${code}&key=${key}`
                    : '/'
                )
                setIsSubmitting(false)
              } else {
                cognitoUser.associateSoftwareToken(this)
              }
            }
          )
        }
      })
    },

    onFailure: function (err) {
      setErrMsg(errorMessage(err))
    },
    associateSecretCode: function (secretCode) {
      setIsSubmitting(false)
      // Show setup MFA modal (including generated QR and secret code)
      mfaSecretCode = secretCode
      setLoginStep(LOGIN_STEPS.SETUP_MFA)
    }
  }

  // Verify token filled in by user
  const verifySoftwareToken = token => {
    setIsSubmitting(true)
    setErrMsg('')

    cognitoUser.verifySoftwareToken(token, t('FRIENDLY_DEVICE_NAME'), {
      onSuccess: function (session) {
        // if input token is valid, then enable MFA for specific user, with SOFTWARE_TOKEN_MFA type
        cognitoUser.setUserMfaPreference(
          { Enabled: true, PreferredMfa: false },
          { Enabled: true, PreferredMfa: false },
          function (err, result) {
            if (err) {
              setErrMsg(t('FAILED_TO_SET_USER_MFA_PREFERENCE'))
            }
          }
        )

        cognitoUser.getUserData((err, res) => {
          if (err) {
            setErrMsg(t('FAILE_TO_GET_USER_DATA'))
          } else {
            setApiInterceptor(cognitoUser)
            setAuthenticationUser(res, cognitoUser, async () => {
              setCurrentStep(2)
              await setMasterKey(res)
              logAccessAttempt(res.Username, res.UserAttributes, res.Username)
            })
            api
              .updateSetupMfaDate(
                res.Username,
                JSON.stringify({
                  setupMfaDate: Math.floor(new Date().getTime() / 1000)
                })
              )
              .then(async res => {
                await api.updateAuthFlow(
                  JSON.stringify({
                    userId: cognitoUser.username,
                    currentFlow: AUTH_FLOW.MFA_FLOW
                  })
                )
                setIsSubmitting(false)
              })
              .catch(err => onError(err))
          }
        })
      },
      onFailure: err => {
        setErrMsg(errorMessage(err))
      }
    })
  }

  const setAuthenticationUser = (userData, cognitoUser, callback) => {
    api
      .getUser(userData.Username)
      .then(res => {
        if (res?.data.message) {
          throw Error(res.data.message)
        }

        if (res.data.isPendingRecovery) {
          Modal.confirm({
            title: t('PENDING_RECOVERY'),
            cancelText: t('RESET_ACCOUNT_NOW'),
            okText: t('WILL_WAIT'),
            icon: null,
            content: (
              <>
                <P1>{t('RECOVER_DATA_FIRST_CONTENT')}</P1>
                <P1>{t('RECOVER_DATA_SECOND_CONTENT')}</P1>
              </>
            ),
            onOk() {
              cognitoUser.signOut()
              clearApiInterceptor()
              localStorage.clear()
              setLoginStep(LOGIN_STEPS.LOGIN)
            },
            onCancel() {
              // Reset account confirmation modal
              Modal.confirm({
                title: t('RESET_ACCOUNT_CONFIRMATION'),
                content: t('RESET_ACCOUNT_MODAL_CONTENT'),
                async onOk() {
                  try {
                    await resetAccount(
                      userData,
                      authDetails.password,
                      cognitoUser,
                      setIsAuthenticated,
                      setUser,
                      history
                    )
                    message.success(t('SUCCESSFULLY_RESET_ACCOUNT'))
                  } catch (err) {
                    onError(err)
                    message.error(t('FAILED_TO_RESET_ACCOUNT'))
                  }
                },
                onCancel() {
                  cognitoUser.signOut()
                  clearApiInterceptor()
                  localStorage.clear()
                  setLoginStep(LOGIN_STEPS.LOGIN)
                }
              })
            }
          })
        } else {
          setUser(cognitoUser)
          callback(res.data.professionalDeputyId)
        }
      })
      .catch(err => {
        setErrMsg(errorMessage(err))
        onError(err)
      })
  }

  const setMasterKey = async userData => {
    const encryptedMasterKey = getUserAttributeValue(
      userData.UserAttributes,
      'custom:master_key'
    )
    const masterKey = AES.decrypt(
      encryptedMasterKey,
      authDetails.password
    ).toString(enc.Latin1)

    // Temporarily store master key in local storage, it will be cleared when user is signed out
    // TODO:
    // - use a more secured alternative to store this key (e.g. Cookie???)
    // - refactor the existing code for documents Pouch (don't need to set and get the key from db)
    const res = await api.getUser(userData.Username)
    const { extraKey } = res.data
    const encryptedKey = AES.encrypt(masterKey, extraKey).toString()
    localStorage.setItem(userData.Username, encryptedKey)
  }

  const resetMFA = e => {
    e.preventDefault()
    setIsResettingMFA(true)
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        callbacks = this
        // disable totp mfa
        cognitoUser.setUserMfaPreference(
          {
            PreferredMfa: false,
            Enabled: true
          },
          {
            PreferredMfa: false,
            Enabled: false
          },
          err => {
            if (err) onError(err)
            else {
              cognitoUser.getUserData((err, res) => {
                if (err) {
                  setErrMsg(t('FAILE_TO_GET_USER_DATA'))
                } else {
                  setApiInterceptor(cognitoUser)
                  setAuthenticationUser(
                    res,
                    cognitoUser,
                    async professionalDeputyId => {
                      // if totp method exists in user mfa setting list, redirect to homepage. Otherwise, open setup totp modal
                      if (res?.UserMFASettingList?.includes(MFA_TYPES.TOTP)) {
                        await setMasterKey(res)
                        setIsAuthenticated(true)
                        logAccessAttempt(
                          res.Username,
                          res.UserAttributes,
                          res.Username
                        )
                        history.push(professionalDeputyId ? '/deputy' : '/')
                        setIsSubmitting(false)
                      } else {
                        cognitoUser.associateSoftwareToken(callbacks)
                      }
                    }
                  )
                }
              })
            }
          }
        )
      },
      onFailure: function (err) {
        setErrMsg(errorMessage(err))
      },
      associateSecretCode: function (secretCode) {
        setIsSubmitting(false)
        // Show setup MFA modal (including generated QR and secret code)
        mfaSecretCode = secretCode
        setLoginStep(LOGIN_STEPS.SETUP_MFA)
      },
      selectMFAType: function () {
        cognitoUser.sendMFASelectionAnswer(LOGIN_STEPS.SMS, this)
      },
      mfaRequired: function () {
        callbacks = this
        setIsResettingMFA(false)
        setLoginStep(LOGIN_STEPS.SMS)
      }
    })
  }

  // Submit to login
  const handleSubmit = e => {
    setErrMsg('')
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      setIsSubmitting(true)
      setErrMsg('')

      authenticationDetails = getAuthenticationDetails(
        values.username,
        values.password
      )
      cognitoUser = new CognitoUser({
        Username: values.username,
        Pool: getUserPool()
      })

      authDetails = { ...values }
      try {
        const res = await api.checkAuthFlow(cognitoUser.username)
        if (res.data) {
          if (res.data === AUTH_FLOW.NEED_MIGRATION) {
            cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')
          }
          if (res.data === AUTH_FLOW.CUSTOM_FLOW) {
            cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
          }
          // default authentication flow type is 'USER_SRP_AUTH'
          // if not fall in one of the cases above
          authenticateUser(authenticationDetails)
        } else {
          setLoginStep(LOGIN_STEPS.SELECT_AUTH_FLOW)
          isSetupAuthFlow = true
        }
      } catch (err) {
        setErrMsg(t('INCORRECT_USERNAME_OR_PASSWORD'))
      }
    })
  }

  const authenticateUser = authenticationDetails => {
    return cognitoUser.authenticateUser(authenticationDetails, {
      ...cognitoCallbacks,

      selectMFAType: function () {
        cognitoUser.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },

      mfaRequired: function () {
        callbacks = cognitoCallbacks
        setIsSubmitting(false)
        setLoginStep(LOGIN_STEPS.SMS)
      },

      customChallenge: function () {
        callbacks = this
        setLoginStep(LOGIN_STEPS.EMAIL)
        setIsSubmitting(false)
        setIsResendEmailCode(false)
        isCustomAuth = true
      },

      // Once user setup MFA successfully, input TOTP step is required
      totpRequired: function () {
        callbacks = cognitoCallbacks
        setIsSubmitting(false)
        setLoginStep(LOGIN_STEPS.TOTP)
      }
    })
  }

  const resendEmailCode = () => {
    setIsResendEmailCode(true)
    authenticateUser(authenticationDetails)
  }

  return (
    <>
      {loginStep === LOGIN_STEPS.LOGIN && (
        <Form
          onSubmit={handleSubmit}
          className="login-form"
          layout="vertical"
          hideRequiredMark={true}
        >
          <div className="form-header">
            <H3>{t('SIGN_IN')}</H3>
            <P1>
              {t('WELL_COME_TO_VAULTBOX')} <b>vaultbox</b>
            </P1>
          </div>
          {errMsg && (
            <Alert
              message={errMsg}
              type="error"
              closable
              style={{ marginBottom: 16 }}
            />
          )}
          <FormItem label={t('EMAIL')}>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: t('INPUT_EMAIL_MSG')
                }
              ],
              normalize: value => {
                return value?.replace(/\s/g, '')
              }
            })(<TextInput placeholder="email@example.com" allowClear />)}
          </FormItem>
          <FormItem label={t('PASSWORD')}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: t('INPUT_PASSWORD_MSG') }]
            })(<Input.Password placeholder={t('PASSWORD')} maxLength={30} />)}
          </FormItem>
          <FormItem>
            <Span1>
              <a
                href="/terms-of-service"
                target="_blank"
                onClick={() => {
                  GAevent({
                    category: 'Login',
                    action: 'Clicked Terms of Service',
                    label: 'Form link'
                  })
                }}
              >
                {t('TERMS_OF_SERVICE')}
              </a>
            </Span1>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              block
              style={{ margin: '10px 0' }}
              loading={isSubmitting}
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Sign in'
                })
              }}
            >
              {t('SIGN_IN')}
            </Button>
            <Button
              className="login-form-forgot"
              type="link"
              //href="/resetpassword"
              style={{ padding: 0 }}
              onClick={() => {
                GAevent({
                  category: 'Login',
                  action: 'Clicked Forgot password'
                })
              }}
            >
              <Link to="/resetpassword">{t('FORGOT_PASSWORD')}</Link>
            </Button>
            <Divider>{t('OR')}</Divider>
            <button
              type="button"
              onClick={() => {
                window.location.href = `${config.cognito.COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${config.cognito.COGNITO_CALLBACK_URL}&response_type=TOKEN&client_id=${config.cognito.APP_CLIENT_ID}&scope=email openid phone profile`
              }}
              class="btn google-button socialButton-customizable"
            >
              <span>
                <svg
                  class="social-logo"
                  viewBox="0 0 256 262"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                >
                  <path
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    fill="#34A853"
                  ></path>
                  <path
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    fill="#EA4335"
                  ></path>
                </svg>
              </span>
              <span>{t('LOGIN_WITH_GOOGLE')}</span>
            </button>
            <button
              type="button"
              onClick={() => {
                window.location.href = `${config.cognito.COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Facebook&redirect_uri=${config.cognito.COGNITO_CALLBACK_URL}&response_type=TOKEN&client_id=${config.cognito.APP_CLIENT_ID}&scope=email openid phone profile`
              }}
              class="btn facebook-button socialButton-customizable"
            >
              <span>
                <svg
                  class="social-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 216 216"
                  color="#ffffff"
                >
                  <path
                    fill="#ffffff"
                    d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"
                  ></path>
                </svg>
              </span>
              <span>{t('LOGIN_WITH_FACEBOOK')}</span>
            </button>
          </FormItem>
        </Form>
      )}

      {loginStep === LOGIN_STEPS.SETUP_MFA && (
        <div style={{ marginBottom: 'auto', padding: '30px 0' }}>
          <SetupMFA
            username={authDetails.username}
            secretCode={mfaSecretCode}
            errMsg={errMsg}
            setErrMsg={setErrMsg}
            isSubmitting={isSubmitting}
            verifySoftwareToken={verifySoftwareToken}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleCancel={() => {
              cognitoUser.signOut()
              mfaSecretCode = undefined
              clearApiInterceptor()
              setLoginStep(LOGIN_STEPS.LOGIN)
            }}
            handleFinish={() => {
              setIsAuthenticated(true)
              history.push(
                pendingCode && encryptKey
                  ? `/?code=${pendingCode}&key=${encryptKey}`
                  : '/'
              )
            }}
          />
        </div>
      )}

      {loginStep === LOGIN_STEPS.TOTP && (
        <MfaForm
          mfaType={loginStep}
          cognitoUser={cognitoUser}
          className="mfa-form"
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          callbacks={cognitoCallbacks}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          extra={
            <>
              <div className="mfa-helper">
                <Button
                  type="link"
                  style={{ padding: '0 0.5em 0 0' }}
                  onClick={resetMFA}
                  loading={isResettingMfa}
                >
                  {t('RESET_MFA')}
                </Button>
                <Tooltip
                  title={
                    <>
                      <div>{t('RESET_MFA_FIRST_CONTENT')}</div>
                      <div>{t('RESET_MFA_SECOND_CONTENT')}</div>
                    </>
                  }
                  overlayStyle={{ maxWidth: 300 }}
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </div>
              <div className="mfa-helper">
                <Button
                  type="link"
                  style={{ padding: '0 0.5em 0 0' }}
                  onClick={() => setLoginStep('reset_phone')}
                >
                  {t('RESET_MFA_WITH_PHONE_NUMBER')}
                </Button>
                <Tooltip
                  title={<div>{t('RESET_MFA_WITH_PHONE_NUMBER_CONTENT')}</div>}
                  overlayStyle={{ maxWidth: 300 }}
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </div>
            </>
          }
        />
      )}

      {loginStep === LOGIN_STEPS.SMS && (
        <MfaForm
          cognitoUser={cognitoUser}
          mfaType={loginStep}
          callbacks={callbacks}
          className="mfa-form"
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={resetMFA}
          isResending={isResettingMfa}
        />
      )}

      {loginStep === LOGIN_STEPS.EMAIL && (
        <MfaForm
          cognitoUser={cognitoUser}
          mfaType={loginStep}
          callbacks={callbacks}
          className="mfa-form"
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          isResending={isResendEmailCode}
          handleResend={resendEmailCode}
        />
      )}
      {loginStep === LOGIN_STEPS.RESET_PHONE && (
        <ResetPhoneNumber email={authDetails.username} />
      )}
      {loginStep === LOGIN_STEPS.SELECT_AUTH_FLOW && (
        <AuthFlowTypeForm
          className="mfa-form"
          cognitoUser={cognitoUser}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          authenticationDetails={authenticationDetails}
          authenticateUser={authenticateUser}
          handleCancel={e => {
            setLoginStep(LOGIN_STEPS.LOGIN)
            setIsSubmitting(false)
            setErrMsg('')
          }}
        />
      )}
    </>
  )
}

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm)
export default withRouter(WrappedLoginForm)

import api from '../lib/api'
import { getUserAttributeValue } from '../lib/cognito'
import { onError } from '../lib/sentry'

export async function logAccessAttempt(
  userId,
  userAttributes,
  accessedByUserId,
  isPrivateFolder
) {
  try {
    // const ipStackRes = await api.getIpStackInfo()
    const externalUser = localStorage.getItem('External_User')

    const accessAttemptData = {
      userId,
      email: externalUser
        ? userAttributes.find(a => a.Name === 'email')?.Value
        : getUserAttributeValue(userAttributes, 'email'),
      name: externalUser
        ? userAttributes.find(a => a.Name === 'custom:full_name')?.Value
        : getUserAttributeValue(userAttributes, 'custom:full_name'),
      accessedByUserId,
      ipStack: {},
      isPrivateFolder
    }

    await api.logAccessAttempt(accessAttemptData)
  } catch (err) {
    onError(err)
  }
}

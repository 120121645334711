import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'

const initialState = {
  events: [],
  deletedEvents: [],
  activeEvents: [],
  pendingEvents: [],
  rejectedEvents: [],
  eventTags: [],
  pendingEventTags: [],
  isLoading: false,
  isPendingLoading: false,
  error: null,
  pendingError: null
}

const otherEvents = createSlice({
  name: 'events',
  initialState,
  reducers: {
    getOtherEventsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherEventsSuccess(state, action) {
      const { events } = action.payload
      state.events = events
      state.deletedEvents = events.filter(item => item.deleted)
      state.activeEvents = events.filter(item => !item.deleted)
      state.eventTags = events
        .filter(event => !event.deleted && !!event.tags?.length)
        .map(event => event.tags)
      state.isLoading = false
      state.error = null
    },
    getOtherEventsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getOtherPendingEventsStart(state) {
      state.isPendingLoading = true
      state.pendingError = null
    },
    getOtherPendingEventsSuccess(state, action) {
      const { events } = action.payload
      state.pendingEvents = events.filter(c => c.status === 'Draft')
      state.rejectedEvents = events.filter(c => c.status === 'Rejected')
      state.pendingEventTags = events
        .filter(event => event.status === 'Draft' && !!event.tags?.length)
        .map(event => event.tags)
      state.isPendingLoading = false
      state.pendingError = null
    },
    getOtherPendingEventsFailure(state, action) {
      state.isPendingLoading = false
      state.pendingError = action.payload
    }
  }
})

export const {
  getOtherEventsStart,
  getOtherEventsSuccess,
  getOtherEventsFailure,
  getOtherPendingEventsStart,
  getOtherPendingEventsSuccess,
  getOtherPendingEventsFailure
} = otherEvents.actions

export default otherEvents.reducer

export const fetchOtherEvents = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherEventsStart())
    let events = []
    const eventsFromPouchDB = await getRecords(userId, 'events', masterKey) || []
    const eventsFromDynamoDB = await api.getEvents(userId) || []
    if (eventsFromDynamoDB.data?.events) {
      events = eventsFromPouchDB
        .map(event => {
          const results = eventsFromDynamoDB.data.events.filter(
            e => e.id === event._id
          )
          return results.map(e => {
            return {
              ...event,
              ...e
            }
          })
        })
        .flat()
    }
    dispatch(getOtherEventsSuccess({ events }))
  } catch (err) {
    onError(err)
    dispatch(getOtherEventsFailure(err.toString()))
  }
}

export const fetchOtherPendingEvents = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherPendingEventsStart())
    let events = []
    const eventsFromPouchDB = await getRecords(userId, 'pendingEvents', masterKey) || []
    const eventsFromDynamoDB = await api.getEvents(userId) || []
    if (eventsFromDynamoDB.data?.events) {
      events = eventsFromPouchDB
        .map(event => {
          const results = eventsFromDynamoDB.data.events.filter(
            e => e.id === event._id
          )
          return results.map(e => {
            return {
              ...event,
              ...e
            }
          })
        })
        .flat()
    }
    dispatch(getOtherPendingEventsSuccess({ events }))
  } catch (err) {
    onError(err)
    dispatch(getOtherPendingEventsFailure(err.toString()))
  }
}

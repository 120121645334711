import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'

const initialState = {
  events: [],
  eventsFromPouchDB: [],
  pendingEventsFromPouchDB: [],
  deletedEvents: [],
  activeEvents: [],
  pendingEvents: [],
  rejectedEvents: [],
  eventTags: [],
  pendingEventTags: [],
  isLoading: false,
  isPendingLoading: false,
  error: null,
  pendingError: null
}

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    getEventsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getEventsSuccess(state, action) {
      const { events, eventsFromPouchDB } = action.payload
      state.eventsFromPouchDB = eventsFromPouchDB
      state.events = events
      state.deletedEvents = events.filter(item => item.deleted)
      state.activeEvents = events.filter(item => !item.deleted)
      state.eventTags = events
        .filter(event => !event.deleted && !!event.tags?.length)
        .map(event => event.tags)
      state.isLoading = false
      state.error = null
    },
    getEventsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getPendingEventsStart(state) {
      state.isPendingLoading = true
      state.pendingError = null
    },
    getPendingEventsSuccess(state, action) {
      const { events, eventsFromPouchDB } = action.payload
      state.pendingEventsFromPouchDB = eventsFromPouchDB
      state.pendingEvents = events.filter(c => c.status === 'Draft')
      state.rejectedEvents = events.filter(c => c.status === 'Rejected')
      state.pendingEventTags = events
        .filter(event => event.status === 'Draft' && !!event.tags?.length)
        .map(event => event.tags)
      state.isPendingLoading = false
      state.pendingError = null
    },
    getPendingEventsFailure(state, action) {
      state.isPendingLoading = false
      state.pendingError = action.payload
    }
  }
})

export const {
  getEventsStart,
  getEventsSuccess,
  getEventsFailure,
  getPendingEventsStart,
  getPendingEventsSuccess,
  getPendingEventsFailure
} = events.actions

export default events.reducer

export const fetchEvents = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getEventsStart())
    let events = []
    const eventsFromPouchDB =
      (await getRecords(userId, 'events', masterKey)) || []
    const eventsFromDynamoDB = await api.getEvents(userId) || []
    if (eventsFromDynamoDB.data?.events) {
      events = eventsFromPouchDB
        .map(event => {
          const results = eventsFromDynamoDB.data.events.filter(
            e => e.id === event._id
          )
          return results.map(e => {
            return {
              ...event,
              ...e
            }
          })
        })
        .flat()
    }
    dispatch(getEventsSuccess({ events, eventsFromPouchDB }))
  } catch (err) {
    onError(err)
    dispatch(getEventsFailure(err.toString()))
  }
}

export const fetchPendingEvents = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getPendingEventsStart())
    let events = []
    const eventsFromPouchDB =
      (await getRecords(userId, 'pendingEvents', masterKey)) || []
    const eventsFromDynamoDB = await api.getEvents(userId) || []
    if (eventsFromDynamoDB.data?.events) {
      events = eventsFromPouchDB
        .map(event => {
          const results = eventsFromDynamoDB.data.events.filter(
            e => e.id === event._id
          )
          return results.map(e => {
            return {
              ...event,
              ...e
            }
          })
        })
        .flat()
    }
    dispatch(getPendingEventsSuccess({ events, eventsFromPouchDB }))
  } catch (err) {
    onError(err)
    dispatch(getPendingEventsFailure(err.toString()))
  }
}

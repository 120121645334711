import React, { useState, useEffect, useContext } from 'react'
import { Tree } from 'antd'
import { useSelector } from 'react-redux'
import AuthContext from '../../contexts/AuthContext'
import { ACCESS_LEVEL } from './../../share/Constants'
import VaultContext from '../../contexts/VaultContext'

const { TreeNode, DirectoryTree } = Tree

export default function FileDirectory(props) {
  const { isReadonly } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)

  const { onFolderSelect } = props
  const [folders, setFolders] = useState([])
  const { accessLevel } = useSelector(state => state.settings)
  const { activeFolders, pendingFolders } = useSelector(state =>
    isProfessionalDeputy || (isDelegateByPD && isReadonly)
      ? state.otherDocuments
      : state.documents
  )

  useEffect(() => {
    const updateFoldersFromKeys = (key, keys, folders, level = 1) => {
      let folderIndex = folders.findIndex(f => f.name === keys[0])

      if (folderIndex === -1) {
        folderIndex =
          folders.push({
            name: keys[0],
            key,
            level,
            folders: []
          }) - 1
      }

      if (keys.length > 1) {
        updateFoldersFromKeys(
          key,
          keys.slice(1),
          folders[folderIndex].folders,
          level + 1
        )
      }
    }

    // TODO: add loading state
    const allFolders = (
      (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
      accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ? [...activeFolders, ...pendingFolders]
        : activeFolders
    ).map(folder => {
      return {
        ...folder,
        isPrivate: folder.isPrivate,
        password: folder.password
      }
    })

    const privatePaths = []
    allFolders.forEach(folder => {
      if (folder.isPrivate) {
        privatePaths.push(folder.path)
      }
    })

    const removeFolders = []
    privatePaths.forEach(path => {
      allFolders.forEach(folder => {
        if (
          folder.path.length >= path.length &&
          folder.path.slice(0, path.length) === path
        ) {
          removeFolders.push(folder)
        }
      })
    })

    const lstFolders = isReadonly
      ? allFolders.filter(folder => !removeFolders.includes(folder))
      : allFolders

    const updatedFolders = []
    lstFolders
      .map(f => f.path)
      .sort((a, b) => a.localeCompare(b))
      .forEach(folder => {
        const keys = folder.split('/').filter(s => s !== '')

        updateFoldersFromKeys(folder, keys, updatedFolders)
      })

    setFolders(updatedFolders)
  }, [
    activeFolders,
    pendingFolders,
    accessLevel,
    isProfessionalDeputy,
    isReadonly,
    isDelegateByPD
  ])

  const onSelect = keys => {
    onFolderSelect(keys[0])
  }

  const renderTreeNodes = (nodes, parentIndex) => {
    return nodes.map((node, index) => {
      const currentIndex =
        parentIndex !== undefined ? parentIndex + '-' + index : index

      return (
        <TreeNode title={node.name} key={node.key}>
          {node.folders.length && renderTreeNodes(node.folders, currentIndex)}
        </TreeNode>
      )
    })
  }

  return (
    <DirectoryTree
      defaultExpandAll
      onSelect={onSelect}
      expandAction="doubleClick"
      className="file-directory"
    >
      {renderTreeNodes(folders)}
    </DirectoryTree>
  )
}
